import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { SelectState } from './PlacementsForm';
import { CustomSelectMenu } from './CustomSelectMenu';
import { CustomSelectOption } from './CustomSelectOption';

export interface PlacementSelectProps {
    index: number;
    disabled: boolean;
    setLevelState: (state: SelectState) => void;
    levelState: SelectState;
    filtedOptions: (search: string, parent: number | null) => Promise<any[]>;
    placementLevels: any[];
    onChange: (value: any) => void;
    onEdit: (placement: any) => void;
    placeholder: string;
}

export const PlacementSelect: React.FC<PlacementSelectProps> = ({
    index,
    disabled,
    setLevelState,
    levelState,
    filtedOptions,
    placementLevels,
    onChange,
    onEdit,
    placeholder,
}) => {
    const parentIndex = index - 1;
    const parent = (placementLevels && placementLevels[parentIndex]) || null;
    const parentValue = parent ? parent.value : null;

    const value = (placementLevels && placementLevels[index]) || null;
    const isDisabled = disabled || (index !== 0 && !parent);

    const onFocus = useCallback(() => {
        setLevelState({
            defaultOptions: [],
            isLoading: true,
        });

        filtedOptions('', parentValue).then((data: any[]) => {
            setLevelState({
                defaultOptions: data,
                isLoading: undefined,
            });
        });
    }, [parentValue, setLevelState, filtedOptions]);

    const loadOptions = useCallback(
        (inputValue: string) => {
            return filtedOptions(inputValue, parentValue);
        },
        [filtedOptions, parentValue]
    );

    const onChangeValue = useCallback(
        (e: any) => {
            onChange(e);
        },
        [onChange]
    );

    return (
        <div className='flex-grow-1 p-1'>
            <Form.Group>
                <AsyncSelect
                    className='react-select-container'
                    classNamePrefix='react-select' // cacheOptions
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    menuPortalTarget={document.body}
                    onFocus={onFocus}
                    defaultOptions={levelState.defaultOptions}
                    components={{
                        Menu: CustomSelectMenu,
                        Option: (optionProps) => (
                            <CustomSelectOption
                                {...optionProps}
                                onEdit={onEdit}
                            />
                        ),
                    }}
                    loadOptions={loadOptions}
                    value={value}
                    onChange={onChangeValue}
                    // @ts-ignore
                    parent={parentValue}
                    isClearable
                />
            </Form.Group>
        </div>
    );
};
