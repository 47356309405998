import React, { useMemo } from 'react';
import { Stack, Card } from 'react-bootstrap';
import { Column, SortingRule } from 'react-table';
import { useTranslation } from 'react-i18next';
import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import { Table } from '../../../../components/Table';
import { ITableAction } from '../../../../hooks/useTableReducer';
import IProductEnvironmentData from '../../../../types/IProductEnvironmentDataType';
import IProjectType from '../../../../types/IProjectType';
import { CoverImage } from '../../../../components/CoverImage';
import { IImageMediaGroup } from '../../../../types/IMediaType';
import { useWindowSize } from '../../../Organisation/hooks/useWindowSize';
import { useTableSelectedProductEnvironmentData } from '../SelectedProductEnvironmentDataContext';
import { useCategories } from '../../../../routing/productsPrivateRoutes';
import getCategoryTree from '../../../../utils/get-category-tree';

interface Props {
    productEnvironmentData:
        | ResourcePermissionsType<IProductEnvironmentData>[]
        | IProductEnvironmentData[];
    queryPageIndex?: number;
    queryPageSize?: number;
    sortBy?: SortingRule<string>;
    totalCount?: number;
    dispatch?: React.Dispatch<ITableAction>;
    loading?: boolean;
    project?: ResourcePermissionsType<IProjectType>;
}

export const ProductEnvironmentDataListView = ({
    project,
    productEnvironmentData,
    queryPageIndex,
    queryPageSize,
    sortBy,
    totalCount,
    dispatch,
    loading,
}: Props) => {
    const { t } = useTranslation();

    const { setSelectedItems } = useTableSelectedProductEnvironmentData();
    const { categories } = useCategories();

    const computeHiddenColumns = () => {
        if (project?.fourthPlacementTitle) return [];
        if (project?.thirdPlacementTitle) return ['fourthPlacementValue'];
        if (project?.secondPlacementTitle)
            return ['thirdPlacementValue', 'fourthPlacementValue'];
        if (project?.firstPlacementTitle)
            return [
                'secondPlacementValue',
                'thirdPlacementValue',
                'fourthPlacementValue',
            ];

        return [
            'firstPlacementValue',
            'secondPlacementValue',
            'thirdPlacementValue',
            'fourthPlacementValue',
        ];
    };

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns =
        width && width < 1290
            ? [
                  'waste_code',
                  'categories.name',
                  'secondPlacementValue',
                  'thirdPlacementValue',
                  'fourthPlacementValue',
              ]
            : [computeHiddenColumns()];

    const columns = useMemo<
        Column<ResourcePermissionsType<IProductEnvironmentData>>[]
    >(
        () => [
            {
                Header: '',
                id: 'productEnvironmentData_images',
                accessor: (productEnvironmentData: IProductEnvironmentData) => (
                    <div
                        style={{
                            width: '80px',
                            margin: 'auto',
                        }}
                    >
                        <CoverImage
                            images={
                                productEnvironmentData?.product
                                    ?.product_images as IImageMediaGroup
                            }
                            lightbox
                        />
                    </div>
                ),
            },
            {
                Header: t('Name') as string,
                id: 'products.title',
                canSort: true,
                accessor: (productEnvironmentData: IProductEnvironmentData) => (
                    <Stack>
                        <div className='fw-bold'>
                            {productEnvironmentData.product?.title}
                        </div>
                        <div>
                            {productEnvironmentData.product?.barcode &&
                                t('Product-ID') +
                                    ': ' +
                                    productEnvironmentData.product?.barcode}
                        </div>
                    </Stack>
                ),
            },
            {
                Header: t('Category') as string,
                id: 'categories.name',
                canSort: true,
                accessor: (productEnvironmentData: IProductEnvironmentData) => (
                    <Stack>
                        <div>
                            {productEnvironmentData?.product?.category?.name}
                        </div>
                    </Stack>
                ),
            },
            {
                Header: t('Quantity') as string,
                id: 'quantity',
                canSort: true,
                accessor: (productEnvironmentData: IProductEnvironmentData) => (
                    <Stack>
                        <div>
                            {productEnvironmentData?.quantity}{' '}
                            {
                                productEnvironmentData.product?.category?.unit
                                    ?.symbol
                            }
                        </div>
                    </Stack>
                ),
            },
            {
                Header: t('Hazardous substance') as string,
                id: 'substance',
                canSort: true,
                accessor: (row: IProductEnvironmentData) => (
                    <span>{row?.substance}</span>
                ),
            },
            {
                Header: t('Material') as string,
                id: 'material',
                canSort: true,
                accessor: (row: IProductEnvironmentData) => (
                    <div
                        style={{
                            overflow: 'hidden',
                            width: 150,
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {row?.material}
                        </span>
                    </div>
                ),
            },
            {
                Header: t('Waste code') as string,
                id: 'waste_code',
                canSort: true,
                accessor: (row: IProductEnvironmentData) => (
                    <span>{row?.waste_code}</span>
                ),
            },
            {
                Header: t('Is hazardous') as string,
                id: 'is_hazardous',
                canSort: true,
                accessor: (row: IProductEnvironmentData) =>
                    t(row?.is_hazardous ? 'Yes' : 'No'),
            },
            {
                Header: t('Cost') as string,
                id: 'cost',
                canSort: true,
                accessor: (row: IProductEnvironmentData) => (
                    <span>{row.cost}</span>
                ),
            },
            {
                Header: t('Weight') as string,
                id: 'products.weight',
                canSort: true,
                accessor: (row: IProductEnvironmentData) => (
                    <span>
                        {row.product?.weight
                            ? `${row.product.weight} ${row.product?.weight_unit?.symbol}`
                            : ''}
                    </span>
                ),
            },
            /*
            {
                Header: t('Sampling, protocol') as string,
                id: 'protocol',
                canSort: true,
                accessor: (row: IProductEnvironmentData) => (
                    <span>{row.protocol}</span>
                ),
            },
            */
            {
                Header: project?.firstPlacementTitle || '',
                id: 'firstPlacementValue',
                accessor: (productEnvironmentData: IProductEnvironmentData) => {
                    return (
                        <Stack>
                            <div>
                                {productEnvironmentData.firstPlacementValue}
                            </div>
                        </Stack>
                    );
                },
            },
            {
                Header: project?.secondPlacementTitle || '',
                id: 'secondPlacementValue',
                accessor: (productEnvironmentData: IProductEnvironmentData) => {
                    return (
                        <Stack>
                            <div>
                                {productEnvironmentData.secondPlacementValue}
                            </div>
                        </Stack>
                    );
                },
            },
            {
                Header: project?.thirdPlacementTitle || '',
                id: 'thirdPlacementValue',
                accessor: (productEnvironmentData: IProductEnvironmentData) => {
                    return (
                        <Stack>
                            <div>
                                {productEnvironmentData.thirdPlacementValue}
                            </div>
                        </Stack>
                    );
                },
            },
            {
                Header: project?.fourthPlacementTitle || '',
                id: 'fourthPlacementValue',
                accessor: (productEnvironmentData: IProductEnvironmentData) => {
                    return (
                        <Stack>
                            <div>
                                {productEnvironmentData.fourthPlacementValue}
                            </div>
                        </Stack>
                    );
                },
            },
        ],
        [t, project]
    );

    return (
        <Card className='shadow rounded-0'>
            <Table<ResourcePermissionsType<IProductEnvironmentData>>
                name={'productEnvironmentData'}
                columns={columns}
                data={
                    productEnvironmentData as ResourcePermissionsType<IProductEnvironmentData>[]
                }
                total={totalCount}
                initialPageSize={queryPageSize || 0}
                initialPageIndex={queryPageIndex || 0}
                initialSortBy={sortBy || { id: 'products.title', desc: false }}
                hiddenColumns={hiddenColumns}
                loading={loading}
                dispatch={dispatch || (() => {})}
                selectHooks={true}
                setSelectedItems={setSelectedItems}
                getCellTooltip={(cell: any) => {
                    const cellId = cell.column.id;
                    if (cellId === 'categories.name' && categories) {
                        const { category } = cell?.row?.original?.product || {};

                        return (
                            getCategoryTree(category, categories)
                                .map(({ name }) => name)
                                .join(' > ') || null
                        );
                    } else if (cellId === 'material') {
                        return cell?.row?.original?.material;
                    }
                }}
            />
        </Card>
    );
};
