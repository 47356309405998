import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganisationFormState } from '../OrganisationFormState';

interface Props {}

export const ContactPersonPreview = (props: Props) => {
    const { t } = useTranslation();
    const organisation = useOrganisationFormState();
    const contactMeta = organisation?.organisation_meta?.find(
        (m) => m.meta_key === 'contactPerson'
    );

    return <div>{contactMeta?.meta_value ?? t('Please fill in')}</div>;
};
