import { faHeart, faHeartCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import IProduct from '../../../types/IProductType';

interface Props {
    size?: 'xs' | 'sm' | 'md' | 'lg';
    shadow?: 'shadow' | 'shadow-sm' | 'shadow-lg';
    product?: IProduct;
}

export const HeartProducts: React.FC<Props> = ({ product, size, shadow }) => {
    const fs = size === 'lg' ? 3 : size === 'md' ? 4 : size === 'xs' ? 6 : 5;

    const [hearted, setHearted] = useState<Boolean>(
        JSON.parse(
            localStorage.getItem('hearted-product-ids') || '[]'
        )?.includes(product?.id || -1)
    );

    useEffect(() => {
        setHearted(
            JSON.parse(
                localStorage.getItem('hearted-product-ids') || '[]'
            )?.includes(product?.id || -1)
        );
    }, [product]);

    if (!product) return <></>;

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        const heartedIds: Number[] = JSON.parse(
            localStorage.getItem('hearted-product-ids') || '[]'
        );
        let newHeartedIds: Number[];
        if (hearted)
            newHeartedIds = [...heartedIds.filter((n) => n !== product.id)];
        else newHeartedIds = [...heartedIds, product.id];

        setHearted((p) => !p);
        localStorage.setItem(
            'hearted-product-ids',
            JSON.stringify(newHeartedIds)
        );
    };
    return (
        <div className={`cursor-pointer fs-${fs}`} onClick={handleClick}>
            {hearted ? (
                <FontAwesomeIcon color='crimson' icon={faHeartCircleCheck} />
            ) : (
                <FontAwesomeIcon color='lightgrey' icon={faHeart} />
            )}
        </div>
    );
};
