import React, { useState } from 'react';
import SimpleReactLightbox, {
    SRLWrapper,
    useLightbox as useSRL,
} from 'simple-react-lightbox';
import { IImageMediaGroup, IImageMedia } from '../types/IMediaType';
import { imageSortFn } from '../utils/helper';

interface Props {}

interface ILightboxContext {
    showLightboxImages: (product: any, index?: number) => void;
    openLightbox: (index?: number) => void;
    closeLightbox: () => void;
}
export const LightboxContext = React.createContext<ILightboxContext>(
    {} as ILightboxContext
);
export const useLightbox = () => React.useContext(LightboxContext);

// Need to wrap SimpleReactLightbox around something or i cant use its hooks same time
export const SimpleReactLightboxExtended: React.FC<Props> = ({ children }) => (
    <SimpleReactLightbox>
        <SRLE>{children}</SRLE>
    </SimpleReactLightbox>
);

const SRLE: React.FC<Props> = ({ children }) => {
    const { openLightbox, closeLightbox } = useSRL();

    const [SRLElements, setSRLElements] = useState<any[]>([]);

    const showLightboxImages = (
        images?: IImageMediaGroup,
        index: number = 0
    ) => {
        setSRLElements(
            images?.medias?.sort(imageSortFn)?.map((img: IImageMedia) => ({
                src:
                    process.env.REACT_APP_API_ENDPOINT +
                    (img.url.large ||
                        img.url.medium ||
                        img.url.small ||
                        img.url.thumbnail) +
                    '?' +
                    Date.now(),

                caption: img.title || '',
            })) || []
        );
        openLightbox(index);
        setTimeout(() => openLightbox(index), 10); // Need to open after delay by tiny bit to hide the first placeholder image ('need to have this here or first open doesnt work' img tag down below)
    };

    const options = {
        settings: {
            overlayColor: '#014e5cfa',
        },
        caption: {
            captionFontSize: '1.2rem',
            captionContainerPadding: '0',
        },
    };

    return (
        <LightboxContext.Provider
            value={{
                openLightbox,
                closeLightbox,
                showLightboxImages,
            }}
        >
            {children}
            {SRLElements.length ? (
                <SRLWrapper elements={SRLElements} options={options} />
            ) : (
                <SRLWrapper
                    elements={[
                        {
                            src: 'need to have this here or first open doesnt work',
                        },
                    ]}
                />
            )}
        </LightboxContext.Provider>
    );
};
