import { useEffect, useState } from 'react';

import { useAuth } from './useAuth';
import { useIsAuth } from './useIsAuth';

export const useIsSysAdmin = () => {
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState<boolean>();

    const { isSysAdmin } = useAuth();

    const [loadingAuth, isAuth] = useIsAuth();

    useEffect(() => {
        const checkPermission = async () => {
            setLoading(true);

            if (loadingAuth) {
                setLoading(true);
                return;
            }

            if (isAuth) {
                // override as SysAdmin
                const hasSysAdmin = await isSysAdmin();
                if (hasSysAdmin) {
                    setAllowed(true);
                    setLoading(false);
                    return;
                } else {
                    setAllowed(false);
                    setLoading(false);
                    return;
                }
            } else {
                setAllowed(false);
                setLoading(false);
                return;
            }
        };

        checkPermission();
    }, [isAuth, isSysAdmin, loadingAuth]);

    return [loading, allowed];
};

export default useIsSysAdmin;
