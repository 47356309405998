import { Button, Card, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    show: boolean;
    onClose: (e?: any) => void;
}

export const CannotPublishToMarketplaceModal: React.FC<Props> = ({
    show,
    onClose,
}) => {
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('Could not publish to Marketplace')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Card style={{ backgroundColor: '#f5f5f5', border: 'none' }}>
                    <Card.Body>
                        <p>
                            {t(
                                'You have selected to show a product on Dacke.Online marketplace. The product will NOT be visible before all prerequisites below are met'
                            )}
                            :
                        </p>
                        <ul>
                            <li>
                                {t(
                                    'The product is required to have at least one picture that is visible on the marketplace'
                                )}
                            </li>
                            <li>{t('The product has an amount filled')}</li>
                            <li>
                                {t('The product is not preliminarily in stock')}
                            </li>
                            <li>
                                {t(
                                    'The product does not have the status "Avslutad" or "Kasserad"'
                                )}
                            </li>
                        </ul>
                        <p>
                            {t(
                                'Also keep in mind to fill in all known information, write a product description, state condition, price and as much useful product information as possible.'
                            )}
                        </p>
                        <p>
                            {t(
                                'We at Dacke.Online care that the ads on the marketplace are of high informational level and reserve the right to unpublish ads with lacking information or other quality issues.'
                            )}
                        </p>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>{t('Ok')}</Button>
            </Modal.Footer>
        </Modal>
    );
};
