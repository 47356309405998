import { useProductSearch } from '../components/ProductSearch';

export const sort = [
    {
        key: 'title',
        name: 'Name',
        direction: 'desc',
    },
    {
        key: 'title',
        name: 'Name',
        direction: 'asc',
    },
    {
        key: 'sales_price',
        name: 'Price',
        direction: 'desc',
    },
    {
        key: 'sales_price',
        name: 'Price',
        direction: 'asc',
    },
    {
        key: 'barcode',
        name: 'Barcode',
        direction: 'desc',
    },
    {
        key: 'barcode',
        name: 'Barcode',
        direction: 'asc',
    },
    {
        key: 'condition_points',
        name: 'Condition',
        direction: 'desc',
    },
    {
        key: 'condition_points',
        name: 'Condition',
        direction: 'asc',
    },
    {
        key: 'function_points',
        name: 'Function',
        direction: 'desc',
    },
    {
        key: 'function_points',
        name: 'Function',
        direction: 'asc',
    },
    {
        key: 'quantity',
        name: 'Quantity',
        direction: 'desc',
    },
    {
        key: 'quantity',
        name: 'Quantity',
        direction: 'asc',
    },
];

export const useOrderByQuery = () => {
    const { queryState, setQueryState } = useProductSearch();
    const order =
        sort.find(
            (s) =>
                s.direction === queryState.direction &&
                s.key === queryState.sortBy
        ) || sort[0];

    const setOrder = (_sort: any) => {
        setQueryState('sortBy', _sort.key);
        setQueryState('direction', _sort.direction);
    };

    return { order, setOrder };
};
