import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row, Col, Image, Ratio } from 'react-bootstrap';
import { IImageMediaGroup, IImageMedia } from '../types/IMediaType';
import { CoverImage } from './CoverImage';
import { useLightbox } from './SimpleReactLightboxExtended';
import { imageSortFn } from '../utils/helper';

interface PageSRLImageProps {
    images?: IImageMediaGroup | null;
    youtubeVideoUrl?: string;
    imageLimit?: number;
}

export const PageSRLImage: React.FC<PageSRLImageProps> = ({
    images,
    youtubeVideoUrl,
    imageLimit,
}) => {
    const { showLightboxImages } = useLightbox();

    // Converts a youtube URL to a youtube thumbnail URL
    const getThumbnailSrc = (youtubeLink: string) => {
        const pre = 'https://i.ytimg.com/vi/';
        const suf = '/hqdefault.jpg';

        // Extract video ID from youtube link
        var regExp =
            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = youtubeLink?.match(regExp);
        if (match && match[7].length === 11) return pre + match![7] + suf;

        return '';
    };

    return (
        <Col className='p-3 p-md-4'>
            <div
                onClick={() => showLightboxImages(images)}
                className='cursor-pointer'
            >
                <CoverImage
                    fullSized={true}
                    images={images as IImageMediaGroup}
                />
            </div>
            <Row>
                {youtubeVideoUrl && getThumbnailSrc(youtubeVideoUrl) && (
                    <Col xs={6} md={4} className={'pt-2 position-relative'}>
                        <a
                            href={youtubeVideoUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <Image
                                className='card-img'
                                src={getThumbnailSrc(youtubeVideoUrl)}
                                rounded
                            />
                            <FontAwesomeIcon
                                className='position-absolute pe-3 me-2 mt-1 end-0 text-light fs-3'
                                icon={faExternalLink}
                            />
                        </a>
                    </Col>
                )}
                {images &&
                    images.medias
                        .sort(imageSortFn)
                        .map((img: IImageMedia, index) => (!imageLimit || index < imageLimit) && (
                            <Col
                                key={index}
                                xs={6}
                                md={4}
                                className={index === 0 ? 'd-none' : 'pt-2'}
                            >
                                <Ratio
                                    aspectRatio={'4x3'}
                                    className='d-inline-block cursor-pointer'
                                >
                                    <Image
                                        src={
                                            process.env.REACT_APP_API_ENDPOINT +
                                            (img.url.large ||
                                                img.url.medium ||
                                                img.url.small ||
                                                img.url.thumbnail)
                                        }
                                        alt={img.title || ''}
                                        fluid
                                        style={{ objectFit: 'cover' }}
                                        onClick={() =>
                                            showLightboxImages(images, index)
                                        }
                                        rounded
                                    />
                                </Ratio>
                            </Col>
                        ))}
            </Row>
        </Col>
    );
};
