import React, { useCallback } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useProjectFormState } from '../ProjectFormState';
import IProjectType from '../../../../../types/IProjectType';

import regions from '../../../../../utils/se_regions';

interface Props {}

export const BasicInformationFrom = (props: Props) => {
    const { t } = useTranslation();

    const { setFormState, ...project } = useProjectFormState();

    const updateBasicInformation = useCallback(
        (active?) => {
            if (active !== undefined) {
                project.is_active = active;
            }
            const put = axios.put<IProjectType>(
                `/api/organisations/${project?.organisation_id}/projects/${project?.id}`,
                project
            );

            put.then((res) => {
                if (res.data) {
                    setFormState((prev) => ({
                        ...prev,
                        ...res.data,
                    }));
                }
            });
        },
        [project, setFormState]
    );

    const updateRegion = useCallback(
        (region) => {
            axios
                .put(
                    `/api/organisations/${project?.organisation_id}/projects/${project?.id}`,
                    { ...project, region }
                )
                .then((res) => {
                    if (res.data) {
                        setFormState((prev) => ({
                            ...prev,
                            region: res.data.data.region,
                        }));
                    }
                });
        },
        [project, setFormState]
    );

    const formControl = (
        key: keyof IProjectType,
        type: string,
        maxLength: number,
        className?: string
    ) => {
        return (
            <Form.Control
                isInvalid={key === 'title' && (project[key]?.length ?? 0) < 1}
                type={type}
                onChange={(e) => {
                    setFormState((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                    }));
                }}
                className={className ?? ''}
                value={project[key]?.toString() ?? ''}
                maxLength={maxLength}
                onBlur={() => updateBasicInformation()}
            />
        );
    };

    return (
        <Row>
            <Col sm={12} xl={12}>
                <Form>
                    <Row>
                        <Col xs={9} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    {t('Project name')}
                                    <Form.Text>
                                        {' (' +
                                            t('{{inputLength}}/50 characters', {
                                                inputLength:
                                                    project?.title?.length || 0,
                                            }) +
                                            ')'}
                                    </Form.Text>
                                </Form.Label>
                                {formControl('title', 'text', 50)}
                            </Form.Group>
                        </Col>
                        <Col xs={3}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Active')}</Form.Label>
                                <Form.Check
                                    style={{
                                        transform:
                                            'scale(1.5) translate(17%, 17%)',
                                        maxWidth: '100px', // needs to have a width to not scale out of containers
                                    }}
                                    type='switch'
                                    checked={!!project.is_active}
                                    onChange={(e) => {
                                        setFormState((prev) => ({
                                            ...prev,
                                            is_active: e.target.checked,
                                        }));
                                        updateBasicInformation(
                                            e.target.checked
                                        );
                                    }}
                                    color='success'
                                ></Form.Check>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Region')}</Form.Label>
                                <Form.Select
                                    value={project?.region || ''}
                                    onChange={(e) =>
                                        updateRegion(e.target.value)
                                    }
                                >
                                    <option value=''>-</option>
                                    {regions.map((s) => (
                                        <option key={s} value={s}>
                                            {s}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Start date')}</Form.Label>
                                {formControl('project_start', 'date', 100)}
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('End date')}</Form.Label>
                                {formControl('project_finish', 'date', 50)}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
