interface Props {
    title: string;
    show: boolean;
    className?: string;
}

export function FloatingProductNameHeader({ title, show, className }: Props) {
    const baseClasses = [
        show ? 'd-flex' : 'd-none',
        'd-sm-none',
        'w-100',
        'position-fixed',
        'flex-row',
        'justify-content-center',
        'gap-1',
        'bg-light',
        'py-2',
        'border-bottom',
    ];
    const classes = (
        className ? [...baseClasses, className.split(' ')] : baseClasses
    ).join(' ');

    return (
        <div
            className={classes}
            style={{ top: '66px', zIndex: 1200, marginLeft: -12 }}
        >
            <span>{title}</span>
        </div>
    );
}
