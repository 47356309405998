import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganisationFormState } from '../OrganisationFormState';

interface Props {}

export const BasicInformationPreview = (props: Props) => {
    const { t } = useTranslation();
    const organisation = useOrganisationFormState();

    return <div>{organisation?.name ?? t('Please fill in')}</div>;
};
