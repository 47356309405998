import React, { useCallback, useEffect, useState } from 'react';
import history from 'history/browser';
import { useThrottle } from '@react-hook/throttle';
import { useQuery } from '../hooks/useQuery';

const getUrl = () => `${window.location.origin}${window.location.pathname}`;

export type QueryState = {
    [key: string]: any;
};

export const findPropertyFilters = (queryState: QueryState) => {
    const propertyFilters: { [key: number]: any } = {};

    for (const [key, value] of Object.entries(queryState)) {
        if (key.startsWith('propertyFilters[')) {
            const propertyId = parseInt(key.split('[')[1].split(']')[0]);
            propertyFilters[propertyId] = value;
        }
    }
    return propertyFilters;
};

export const findProductMetaFilters = (queryState: QueryState) => {
    const meta_types: { [key: number]: any } = {};

    for (const [key, value] of Object.entries(queryState)) {
        if (key.startsWith('product_meta[')) {
            const metaTypeId = parseInt(key.split('[')[1].split(']')[0]);
            meta_types[metaTypeId] = value;
        }
    }
    return meta_types;
};

// Query state is always nice with [], + and other symbols
// window.location.search and queryString is always not nice with strange %#"=%23052305+" encodings for the symbols
//
// Note: queryString === window.location.search   (If they dont match, queryState will be updated ->>> which leads to an update so, queryString === window.location.search)
// Converts a QueryState object into a URL search
const urlToQueryState = () => {
    if (!window.location.search) return {};

    return Object.fromEntries(
        decodeURIComponent(window.location.search.substring(1) || '')
            .replaceAll('+', ' ')
            .split('&')
            .map((s) => s.split('='))
    );
};
// Reads the search of URL and converts it into a QueryState object
const queryStateToUrl = (qState: any) => {
    const urlQuery = new URLSearchParams();
    Object.keys(qState).forEach((q) => urlQuery.set(q, qState[q]));
    return urlQuery.toString();
};

interface IPRojectSearchContext {
    queryString: string;
    queryState: QueryState;
    setQueryState: (key: string, value: any) => void;
    clearAllFilters: () => void;
}

interface Props {}

export const ProductSearchContext = React.createContext<IPRojectSearchContext>(
    {} as IPRojectSearchContext
);

export const ProductSearch: React.FC<Props> = ({ children }) => {
    // Start with using the URL Query state (In case coming from a bookmark or other)
    const [queryState, setQueryState] = useState<QueryState>(urlToQueryState());
    const [queryString, setQueryString] = useThrottle<string>(
        queryStateToUrl(urlToQueryState()),
        2
    );

    const interceptSetQueryState = useCallback(
        (key: string, value: any) => {
            // Dont update anything if some child is trying to set
            // a key in our QueryState to the same value
            if (queryState[key] === value) return;

            // The user has changed a filter / search / category ...
            // Aka time for a new QueryState which leads to
            // a new queryString which is pushed in as a new Page
            setQueryState((prev) => {
                const newQueryState = { ...prev, [key]: value };

                // Delete the query key if value is not defined
                if (!value) delete newQueryState[key];

                // If category changes -> clear category specific filters
                if (key === 'category_id') {
                    Object.keys(newQueryState).forEach((k) => {
                        if (k.startsWith('propertyFilter'))
                            delete newQueryState[k];
                    });
                }

                // Tiny hax for handling sortby/direction
                // Because it comes in two calls and we dont want two new pages
                // Could make this cleaner by allowing update with an object of multiple query keyvals
                let pushNewPage = true;
                if (key === 'sortBy' && !newQueryState['direction'])
                    pushNewPage = false; // There will be a call with direction in 1 millisecond, we will push page then

                if (pushNewPage)
                    history.push(
                        `${getUrl()}?${queryStateToUrl(newQueryState)}`
                    );

                setQueryString(queryStateToUrl(newQueryState));
                return newQueryState;
            });
        },
        [setQueryString, queryState]
    );

    // Clear filters
    const clearAllFilters = useCallback(() => {
        setQueryString('');
        setQueryState({});
        history.push(`${getUrl()}?limit=10`); // Need something in the query or it will not realize our Query State is new
    }, [setQueryString, setQueryState]);

    // Is the user viewing a page that utilizes ProductSearch ?
    const viewingAProductsPage =
        window.location?.href?.endsWith('products') ||
        window.location?.href?.endsWith('products/');

    // Listen for URL Changes and update our query from that
    const routerUseQuery = useQuery(); // Needed so component rerenders if URL query changes
    useEffect(() => {
        // QueryState picked from the URL
        const urlQueryState = urlToQueryState();
        const urlQueryStateKeys = Object.keys(urlQueryState);

        // Our own QueryState
        const queryStateKeys = Object.keys(queryState);

        // If going to /products. This will fill in /products?sortby... (With cached filters)
        if (queryStateKeys.length && viewingAProductsPage)
            history.replace(`${getUrl()}?${queryStateToUrl(queryState)}`);

        // URL changed -> need to check if queryState matches
        // >Ex, If user navigates with Back/Forward button of the browser
        if (urlQueryStateKeys.length) {
            const urlAndQueryStateAreSame =
                queryStateKeys.length === urlQueryStateKeys.length &&
                queryStateKeys.every((k) => queryState[k] === urlQueryState[k]);

            if (!urlAndQueryStateAreSame) {
                // Our query state and URL aren't the same!!
                // Browser navigation detected -> Lets set our queryState to match the URL
                setQueryState(urlQueryState);
                setQueryString(queryStateToUrl(urlQueryState));
            }
        }
    }, [routerUseQuery, queryState, setQueryString, viewingAProductsPage]);

    // Clear category when browsing project products
    useEffect(() => {
        if (
            queryState.category_id &&
            viewingAProductsPage &&
            window.location.href.includes('projects')
        ) {
            interceptSetQueryState('category_id', '');
        }
    }, [
        routerUseQuery,
        queryState,
        interceptSetQueryState,
        viewingAProductsPage,
    ]);

    return (
        <ProductSearchContext.Provider
            value={{
                queryString,
                queryState,
                setQueryState: interceptSetQueryState,
                clearAllFilters,
            }}
        >
            {children}
        </ProductSearchContext.Provider>
    );
};

export const useProductSearch = () => React.useContext(ProductSearchContext);
