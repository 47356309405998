import React from 'react';
import { Button } from 'react-bootstrap';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

export const CustomSelectOption: React.FC<any> = ({
    children,
    onEdit,
    ...props
}) => {
    const { data } = props;

    function onClick(e: any) {
        e.stopPropagation();
        onEdit(data);
    }

    return (
        <components.Option {...props}>
            <div className='d-flex justify-content-between align-items-center'>
                {children}
                <Button variant='light' size='sm' onClick={(e) => onClick(e)}>
                    <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
            </div>
        </components.Option>
    );
};
