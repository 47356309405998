import { Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CardRow } from './CardRow';
import { CoverImage } from '../../../../components/CoverImage';
import { PageCountPicker } from '../../../../components/PageCountPicker';
import { useTableSelectedProductItems } from '../SelectedProductItemsContext';

import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import { IProductItem } from '../../../../types/IProductItem';
import IProjectType from '../../../../types/IProjectType';
import { IImageMediaGroup } from '../../../../types/IMediaType';
import {
    ITableAction,
    PAGE_SIZE_CHANGED,
} from '../../../../hooks/useTableReducer';

import './ProductItemsMobileView.scss';

const ProductItemMobileView = ({
    project,
    productItem,
    selected,
    toggle,
}: {
    project?: ResourcePermissionsType<IProjectType>;
    productItem: ResourcePermissionsType<IProductItem>;
    selected: boolean;
    toggle: () => void;
}) => {
    const { t } = useTranslation();
    const { product } = productItem;
    return (
        <div className='py-2 d-flex flex-column gap-1 px-1'>
            <Row>
                <Col xs={5} className='m-auto'>
                    <CoverImage
                        images={
                            productItem?.product
                                ?.product_images as IImageMediaGroup
                        }
                        lightbox
                    />
                </Col>
                <Col
                    xs={7}
                    className='d-flex flex-column justify-content-center'
                >
                    <Row>
                        <Col xs={9}>
                            <h5 className='fw-bold'>
                                {product?.title} ({product?.barcode})
                            </h5>
                        </Col>
                        <Col xs={3}>
                            <Form.Check
                                type='checkbox'
                                checked={selected}
                                onChange={toggle}
                            />
                        </Col>
                    </Row>
                    <CardRow
                        title={t('Category')}
                        value={product?.category?.name}
                    />
                    <CardRow
                        title={t('Quantity')}
                        value={`${productItem?.quantity} ${product?.category?.unit?.symbol}`}
                    />
                    <small>{productItem?.description}</small>

                    <CardRow
                        title={project?.firstPlacementTitle}
                        value={productItem?.firstPlacementValue}
                    />
                    <CardRow
                        title={project?.secondPlacementTitle}
                        value={productItem?.secondPlacementValue}
                    />
                    <CardRow
                        title={project?.thirdPlacementTitle}
                        value={productItem?.thirdPlacementValue}
                    />
                    <CardRow
                        title={project?.fourthPlacementTitle}
                        value={productItem?.fourthPlacementValue}
                    />
                </Col>
            </Row>
        </div>
    );
};

interface Props {
    project?: ResourcePermissionsType<IProjectType>;
    productItems: ResourcePermissionsType<IProductItem>[];
    queryPageSize?: number;
    totalCount?: number;
    dispatch?: React.Dispatch<ITableAction>;
}

export const ProductItemsMobileView = ({
    productItems,
    project,
    queryPageSize,
    totalCount,
    dispatch,
}: Props) => {
    const { selectedItems, setSelectedItems } = useTableSelectedProductItems();

    function toggle(productItem: ResourcePermissionsType<IProductItem>) {
        setSelectedItems((prev) => {
            if (prev.includes(productItem)) {
                return prev.filter((item) => item.id !== productItem.id);
            }
            return [...prev, productItem];
        });
    }

    return (
        <Stack gap={3}>
            <Stack id='product-item-mobile-view' className='overflow-hidden'>
                {productItems.map((productItem) => (
                    <ProductItemMobileView
                        key={productItem.product_item_id}
                        project={project}
                        productItem={productItem}
                        selected={selectedItems.includes(productItem)}
                        toggle={() => toggle(productItem)}
                    />
                ))}
            </Stack>
            <PageCountPicker
                className='d-flex justify-content-center'
                pageSize={queryPageSize || 0}
                onChangePageSize={(size) => {
                    dispatch &&
                        dispatch({
                            type: PAGE_SIZE_CHANGED,
                            payload: size,
                        });
                }}
                totalCount={totalCount || 0}
            />
        </Stack>
    );
};
