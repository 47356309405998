import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useProductEnvironmentDataModals } from './ProductEnvironmentDataModals/ProductEnvironmentDataModals';
import { useTranslation } from 'react-i18next';
import { useTableSelectedProductEnvironmentData } from './SelectedProductEnvironmentDataContext';

export const ProductEnvironmentDataManageButton = () => {
    const { t } = useTranslation();
    const { setShowExcelExportModal } = useProductEnvironmentDataModals();

    const { selectedItems } = useTableSelectedProductEnvironmentData();

    return (
        <DropdownButton
            id='dropdown-basic-button'
            title={<>{t('With selected')}...</>}
            variant='primary'
            disabled={!selectedItems?.length}
            className='d-inline-block'
        >
            <Dropdown.Item
                onClick={() => {
                    setShowExcelExportModal(selectedItems);
                }}
            >
                {t('Export to excel')}
            </Dropdown.Item>
        </DropdownButton>
    );
};
