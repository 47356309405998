import { faGauge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet } from 'react-router';
import { AdminSideNavLayout } from '../components/Layout/AdminSideNavLayout';
import { DashboardPage } from '../pages/Dashboard/DashboardPage';
import { IPrivateRoute } from '../types/IRouteType';
import { routesPrivate } from './routes';

const Dashboards = () => {
    return (
        <AdminSideNavLayout routesPrivate={routesPrivate}>
            <Outlet />
        </AdminSideNavLayout>
    );
};

export const dashboardPrivetRoutes: IPrivateRoute[] = [
    {
        name: 'Dashboard',
        path: 'dash',
        icon: <FontAwesomeIcon size='2x' icon={faGauge} />,
        element: <Dashboards />,
        children: [
            {
                name: 'Dashboard',
                index: true,
                element: <DashboardPage />,
            },
        ],
    },
];
