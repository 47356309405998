import { TbFlipHorizontal, TbFlipVertical } from 'react-icons/tb';

interface Props {
    flip: (direction: 'h' | 'v') => void;
    disabled?: boolean;
}

const noop = () => {};

export const ImageFlipButtons: React.FC<Props> = ({ flip, disabled }) => {
    const flipVert = () => flip('h');
    const flipHori = () => flip('v');
    const style = disabled
        ? {
              color: 'gray',
          }
        : {
              cursor: 'pointer',
          };
    return (
        <>
            <TbFlipHorizontal
                onClick={disabled ? noop : flipHori}
                size='2rem'
                style={style}
            />
            <TbFlipVertical
                onClick={disabled ? noop : flipVert}
                size='2rem'
                style={style}
            />
        </>
    );
};
