import React, { CSSProperties } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BarStat } from './BarStat';

interface Props {
    data: {
        title: string;
        data: { name: string; value: number }[];
    }[];
    title: string;
    style?: CSSProperties;
    fills?: string[];
}

export const PeriodicStat: React.FC<Props> = ({
    title,
    data,
    style,
    fills,
}) => {
    const { t } = useTranslation();
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const last6Months = Array.from(Array(6).keys()).map(
        (i) => monthNames[(new Date().getMonth() + 12 - i) % 12]
    );

    const addEmptyMonths = (data: { name: string; value: number }[]) => {
        return last6Months.map((m) => ({
            name: t(m).substring(0, 3),
            value: Math.max(
                data.find((d) => d.name.startsWith(m))?.value || 0,
                0
            ),
        }));
    };
    return (
        <div style={{ ...style }} className='border px-4 py-2 h-100'>
            <div className='fw-bold fs-3 border-bottom border-2 border-secondary my-3'>
                {title}
            </div>
            <Row>
                {data.map((d, i) => (
                    <Col key={i} sm={12 / 2} md={12 / 3} lg={12 / data.length}>
                        <BarStat
                            nameValueData={addEmptyMonths(d.data)}
                            title={d.title}
                            fills={fills}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};
