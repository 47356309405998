import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProductFormState } from '../ProductFormState';

interface Props {}

export const BasicInformationPreview = (props: Props) => {
    const { t } = useTranslation();
    const product_data = useProductFormState();
    const { barcode: productId } = product_data;

    return (
        <div>
            <span className='d-none d-sm-inline-block'>{t('Product-ID')}:</span>{' '}
            <span>{productId}</span>
        </div>
    );
};
