import React, { useCallback, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useProjectFormState } from '../ProjectFormState';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCloudUpload,
    faExternalLink,
} from '@fortawesome/free-solid-svg-icons';
import {
    createDocuments,
    createDocumentsFromMedias,
    updateDocuments,
    deleteDocument,
} from '../../../../../API/project/documents';
import { SimpleDropZonePreview } from '../../../../../components/SimpleDropZone/SimpleDropZonePreview';
import { dropzone } from '../../../../../components/SimpleDropZone/Styles';

interface Props {}

export const DocumentsForm = (props: Props) => {
    const { t } = useTranslation();

    const [selectedFileId, setSelectedFileId] = useState<number>(-1);
    const labelOptions: string[] = ['Ospecificerat', 'Avtal', 'Förmedling', 'Projekthandlingar', 'Rapporter', 'Ritningar'];

    const {
        setProjectDocuments: setFiles,
        projectDocuments: files,
        project_documents,
        setFormState,
        ...project
    } = useProjectFormState();

    const onDrop = useCallback(
        (acceptedFiles) => {
            const newFiles: any[] =
                acceptedFiles.map((file: any, index: number) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        index: files.length + index,
                    })
                ) || [];

            if (project.organisation_id && project.id) {
                createDocuments(
                    project.organisation_id,
                    project.id,
                    newFiles
                ).then((res) => {
                    setFormState((prev) => ({
                        ...prev,
                        projectDocuments: createDocumentsFromMedias(res.medias),
                        project_documents: res,
                    }));
                });
            } else {
                setFiles((prev: any[]) => {
                    return [...prev, ...newFiles];
                });
            }
        },
        [files.length, project, setFiles, setFormState]
    );

    const acceptedFiletypes = [
        'text/csv',
        'application/octet-stream', // .eml
        'image/x-dwg', // .dwg
        'application/rtf',
        'text/plain', // .txt
        'application/zip',
        'application/gzip',
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vdn.ms-outlook', // .msg
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptedFiletypes.join(','),
        onDrop,
    });

    const handleRemove = useCallback(
        (fileId: number) => {
            if (project.organisation_id && project.id && project_documents) {
                deleteDocument(
                    project.organisation_id,
                    project.id,
                    project_documents.id,
                    files.find((f) => f.id === fileId)
                ).then((res) => {
                    setSelectedFileId(-1);
                    setFiles((prev: any[]) => {
                        return [...prev.filter((f) => f.id !== fileId)];
                    });
                });
            }
            setSelectedFileId(-1);
            setFiles((prev: any[]) => {
                return [...prev.filter((f) => f.id !== fileId)];
            });
        },
        [
            files,
            project.organisation_id,
            project_documents,
            project.id,
            setFiles,
        ]
    );
    const changeIsUnprotected = useCallback(
        (fileId: number) => {
            const file = files?.find((f) => f.id === fileId);
            if (
                file &&
                project.organisation_id &&
                project.id &&
                project_documents
            ) {
                file.is_unprotected = !!!file.is_unprotected;
                updateDocuments(
                    project.organisation_id,
                    project.id,
                    project_documents.id,
                    [file]
                ).then((res) => {
                    setFiles((prev: any[]) => {
                        return [
                            ...prev.map((f) => (f.id !== fileId ? f : file)),
                        ];
                    });
                });
            }
        },
        [
            files,
            project.organisation_id,
            project_documents,
            project.id,
            setFiles,
        ]
    );

    const changeLabel = useCallback(
        (fileId: number, label: string) => {
            const file = files?.find((f) => f.id === fileId);
            if (
                file &&
                project.organisation_id &&
                project.id &&
                project_documents
            ) {
                file.label = label;
                updateDocuments(
                    project.organisation_id,
                    project.id,
                    project_documents.id,
                    [file]
                ).then((res) => {
                    setFiles((prev: any[]) => {
                        return [
                            ...prev.map((f) => (f.id !== fileId ? f : file)),
                        ];
                    });
                });
            }
        },
        [
            files,
            project.organisation_id,
            project_documents,
            project.id,
            setFiles,
        ]
    );
    
    const selectedFile: any = files.find((f) => f.id === selectedFileId);
    return (
        <Row className='d-flex flex-row'>
            <Col
                {...getRootProps({
                    className: 'd-flex',
                })}
                md={12}
                lg={4}
                style={dropzone}
            >
                <div
                    className='d-flex flex-grow-1 flex-column dropzone card bg-light text-center border-0'
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <input {...getInputProps()} />
                    <div className='m-4 fs-1'>
                        <FontAwesomeIcon icon={faCloudUpload} />
                    </div>
                    <h5>{t('Upload files')}</h5>
                    <p>
                        {t(
                            'Drag one or more files here, or click to select files'
                        )}
                    </p>
                </div>
            </Col>

            <Col md={12} lg={5}>
                <SimpleDropZonePreview
                    {...{ files, handleRemove }}
                    title={`${t('Uploaded documents')}: ${files.length}`}
                    type='Documents'
                    handleOnClick={(id: number) => setSelectedFileId(id)}
                    highlightId={selectedFileId}
                />
            </Col>

            <Col md={12} lg={3}>
                {selectedFileId < 1 && <div className='py-4'></div>}
                {selectedFile?.preview && (
                    <a
                        href={selectedFile.preview}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-decoration-none fw-bolder'
                    >
                        <p className='pe-5 pt-3 text-break'>
                            {selectedFile?.title || selectedFile?.name}
                            <FontAwesomeIcon
                                className='ms-2 text-secondary'
                                icon={faExternalLink}
                            />
                        </p>
                    </a>
                )}
                <Form.Group className='mb-3'>
                    <Form.Label>{t('Label') + ':'}</Form.Label>
                    <Form.Select
                        value={selectedFile?.label || 'Unspecified'}
                        onChange={(e) => changeLabel(selectedFileId, e.target.value)}
                    >
                        {labelOptions.map((s) => (
                            <option key={s} value={s}>
                                {s}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className='mb-3' controlId='public'>
                    <Form.Label
                        className={selectedFileId < 1 ? 'text-muted' : ''}
                    >
                        {t('Unprotected')}
                    </Form.Label>
                    <Form.Check
                        disabled={selectedFileId < 1}
                        type='switch'
                        id='custom-switch'
                        checked={!!selectedFile?.is_unprotected}
                        onChange={() => changeIsUnprotected(selectedFileId)}
                    />
                </Form.Group>
                <Button
                    disabled={selectedFileId < 1}
                    size='sm'
                    onClick={() => handleRemove(selectedFileId)}
                    variant='danger'
                >
                    {t('Remove')}
                </Button>
            </Col>
        </Row>
    );
};
