import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface Props {
    title?: string;
    description?: string;
    asText?: boolean;
    asH6?: boolean;
    className?: string;
}

export const SEO: React.FC<Props> = ({
    title,
    description,
    asText,
    asH6,
    className,
}) => {
    const { pathname } = useLocation();
    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Dacke.online | {title || ''}</title>
                <link
                    rel='canonical'
                    href={`https://dacke.online${pathname}`}
                />
                <meta name='description' content={description} />
            </Helmet>

            {asText && <span>{title}</span>}

            {asH6 && (
                <h1 className={`text-uppercase h6 fw-bold  ${className}`}>
                    {title}
                </h1>
            )}

            {!asText && !asH6 && <h1 className={className}>{title}</h1>}
        </>
    );
};
