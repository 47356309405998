import { useProductFormState } from '../ProductFormState';

interface Props {}

export const MessuresPreview = (props: Props) => {
    const product_data = useProductFormState();

    const { width, height, depth, length, unit } = product_data.messures;

    return (
        <div className='d-flex flex-wrap'>
            <span className='me-1 text-nowrap'>
                B{width || 0} {unit?.symbol}
            </span>
            <span className='me-1 text-nowrap'>x</span>

            <span className='me-1 text-nowrap'>
                H{height || 0} {unit?.symbol}
            </span>
            <span className='me-1 text-nowrap'>x</span>

            <span className='me-1 text-nowrap'>
                D{depth || 0} {unit?.symbol}
            </span>
            <span className='me-1 text-nowrap'>x</span>

            <span className='me-1 text-nowrap'>
                L{length || 0} {unit?.symbol}
            </span>
        </div>
    );
};
