import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useProjectFormState } from '../ProjectFormState';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faStar,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { Column, Row as TRow } from 'react-table';
import { useTableReducer } from '../../../../../hooks/useTableReducer';
import IContactPersonType from '../../../../../types/IContactPersonType';
import { useWindowSize } from '../../../../Organisation/hooks/useWindowSize';
import { Table } from '../../../../../components/Table';

interface Props {}

export const ShowContactPersonForm = (props: Props) => {
    const { t } = useTranslation();

    const { setFormState, ...project } = useProjectFormState();

    const [{ queryPageIndex, queryPageSize }, dispatch] = useTableReducer();

    const columns = React.useMemo<Column<IContactPersonType>[]>(
        () => [
            {
                Header: t('Name') as string,
                accessor: 'name',
            },
            {
                Header: t('Email') as string,
                accessor: 'email',
            },
            {
                Header: t('Phone number') as string,
                accessor: 'phone',
            },
            {
                Header: t('Position/Role') as string,
                accessor: 'role',
            },
        ],
        [t]
    );

    const actions = React.useMemo(() => {
        return [
            {
                name: 'Project sales contact',
                render: (instance: TRow<IContactPersonType>) => {
                    const isPrimary =
                        project?.primary_contact === instance.original.id;

                    return (
                        <div>
                            {isPrimary ? (
                                <FontAwesomeIcon icon={faStar} width='12' />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faStarRegular}
                                    width='12'
                                />
                            )}
                        </div>
                    );
                },
            }
        ];
    }, [ project ]);

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns =
        width && width < 992 ? ['email', 'phone', 'role'] : [];

    return (
        <Row>
            <Col sm={12} xl={12}>
                <Table<IContactPersonType>
                    name={'testTable'}
                    columns={columns}
                    data={project?.contacts}
                    initialPageSize={queryPageSize || 0}
                    initialPageIndex={queryPageIndex || 0}
                    hiddenColumns={hiddenColumns}
                    actions={actions}
                    dispatch={dispatch}
                    size='sm'
                />
            </Col>
        </Row>
    );
};
