import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../../../Auth/hooks/useAuth';
import { findUnitFromInt } from '../messureUnits';
import { useProductFormState } from '../ProductFormState';
import axios from 'axios';
import { IMessurementType } from '../../../../../types/IMessurementType';
import IProduct from '../../../../../types/IProductType';
import { getUnits } from '../../../../../API/units';
import { IUnitType } from '../../../../../types/IUnitType';

export const MessuresForm: React.FC = () => {
    const [messureUnits, setMessureUnits] = useState<IUnitType[]>([]);
    const [weightUnits, setWeightUnits] = useState<IUnitType[]>([]);

    const { projectId, productId } = useParams();
    const { organisation } = useAuth();

    const { t } = useTranslation();

    const {
        setMessures,
        messures,
        weight,
        weight_unit_id,
        setBasicInformation,
        setFormState,
        ...basicInformation
    } = useProductFormState();

    const initUnits = useCallback(async () => {
        const res = await getUnits();

        if (res.data) {
            const messureUnits = res.data
                .filter((unit) => unit.group === 'length')
                .sort((a, b) => a.sort_order - b.sort_order);

            setMessureUnits(messureUnits);

            const weightUnits = res.data
                .filter((unit) => unit.group === 'weight')
                .sort((a, b) => a.sort_order - b.sort_order);
            setWeightUnits(weightUnits);

            // set default messuresUnit and weightUnit
            const defaultMeasureUnit =
                messureUnits.find((u) => u.symbol === 'cm') || messureUnits[1];
            const defaultWeightUnit =
                weightUnits.find((u) => u.symbol === 'kg') || weightUnits[1];
            if (!messures?.unit && defaultMeasureUnit) {
                setMessures('unit', defaultMeasureUnit);
                setMessures('unit_id', defaultMeasureUnit.id);
            }
            !weight_unit_id &&
                defaultWeightUnit &&
                setBasicInformation('weight_unit_id', defaultWeightUnit.id);
        }
    }, [messures?.unit, setBasicInformation, setMessures, weight_unit_id]);

    useEffect(() => {
        if (messureUnits.length <= 0 || weightUnits.length <= 0) {
            initUnits();
        }
    }, [initUnits, messureUnits.length, weightUnits.length]);

    const weightUnit = findUnitFromInt(weight_unit_id, weightUnits);

    const updateMessure = useCallback(
        (key?, value?) => {
            if (productId) {
                if (messures?.id) {
                    // update existing messure
                    axios
                        .patch(
                            `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/messurements/${messures?.id}`,
                            {
                                [key]: Math.max(0, value),
                            }
                        )
                        .then((res) => {
                            setMessures(key, res?.data?.data?.[key]);
                        });
                } else {
                    const messureData: IMessurementType = {
                        ...messures,
                    };

                    if (key === 'unit_id') {
                        messureData.unit_id = value;
                    }

                    // create new messure
                    axios
                        .post(
                            `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/messurements`,
                            messureData
                        )
                        .then((res) => {
                            setMessures(key, value);

                            // Have to set up the ID after first post so that it doesnt keep posting
                            if (!messures?.id && res.data.data.id)
                                setMessures('id', res.data.data.id);
                        });
                }
            } else {
                // just save untill post
                setMessures(key, value);
            }
        },
        [messures, organisation?.id, productId, projectId, setMessures]
    );

    // TODO: [DAC-521] move Weight to messure?
    const updateWeight = useCallback(
        (key?, value?) => {
            if (productId) {
                // update existing messure

                const productData = {
                    project_id: basicInformation.project_id,
                    category_id: basicInformation.category_id,

                    weight: weight,
                    weight_unit_id: weight_unit_id,
                } as IProduct;

                if (key === 'weight_unit_id') {
                    productData.weight_unit_id = value;
                }

                axios
                    .put(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}`,
                        productData
                    )
                    .then((res) => {
                        if (res.data?.data) {
                            const product = res.data?.data;

                            setFormState((prev) => ({
                                ...prev,
                                weight: product.weight,
                                weight_unit_id: product.weight_unit_id,
                            }));
                        }
                    });
            } else {
                // just save untill post
                setBasicInformation(key, value);
            }
        },
        [
            basicInformation.category_id,
            basicInformation.project_id,
            organisation?.id,
            productId,
            projectId,
            setBasicInformation,
            setFormState,
            weight,
            weight_unit_id,
        ]
    );

    const messureFormControl = (
        key: keyof IMessurementType,
        className?: string
    ) => {
        return (
            <InputGroup>
                <Form.Control
                    type='number'
                    placeholder={messures?.unit?.symbol || 'cm'}
                    onChange={(e) => {
                        e.currentTarget.focus();
                        setMessures(key, e.target.value);
                    }}
                    className={className ?? ''}
                    value={messures[key]?.toString() ?? ''}
                    onBlur={() =>
                        messures[key] !== null &&
                        updateMessure(key, messures[key])
                    }
                    step='0.1'
                    min='0'
                    max='999999999.999'
                />
                <InputGroup.Text id='basic-addon2'>
                    {messures?.unit?.symbol || 'cm'}
                </InputGroup.Text>
            </InputGroup>
        );
    };

    return (
        <div>
            <Row>
                <Col md={12}>
                    <Form>
                        <Row>
                            <Col xs={12} sm={6} xxl={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('Width')}</Form.Label>
                                    {messureFormControl('width')}
                                </Form.Group>

                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('Height')}</Form.Label>
                                    {messureFormControl('height')}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={6} xxl={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('Depth')}</Form.Label>
                                    {messureFormControl('depth')}
                                </Form.Group>

                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('Diameter')}</Form.Label>
                                    {messureFormControl('diamter')}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={6} xxl={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('Length')}</Form.Label>
                                    {messureFormControl('length')}
                                </Form.Group>

                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('Thickness')}</Form.Label>
                                    {messureFormControl('thickness')}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={6} xxl={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('Weight')}</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type='number'
                                            placeholder={weightUnit}
                                            onChange={(e) => {
                                                e.currentTarget.focus();
                                                setBasicInformation(
                                                    'weight',
                                                    e.target.value
                                                );
                                            }}
                                            value={weight || ''}
                                            onBlur={updateWeight}
                                            min='0'
                                            max='999999999.999'
                                            step='0.1'
                                        />
                                        <InputGroup.Text id='basic-addon2'>
                                            {weightUnit}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={6} xxl={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>
                                        {t('Measure weight in')}:
                                    </Form.Label>
                                    <br />
                                    <div className='d-flex'>
                                        {weightUnits.map((unit) => (
                                            <Form.Check
                                                key={unit.id}
                                                inline
                                                label={unit?.symbol}
                                                name='weightUnit'
                                                type='radio'
                                                id={`inline-radio-${unit?.symbol}`}
                                                value={unit.id || ''}
                                                checked={
                                                    weight_unit_id === unit.id
                                                }
                                                style={{
                                                    flex: '30px',
                                                }}
                                                onChange={(e) => {
                                                    updateWeight(
                                                        'weight_unit_id',
                                                        parseInt(
                                                            e.target.value,
                                                            10
                                                        )
                                                    );
                                                }}
                                            />
                                        ))}
                                    </div>
                                </Form.Group>

                                <Form.Group className='mb-3'>
                                    <Form.Label>
                                        {t('Measure size in')} :
                                    </Form.Label>
                                    <br />
                                    <div className='d-flex'>
                                        {messureUnits.map((unit) => (
                                            <Form.Check
                                                key={unit.id}
                                                inline
                                                label={unit?.symbol}
                                                name='messureUnit'
                                                type='radio'
                                                id={`inline-radio-${unit?.symbol}`}
                                                value={unit.id || ''}
                                                checked={
                                                    messures?.unit?.id ===
                                                    unit.id
                                                }
                                                style={{
                                                    flex: '30px',
                                                }}
                                                onChange={(e) => {
                                                    const newId = parseInt(
                                                        e.target.value,
                                                        10
                                                    );
                                                    updateMessure(
                                                        'unit_id',
                                                        newId
                                                    );
                                                    setMessures(
                                                        'unit',
                                                        messureUnits.find(
                                                            (m) =>
                                                                m.id === newId
                                                        )
                                                    );
                                                }}
                                            />
                                        ))}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
