import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface Props {
    handleClose: () => void;
    show: boolean;
    headerText: string;
    headerIcon?: IconDefinition;
    body: JSX.Element;
    footer?: JSX.Element;
    size?: 'sm' | 'lg' | 'xl';
}

export const InfoModal = ({
    handleClose,
    show,
    headerText,
    headerIcon,
    body,
    footer,
    size,
}: Props) => {
    return (
        <Modal show={show} onHide={handleClose} centered size={size}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <h5 className='text-center w-100 ps-2 mt-2 text-uppercase fw-bold m-0'>
                    {headerIcon && (
                        <FontAwesomeIcon
                            icon={headerIcon}
                            width='16'
                            className='me-2'
                        />
                    )}
                    {headerText}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12} xl={12}>
                        {body}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>
        </Modal>
    );
};
