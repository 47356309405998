import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useProjectFormState } from '../ProjectFormState';
import IProjectType from '../../../../../types/IProjectType';

interface Props {}

export const PlacementForm = (props: Props) => {
    const { t } = useTranslation();

    const { setFormState, ...project } = useProjectFormState();

    const updatePlacement = useCallback(() => {
        const put = axios.put<IProjectType>(
            `/api/organisations/${project?.organisation_id}/projects/${project?.id}`,
            project
        );

        put.then((res) => {
            if (res.data) {
                setFormState((prev) => ({
                    ...prev,
                    ...res.data,
                }));
            }
        });
    }, [project, setFormState]);

    const formControl = (
        key: keyof IProjectType,
        type: string,
        maxLength: number,
        keyHasToBeSet?: keyof IProjectType,
        className?: string
    ) => {
        return (
            <Form.Control
                type={type}
                onChange={(e) => {
                    setFormState((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                    }));
                }}
                className={className ?? ''}
                value={project[key]?.toString() ?? ''}
                maxLength={maxLength}
                onBlur={() => updatePlacement()}
                disabled={
                    keyHasToBeSet !== undefined
                        ? !project[keyHasToBeSet] ||
                          project[keyHasToBeSet].length <= 0
                        : false
                }
            />
        );
    };

    return (
        <Row>
            <Col sm={12} xl={12}>
                <Form>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Title') + ' 1'}</Form.Label>
                                {formControl('firstPlacementTitle', 'text', 50)}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Title') + ' 2'}</Form.Label>
                                {formControl(
                                    'secondPlacementTitle',
                                    'text',
                                    50,
                                    'firstPlacementTitle'
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Title') + ' 3'}</Form.Label>
                                {formControl(
                                    'thirdPlacementTitle',
                                    'text',
                                    50,
                                    'secondPlacementTitle'
                                )}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Title') + ' 4'}</Form.Label>
                                {formControl(
                                    'fourthPlacementTitle',
                                    'text',
                                    50,
                                    'thirdPlacementTitle'
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
