import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useAxios from 'axios-hooks';
import { Card, Container, Row, Col, ButtonToolbar } from 'react-bootstrap';

import { Page } from '../../components/Layout/Page';
import { FloatingProductNameHeader } from '../Products/components/FloatingProductNameHeader';
import IProduct from '../../types/IProductType';
import IServerResponseType from '../../types/IServerResponseType';
import { ProductsBreadCrumbs } from '../../components/ProductsBreadCrumbs';
import { useCategories } from '../../routing/marketplaceRoutes';
import { ICategory } from '../../types/ICategory';
import axios from 'axios';
import { OrganisationCard } from './components/OrganisationCard';
import { ProductCard } from '../Products/components/ProductCard';
import useScrollY from '../../hooks/useScrollY';

interface Props {}

export const MarketplaceProductPage = (props: Props) => {
    const { productId } = useParams<any>();
    const { setSelectedCategory } = useCategories();
    const scrollY = useScrollY();
    const [enableFloatingTitle, setEnableFloatingTitle] = useState(true);

    const [{ data, loading, error }, refetch] = useAxios<
        IServerResponseType<IProduct>
    >(`/api/products/${productId}`, {
        manual: true,
    });
    const product = data?.data;

    useEffect(() => {
        refetch();
    }, [refetch]);

    const [category, setCategory] = useState<ICategory>();
    useEffect(() => {
        if (product)
            axios
                .get<IServerResponseType<ICategory>>(
                    `/api/categories/${product?.category_id}`
                )
                .then((res) => setCategory(res.data.data));
    }, [product]);

    if (error) return <p>Error!</p>;

    const breadcrumbs = () => (
        <ProductsBreadCrumbs
            to='/products'
            lastCategoryClickable={true}
            category={category}
            setSelectedCategory={setSelectedCategory}
        />
    );

    return (
        <Page className='px-0 px-sm-inherit'>
            <Container fluid className='px-0 px-sm-inherit'>
                <FloatingProductNameHeader
                    title={product?.title || ''}
                    show={enableFloatingTitle && scrollY > 90}
                />

                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    {breadcrumbs()}
                </ButtonToolbar>
                <Row className='gap-3 p-0 m-0'>
                    <Col className='p-0'>
                        <Card className='shadow'>
                            <ProductCard
                                loading={loading}
                                product={product}
                                toggleFloatingTitle={setEnableFloatingTitle}
                            />
                        </Card>
                    </Col>
                    <Col className='p-0' xs={12} lg={3}>
                        <Card className='shadow'>
                            <OrganisationCard
                                loading={loading}
                                organisation={product?.organisation}
                                projectContact={product?.primary_contact}
                            />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
