import React from 'react';
import { Row, Col, Button, Card, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Layout/Page';
import { AdminSideNavLayout } from '../../../components/Layout/AdminSideNavLayout';
import { useHandleModal } from '../../../hooks/useHandleModal';
import IUserType from '../../../types/IUserType';
import { ChangePasswordModal } from '../components/Modals/ChangePasswordModal';
import { useAuth } from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { routesPrivate } from '../../../routing/routes';
import { SEO } from '../../../components/SEO';

interface Props {}

export const MePage: React.FC<Props> = () => {
    const auth = useAuth();
    const { t } = useTranslation();
    const { user, organisations } = auth;
    const createDate = (
        user?.created_at
            ? new Date(user?.created_at?.substring(0, 10))
            : new Date()
    ).toLocaleDateString(navigator.language || 'sv-SE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const passwordModal = useHandleModal<IUserType>();
    return (
        <AdminSideNavLayout routesPrivate={routesPrivate}>
            <Page>
                <Container fluid>
                    <div className='d-flex align-items-center'>
                        <SEO title={t('My page')} asH6></SEO>
                    </div>
                    <Card className='mt-2'>
                        <Card.Body>
                            <div className='d-flex justify-content-end flex-fill'>
                                <Button
                                    variant='secondary'
                                    size='sm'
                                    className='m-1 mb-4 mb-sm-0'
                                    onClick={() => {
                                        passwordModal.setItem(user);
                                        passwordModal.showModal();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        width='8'
                                        className='me-1 mt-1'
                                    />
                                    {t('Change password')}
                                </Button>
                            </div>
                            <Row>
                                <Col>
                                    <h5>
                                        {t('Greetings')}, {user?.name}
                                    </h5>
                                    <div className='text-end  d-none d-sm-block'>
                                        <div className='d-inline-block text-center text-muted'>
                                            {t('Member since')}
                                            <br />
                                            {createDate}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='pb-4 pt-3'>
                                    <h6>Info</h6>
                                    <div>
                                        {t('Email')}: {user?.email}
                                    </div>
                                    <div>
                                        {t('Phone')}: {user?.phone}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='pb-5'>
                                    <h6>Organisations</h6>
                                    {organisations?.map((o, i) => (
                                        <div key={i}>{o.name}</div>
                                    ))}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>

                <ChangePasswordModal
                    show={passwordModal.show}
                    handleClose={passwordModal.closeModal}
                    user={passwordModal.item}
                ></ChangePasswordModal>
            </Page>
        </AdminSideNavLayout>
    );
};
