import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Modal, Dropdown, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faPlusCircle,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import IProductFieldType from '../../../../../types/IProductFieldType';
import { useThrottle } from '@react-hook/throttle';

interface Props {
    handleClose: () => void;
    show: boolean;
    organisationId: number;
}

export const ConnectFieldModal = ({
    handleClose,
    show,
    organisationId,
}: Props) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState<any>({});
    const [fields, setFields] = useState<IProductFieldType[]>([]);
    const [connectedFieldIds, setConnectedFieldIds] = useState<Number[]>([]);
    const [searchTerm, setSearchTerm] = useThrottle('', 0);
    const dropdownRef = useRef<HTMLDivElement>(null);
    // Get global list
    useEffect(() => {
        // Get global list
        axios
            .get(`/api/metatypes`)
            .then((res) => {
                setFields(res.data.data);
            })
            .catch(() => {});
    }, []);

    // Get already connected to organisation list
    // Refetch every time modal opens
    useEffect(() => {
        if (!show) return;
        axios
            .get(`/api/organisations/${organisationId}/organisationmetatypes`)
            .then((res) => {
                let ids = res.data.data.map((f: any) => f.meta_type_id);
                setConnectedFieldIds(Array.from(new Set(ids))); // Set makes it unique
            })
            .catch(() => {});
    }, [show, organisationId]);

    // Add a new field
    const onSubmit = (fieldId: Number) => {
        setIsSubmitting({ ...isSubmitting, [fieldId.toString()]: true });
        axios
            .post(
                `/api/organisations/${organisationId}/organisationmetatypes`,
                { meta_type_id: fieldId, organisation_id: organisationId }
            )
            .then(() => {
                setIsSubmitting({
                    ...isSubmitting,
                    [fieldId.toString()]: false,
                });
                setConnectedFieldIds([...connectedFieldIds, fieldId]);
            })
            .catch(() =>
                setIsSubmitting({
                    ...isSubmitting,
                    [fieldId.toString()]: false,
                })
            );
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header className='border-0 pb-0' closeButton>
                <h5 className='text-center w-100 ps-2 mt-2 text-uppercase fw-bold m-0'>
                    <FontAwesomeIcon
                        icon={faPlusCircle}
                        width='16'
                        className='me-2'
                    />
                    {t('Add product field')}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12} xl={12}>
                        <Dropdown autoClose={false} style={{ width: '100%' }}>
                            <Dropdown.Toggle className='w-100 d-flex justify-content-between align-items-center ps-3'>
                                <FormControl
                                    onClick={(e) => {
                                        // Dont hide the dropdown list if clicking the search input field
                                        let showing =
                                            dropdownRef.current?.classList.contains(
                                                'show'
                                            );
                                        if (showing) {
                                            e.stopPropagation();
                                            return false;
                                        }
                                    }}
                                    type='search'
                                    size='sm'
                                    placeholder={t('Search')}
                                    aria-label={t('Search')}
                                    aria-describedby='text-search-users'
                                    onChange={(e) =>
                                        setSearchTerm(
                                            e.target.value.trim().toLowerCase()
                                        )
                                    }
                                    className='d-inline w-75'
                                    value={searchTerm}
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                                style={{
                                    overflowY: 'scroll',
                                    maxHeight: '150px',
                                }}
                                className='w-100'
                                ref={dropdownRef}
                            >
                                {fields.map(
                                    (f, i) =>
                                        f.name
                                            .toLowerCase()
                                            .includes(searchTerm) && (
                                            <Dropdown.Item
                                                onClick={() => onSubmit(f.id)}
                                                key={i}
                                                disabled={
                                                    isSubmitting[
                                                        f.id.toString()
                                                    ] ||
                                                    connectedFieldIds.includes(
                                                        f.id
                                                    )
                                                }
                                                className='w-100'
                                            >
                                                {isSubmitting[
                                                    f.id.toString()
                                                ] && (
                                                    <span className='spinner-border spinner-border-sm me-1' />
                                                )}
                                                {connectedFieldIds.includes(
                                                    f.id
                                                ) ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        width='12'
                                                        className='me-2'
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        color={'green'}
                                                        icon={faPlus}
                                                        width='12'
                                                        className='me-2'
                                                    />
                                                )}
                                                {f.name}
                                            </Dropdown.Item>
                                        )
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};
