import React, { useContext } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';
import { faSortDown, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface CategorieTogglerProps {
    eventKey: string;
}

export const CategorieToggler: React.FC<CategorieTogglerProps> = ({
    children,
    eventKey,
}) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => null);

    const isCurrentEventKey = Array.isArray(activeEventKey)
        ? activeEventKey.includes(eventKey)
        : activeEventKey === eventKey;

    return (
        <span className='recursive-category-list-toggler d-flex'>
            {children}
            <span onClick={decoratedOnClick} className='flex-grow-1'>
                {isCurrentEventKey ? (
                    <span className='cursor-pointer ps-1'>
                        <FontAwesomeIcon
                            size={'lg'}
                            color={'orange'}
                            icon={faSortDown}
                        />
                    </span>
                ) : (
                    <span className='cursor-pointer ps-1'>
                        <FontAwesomeIcon
                            size={'xs'}
                            color={'slategrey'}
                            icon={faPlay}
                        />
                    </span>
                )}
            </span>
        </span>
    );
};
