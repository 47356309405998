import React from 'react';
import { routes, routesPrivate, systemadminRoutes } from './routes';
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router';
import IRoute, { IPrivateRoute } from '../types/IRouteType';
import { Restricted } from '../pages/Auth/components/Restricted';
import useGaTracker from '../hooks/useGaTracker';
import { FullLoadingPage } from '../pages/FullLoadingPage';

interface Props {}

const gaTrackedRoutes = routes
    .reduce((p: IRoute[], c: IRoute, i) => {
        if (c.children && c.children.length > 0) {
            return [...p, c, ...c.children];
        }
        return [...p, c];
    }, [])
    .filter((r) => r && !r.auth)
    .map((r) => r.path)
    .filter((r) => r) as string[];

export const RootRouter = (props: Props) => {
    const location = useLocation();

    useGaTracker(gaTrackedRoutes);

    const recursiveRouts = <T extends IPrivateRoute>(routes: T[]): T[] => {
        return routes.map((route) => {
            let newRoute = route;

            if (route.auth) {
                if (route.sysAdmin) {
                    // only sysAdmin can access this
                    newRoute.element = (
                        <Restricted
                            toSysAdmin
                            loadingComponent={<FullLoadingPage />}
                            fallBackCompoonet={
                                <Navigate
                                    to='/login'
                                    state={{ from: location }}
                                />
                            }
                        >
                            {route.element}
                        </Restricted>
                    );
                } else if (route.admin) {
                    // only admin can access this route
                    newRoute.element = (
                        <Restricted
                            toAdmin
                            loadingComponent={<FullLoadingPage />}
                            fallBackCompoonet={
                                <Navigate
                                    to='/login'
                                    state={{ from: location }}
                                />
                            }
                        >
                            {route.element}
                        </Restricted>
                    );
                } else {
                    newRoute.element = (
                        <Restricted
                            toAuth
                            loadingComponent={<FullLoadingPage />}
                            fallBackCompoonet={
                                <Navigate
                                    to='/login'
                                    state={{ from: location }}
                                />
                            }
                        >
                            {route.element}
                        </Restricted>
                    );
                }
            } else {
                newRoute.element = route.element;
            }

            if (newRoute && newRoute.children) {
                newRoute.children = recursiveRouts<IPrivateRoute>(
                    newRoute.children
                );
            }
            return newRoute;
        });
    };

    const allRoutes = recursiveRouts([
        {
            path: '/admin',
            element: <Navigate to='/admin/projects' replace />,
        },
        {
            path: 'admin',
            element: <Outlet />,
            auth: true,
            children: routesPrivate,
        },
        {
            path: '/system-admin',
            element: <Navigate to='/system-admin/organisations' replace />,
        },
        {
            path: 'system-admin',
            element: <Outlet />,
            auth: true,
            sysAdmin: true,
            children: systemadminRoutes,
        },

        ...routes,

        {
            path: '*',
            element: <Navigate to='/404' replace />,
        },
    ]);

    const Element = useRoutes(allRoutes);

    return Element;
};
