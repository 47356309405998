import { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';

import {
    Breadcrumb,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Container,
    Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faX } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Page } from '../../components/Layout/Page';

import { SearchInput } from '../../components/SearchInput';
import { useTableReducer } from '../../hooks/useTableReducer';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import IProduct from '../../types/IProductType';
import IProjectType from '../../types/IProjectType';
import IServerResponseWithTotalType from '../../types/IServerResponseWithTotalType';
import ResourcePermissionsType from '../../types/ResourcePermissionsType';
import { Restricted } from '../Auth/components/Restricted';
import { useAuth } from '../Auth/hooks/useAuth';
import { ProductListView } from './components/ListViews/ProductListView';
import { ProductMobileView } from './components/ListViews/ProductMobileView';
import { TableSelectedProductsProvider } from './components/SelectedProductsContext';
import { ProductModals } from './components/ProductModals/ProductModals';
import { ProductPlacementFilters } from './components/ProductPlacementFilters';
import { useProductSearch } from './components/ProductSearch';
import { ProductTextFilters } from './components/ProductTextFilters';
import { ProductThumbnailView } from './components/ProductThumbnailView';
import { PropertyFilters } from './components/PropertyFilters';
import { useViewSwitch } from './components/ViewSwitch';

import { ReactComponent as ADDIcon } from '../../assets/icones/Dacke_nytt.svg';
import { SEO } from '../../components/SEO';
import { ProductsBreadCrumbs } from '../../components/ProductsBreadCrumbs';
import { useCategories } from '../../routing/productsPrivateRoutes';
import { InfoModal } from '../../components/Modals/InfoModal';
import { useHandleModal } from '../../hooks/useHandleModal';
import CategoryNavList from '../../components/CategoryNavList';
import { ViewToggler } from './components/ViewToggler';
import { ProductManageButton } from './components/ProductManageButton';
import { ImportExcelModal } from "./components/Modals/ImportExcelModal";

const DEFAULT_PAGE_SIZE = 200;

const FilterButton = ({
    modal,
    setSelectedCategory,
    clearAllFilters,
    queryState,
}: {
    modal: any;
    queryState: any;
    setSelectedCategory: (category: any) => void;
    clearAllFilters: () => void;
}) => {
    const { t } = useTranslation();

    const numFilters = Object.keys(queryState).filter(
        (k) => !['limit', 'skip', 'sortBy', 'direction'].includes(k)
    ).length;

    return (
        <ButtonGroup
            className='my-1 d-flex align-items-center'
            aria-label='Basic example'
        >
            <Button
                size='sm'
                onClick={() => modal.showModal()}
                aria-controls='filter'
                aria-expanded={modal.show}
                variant='outline'
                className='bg-light text-gray border-primary text-nowrap'
            >
                <FontAwesomeIcon
                    className='me-1 text-secondary'
                    icon={faFilter}
                />
                {t('Apply filters')} ({numFilters})
            </Button>

            {!!numFilters && (
                <Button
                    size='sm'
                    variant='primary'
                    style={{
                        maxWidth: '4rem',
                    }}
                    onClick={() => {
                        setSelectedCategory(undefined);
                        clearAllFilters();
                    }}
                >
                    <FontAwesomeIcon icon={faX} />
                </Button>
            )}
        </ButtonGroup>
    );
};

export const ProductsPage = () => {
    const [loading, setLoading] = useState(false);

    const { projectId } = useParams();
    const { pathname } = useLocation();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { organisation } = useAuth();

    const { queryState, setQueryState, queryString, clearAllFilters } =
        useProductSearch();

    const { categories, selectedCategory, setSelectedCategory } =
        useCategories();

    const initialState = {
        queryPageIndex: 0,
        queryPageSize: parseInt(queryState['limit'] ?? DEFAULT_PAGE_SIZE),
        totalCount: -1,
    };
    const [showImportModal, setShowImportModal] =
        useState<boolean>(false);

    // Pagination
    const [{ queryPageIndex, queryPageSize, sortBy }, dispatch] =
        useTableReducer(initialState);

    useEffect(() => {
        const oldLimit = parseInt(queryState['limit'] ?? DEFAULT_PAGE_SIZE);
        if (oldLimit !== queryPageSize) setQueryState('limit', queryPageSize);
    }, [queryState, queryPageSize, setQueryState]);

    useEffect(() => {
        const oldSkip = parseInt(queryState['skip'] ?? 0);
        if (oldSkip !== queryPageIndex * queryPageSize)
            setQueryState('skip', queryPageIndex * queryPageSize);
    }, [queryState, setQueryState, queryPageIndex, queryPageSize]);

    useEffect(() => {
        const oldSort = queryState['sortBy'];
        const oldDir = queryState['direction'];
        if (oldSort !== sortBy?.id) {
            setQueryState('sortBy', sortBy?.id);
            setQueryState('direction', sortBy?.desc ? 'desc' : 'asc');
        } else if ((oldDir === 'desc') !== sortBy?.desc) {
            setQueryState('direction', sortBy?.desc ? 'desc' : 'asc');
        }
    }, [queryState, setQueryState, sortBy]);

    // API fetch project
    const [{ data: projectData }, refetchProject] = useAxios<
        IServerResponseWithTotalType<ResourcePermissionsType<IProjectType>>
    >(`/api/organisations/${organisation?.id}/projects/${projectId}`, {
        manual: true,
    });
    const project = projectData?.data;

    // Make sure there is always a limit on fetch products
    const fetchApiProductsLink = () => {
        let defaultLimit = '';
        if (!queryState['limit']) {
            defaultLimit = 'limit=' + DEFAULT_PAGE_SIZE + '&';
        }
        return `/api/organisations/${organisation?.id}${
            projectId ? '/projects/' + projectId : ''
        }/products?${defaultLimit}${queryString}`;
    };
    // API fetch products
    const [{ data, error }, refetch] = useAxios<
        IServerResponseWithTotalType<ResourcePermissionsType<IProduct>[]>
    >(fetchApiProductsLink(), { manual: true });

    const products: ResourcePermissionsType<IProduct>[] = data?.data ?? [];
    const totalCount: number = data?.total ?? -1;

    useEffect(() => {
        if (organisation) {
            setLoading(true);
            refetch()
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [organisation, queryString, refetch]);

    useEffect(() => {
        if (projectId) {
            refetchProject();
        }
    }, [projectId, refetchProject]);

    const modal = useHandleModal();
    const modalBody = () => {
        return (
            <Form>
                <div className='d-lg-none ps-3'>
                    <CategoryNavList
                        categories={categories}
                        setSelectedCategory={setSelectedCategory}
                    />
                    <hr />
                </div>
                <ProductTextFilters />
                <ProductPlacementFilters />
                <PropertyFilters />
                <div className='d-flex justify-content-center'>
                    <Button
                        variant='outline-secondary'
                        onClick={() => modal.closeModal()}
                    >
                        {t('Apply filters')}
                    </Button>
                </div>
            </Form>
        );
    };

    const { view } = useViewSwitch();
    const isMobile = useBreakpoint('down', 'sm');

    if (error) return <p>Error!</p>;

    return (
        <TableSelectedProductsProvider>
            <ProductModals refetch={refetch}>
                <Page className='px-0'>
                    <ImportExcelModal
                        show={showImportModal}
                        handleClose={() => setShowImportModal(false)}
                    ></ImportExcelModal>
                    <Container fluid className='px-0 px-sm-inherit'>
                        <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                            <div className='d-flex justify-content-between flex-grow-1'>
                                <Breadcrumb className='d-flex align-content-center'>
                                    {(pathname.includes('/projects') && (
                                        <>
                                            <Breadcrumb.Item
                                                linkAs={Link}
                                                linkProps={{
                                                    to: `/admin/projects`,
                                                }}
                                            >
                                                {t('Projects')}
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                linkAs={Link}
                                                linkProps={{
                                                    to: `/admin/projects/${project?.id}`,
                                                }}
                                            >
                                                {project?.title}
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                <SEO
                                                    title={t('Products')}
                                                    asText
                                                ></SEO>
                                            </Breadcrumb.Item>
                                        </>
                                    )) || (
                                        <ProductsBreadCrumbs
                                            to='/admin/products'
                                            lastCategoryClickable={true}
                                            category={selectedCategory}
                                            setSelectedCategory={
                                                setSelectedCategory
                                            }
                                        />
                                    )}
                                </Breadcrumb>

                                {/* display filter button top right */}
                                <div className='d-flex d-md-none align-items-center'>
                                    <FilterButton
                                        {...{
                                            queryState,
                                            clearAllFilters,
                                            setSelectedCategory,
                                            modal,
                                        }}
                                    ></FilterButton>
                                </div>
                            </div>

                            <div className='d-flex flex-column flex-md-row align-items-md-center '>
                                <SearchInput
                                    onChange={(value: string) =>
                                        setQueryState('search', value)
                                    }
                                    placeholder={t('Search')}
                                ></SearchInput>

                                {/* display filter button behind search up to md */}
                                <div className='ms-5 d-none d-md-flex   align-items-center'>
                                    <FilterButton
                                        {...{
                                            queryState,
                                            clearAllFilters,
                                            setSelectedCategory,
                                            modal,
                                        }}
                                    ></FilterButton>
                                </div>
                            </div>

                            <div className='d-none d-sm-flex align-items-center'>
                                <ViewToggler />
                            </div>
                        </ButtonToolbar>

                        <ButtonToolbar className='d-flex flex-row align-content-stretch align-content-sm-end align-items-sm-center justify-content-between justify-content-sm-end mb-2 gap-sm-2'>
                            <div className='d-sm-none'>
                                <ProductManageButton />
                            </div>
                            
                            <div className='d-none d-sm-flex'>
                                {view === 'list-view' && (
                                    <ProductManageButton />
                                )}
                            </div>

                            {pathname.includes("/projects") && (
                                <Restricted
                                    to="create single product"
                                    resource={project}
                                >
                                    <Button
                                        variant={"secondary"}
                                        className="d-flex justify-content-center align-items-center text-nowrap"
                                        onClick={() => setShowImportModal(true)}
                                    >
                                        <ADDIcon width="12" className="me-1" />
                                        {t("Import from excel")}
                                    </Button>
                                </Restricted>
                            )}

                            {pathname.includes('/projects') && (
                                <Restricted
                                    to='create single product'
                                    resource={project}
                                >
                                    <Button
                                        variant='secondary'
                                        className={isMobile ? 'w-50' : ''}
                                        onClick={() => {
                                            if (projectId) {
                                                navigate(
                                                    `/admin/projects/${projectId}/product-form`
                                                );
                                            } else {
                                                navigate(
                                                    `/admin/products/product-form`
                                                );
                                            }
                                        }}
                                    >
                                        <ADDIcon width='12' className='me-1' />
                                        {t('Add new')}
                                    </Button>
                                </Restricted>
                            )}
                        </ButtonToolbar>

                        <div className='d-sm-none'>
                            <ProductMobileView
                                {...{
                                    products,
                                    queryPageIndex,
                                    queryPageSize,
                                    totalCount,
                                    dispatch,
                                    loading,
                                }}
                            />
                        </div>
                        <div className='d-none d-sm-block'>
                            {view === 'card-view' ? (
                                <ProductThumbnailView
                                    {...{
                                        products,
                                        queryPageSize,
                                        totalCount,
                                        dispatch,
                                    }}
                                />
                            ) : (
                                !isMobile && (
                                    <ProductListView
                                        {...{
                                            products,
                                            queryPageIndex,
                                            queryPageSize,
                                            totalCount,
                                            dispatch,
                                            loading,
                                        }}
                                        elevatedActions={pathname.includes(
                                            '/projects'
                                        )}
                                    />
                                )
                            )}
                        </div>
                    </Container>
                </Page>
                <InfoModal
                    handleClose={modal.closeModal}
                    show={modal.show}
                    headerText={t('Filter products')}
                    headerIcon={faFilter}
                    body={modalBody()}
                    size='lg'
                />
            </ProductModals>
        </TableSelectedProductsProvider>
    );
};
