import axios from 'axios';
import React, { CSSProperties, useState } from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import IProduct from '../../../../types/IProductType';
import { CoverImage } from '../../../../components/CoverImage';
import { IImageMediaGroup } from '../../../../types/IMediaType';
import ReCAPTCHA from 'react-google-recaptcha';
import { tryFormatDefaultDate } from '../../../../utils/helper';

interface Props {
    handleClose: () => void;
    show: boolean;
    item?: IProduct;
}
enum CaptchaState {
    Initial,
    Overlooked,
    Completed,
}
export const RequestModal = ({ item, handleClose, show }: Props) => {
    const { t } = useTranslation();
    const recaptchaSitekey = '6Lesrt8fAAAAAAN-azOB6Lf9RaPsH0-L8Uu1ElYy';
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [captcha, setCaptcha] = useState<CaptchaState>();
    const inputStyle: CSSProperties = {
        backgroundColor: 'rgb(242,242,242)',
        border: 'none',
        padding: '0.55rem',
    };
    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const onClose = () => {
        reset();
        setCaptcha(CaptchaState.Initial);
        handleClose();
    };

    const onSubmit = (data: any) => {
        if (captcha !== CaptchaState.Completed) {
            setCaptcha(CaptchaState.Overlooked);
            return;
        }
        setIsSubmitting(true);

        axios
            .post(`/api/sendproductinteresedmail/${item?.id}`, data)
            .then(() => {
                setIsSubmitting(false);
                onClose();
            })
            .catch(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <Modal show={show} onHide={onClose} centered size='xl'>
            <Modal.Header
                className=' border-bottom border-2 border-secondary mx-4 px-0 pt-4 pb-3'
                closeButton
            >
                <h5 className='text-uppercase fw-bold m-0'>
                    {t('Place a request')}
                </h5>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                <Modal.Body className='px-4 pb-sm-4'>
                    <Row className='pb-4 gap-4'>
                        <Col
                            sm={8}
                            xl={8}
                            className='d-flex flex-column flex-grow-1'
                            style={{ flex: '1' }}
                        >
                            <Form.Group className='mb-3'>
                                <Controller
                                    control={control}
                                    name='from_name'
                                    rules={{
                                        required: true,
                                    }}
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <Form.Control
                                            style={inputStyle}
                                            required
                                            onChange={onChange}
                                            value={value || ''}
                                            type='text'
                                            ref={ref}
                                            isInvalid={errors.from_name}
                                            placeholder={t('Name')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.from_name?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Controller
                                    control={control}
                                    name='from_email'
                                    rules={{
                                        required: true,
                                    }}
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <Form.Control
                                            style={inputStyle}
                                            required
                                            onChange={onChange}
                                            value={value || ''}
                                            type='email'
                                            ref={ref}
                                            isInvalid={errors.from_email}
                                            placeholder={t('Email')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.from_email?.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Controller
                                    control={control}
                                    name='phone'
                                    rules={{
                                        required: true,
                                    }}
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <Form.Control
                                            style={inputStyle}
                                            required
                                            onChange={onChange}
                                            value={value || ''}
                                            type='phone'
                                            ref={ref}
                                            isInvalid={errors.to_name}
                                            placeholder={t('Phone number')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.phone?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Controller
                                    control={control}
                                    name='text'
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <Form.Control
                                            style={inputStyle}
                                            as='textarea'
                                            rows={6}
                                            onChange={onChange}
                                            value={value || ''}
                                            type='text'
                                            ref={ref}
                                            isInvalid={errors.text}
                                            placeholder={t(
                                                'Message (optional)'
                                            )}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.text?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className='d-flex flex-column-reverse flex-grow-1'>
                                <Row>
                                    <Col sm={12} lg={8}>
                                        <Controller
                                            name='submit'
                                            control={control}
                                            render={({ field: { ref } }) => (
                                                <Button
                                                    className='w-100 text-uppercase text-light'
                                                    size='lg'
                                                    type='submit'
                                                    disabled={
                                                        captcha !==
                                                            CaptchaState.Completed ||
                                                        isSubmitting
                                                    }
                                                >
                                                    {isSubmitting && (
                                                        <span className='spinner-border spinner-border-sm mr-1' />
                                                    )}
                                                    {t('Send request')}
                                                </Button>
                                            )}
                                        />
                                    </Col>
                                    <Col sm={0} lg={4}></Col>
                                </Row>
                                <ReCAPTCHA
                                    sitekey={recaptchaSitekey}
                                    onChange={() =>
                                        setCaptcha(CaptchaState.Completed)
                                    }
                                    className={
                                        'my-3' +
                                        (captcha === CaptchaState.Overlooked
                                            ? ' ps-3 border-start border-3 border-danger'
                                            : '')
                                    }
                                />
                                {/* <Form.Group className="my-2 ms-2 d-flex gap-2">
                                    <Controller
                                        control={control}
                                        name="subscribe"
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Check
                                                onChange={onChange}
                                                value={value || ""}
                                                ref={ref}
                                                isInvalid={errors.subscribe}
                                            />
                                        )}
                                    />
                                    <Form.Label>{t('Subscribe to our newsletter')}</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.subscribe?.message}
                                    </Form.Control.Feedback>
                                </Form.Group> */}
                            </div>
                        </Col>
                        <Col sm={12} lg={4}>
                            <CoverImage
                                fullSized={true}
                                images={
                                    item?.product_images as IImageMediaGroup
                                }
                            />

                            <div className='fs-5 fw-bold pt-1'>
                                {item?.title || ''}
                                {item?.quantity &&
                                    `, ${item.quantity || 0} ${
                                        item.category?.unit?.symbol
                                    }`}
                            </div>
                            <div className='text-danger fs-5'>
                                {item?.sales_price ?? '-'}
                                :- /{item?.category?.unit?.symbol || 'stk'}
                            </div>
                            <div>
                                <span className='fw-bold'>
                                    {t('Product-ID')}:{' '}
                                </span>
                                {item?.barcode}
                            </div>
                            <div>
                                <span className='fw-bold'>
                                    {t('Available from')}:{' '}
                                </span>
                                {tryFormatDefaultDate(item?.delivery_date)}
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Form>
        </Modal>
    );
};
