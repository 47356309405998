import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useOrganisationFormState } from '../OrganisationFormState';
import { useTranslation } from 'react-i18next';
import ICriteriaType from '../../../../../types/ICriteriaType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ADDIcon } from '../../../../../assets/icones/Dacke_nytt.svg';
import { useHandleModal } from '../../../../../hooks/useHandleModal';
import { DeleteCriteriaModal } from './DeleteCriteriaModal';
import axios from 'axios';
import IOrganisationCriteriaType from '../../../../../types/IOrganisationCriteriaType';

const defaultCriteria: ICriteriaType = {
    title: '',
    firstPointTitle: '',
    secondPointTitle: '',
    thirdPointTitle: '',
    fourthPointTitle: '',
    fifthPointTitle: '',
    description: 'description',
};
const defaultOrganisationCriteria: IOrganisationCriteriaType = {
    id: 0,
    created_at: '',
    updated_at: '',
    criteria: defaultCriteria,
};

interface Props {}

export const CriteriasForm = (props: Props) => {
    const { t } = useTranslation();

    const { setMeta, updateMeta, setFormState, ...organisation } =
        useOrganisationFormState();
    const [organisationCriterias, setOrganisationCriterias] = useState<
        IOrganisationCriteriaType[]
    >([]);

    const [loading, setLoading] = useState(false);

    const refetchCriteries = useCallback(() => {
        setLoading(true);
        axios
            .get(`/api/organisations/${organisation.id}/organisationcriterias`)
            .then((res) => {
                // Fill up with received from backend (But don't remove the ones that are not fully filled out yet aka not saved to backend yet)
                setOrganisationCriterias((prev) => [
                    ...res.data.data,
                    ...prev.filter((c) => !c.id),
                ]);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [organisation]);

    const updateCriteria = (
        organisationCriteria: IOrganisationCriteriaType
    ) => {
        if (organisationCriteria.id) {
            axios
                .put(
                    `/api/criterias/${organisationCriteria.criteria.id}`,
                    organisationCriteria.criteria
                )
                .then((res) => {
                    const updatedCriteria = res.data.data;
                    setOrganisationCriterias((prev) => [
                        ...prev.map((c) =>
                            c !== organisationCriteria
                                ? c
                                : {
                                      ...organisationCriteria,
                                      criteria: updatedCriteria,
                                  }
                        ),
                    ]);
                });
        } else {
            // Create a new criteria
            // const {id, created_at, updated_at, default, ...requiredInput} = organisationCriteria.criteria; // Doesn't work because default is a reserved name
            const requiredInput = ({ title }: any) => ({ title });
            const isFilledIn = Object.values(
                requiredInput(organisationCriteria.criteria)
            ).every((cv) => (cv ? true : false));
            if (!isFilledIn) return;
            setLoading(true);
            axios
                .post(
                    `/api/organisations/${organisation.id}/organisationcriterias`,
                    organisationCriteria.criteria
                )
                .then((res) => {
                    const newOrganisationCritera = res.data.data;
                    setOrganisationCriterias((prev) => [
                        ...prev.map((c) =>
                            c !== organisationCriteria
                                ? c
                                : newOrganisationCritera
                        ),
                    ]);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        }
    };

    // Fetch from backend first time comp is loaded
    const [initialFetched, setInitialFetched] = useState(false);
    useEffect(() => {
        if (!initialFetched) {
            setInitialFetched(true);
            refetchCriteries();
        }
    }, [refetchCriteries, initialFetched]);

    const deleteModal = useHandleModal<IOrganisationCriteriaType>({
        onClose: (needRefetch?: boolean) => needRefetch && refetchCriteries(),
    });
    const formControl = (
        organisationCriteria: IOrganisationCriteriaType,
        key: keyof ICriteriaType,
        type: string,
        maxLength: number,
        placeholder: string
    ) => {
        return (
            <Form.Control
                placeholder={placeholder}
                type={type}
                disabled={loading}
                onChange={(e) =>
                    setOrganisationCriterias((prev) => [
                        ...prev.map((c) =>
                            c !== organisationCriteria
                                ? c
                                : {
                                      ...organisationCriteria,
                                      criteria: {
                                          ...organisationCriteria.criteria,
                                          [key]: e.target.value,
                                      },
                                  }
                        ),
                    ])
                }
                value={organisationCriteria.criteria[key]?.toString() || ''}
                maxLength={maxLength}
                onBlur={() => updateCriteria(organisationCriteria)}
            />
        );
    };

    return (
        <Row>
            <Col sm={12} xl={12}>
                {organisationCriterias.map((crit, i) => (
                    <Form key={i}>
                        <Row
                            className={i % 2 ? 'bg-light p-3 pb-0' : 'p-3 pb-0'}
                        >
                            <Col>
                                <Row className='mb-2'>
                                    <Col xs={10} lg={6}>
                                        <Form.Group className=''>
                                            {formControl(
                                                crit,
                                                'title',
                                                'text',
                                                100,
                                                t('Enter criteria')
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={2} lg={6}>
                                        {loading ? (
                                            <span className='float-end spinner-border spinner-border-sm me-1' />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                width='16'
                                                className='float-end cursor-pointer p-2'
                                                onClick={() => {
                                                    if (crit.id) {
                                                        // It is stored in the backend, we must do proper delete
                                                        deleteModal.setItem(
                                                            crit
                                                        );
                                                        deleteModal.showModal();
                                                    } else {
                                                        // Not stored in backend yet (Aka user added new criteria, didnt fill in and removed it again)
                                                        setOrganisationCriterias(
                                                            (prev) => [
                                                                ...prev.filter(
                                                                    (c) =>
                                                                        c !==
                                                                        crit
                                                                ),
                                                            ]
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} lg={2}>
                                        <Form.Group className='mb-3'>
                                            {formControl(
                                                crit,
                                                'firstPointTitle',
                                                'text',
                                                100,
                                                t('Enter level') + ' 1'
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} lg={2}>
                                        <Form.Group className='mb-3'>
                                            {formControl(
                                                crit,
                                                'secondPointTitle',
                                                'text',
                                                100,
                                                t('Enter level') + ' 2'
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} lg={2}>
                                        <Form.Group className='mb-3'>
                                            {formControl(
                                                crit,
                                                'thirdPointTitle',
                                                'text',
                                                100,
                                                t('Enter level') + ' 3'
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} lg={2}>
                                        <Form.Group className='mb-3'>
                                            {formControl(
                                                crit,
                                                'fourthPointTitle',
                                                'text',
                                                100,
                                                t('Enter level') + ' 4'
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} lg={2}>
                                        <Form.Group className='mb-3'>
                                            {formControl(
                                                crit,
                                                'fifthPointTitle',
                                                'text',
                                                100,
                                                t('Enter level') + ' 5'
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                ))}
                <Row>
                    <Col className='text-center'>
                        <Button
                            className='px-lg-5 mt-2'
                            variant='secondary'
                            onClick={() =>
                                setOrganisationCriterias((prev) => [
                                    ...prev,
                                    {
                                        ...defaultOrganisationCriteria,
                                        criteria: { ...defaultCriteria },
                                    },
                                ])
                            }
                        >
                            <ADDIcon width='12' className='me-1' />
                            {t('New criteria')}
                        </Button>
                    </Col>
                </Row>
                <DeleteCriteriaModal
                    handleClose={deleteModal.closeModal}
                    show={deleteModal.show}
                    organisationId={organisation.id}
                    organisationCriteria={deleteModal.item}
                ></DeleteCriteriaModal>
            </Col>
        </Row>
    );
};
