import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useOrganisationFormState } from '../OrganisationFormState';
import { useTranslation } from 'react-i18next';

interface Props {}

export const ContactPersonForm = (props: Props) => {
    const { t } = useTranslation();

    const { setMeta, updateMeta, setFormState, ...organisation } =
        useOrganisationFormState();

    const getMetaValue = (key: string) =>
        organisation?.organisation_meta?.find((m) => m.meta_key === key)
            ?.meta_value;

    const formControl = (key: string, type: string, maxLength: number) => {
        return (
            <Form.Control
                type={type}
                onChange={(e) => {
                    setMeta(key, e.target.value);
                }}
                value={getMetaValue(key) || ''}
                maxLength={maxLength}
                onBlur={() => updateMeta(key)}
            />
        );
    };

    return (
        <Row>
            <Col sm={12} xl={12}>
                <Form>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    {t('Contact person') + ':'}
                                </Form.Label>
                                {formControl('contactPerson', 'text', 100)}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    {t('Email address') + ':'}
                                </Form.Label>
                                {formControl('contactPersonMail', 'text', 100)}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    {t('Phone number') + ':'}
                                </Form.Label>
                                {formControl('contactPersonPhone', 'text', 20)}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
