import { useCallback, useState } from 'react';
import {
    createImages,
    createImagesFromMedias,
    deleteImage,
    // updateImage,
    updateImages,
} from '../../API/images';
interface Outgoing {
    loading: boolean;
    create: (addFiles: any[]) => Promise<any>;
    update: (updateFiles: any[]) => Promise<any>;
    remove: (deleteFiles: any[]) => Promise<any>;
}
interface Props {
    apiUrl: string;
    mediaGroup: any;
}

export function useHandleImageAPI({ apiUrl, mediaGroup }: Props): Outgoing {
    const [loading, setLoading] = useState(false);

    // Add an image
    const create = useCallback(
        async (addFiles: any[]) => {
            setLoading(true);
            return createImages(addFiles, apiUrl, mediaGroup)
                .then((res: any) => {
                    setLoading(false);
                    return {
                        mediaGroup: res,
                        images: createImagesFromMedias(res.medias),
                    };
                })
                .catch(() => setLoading(false));
        },
        [apiUrl, mediaGroup]
    );

    // Update an image
    const update = useCallback(
        async (updateFiles: any[]) => {
            setLoading(true);
            return updateImages(updateFiles, apiUrl, mediaGroup)
                .then((res: any) => {
                    setLoading(false);
                    return createImagesFromMedias(res.data.medias);
                })
                .catch(() => setLoading(false));
        },
        [apiUrl, mediaGroup]
    );

    // Remove an image
    const remove = useCallback(
        async (deleteFiles: any[]) => {
            setLoading(true);
            return deleteImage(deleteFiles[0], apiUrl, mediaGroup)
                .then((res) => {
                    setLoading(false);
                    return res;
                })
                .catch(() => setLoading(false));
        },
        [apiUrl, mediaGroup]
    );

    return {
        loading,
        create,
        update,
        remove,
    };
}
