import { useState } from 'react';
import Select from 'react-select';
import { AlertModal } from '../../Modals/AlertModal';
import IEnvironmentData from '../../../../../types/IEnvironmentDataType';

interface GroupedOption {
    substance: string;
    options: IEnvironmentData[];
}

interface Props {
    value: IEnvironmentData[];
    onChange: (ids: IEnvironmentData[]) => void;
    options: IEnvironmentData[];
}

const formatGroupLabel = (data: GroupedOption) => (
    <div>
        <span>{data.substance}</span>
    </div>
);

const groupOptions = (acc: GroupedOption[], opt: IEnvironmentData) => {
    const { substance } = opt;
    let idx = acc.findIndex((x) => x.substance === substance);
    if (idx < 0) {
        acc.push({ substance, options: [] });
        idx = acc.length - 1;
    }
    acc[idx].options.push(opt);
    return acc;
};

export const EnvironmentDataSelect = ({ value, onChange, options }: Props) => {
    const [confirmClear, setConfirmClear] = useState<boolean>(false);
    const [toRemove, setToRemove] = useState<IEnvironmentData | null>(null);

    const getOptionLabel = (o: IEnvironmentData) =>
        `${o.material} (${o.waste_code})${o.is_hazardous ? '*' : ''}`;
    const getOptionValue = (o: IEnvironmentData) =>
        `${o.substance} ${o.material} (${o.waste_code})`;
    const getSelectLabel = (o: IEnvironmentData) =>
        `[${o.substance}] ${o.material} (${o.waste_code})${
            o.is_hazardous ? '*' : ''
        }`;

    const groupedOptions = (options || [])
        .reduce(groupOptions, [] as GroupedOption[])
        .sort((a, b) => a.substance.localeCompare(b.substance));

    const onClear = () => {
        onChange([]);
        setConfirmClear(false);
    };
    const onRemove = () => {
        if (toRemove) onChange(value.filter((x) => x.id !== toRemove.id));
        setToRemove(null);
    };

    const handleChange = (e: any, action: any) => {
        if (action.action === 'clear') {
            setConfirmClear(true);
        } else if (action.action === 'remove-value') {
            setToRemove(action.removedValue);
        } else {
            onChange(e);
        }
    };

    const MultiValueLabel = ({ data }: any) => (
        <span
            style={{
                width: 145,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {getSelectLabel(data)}
        </span>
    );

    return (
        <>
            <Select<IEnvironmentData, true, GroupedOption>
                defaultValue={[]}
                value={value}
                onChange={handleChange}
                options={groupedOptions}
                components={{ MultiValueLabel }}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                formatGroupLabel={formatGroupLabel}
                isMulti
                closeMenuOnSelect={false}
            />
            <AlertModal
                showAlertModal={
                    confirmClear
                        ? {
                              title: 'Confirm clear',
                              message:
                                  'Are you sure you want to remove ALL selected?',
                          }
                        : null
                }
                onClose={() => setConfirmClear(false)}
                onSave={onClear}
                cancelLabel='No'
            />
            <AlertModal
                showAlertModal={
                    toRemove
                        ? {
                              title: 'Confirm remove',
                              message: `Are you sure you want to remove {{item}}?`,
                              messageData: { item: getSelectLabel(toRemove) },
                          }
                        : null
                }
                onClose={() => setToRemove(null)}
                onSave={onRemove}
                cancelLabel='No'
            />
        </>
    );
};
