import axios from 'axios';
import React, { useState } from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import IUserType from '../../../../types/IUserType';
import { Controller, useForm } from 'react-hook-form';

interface Props {
    handleClose: () => void;
    show: boolean;
    user?: IUserType;
}

export const ChangePasswordModal = ({ user, handleClose, show }: Props) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        getValues,
        setError,
    } = useForm();

    const onSubmit = (data: any) => {
        setIsSubmitting(true);

        axios
            .put(`/user/password`, data)
            .then(() => {
                setIsSubmitting(false);
                reset();
                handleClose();
            })
            .catch(() => {
                setIsSubmitting(false);
                setError('current_password', {
                    message: t('Incorrect password'),
                });
            });
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header className='border-0 pb-0' closeButton>
                <h5 className='text-center w-100 ps-2 mt-2 text-uppercase fw-bold m-0'>
                    <FontAwesomeIcon
                        icon={faLock}
                        width='16'
                        className='me-2'
                    />
                    {t('Change password')}
                </h5>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                <Modal.Body>
                    <Row>
                        <Col sm={12} xl={12}>
                            <p className='m-0 mb-2'>
                                {t('Do you want to change your password?')}
                            </p>

                            <Form.Group className='mb-3'>
                                <Form.Label className='text-muted'>
                                    {t('Current password')}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='current_password'
                                    rules={{
                                        required: true,
                                    }}
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <Form.Control
                                            required
                                            onChange={onChange}
                                            value={value}
                                            type='password'
                                            ref={ref}
                                            isInvalid={errors.current_password}
                                            placeholder={t(
                                                'Enter current password'
                                            )}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.current_password?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <hr />
                            <Form.Group className='mb-3'>
                                <Form.Label className='text-muted'>
                                    {t('New password')}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='password'
                                    rules={{
                                        required: true,
                                        minLength: {
                                            value: 8,
                                            message: t('Minimum 8 characters'),
                                        },
                                    }}
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <Form.Control
                                            required
                                            onChange={onChange}
                                            value={value}
                                            type='password'
                                            ref={ref}
                                            isInvalid={errors.password}
                                            placeholder={t(
                                                'Enter new password'
                                            )}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.password?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label className='text-muted'>
                                    {t('Confirm')}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='password_confirmation'
                                    rules={{
                                        required: true,
                                        minLength: {
                                            value: 8,
                                            message: t('Minimum 8 characters'),
                                        },
                                        validate: (value) => {
                                            const { password } = getValues();
                                            const dd = t(
                                                'Passwords must match'
                                            );
                                            return password === value || dd;
                                        },
                                    }}
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <Form.Control
                                            required
                                            onChange={onChange}
                                            value={value}
                                            type='password'
                                            ref={ref}
                                            isInvalid={
                                                errors.password_confirmation
                                            }
                                            placeholder={t(
                                                'Enter new password'
                                            )}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.password_confirmation?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Controller
                        name='submit'
                        control={control}
                        render={({ field: { ref } }) => (
                            <Button
                                className='d-block w-100 text-uppercase'
                                variant='secondary'
                                type='submit'
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <span className='spinner-border spinner-border-sm mr-1' />
                                )}
                                {t('Update')}
                            </Button>
                        )}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
