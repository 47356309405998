import { Row, Col } from 'react-bootstrap';
import { IProductItem } from '../../../../../types/IProductItem';

const PlacementAmountInput = ({
    disabled,
    value,
    setQuantity,
    index,
    addNewItem,
    onBlurQuantity,
}: {
    disabled: boolean;
    index: number;
    addNewItem: (quantity: number) => void;
    setQuantity: (index: number, quantity: number) => void;
    onBlurQuantity: (index: number, quantity: number) => void;
    value: number;
}) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const quantity = parseInt((e.target as HTMLInputElement).value, 10);

        if (!disabled) {
            return setQuantity(index, quantity < 1 ? 1 : quantity);
        }

        if (quantity) {
            addNewItem(quantity);
        }
    };
    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const quantity =
            parseInt((e.target as HTMLInputElement).value, 10) || 0;

        if (!disabled) {
            return onBlurQuantity(index, quantity < 1 ? 1 : quantity);
        }

        if (quantity) {
            addNewItem(quantity);
        }
    };
    const style: React.CSSProperties = {
        width: '50px',
        textAlign: 'center',
        border: 'none',
        backgroundColor: 'transparent',
    };

    return (
        <span className='placements-table-amount-value'>
            <input
                type='number'
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                style={style}
                min={1}
            />
        </span>
    );
};

interface Props {
    disabled: boolean;
    index: number;
    item: IProductItem;
    addNewItem: (quantity: number) => void;
    addQuantity: (index: number) => void;
    removeItem: (index: number) => void;
    removeQuantity: (index: number) => void;
    setQuantity: (index: number, quantity: number) => void;
    onBlurQuantity: (index: number, quantity: number) => void;
}

const PlacementAmount = ({
    disabled,
    index,
    item,
    addNewItem,
    addQuantity,
    removeItem,
    removeQuantity,
    setQuantity,
    onBlurQuantity,
}: Props) => {
    const onMinusClick = () => {
        if (item.quantity === 1) {
            removeItem(index);
        } else {
            removeQuantity(index);
        }
    };

    const onPlusClick = () => {
        if (disabled) {
            addNewItem(1);
        } else {
            addQuantity(index);
        }
    };

    const rowClasses = [
        'd-flex',
        'gap-1',
        'flex-lg-column-reverse',
        'justify-content-center',
        'my-3',
        'mt-sm-inherit',
        'm-lg-0',
        'align-items-center',
        'justify-content-center',
    ];
    const btnClasses = ['placements-table-amount-button', 'btn-primary'];
    const inputClasses = ['d-flex', 'justify-content-center'];

    return (
        <Row className={rowClasses.join(' ')}>
            <Col
                xs={3}
                className={[
                    ...btnClasses,
                    'placements-table-amount-button-minus',
                ].join(' ')}
                disabled={disabled}
                onClick={onMinusClick}
            >
                -
            </Col>
            <Col xs={6} className={inputClasses.join(' ')}>
                <PlacementAmountInput
                    disabled={disabled}
                    value={item.quantity}
                    setQuantity={setQuantity}
                    index={index}
                    addNewItem={addNewItem}
                    onBlurQuantity={onBlurQuantity}
                />
            </Col>
            <Col
                xs={3}
                className={[
                    ...btnClasses,
                    'placements-table-amount-button-plus',
                ].join(' ')}
                onClick={onPlusClick}
            >
                +
            </Col>
        </Row>
    );
};

export default PlacementAmount;
