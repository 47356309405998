import React from 'react';
import { Nav } from 'react-bootstrap';
import IRoute from '../types/IRouteType';
import {
    generatePath,
    Link,
    useMatch,
    useResolvedPath,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './AdminNav.scss';
import { Restricted } from '../pages/Auth/components/Restricted';

interface Props {
    show: boolean;
    links: IRoute[];
}

export const NoneAuthAdminNav: React.FC<Props> = ({ show, links }) => {
    const { t } = useTranslation();

    return (
        <Nav
            variant='pills'
            className='nav-flush bg-side-nav flex-row flex-lg-column px-2 px-sm-3 p-lg-0 sidebar'
        >
            {links.map((r: any) => {
                return (
                    <NavLink key={r.name} path={r.path}>
                        {r.icon ? (
                            <div className='fw-light text-uppercase text-white'>
                                <div>{r.icon}</div>
                                <div className='pt-2'>
                                    {r.name && t(r.name)}
                                </div>
                            </div>
                        ) : (
                            <div className='fw-light text-uppercase text-white'>
                                {r.name && t(r.name)}
                            </div>
                        )}
                    </NavLink>
                );
            })}
        </Nav>
    );
};

export const AdminNav: React.FC<Props> = (props) => {
    return (
        <Restricted toAuth>
            <NoneAuthAdminNav {...props} />
        </Restricted>
    );
};

interface NavLinkProps {
    path: string;
}

const NavLink: React.FC<NavLinkProps> = ({ path, children }) => {
    const to = generatePath(`../${path}`);
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: false });

    return (
        <Nav.Item
            className={`p-0 m-0 d-flex flex-grow-1 flex-lg-grow-0 justify-content-center btn ${
                match ? 'btn-secondary' : 'btn-side-nav'
            } border-bottom-0 rounded-0 rounded-top border border-side-nav`}
        >
            <Nav.Link
                as={Link}
                to={to}
                className='admin-nav-link py-3 py-md-4 px-1 d-flex flex-grow-1 justify-content-center'
            >
                {children}
            </Nav.Link>
        </Nav.Item>
    );
};
