import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet } from 'react-router';
import { ProductFormPage } from '../pages/Products/ProductFormPage';
import { ProductItemsPage } from '../pages/Products/ProductItemsPage';
import { ProductEnvironmentDataPage } from '../pages/Products/ProductEnvironmentDataPage';
import { ProductPage } from '../pages/Products/ProductPage';
import { ProductsPage } from '../pages/Products/ProductsPage';
import { AdminSideNavLayout } from '../components/Layout/AdminSideNavLayout';
import { ProjectFormPage } from '../pages/Projects/ProjectFormPage';
import { ProjectPage } from '../pages/Projects/ProjectPage';
import { ProjectsPage } from '../pages/Projects/ProjectsPage';
import { IPrivateRoute } from '../types/IRouteType';
import { routesPrivate } from './routes';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { ICategory } from '../types/ICategory';
import IServerResponseType from '../types/IServerResponseType';

const Projects = () => {
    // Categories
    const [{ data }, refetch] = useAxios<IServerResponseType<ICategory[]>>(
        `/api/categories/`,
        { manual: true }
    );

    const parentize = (category: ICategory, parent: ICategory) => {
        // Can probably do this in BE instead
        category.parent = parent;
        category.children.forEach((c) => parentize(c, category));
        return category;
    };
    const categories = (data?.data || []).map((c) => parentize(c, c.parent));
    useEffect(() => {
        refetch();
    }, [refetch]);
    const [selectedCategory, setSelectedCategory] = useState<ICategory>();

    return (
        <AdminSideNavLayout routesPrivate={routesPrivate}>
            <Outlet
                context={{ categories, selectedCategory, setSelectedCategory }}
            />
        </AdminSideNavLayout>
    );
};

export const projectsPrivateRoutes: IPrivateRoute[] = [
    {
        name: 'Projects',
        path: 'projects',
        icon: <FontAwesomeIcon size='2x' icon={faTools} />,
        element: <Projects />,
        children: [
            {
                name: 'Projects',
                index: true,
                element: <ProjectsPage />,
            },
            {
                path: ':projectId',
                element: <ProjectPage />,
            },
            {
                path: ':projectId/project-form',
                element: <ProjectFormPage />,
            },
            {
                path: ':projectId/products',
                element: <ProductsPage />,
            },
            {
                path: ':projectId/products/:productId',
                element: <ProductPage />,
            },
            {
                path: ':projectId/product-form',
                element: <ProductFormPage isNewProduct />,
            },
            {
                path: ':projectId/product-form/:productId',
                element: <ProductFormPage />,
            },
            {
                path: ':projectId/product-items',
                element: <ProductItemsPage />,
            },
            {
                path: ':projectId/product-environment-data',
                element: <ProductEnvironmentDataPage />,
            },
        ],
    },
];
