import React, { useCallback, useEffect, useState } from 'react';
import { Page } from '../../../components/Layout/Page';

import useAxios from 'axios-hooks';

import {
    Badge,
    Breadcrumb,
    ButtonToolbar,
    Card,
    Container,
    ListGroup,
    Stack,
} from 'react-bootstrap';

import { useThrottle } from '@react-hook/throttle';
import IServerResponseWithTotalType from '../../../types/IServerResponseWithTotalType';

import { useTranslation } from 'react-i18next';

import { SearchInput } from '../../../components/SearchInput';
import { useTableReducer } from '../../../hooks/useTableReducer';
import { useWindowSize } from '../../Organisation/hooks/useWindowSize';
import { Column, Row as TRow } from 'react-table';
import { Table } from '../../../components/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { ILogEntry } from '../../../types/ILogEntry';
import { useHandleModal } from '../../../hooks/useHandleModal';
import { InfoModal } from '../../../components/Modals/InfoModal';
import { CollapsibleText } from '../../../components/CollapsibleText';
import { tryFormat } from '../../../utils/helper';
import { SEO } from '../../../components/SEO';

interface Props {}

export const SystemadminLogsPage = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useThrottle('', 2);

    const { t } = useTranslation();

    // Pagination
    const [{ queryPageIndex, queryPageSize, sortBy }, dispatch] =
        useTableReducer();

    // API fetch logs
    const [{ data, error }, refetch] = useAxios<
        IServerResponseWithTotalType<ILogEntry[]>
    >(
        `/api/systemlogs?skip=${
            queryPageSize * queryPageIndex
        }&limit=${queryPageSize}&sortBy=${sortBy?.id || 'id'}&direction=${
            !sortBy?.desc ? 'asc' : 'desc'
        }&search=${search}`,
        {
            manual: true,
        }
    );

    const logEntries: ILogEntry[] = data?.data ?? [];
    const total: number = data?.total ?? -1;

    const getColumn = useCallback(
        (header: string, key: keyof ILogEntry) => {
            return {
                id: key,
                Header: t(header),
                accessor: (row: ILogEntry) => row[key],
            };
        },
        [t]
    );

    const modal = useHandleModal<ILogEntry>();

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns = width && width < 992 ? ['id', 'message', 'user'] : [];

    const columns = React.useMemo<Column<ILogEntry>[]>(
        () => [
            getColumn('ID', 'id'),
            {
                id: 'created_at',
                Header: t('Time') as string,
                accessor: (row: ILogEntry) =>
                    tryFormat(row.created_at, 'yyyy-MM-dd HH:mm:ss'),
            },
            getColumn('Type', 'type'),
            {
                id: 'message',
                Header: t('Message') as string,
                accessor: (row: ILogEntry) =>
                    (row['message']?.length || 0) < 50
                        ? row['message']
                        : row['message'].substring(0, 50) + '...',
            },
            {
                id: 'user',
                Header: t('User') as string,
                accessor: (row: ILogEntry) => row['user']?.name,
            },
        ],
        [getColumn, t]
    );
    const actions = React.useMemo(
        () => [
            {
                name: 'Info',
                render: (instance: TRow<ILogEntry>) => (
                    <div
                        onClick={() => {
                            modal.setItem(instance.original);
                            modal.showModal();
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon
                            icon={faInfo}
                            width='15'
                            className='cursor-pointer'
                        />
                    </div>
                ),
            },
        ],
        [modal]
    );

    const modalBody = useCallback(() => {
        const entry = modal.item;
        const items = [entry?.organisation, entry?.project, entry?.product];
        const itemNames = [
            t('Affected organisation'),
            t('Affected project'),
            t('Affected product'),
        ];
        if (!entry) return <></>;

        return (
            <ListGroup as='ol'>
                <ListGroup.Item
                    as='li'
                    className='d-flex justify-content-between align-items-start'
                >
                    <div className='ms-2 me-auto'>
                        <div className='fw-bold text-muted'>{t('Type')}</div>
                        {entry.type}
                    </div>
                    <Badge bg='primary' pill>
                        {tryFormat(entry.created_at, 'yyyy-MM-dd HH:mm:ss')}
                    </Badge>
                </ListGroup.Item>
                <ListGroup.Item
                    as='li'
                    className='d-flex justify-content-between align-items-start'
                >
                    <div className='ms-2 me-auto'>
                        <div className='fw-bold text-muted'>{t('Message')}</div>
                        <span className='text-break'>{entry.message}</span>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className='ms-2 me-auto'>
                        <div className='fw-bold text-muted'>{t('Data')}</div>
                        <pre>
                            <CollapsibleText
                                text={JSON.stringify(
                                    JSON.parse(entry.data),
                                    null,
                                    2
                                )}
                                charLimit={250}
                            />
                        </pre>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item
                    as='li'
                    className='d-flex justify-content-between align-items-start'
                >
                    <div className='ms-2 me-auto'>
                        <div className='fw-bold text-muted'>
                            {t('Responsible user')}
                        </div>
                        <Stack direction='horizontal' gap={2}>
                            <Stack>
                                <div className='text-muted'>{t('Id')}: </div>
                                <div className='text-muted'>{t('Name')}: </div>
                                <div className='text-muted'>{t('Email')}: </div>
                            </Stack>
                            <Stack>
                                <div>{entry.user?.id}</div>
                                <div>{entry.user?.name}</div>
                                <div>{entry.user?.email}</div>
                            </Stack>
                        </Stack>
                    </div>
                </ListGroup.Item>
                {items.map(
                    (item, i) =>
                        item && (
                            <ListGroup.Item
                                as='li'
                                className='d-flex justify-content-between align-items-start'
                            >
                                <div className='ms-2 me-auto'>
                                    <div className='fw-bold text-muted'>
                                        {itemNames[i]}
                                    </div>
                                    <Stack direction='horizontal' gap={2}>
                                        <Stack>
                                            <div className='text-muted'>
                                                {t('Id')}:{' '}
                                            </div>
                                            <div className='text-muted'>
                                                {t('Name')}:{' '}
                                            </div>
                                        </Stack>
                                        <Stack>
                                            <div>{item.id}</div>
                                            <div>
                                                {item.title ||
                                                    (item as any).name}
                                            </div>
                                        </Stack>
                                    </Stack>
                                </div>
                            </ListGroup.Item>
                        )
                )}
            </ListGroup>
        );
    }, [modal, t]);

    useEffect(() => {
        setLoading(true);
        refetch()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [refetch, sortBy, queryPageSize, queryPageIndex]);

    if (error) return <p>Error!</p>;

    return (
        <Page className='px-0 px-sm-inherit'>
            <Container fluid className='px-0 px-sm-inherit'>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    <Breadcrumb>
                        {/* handles breadcrumb to change between system admin page and orgadmin page */}
                        <Breadcrumb.Item>{t('System admin')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {t('System logs')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </ButtonToolbar>

                <Card>
                    <Card.Body>
                        <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <SEO title={t('System logs')} asH6></SEO>
                            </div>

                            <div className='d-flex align-items-center '>
                                <div className='d-flex gap-3 flex-column flex-md-row flex-fill'>
                                    <div className='d-flex justify-content-end'>
                                        <SearchInput
                                            onChange={setSearch}
                                            placeholder={t('Search')}
                                            size='sm'
                                        />
                                    </div>
                                </div>
                            </div>
                        </ButtonToolbar>
                        <Table<ILogEntry>
                            name={'System logs'}
                            columns={columns}
                            data={logEntries}
                            initialPageSize={queryPageSize || 0}
                            initialPageIndex={queryPageIndex || 0}
                            initialSortBy={sortBy || { id: 'id', desc: true }}
                            loading={loading}
                            hiddenColumns={hiddenColumns}
                            actions={actions}
                            dispatch={dispatch}
                            size='sm'
                            variant='sysadmin'
                            total={total}
                        />
                    </Card.Body>
                </Card>
            </Container>

            <InfoModal
                handleClose={modal.closeModal}
                show={modal.show}
                headerText={t('Log entry') + ' #' + modal.item?.id}
                body={modalBody()}
            />
        </Page>
    );
};
