import React, { useCallback, useEffect, useState } from 'react';
import {
    ButtonToolbar,
    Button,
    Container,
    Card,
    Breadcrumb,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ADDIcon } from '../../../assets/icones/Dacke_nytt.svg';

import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Table } from '../../../components/Table';
import { Column, Row as TRow, SortingRule } from 'react-table';
import { useThrottle } from '@react-hook/throttle';
import IUserType from '../../../types/IUserType';
import { Page } from '../../../components/Layout/Page';
import { useWindowSize } from '../../Organisation/hooks/useWindowSize';
import { UserCreateModal } from '../components/UserCreateModal';
import { ReInviteUserModal } from '../../../components/Modals/ReInviteUserModal';
import { useHandleModal } from '../../../hooks/useHandleModal';
import { DeleteUserModal } from '../../../components/Modals/DeleteUserModal';
import { useTableReducer } from '../../../hooks/useTableReducer';
import { SearchInput } from '../../../components/SearchInput';
import { SEO } from '../../../components/SEO';

interface Props {}

export const SystemadminUsersPage = (props: Props) => {
    const { t } = useTranslation();

    const [{ queryPageIndex, queryPageSize, sortBy }, dispatch] =
        useTableReducer();

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useThrottle('', 2);

    const [users, setUsers] = useState<IUserType[]>([]);
    const [editUser, setEditUser] = useState<IUserType>();
    const [total, setTotal] = useState<number>(-1);

    // Create User Modal
    const [showUserCreateModal, setShowUserCreateModal] = useState(false);

    const handleUserCreateModalClose = (newUserWasCreated: boolean) => {
        if (newUserWasCreated)
            fetchAPIData(queryPageSize, queryPageIndex, sortBy);
        setShowUserCreateModal(false);
    };
    const handleUserCreateModalShow = (user?: IUserType) => {
        setEditUser(user);
        setShowUserCreateModal(true);
    };
    // Re Invite user Modal
    const reInviteModal = useHandleModal<IUserType>();
    const deleteModal = useHandleModal<IUserType>({
        onClose: () => fetchAPIData(queryPageSize, queryPageIndex, sortBy),
    });

    // Reset form if editUser changes
    useEffect(() => {
        if (!showUserCreateModal) setEditUser(undefined);
    }, [setEditUser, showUserCreateModal]);

    // TODO: Needs to be updated with all different roles that is going to exist
    const userRoleToString = useCallback(
        (user: IUserType) => {
            if (user.is_sys_admin) return t('System admin');

            return t('System user');
        },
        [t]
    );

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns =
        width && width < 1000 ? ['id', 'email', 'is_active', 'phone'] : [];

    const columns = React.useMemo<Column<IUserType>[]>(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                canSort: true,
            },
            {
                Header: 'Email',
                accessor: 'email',
                canSort: true,
            },
            {
                Header: 'Phone number',
                accessor: 'phone',
                canSort: true,
            },
            {
                Header: 'Role',
                canSort: false,
                accessor: (row) => userRoleToString(row),
            },
            {
                Header: t('Active') as string,
                id: 'is_active',
                canSort: false,
                accessor: (row) =>
                    row.is_active ? (
                        <span className='text-success'>{t('Yes')}</span>
                    ) : (
                        <span className='text-danger'>{t('No')}</span>
                    ),
            },
        ],
        [t, userRoleToString]
    );

    const fetchAPIData = useCallback(
        async (
            pageSize: number,
            pageIndex: number,
            sortBy?: SortingRule<string>
        ) => {
            try {
                setLoading(true);

                const sortStr = sortBy
                    ? `&sortBy=${sortBy.id}&direction=${
                          sortBy.desc ? 'desc' : 'asc'
                      }`
                    : '';

                const response = await axios.get(
                    `/api/users?limit=${pageSize}&skip=${
                        pageIndex * pageSize
                    }${sortStr}&search=${searchTerm}`
                );

                if (response && response.data.data) {
                    const { data: d, total: t } = response.data;
                    setUsers(d);
                    setTotal(t);

                    setLoading(false);
                }
            } catch (e) {
                console.error('Error while fetching', e);
                setLoading(false);
            }
        },
        [searchTerm]
    );

    const actions = React.useMemo(
        () => [
            {
                name: 'Re-invite',
                render: (instance: TRow<IUserType>) => (
                    <div
                        onClick={() => {
                            reInviteModal.setItem(instance.original);
                            reInviteModal.showModal();
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon icon={faPaperPlane} width='12' />
                    </div>
                ),
            },
            {
                name: 'Delete',
                render: (instance: TRow<IUserType>) => (
                    <div
                        onClick={() => {
                            deleteModal.setItem(instance.original);
                            deleteModal.showModal();
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon icon={faTrashCan} width='15' />
                    </div>
                ),
            },
            {
                name: 'Edit',
                render: (instance: TRow<IUserType>) => (
                    <div
                        onClick={() => {
                            handleUserCreateModalShow(instance.original);
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            width='15'
                            className='cursor-pointer'
                        />
                    </div>
                ),
            },
        ],
        [deleteModal, reInviteModal]
    );

    useEffect(() => {
        fetchAPIData(queryPageSize, queryPageIndex, sortBy);
    }, [fetchAPIData, queryPageSize, queryPageIndex, sortBy]);

    return (
        <Page className='px-0 px-sm-inherit'>
            <Container fluid className='px-0 px-sm-inherit'>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    <Breadcrumb>
                        <Breadcrumb.Item>{t('System admin')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('Users')}</Breadcrumb.Item>
                    </Breadcrumb>
                </ButtonToolbar>

                <Card>
                    <Card.Body>
                        <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <SEO title={t('Users')} asH6></SEO>
                            </div>
                            <div className='d-flex align-items-center '>
                                <div className='d-flex gap-3 flex-column flex-md-row flex-fill d-none d-sm-block'>
                                    <div className='d-flex justify-content-end'>
                                        <SearchInput
                                            onChange={setSearchTerm}
                                            placeholder={t('Search')}
                                            size='sm'
                                        />
                                        <Button
                                            onClick={() =>
                                                handleUserCreateModalShow()
                                            }
                                            variant='secondary'
                                            size='sm'
                                            className='m-1 ms-4 d-none d-sm-block'
                                        >
                                            <ADDIcon width='12' />
                                            {t('Add new')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </ButtonToolbar>
                        <Table<IUserType>
                            name={'Users'}
                            columns={columns}
                            data={users}
                            initialPageSize={queryPageSize || 0}
                            initialPageIndex={queryPageIndex || 0}
                            initialSortBy={
                                sortBy || { id: 'name', desc: false }
                            }
                            loading={loading}
                            hiddenColumns={hiddenColumns}
                            actions={actions}
                            dispatch={dispatch}
                            size='sm'
                            variant='sysadmin'
                            total={total}
                        />
                        <Button
                            onClick={() => handleUserCreateModalShow()}
                            variant='secondary'
                            className='d-sm-none m-1 w-100'
                        >
                            <ADDIcon width='12' />
                            {t('Add new')}
                        </Button>
                    </Card.Body>

                    <UserCreateModal
                        show={showUserCreateModal}
                        handleClose={handleUserCreateModalClose}
                        handleShow={handleUserCreateModalShow}
                        editUser={editUser}
                    ></UserCreateModal>
                    <ReInviteUserModal
                        show={reInviteModal.show}
                        user={reInviteModal.item}
                        handleClose={reInviteModal.closeModal}
                    ></ReInviteUserModal>
                    <DeleteUserModal
                        show={deleteModal.show}
                        user={deleteModal.item}
                        handleClose={deleteModal.closeModal}
                        apiRoute={`/api/users/${deleteModal.item?.id}`}
                    ></DeleteUserModal>
                </Card>
            </Container>
        </Page>
    );
};
