import React from 'react';

interface AccordionToggleProps {
    eventKey: string;
    toggle: (key: string) => void;
    disabled: boolean;
}
export const AccordionToggle: React.FC<AccordionToggleProps> = ({
    children,
    eventKey,
    toggle,
    disabled,
}) => {
    return <div onClick={() => !disabled && toggle(eventKey)}>{children}</div>;
};
