import { Col, Row } from 'react-bootstrap';

export const CardRow = ({ title, value }: { title?: string; value?: string }) =>
    title && value ? (
        <Row>
            <Col xs={6} className='fw-bold'>
                {title}
            </Col>
            <Col xs={6} className='text-break'>
                {value}
            </Col>
        </Row>
    ) : null;
