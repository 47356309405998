import React, { useCallback, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { PropertyFilterInputSwitch } from '../../PropertyFilterInputSwitch';
import { useProductFormState } from '../ProductFormState';
import { IProporty } from '../../../../../types/IProductProperty';
import useAxios from 'axios-hooks';
import IServerResponseType from '../../../../../types/IServerResponseType';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import IProductFieldType from '../../../../../types/IProductFieldType';
import IProductMetaType from '../../../../../types/IProductMetaType';

interface Props {
    customProductFields: IProductFieldType[];
}

export const PropertiesForm: React.FC<Props> = (props: Props) => {
    const { projectId, productId } = useParams();
    const { organisation } = useAuth();

    const product_data = useProductFormState();

    const { category_id } = product_data;

    const {
        setProductProperies,
        productProperies,
        setProductMeta,
        ...basicInformation
    } = useProductFormState();

    const [{ data: propertiesData }, refetchProperties] = useAxios<
        IServerResponseType<IProporty[]>
    >(
        `/api/organisations/${organisation?.id}/properties?category_id=${category_id}`,
        {
            manual: true,
        }
    );

    useEffect(() => {
        if (organisation && category_id) {
            refetchProperties();
        }
    }, [organisation, category_id, refetchProperties]);

    const updateProductProperie = useCallback(
        (id, value) => {
            if (productId) {
                // find the property
                const productProperties = productProperies.find(
                    (p) => p.property_id === id
                );

                if (productProperties && productProperties.id) {
                    axios
                        .put(
                            `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productproperties/${productProperties?.id}`,
                            { ...productProperties, value }
                        )
                        .then((res) => {
                            setProductProperies(id, value);
                        });
                } else {
                    axios
                        .post(
                            `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productproperties`,
                            {
                                property_id: id,
                                value,
                            }
                        )
                        .then((res) => {
                            setProductProperies(id, value);
                        });
                }
            } else setProductProperies(id, value);
        },
        [
            organisation?.id,
            productId,
            productProperies,
            projectId,
            setProductProperies,
        ]
    );

    const updateProductMeta = useCallback(
        (meta_type_id: number) => {
            if (!productId)
                // For create product form, they post this in a big request
                return;
            const meta = basicInformation.product_meta?.find(
                (m: IProductMetaType) => m.meta_type_id === meta_type_id
            );
            if (meta && meta.id) {
                axios
                    .put(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productmetas/${meta.id}`,
                        meta
                    )
                    .then((res) => {
                        let newMeta: IProductMetaType = res.data.data;
                        setProductMeta(
                            newMeta.meta_type_id,
                            newMeta.meta_value,
                            newMeta.id
                        );
                    });
            } else {
                axios
                    .post(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productmetas`,
                        meta
                    )
                    .then((res) => {
                        let newMeta: IProductMetaType = res.data.data;
                        setProductMeta(
                            newMeta.meta_type_id,
                            newMeta.meta_value,
                            newMeta.id
                        );
                    });
            }
        },
        [
            basicInformation.product_meta,
            organisation?.id,
            productId,
            projectId,
            setProductMeta,
        ]
    );

    return (
        <Row className='px-1'>
            {/* propertiesData */}
            {propertiesData?.data?.map((property) => (
                <Col className='p-2' key={property.id} sm={6} xl={3} xs={12}>
                    <PropertyFilterInputSwitch
                        onChange={(key, value) =>
                            setProductProperies(key, value)
                        }
                        value={
                            productProperies.find(
                                (p) => p.property_id === property.id
                            )?.value
                        }
                        keyName={property.id || 0}
                        property={property}
                        update={(key, value) =>
                            updateProductProperie(key, value)
                        }
                    />
                </Col>
            ))}
            {props.customProductFields?.map(
                (p, i) =>
                    !p.favorite && (
                        <Col className='p-2' key={i} sm={6} xl={3} xs={12}>
                            <Form.Group>
                                <Form.Label>{p.meta_type.name}</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder={p.meta_type.name}
                                    onChange={(e) => {
                                        setProductMeta(
                                            p.meta_type.id,
                                            e.target.value
                                        );
                                    }}
                                    onBlur={() =>
                                        updateProductMeta(p.meta_type.id)
                                    }
                                    value={
                                        basicInformation.product_meta?.find(
                                            (m: IProductMetaType) =>
                                                m.meta_type_id ===
                                                p.meta_type.id
                                        )?.meta_value || ''
                                    }
                                />
                            </Form.Group>
                        </Col>
                    )
            )}
        </Row>
    );
};
