import React, { useCallback } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { SEO } from './SEO';

import { ICategory } from '../types/ICategory';
import { useProductSearch } from '../pages/Products/components/ProductSearch';

interface Props {
    category?: ICategory;
    active?: string;
    setSelectedCategory: (cat?: ICategory) => void;
    lastCategoryClickable?: boolean;
    to: string;
}

export const ProductsBreadCrumbs = ({
    active,
    category,
    setSelectedCategory,
    lastCategoryClickable,
    to,
}: Props) => {
    const { t } = useTranslation();
    const { setQueryState } = useProductSearch();

    const categoryBreadCrumbs = useCallback(() => {
        return [
            category?.parent?.parent?.parent,
            category?.parent?.parent,
            category?.parent,
            category,
        ].filter((c) => c);
    }, [category]);

    return (
        <Breadcrumb className='d-flex'>
            <Breadcrumb.Item
                linkAs={Link}
                linkProps={{
                    to,
                }}
                onClick={() => {
                    setSelectedCategory(undefined);
                    setQueryState('category_id', '');
                }}
                active={!category}
            >
                {categoryBreadCrumbs()?.length > 0 ? (
                    t('Products')
                ) : (
                    <SEO title={t('Products')} asText />
                )}
            </Breadcrumb.Item>
            {categoryBreadCrumbs()?.map(
                (c) =>
                    c && (
                        <Breadcrumb.Item
                            key={c.id}
                            linkAs={Link}
                            linkProps={{
                                to,
                            }}
                            onClick={() => {
                                setSelectedCategory(c);
                                setQueryState('category_id', c.id.toString());
                            }}
                            active={
                                !active &&
                                !lastCategoryClickable &&
                                c.id === category?.id
                            }
                        >
                            {c.name}
                        </Breadcrumb.Item>
                    )
            )}
            {active && <Breadcrumb.Item active>{active}</Breadcrumb.Item>}
        </Breadcrumb>
    );
};
