import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../pages/Auth/hooks/useAuth';
import { useSanctum } from 'react-sanctum';
import { useTranslation } from 'react-i18next';
import { Restricted } from '../../pages/Auth/components/Restricted';
import { Logo } from '../Logo';

interface Props {
    handleShow: () => void;
}

export const UserNav: React.FC<Props> = ({ handleShow }) => {
    const { user, organisations, organisation } = useAuth();
    const { clearAuthCache } = useAuth();
    const { signOut } = useSanctum();

    const { t } = useTranslation();

    return (
        <Restricted
            toAuth
            fallBackCompoonet={
                <Nav.Link as={Link} to='/login' className={'pe-0'}>
                    {t('Sign in')}
                </Nav.Link>
            }
        >
            <NavDropdown
                title={
                    <span className='d-flex'>
                        <Logo images={organisation?.organisation_logo} />
                        <span className='d-flex flex-column'>
                            <span>{user?.name}</span>
                            <small>
                                {organisation?.id
                                    ? String(organisation.id).padStart(3, '0')
                                    : ''}
                                , {organisation?.name}
                            </small>
                        </span>
                    </span>
                }
                bsPrefix={`d-flex nav-link pe-0  align-items-end`}
            >
                <NavDropdown.Item to='/admin/my-page' as={Link}>
                    {t('My page')}
                </NavDropdown.Item>

                <Restricted toSysAdmin>
                    <NavDropdown.Item to='/system-admin' as={Link}>
                        {t('System Admin')}
                    </NavDropdown.Item>
                </Restricted>

                <NavDropdown.Divider />
                {organisations && organisations?.length > 1 && (
                    <NavDropdown.Item onClick={() => handleShow()}>
                        {t('Choose organisation')}
                    </NavDropdown.Item>
                )}
                <NavDropdown.Item
                    onClick={() => {
                        (signOut as () => Promise<void>)()
                            .then(() => {
                                clearAuthCache && clearAuthCache();
                                window.location.reload();
                            })
                            .catch(() => {
                                clearAuthCache && clearAuthCache();
                                window.location.reload();
                            });
                    }}
                >
                    {t('Sign out')}
                </NavDropdown.Item>
            </NavDropdown>
        </Restricted>
    );
};
