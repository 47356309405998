import { Outlet } from 'react-router-dom';
import { AdminSideNavLayout } from '../components/Layout/AdminSideNavLayout';

import { IPrivateRoute } from '../types/IRouteType';
import { OrganisationFormPage } from '../pages/Organisation/pages/OrganisationFormPage';
import { SystemadminLogsPage } from '../pages/Systemadmin/pages/SystemadminLogsPage';
import { SystemadminOrganisationsPage } from '../pages/Systemadmin/pages/SystemadminOrganisationsPage';
import { SystemadminUsersPage } from '../pages/Systemadmin/pages/SystemadminUsersPage';

import { systemadminRoutes } from './routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClipboardList,
    faNetworkWired,
    faUsersCog,
} from '@fortawesome/free-solid-svg-icons';

const System = () => (
    <AdminSideNavLayout routesPrivate={systemadminRoutes[0].children}>
        <Outlet />
    </AdminSideNavLayout>
);

export const systemadminPrivetRouter: IPrivateRoute[] = [
    {
        element: <System />,
        sysAdmin: true,
        children: [
            {
                name: 'Organisations',
                path: 'organisations',
                icon: <FontAwesomeIcon size='2x' icon={faNetworkWired} />,
                element: <SystemadminOrganisationsPage />,
                sysAdmin: true,
            },
            {
                path: 'organisations/:organisationId',
                element: <OrganisationFormPage />,
                sysAdmin: true,
            },
            {
                name: 'Users',
                path: 'users',
                icon: <FontAwesomeIcon size='2x' icon={faUsersCog} />,
                element: <SystemadminUsersPage />,
                sysAdmin: true,
            },
            {
                name: 'SystemLogs',
                path: 'logs',
                icon: <FontAwesomeIcon size='2x' icon={faClipboardList} />,
                element: <SystemadminLogsPage />,
                sysAdmin: true,
            },
        ],
    },
];
