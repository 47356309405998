import i18n from 'i18next';
import { formatInTimeZone } from 'date-fns-tz';
import { ICategory } from '../types/ICategory';
import { IImageMedia } from '../types/IMediaType';

export const tryFormat = (dateString: string, f: string) => {
    try {
        const date = new Date(dateString);
        return formatInTimeZone(
            date,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            f
        );
    } catch (e) {
        return dateString;
    }
};

// Returns platforms default date formatting
export const tryFormatDefaultDate = (dateString?: string | null) => {
    if (dateString === undefined || dateString === null) return null;
    try {
        const date = new Date(dateString);
        return formatInTimeZone(
            date,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            'yyyy-MM-dd'
        );
    } catch (e) {
        return dateString;
    }
};

export const categorySortFn = (a: ICategory, b: ICategory) =>
    (a?.name && b?.name && a.name.localeCompare(b.name, 'sv')) ||
    a?.sort_order - b?.sort_order ||
    a?.id - b?.id ||
    0;

export const imageSortFn = (a: IImageMedia, b: IImageMedia) =>
    a.sort_order - b.sort_order || a?.id - b?.id || 0;

export const fmtNumber = (n?: number) =>
    n ? Intl.NumberFormat(i18n.resolvedLanguage).format(n) : '';
