import React from 'react';
import { Card } from 'react-bootstrap';
import { SEO } from './SEO';

interface Props {
    title?: string;
    description?: string;
    titleClassName?: string;
    descriptionClassName?: string;
}

export const PageCardTitle: React.FC<Props> = ({
    title,
    description,
    titleClassName,
    descriptionClassName,
}) => {
    const titleClasses = ['d-flex', titleClassName].join(' ');
    const descClasses = [
        'border-bottom',
        'border-2',
        'border-secondary',
        'flex-fill',
        (descriptionClassName || '').match(/h\d/gi) ? null : 'h1',
        descriptionClassName,
    ]
        .filter(Boolean)
        .join(' ');

    return (
        <Card.Title className={titleClasses}>
            <SEO
                title={title}
                description={description}
                className={descClasses}
            ></SEO>
        </Card.Title>
    );
};
