import React, { useState } from 'react';
import IProductEnvironmentData from '../../../../types/IProductEnvironmentDataType';
import { ProductEnvironmentDataExcelExportModal } from './ProductEnvironmentDataExcelExportModal';

export const ProductEnvironmentDataModalsContext = React.createContext<any>(
    {} as any
);

export const ProductEnvironmentDataModals: React.FC<any> = ({
    children,
    refetch,
}) => {
    const [showExcelExportModal, setShowExcelExportModal] = useState<
        IProductEnvironmentData[] | null
    >(null);

    return (
        <ProductEnvironmentDataModalsContext.Provider
            value={{
                setShowExcelExportModal,
                showExcelExportModal,
            }}
        >
            <ProductEnvironmentDataExcelExportModal
                selected={showExcelExportModal}
                onClose={() => {
                    setShowExcelExportModal(null);
                }}
                onSave={() => {}}
            ></ProductEnvironmentDataExcelExportModal>
            {children}
        </ProductEnvironmentDataModalsContext.Provider>
    );
};

export const useProductEnvironmentDataModals = () =>
    React.useContext(ProductEnvironmentDataModalsContext);
