import React, { useCallback, useEffect, useState } from 'react';

import { routes } from '../../routing/routes';

import { HeaderNavbar } from './HeaderNavbar';
import { AdminNav } from '../AdminNav';
import { useAuth } from '../../pages/Auth/hooks/useAuth';
import { IPrivateRoute } from '../../types/IRouteType';
import { Col, Container, Row } from 'react-bootstrap';

interface Props {
    routesPrivate?: IPrivateRoute[];
}

export const AdminSideNavLayout: React.FC<Props> = ({
    children,
    routesPrivate,
}) => {
    const [adminLinks] = useState<IPrivateRoute[]>(
        routesPrivate?.filter((route) => route.name) || []
    );
    const [filterdAdminLinks, setFilterdAdminLinks] = useState<IPrivateRoute[]>(
        []
    );

    const { isAdmin, isSysAdmin } = useAuth();

    const checkLinkAccess = useCallback(async () => {
        const awaitHasRoles = adminLinks.map(async (r) => {
            let hasAccess = false;

            if (r.sysAdmin) {
                hasAccess = await isSysAdmin();
            }

            if (r.admin) {
                hasAccess = (await isAdmin()) || (await isSysAdmin());
            }

            if (!r.sysAdmin && !r.admin) {
                hasAccess = true;
            }

            return Promise.resolve(hasAccess);
        });

        const hasRoles = await Promise.all(awaitHasRoles);
        const links = adminLinks.filter((r, i) => hasRoles[i]);

        setFilterdAdminLinks(links);
    }, [adminLinks, isAdmin, isSysAdmin]);

    useEffect(() => {
        checkLinkAccess();
    }, [checkLinkAccess]);

    return (
        <>
            <HeaderNavbar links={routes.filter((route) => route.name)} />

            <Container fluid>
                <Row>
                    <Col lg={1} id='sidebar-wrapper'>
                        <AdminNav show={true} links={filterdAdminLinks} />
                    </Col>
                    <Col>
                        <div id='page-content-wrapper'>{children}</div>
                    </Col>
                    {/* Filler div to make stuff not end up behind bottom navbar in mobile view */}
                    <div
                        className='d-lg-none'
                        style={{ minHeight: '5rem' }}
                    ></div>
                </Row>
            </Container>
        </>
    );
};
