import React, { useMemo } from 'react';
import {
    Button,
    ButtonToolbar,
    Accordion,
    Col,
    Modal,
    Row,
    Stack,
    Card,
} from 'react-bootstrap';
import { ICategory } from '../../../../types/ICategory';
import { RecursiveCategoryList } from '../RecursiveCategoryList';
import { CategorieToggler } from '../CategorieToggler';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../../components/SearchInput';
import { categorySortFn } from '../../../../utils/helper';

interface Props {
    show: boolean;
    handleClose: (e?: any) => void;
    categories: ICategory[];
    title: string;
}

const recursiveCategorySearch = (
    categories: ICategory[],
    search: string
): ICategory[] => {
    const searchLower = search.toLowerCase();

    /**
     * Find all categories that match or where ancestor matches.
     */
    const found = new Set<number>();

    function find(category: ICategory, parentMatches: boolean) {
        const { children } = category;

        const currentMatches = [category?.name, category?.search_words].some(
            (c) => c?.toLowerCase()?.includes(searchLower)
        );
        const childMatches = children.map((child) =>
            find(child, parentMatches || currentMatches)
        );
        const matches = currentMatches || childMatches.some((x) => !!x);

        if (matches || parentMatches) {
            found.add(category.id);
            return true;
        }

        return false;
    }

    categories.forEach((category) => find(category, false));

    /**
     * Clean and sort the tree.
     */
    function clean(categories: ICategory[]) {
        return categories
            .filter((category) => found.has(category.id))
            .sort(categorySortFn)
            .map((category) => {
                const rv = { ...category };
                rv.children = clean(rv.children);
                return rv;
            });
    }

    return clean(categories);
};

export const CategorySelectionModal: React.FC<Props> = ({
    show,
    handleClose,
    categories,
    title,
}) => {
    const { t } = useTranslation();

    const [selectedCategory, setSelectedCategory] =
        React.useState<ICategory | null>(null);

    const [searchCategory, setSearchCategory] = React.useState<string>('');

    const clearSearch = () => {
        setSelectedCategory(null);
        setSearchCategory('');
    };

    const onClose = () => {
        handleClose();
    };

    const onSubmit = () => {
        if (selectedCategory) {
            handleClose(selectedCategory.id);
        }
    };

    const select = (category: ICategory) => {
        setSelectedCategory(category);
    };

    const searchFilteredCategories = useMemo(
        () =>
            recursiveCategorySearch(
                categories.filter(
                    ({ name }) => name?.toLowerCase() !== 'övrigt'
                ),
                searchCategory
            ),
        [categories, searchCategory]
    );
    const ovrigtCategory = categories.find(
        ({ name }) => name?.toLowerCase() === 'övrigt'
    );

    return (
        <Modal size='lg' onHide={onClose} centered show={show}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {title} {' > '} {t('Choose category')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Card style={{ backgroundColor: '#f5f5f5', border: 'none' }}>
                    <Card.Header
                        style={{ backgroundColor: '#e6e6e6', border: 'none' }}
                    >
                        <ButtonToolbar className='d-flex justify-content-end'>
                            <SearchInput
                                placeholder={t('Search')}
                                value={searchCategory}
                                onChange={(value) => setSearchCategory(value)}
                            ></SearchInput>
                        </ButtonToolbar>
                    </Card.Header>
                    <Card.Body>
                        <Accordion alwaysOpen>
                            <Row>
                                {searchFilteredCategories.length < 1 &&
                                categories?.length ? (
                                    <Stack
                                        gap={2}
                                        className='align-items-center'
                                    >
                                        <p>
                                            {t(
                                                'No matches found, do you wish to add the product under Övrigt?'
                                            )}
                                        </p>
                                        <Button
                                            variant={
                                                selectedCategory?.name?.toLowerCase() ===
                                                'övrigt'
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                            onClick={() =>
                                                ovrigtCategory &&
                                                select(ovrigtCategory)
                                            }
                                        >
                                            {t('Yes')}
                                        </Button>
                                        <Button onClick={clearSearch}>
                                            {t('No')}
                                        </Button>
                                    </Stack>
                                ) : (
                                    searchFilteredCategories.map((c) => (
                                        <Col key={c.id} xs={12} sm={3}>
                                            <ul className='m-0 p-0'>
                                                {c.children.length > 0 ? (
                                                    <li
                                                        style={{
                                                            listStyleType:
                                                                'none',
                                                            fontWeight:
                                                                'bolder',
                                                        }}
                                                    >
                                                        <CategorieToggler
                                                            eventKey={c.name}
                                                        >
                                                            <span>
                                                                {c.name}
                                                            </span>
                                                        </CategorieToggler>
                                                        <ul
                                                            className='m-0 p-0 ps-2'
                                                            style={{
                                                                fontSize:
                                                                    '0.9rem',
                                                                fontWeight: 300,
                                                            }}
                                                        >
                                                            <Accordion.Collapse
                                                                eventKey={
                                                                    c.name
                                                                }
                                                            >
                                                                <RecursiveCategoryList
                                                                    categories={
                                                                        c.children
                                                                    }
                                                                    select={
                                                                        select
                                                                    }
                                                                    onlyEnds={
                                                                        true
                                                                    }
                                                                    selectedId={
                                                                        selectedCategory?.id
                                                                    }
                                                                />
                                                            </Accordion.Collapse>
                                                        </ul>
                                                    </li>
                                                ) : (
                                                    // im a end node...
                                                    <li
                                                        style={{
                                                            listStyleType:
                                                                'none',
                                                        }}
                                                    >
                                                        <span
                                                            onClick={() => {
                                                                select(c);
                                                            }}
                                                        >
                                                            {c.name}
                                                        </span>
                                                    </li>
                                                )}
                                            </ul>
                                        </Col>
                                    ))
                                )}
                            </Row>
                        </Accordion>
                    </Card.Body>
                </Card>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={onSubmit}
                    disabled={!selectedCategory}
                >
                    {t('Continue')} {'>>'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
