import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useViewSwitch } from './ViewSwitch';

import { ReactComponent as DackeListIcone } from './../../../assets/icones/dacke_listview-list.svg'; // Tell webpack this JS file uses this image
import { ReactComponent as DackeThumbnailIcone } from './../../../assets/icones/dacke_listview-thumbnails.svg'; // Tell webpack this JS file uses this image

interface Props {}

export const ViewToggler = (props: Props) => {
    const { view, handleChange, views } = useViewSwitch();

    return (
        <>
            <ToggleButtonGroup
                type='radio'
                name='radio'
                value={view}
                onChange={(value) => {
                    return handleChange(value);
                }}
            >
                {views.map((v, idx) => (
                    <ToggleButton
                        id={`tbg-radio-${v}`}
                        key={v}
                        type='radio'
                        variant={'outline-light'}
                        className={`p-0 rounded-1 ${idx === 0 ? 'me-2' : ''}`}
                        value={v}
                        style={{
                            color: '#999999',
                            borderColor: '#EBEBEB',
                        }}
                    >
                        {v === 'list-view' ? (
                            <DackeListIcone width={35} />
                        ) : (
                            <DackeThumbnailIcone width={35} />
                        )}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </>
    );
};
