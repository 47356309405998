import React from 'react';
import { Button } from 'react-bootstrap';
import { components } from 'react-select';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export const CustomSelectMenu: React.FC<any> = ({ children, ...props }) => {
    const { t } = useTranslation();
    const { organisation } = useAuth();

    const { setValue } = props;
    const { inputValue, parent } = props.selectProps;
    const { projectId } = useParams();

    const createPlacement = async (parent: number | null) => {
        const response = await axios.post(
            `/api/organisations/${organisation?.id}/projects/${projectId}/placements`,
            {
                title: inputValue,
                parent_placement_id: parent,
            }
        );

        if (response) {
            const { id, title } = response.data.data;

            setValue(
                {
                    value: id,
                    label: title,
                },
                'select-option'
            );
        }
    };

    return (
        <components.Menu {...props}>
            {children}
            <div className='d-flex'>
                <Button
                    className='p-1 rounded flex-grow-1'
                    disabled={!inputValue || inputValue.length < 1}
                    onClick={() => {
                        createPlacement(parent);
                    }}
                    // For some unknown reason onClick doesnt seem to trigger with touch
                    // (at least when using the browser emulator)
                    onTouchStart={() => {
                        createPlacement(parent);
                    }}
                >
                    + {t('Add new')}
                </Button>
            </div>
        </components.Menu>
    );
};
