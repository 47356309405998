import { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { Breadcrumb, ButtonToolbar, Container } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useThrottle } from '@react-hook/throttle';

import { Page } from '../../components/Layout/Page';
import { SearchInput } from '../../components/SearchInput';
import { SEO } from '../../components/SEO';
import { ProductEnvironmentDataManageButton } from './components/ProductEnvironmentDataManageButton';
import { ProductEnvironmentDataListView } from './components/ListViews/ProductEnvironmentDataListView';
import { ProductEnvironmentDataMobileView } from './components/ListViews/ProductEnvironmentDataMobileView';
import { TableSelectedProductEnvironmentDataProvider } from './components/SelectedProductEnvironmentDataContext';
import { ProductEnvironmentDataModals } from './components/ProductEnvironmentDataModals/ProductEnvironmentDataModals';

import { useAuth } from '../Auth/hooks/useAuth';
import { useTableReducer } from '../../hooks/useTableReducer';
import { useBreakpoint } from '../../hooks/useBreakpoint';

import IProjectType from '../../types/IProjectType';
import IServerResponseWithTotalType from '../../types/IServerResponseWithTotalType';
import ResourcePermissionsType from '../../types/ResourcePermissionsType';
import IProductEnvironmentData from '../../types/IProductEnvironmentDataType';

interface Props {}

export const ProductEnvironmentDataPage = (props: Props) => {
    const { pathname } = useLocation();
    const { organisation } = useAuth();
    const { projectId } = useParams();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useThrottle('', 2);
    const displayListView = useBreakpoint('up', 'md');

    // Pagination
    const [{ queryPageIndex, queryPageSize, sortBy }, dispatch] =
        useTableReducer();

    // API fetch project
    const [{ data: projectData }, refetchProject] = useAxios<
        IServerResponseWithTotalType<ResourcePermissionsType<IProjectType>>
    >(`/api/organisations/${organisation?.id}/projects/${projectId}`, {
        manual: true,
    });
    const project = projectData?.data;

    const url = `/api/organisations/${organisation?.id}${
        projectId ? '/projects/' + projectId : ''
    }/productenvironmentdata?limit=${queryPageSize}${
        queryPageIndex ? `&skip=${queryPageSize * queryPageIndex}` : ''
    }${
        sortBy?.id
            ? `&sortBy=${sortBy.id}&direction=${sortBy.desc ? 'desc' : 'asc'}`
            : ''
    }${search ? `&search=${search}` : ''}`;

    // API fetch products
    const [{ data, error }, refetch] = useAxios<
        IServerResponseWithTotalType<
            ResourcePermissionsType<IProductEnvironmentData>[]
        >
    >(url, { manual: true });
    const productEnvironmentData: ResourcePermissionsType<IProductEnvironmentData>[] =
        data?.data ?? [];
    const totalCount = data?.total ?? -1;

    useEffect(() => {
        if (!projectId) return;
        refetchProject();
    }, [projectId, refetchProject]);

    useEffect(() => {
        if (!organisation) return;
        setLoading(true);
        refetch()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [organisation, refetch, sortBy, queryPageSize, queryPageIndex]);

    if (error) return <p>Error!</p>;

    return (
        <TableSelectedProductEnvironmentDataProvider>
            <ProductEnvironmentDataModals refetch={refetch}>
                <Page className='px-0 px-sm-inherit'>
                    <Container fluid className='px-0 px-sm-inherit'>
                        <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                            <Breadcrumb className='d-flex align-content-center'>
                                {pathname.includes('/projects') && (
                                    <>
                                        <Breadcrumb.Item
                                            linkAs={Link}
                                            linkProps={{
                                                to: `/admin/projects`,
                                            }}
                                        >
                                            {t('Projects')}
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item
                                            linkAs={Link}
                                            linkProps={{
                                                to: `/admin/projects/${project?.id}`,
                                            }}
                                        >
                                            {project?.title}
                                        </Breadcrumb.Item>
                                    </>
                                )}
                                <Breadcrumb.Item active>
                                    <SEO
                                        title={t('Environment data')}
                                        asText
                                    ></SEO>
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            <div className='d-flex flex-row align-content-md-center justify-content-between gap-md-4'>
                                <SearchInput
                                    onChange={(value: string) =>
                                        setSearch(value)
                                    }
                                    placeholder={t('Search')}
                                ></SearchInput>
                            </div>
                        </ButtonToolbar>
                        <ButtonToolbar className='d-flex flex-md-row-reverse align-content-center gap-2 mb-4'>
                            <ProductEnvironmentDataManageButton />
                        </ButtonToolbar>

                        {error ? (
                            <p>Error!</p>
                        ) : (
                            <>
                                <div className='d-md-none'>
                                    <ProductEnvironmentDataMobileView
                                        {...{
                                            project,
                                            productEnvironmentData,
                                            queryPageIndex,
                                            queryPageSize,
                                            sortBy,
                                            totalCount,
                                            dispatch,
                                            loading,
                                        }}
                                    />
                                </div>
                                <div className='d-none d-md-block'>
                                    {displayListView && (
                                        <ProductEnvironmentDataListView
                                            {...{
                                                project,
                                                productEnvironmentData,
                                                queryPageIndex,
                                                queryPageSize,
                                                sortBy,
                                                totalCount,
                                                dispatch,
                                                loading,
                                            }}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </Container>
                </Page>
            </ProductEnvironmentDataModals>
        </TableSelectedProductEnvironmentDataProvider>
    );
};
