import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { useProjectFormState } from '../ProjectFormState';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Column, Row as TRow } from 'react-table';
import { useThrottle } from '@react-hook/throttle';

import { UserCreateModal } from './UserCreateModal';

import { ReactComponent as ADDIcon } from '../../../../../assets/icones/Dacke_nytt.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { ReInviteUserModal } from '../../../../../components/Modals/ReInviteUserModal';
import { SearchInput } from '../../../../../components/SearchInput';
import { useHandleModal } from '../../../../../hooks/useHandleModal';
import { useTableReducer } from '../../../../../hooks/useTableReducer';
import IUserType from '../../../../../types/IUserType';
import { useWindowSize } from '../../../../Organisation/hooks/useWindowSize';
import { Table } from '../../../../../components/Table';

interface Props {}

export const UsersForm = (props: Props) => {
    const { t } = useTranslation();
    const { ...project } = useProjectFormState();

    const [{ queryPageIndex, queryPageSize }, dispatch] = useTableReducer();

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useThrottle('', 2);

    const [editUser, setEditUser] = useState<IUserType>();

    const [users, setUsers] = useState<IUserType[]>([]);

    // Create User Modal
    const [showUserCreateModal, setShowUserCreateModal] = useState(false);

    const handleUserCreateModalClose = (newUserWasCreated: boolean) => {
        if (newUserWasCreated) fetchAPIData(queryPageSize, queryPageIndex);
        setShowUserCreateModal(false);
    };
    const handleUserCreateModalShow = (user?: IUserType) => {
        setEditUser(user);
        setShowUserCreateModal(true);
    };

    // Reset form if editUser changes
    useEffect(() => {
        if (!showUserCreateModal) setEditUser(undefined);
    }, [setEditUser, showUserCreateModal]);

    const [roles, setRoles] = useState<any[]>([]);

    const fetchRoles = useCallback(async () => {
        const response = await axios.get(`/api/roles`);

        if (response?.data?.data) {
            setRoles(response.data.data);
        }
    }, []);

    useEffect(() => {
        if (roles.length <= 0) fetchRoles();
    }, [fetchRoles, roles]);

    const userRoleToString = useCallback(
        (user: IUserType) => {
            const roleId = user?.roles?.find(
                (r) => Number(r?.pivot?.project_id) === Number(project.id)
            )?.pivot?.role_id;

            return roles.find((r) => r.id === roleId)?.name;
        },
        [roles, project.id]
    );

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns =
        width && width < 992 ? ['id', 'email', 'is_active', 'phone'] : [];

    const columns = React.useMemo<Column<IUserType>[]>(
        () => [
            {
                Header: t('Name') as string,
                accessor: 'name',
            },
            {
                Header: t('Email') as string,
                accessor: 'email',
            },
            {
                Header: t('Phone number') as string,
                accessor: 'phone',
            },
            {
                Header: t('Role') as string,
                accessor: (row) => userRoleToString(row),
            },
            {
                Header: t('Active') as string,
                id: 'is_active',
                accessor: (row) =>
                    row.is_active ? (
                        <span className='text-success'>{t('Yes')}</span>
                    ) : (
                        <span className='text-danger'>{t('No')}</span>
                    ),
            },
        ],
        [t, userRoleToString]
    );

    const fetchAPIData = useCallback(
        async (pageSize: number, pageIndex: number) => {
            try {
                setLoading(true);

                const response = await axios.get(
                    `/api/organisations/${project?.organisation_id}/projects/${
                        project?.id
                    }/users?limit=${pageSize}&skip=${
                        pageIndex * pageSize
                    }&search=${searchTerm}`
                );

                if (response && response.data.data) {
                    setUsers(response.data.data);

                    setLoading(false);
                }
            } catch (e) {
                console.error('Error while fetching', e);
                setLoading(false);
            }
        },
        [project?.id, project?.organisation_id, searchTerm]
    );

    // Re Invite user Modal
    const reInviteModal = useHandleModal<IUserType>();
    const actions = React.useMemo(
        () => [
            {
                name: 'Re-invite',
                render: (instance: TRow<IUserType>) => (
                    <div
                        onClick={() => {
                            reInviteModal.setItem(instance.original);
                            reInviteModal.showModal();
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon icon={faPaperPlane} width='12' />
                    </div>
                ),
            },
            {
                name: 'Edit',
                render: (instance: TRow<IUserType>) => (
                    <div
                        onClick={() => {
                            handleUserCreateModalShow(instance.original);
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            width='15'
                            className='cursor-pointer'
                        />
                    </div>
                ),
            },
        ],
        [reInviteModal]
    );

    useEffect(() => {
        if (project?.organisation_id)
            fetchAPIData(queryPageSize, queryPageIndex);
    }, [fetchAPIData, queryPageSize, queryPageIndex, project?.organisation_id]);

    return (
        <Row>
            <Col sm={12} xl={12}>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-end'>
                    <div className='d-flex align-items-center '>
                        <div className='d-flex gap-3 flex-column flex-md-row flex-fill d-none d-sm-block'>
                            <div className='d-flex justify-content-end'>
                                <SearchInput
                                    onChange={setSearchTerm}
                                    placeholder={t('Search')}
                                    size='sm'
                                />
                                {/* <Button variant="primary" size="sm" className="m-1">
                                                {t("Manage selected...")}...
                                            </Button>
                                            <Button variant="primary" size="sm" className="m-1">
                                                {t("Filter")}
                                            </Button> */}
                                <Button
                                    onClick={() => handleUserCreateModalShow()}
                                    variant='secondary'
                                    size='sm'
                                    className='m-1 ms-4 d-none d-sm-block'
                                >
                                    <ADDIcon width='12' />
                                    {t('Add new')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </ButtonToolbar>
                <Table<IUserType>
                    name={'User Table'}
                    columns={columns}
                    data={users}
                    initialPageSize={queryPageSize || 0}
                    initialPageIndex={queryPageIndex || 0}
                    loading={loading}
                    hiddenColumns={hiddenColumns}
                    actions={actions}
                    dispatch={dispatch}
                    size='sm'
                />
                <Button
                    onClick={() => handleUserCreateModalShow()}
                    variant='secondary'
                    className='d-sm-none m-1 w-100'
                >
                    <ADDIcon width='12' className='me-1' />
                    {t('Add new')}
                </Button>
            </Col>

            <UserCreateModal
                show={showUserCreateModal}
                handleClose={handleUserCreateModalClose}
                handleShow={handleUserCreateModalShow}
                projectId={project.id}
                organisationId={project.organisation_id}
                editUser={editUser}
                roles={roles}
            ></UserCreateModal>
            <ReInviteUserModal
                show={reInviteModal.show}
                user={reInviteModal.item}
                handleClose={reInviteModal.closeModal}
            ></ReInviteUserModal>
        </Row>
    );
};
