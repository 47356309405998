import IRoute from '../types/IRouteType';
import { MarketplacePage } from '../pages/Marketplace/MarketplacePage';
import { MarketplaceProductsPage } from '../pages/Marketplace/MarketplaceProductsPage';
import { MarketplaceProductPage } from '../pages/Marketplace/MarketplaceProductPage';
import { MarketplaceMyFavoritesPage } from '../pages/Marketplace/MarketplaceMyFavoritesPage';
import { Outlet, useNavigate, useOutletContext } from 'react-router';
import { Layout } from '../components/Layout/Layout';
import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    Navbar,
    Row,
} from 'react-bootstrap';
import CategoryNavList from '../components/CategoryNavList';
import { SearchInput } from '../components/SearchInput';
import { useProductSearch } from '../pages/Products/components/ProductSearch';
import { useTranslation } from 'react-i18next';
import { useHandleModal } from '../hooks/useHandleModal';
import { InfoModal } from '../components/Modals/InfoModal';
import { ProductTextFilters } from '../pages/Products/components/ProductTextFilters';
import { ProductPlacementFilters } from '../pages/Products/components/ProductPlacementFilters';
import { PropertyFilters } from '../pages/Products/components/PropertyFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faX } from '@fortawesome/free-solid-svg-icons';
import useAxios from 'axios-hooks';
import IServerResponseType from '../types/IServerResponseType';
import { useEffect, useState } from 'react';
import { ICategory } from '../types/ICategory';
import axios from 'axios';
import { cmsPublicRoutes } from './cmsRoutes';
import { Footer } from '../components/Layout/Footer';
import { Link } from 'react-router-dom';

const Marketplace = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { queryState, setQueryState, clearAllFilters } = useProductSearch();
    const modal = useHandleModal();
    const modalBody = () => {
        return (
            <Form>
                <div className='d-lg-none ps-3'>
                    <CategoryNavList
                        categories={categories}
                        setSelectedCategory={setSelectedCategory}
                    />
                    <hr />
                </div>
                <ProductTextFilters />
                <ProductPlacementFilters isMarketPlace={true} />
                <PropertyFilters isMarketPlace={true} />
                <div className='d-flex justify-content-center'>
                    <Link
                        className='btn btn-outline-secondary'
                        to={
                            window.location.href.includes('favorites')
                                ? 'favorites'
                                : 'products'
                        }
                        onClick={() => modal.closeModal()}
                    >
                        {t('Apply filters')}
                    </Link>
                </div>
            </Form>
        );
    };
    const numFilters = Object.keys(queryState).filter(
        (k) => !['limit', 'skip', 'sortBy', 'direction'].includes(k)
    ).length;

    // Categories
    const [{ data }, refetch] = useAxios<IServerResponseType<ICategory[]>>(
        `/api/categories/`,
        { manual: true }
    );
    const parentize = (category: ICategory, parent: ICategory) => {
        // Can probably do this in BE instead
        category.parent = parent;
        category.children.forEach((c) => parentize(c, category));
        return category;
    };
    const categories = data?.data?.map((c) => parentize(c, c.parent));
    useEffect(() => {
        refetch();
    }, [refetch]);
    const [selectedCategory, setSelectedCategory] = useState<ICategory>();
    useEffect(() => {
        if (queryState.category_id && !selectedCategory) {
            axios
                .get<IServerResponseType<ICategory>>(
                    `/api/categories/${queryState.category_id}`
                )
                .then((res) => {
                    if (res.data.data) {
                        setSelectedCategory(res.data.data);
                    }
                });
        }
    }, [queryState.category_id, selectedCategory]);

    const filterButton = () => (
        <ButtonGroup className='d-flex align-items-center'>
            <Button
                size='sm'
                onClick={modal.showModal}
                aria-controls='filter'
                aria-expanded={modal.show}
                variant='outline'
                className='bg-light text-gray border-primary text-nowrap'
            >
                <FontAwesomeIcon
                    className='me-1 text-secondary'
                    icon={faFilter}
                />
                {t('Apply filters')} ({numFilters})
            </Button>
            {!!numFilters && (
                <Button
                    size='sm'
                    variant='primary'
                    style={{
                        maxWidth: '4rem',
                    }}
                    onClick={() => {
                        setSelectedCategory(undefined);
                        clearAllFilters();
                    }}
                >
                    <FontAwesomeIcon icon={faX} />
                </Button>
            )}
        </ButtonGroup>
    );

    return (
        <Layout>
            <Row>
                <Col id='page-content-wrapper'>
                    <Row>
                        <Col
                            lg={1}
                            id='inner-sidebar-wrapper'
                            style={{
                                left: 0,
                                right: 0,
                            }}
                        >
                            <Navbar
                                bg='light'
                                expand='lg'
                                className='align-items-start inner-sidebar py-0 pe-lg-4'
                                style={{
                                    left: '0px',
                                }}
                            >
                                <Navbar.Collapse className='mt-4'>
                                    <Container className='align-items-start'>
                                        <Row>
                                            <Col className='pb-3'>
                                                <SearchInput
                                                    onChange={(value: string) =>
                                                        setQueryState(
                                                            'search',
                                                            value
                                                        )
                                                    }
                                                    placeholder={t('Search')}
                                                    value={
                                                        queryState.search || ''
                                                    }
                                                    onAction={(value) => {
                                                        navigate('/products');
                                                    }}
                                                />
                                                <div className='pt-4'>
                                                    {filterButton()}
                                                </div>
                                            </Col>
                                        </Row>
                                        <CategoryNavList
                                            categories={categories}
                                            setSelectedCategory={
                                                setSelectedCategory
                                            }
                                        />
                                    </Container>
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>
                        <Col>
                            <Outlet
                                context={{
                                    categories,
                                    selectedCategory,
                                    setSelectedCategory,
                                    filterButton,
                                }}
                            />

                            <InfoModal
                                handleClose={modal.closeModal}
                                show={modal.show}
                                headerText={t('Filter products')}
                                headerIcon={faFilter}
                                body={modalBody()}
                                size='lg'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='p-0'>
                            <Footer
                                links={[
                                    ...marketplacePublicRoutes,
                                    ...cmsPublicRoutes,
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout>
    );
};
export function useCategories() {
    return useOutletContext<{
        categories: ICategory[];
        selectedCategory: ICategory;
        setSelectedCategory: (category?: ICategory) => void;
        filterButton: () => JSX.Element;
    }>();
}

export const marketplacePublicRoutes: IRoute[] = [
    {
        name: 'Start',
        path: '/',
        element: <Marketplace />,
        children: [
            {
                name: 'Start',
                index: true,
                element: <MarketplacePage />,
            },
            {
                name: 'Products',
                path: 'products',
                element: <MarketplaceProductsPage />,
            },
            {
                name: 'My favorites',
                path: 'favorites',
                element: <MarketplaceMyFavoritesPage />,
            },
            {
                path: 'products/:productId',
                element: <MarketplaceProductPage />,
            },
        ],
    },
];
