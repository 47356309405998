import { Pagination } from 'react-bootstrap';

export const TablePagination = ({
    to,
    active,
    pageCount,
    previousPage,
    nextPage,
    gotoPage,
    canNextPage,
    canPreviousPage,
    showAmount,
    pageSize,
    size,
}: any) => {
    const from = to > 2 ? to - 1 : 1;

    const items = [...Array(to - from + 2)].map((_, index) => {
        const number = from + index - 1;

        return (
            <Pagination.Item
                key={number}
                active={number === active}
                onClick={() => {
                    gotoPage(number);
                }}
            >
                {number + 1}
            </Pagination.Item>
        );
    });

    return (
        <Pagination className='m-0' size={size}>
            {/* always access page 0 */}
            <Pagination.First
                disabled={active === 0}
                onClick={() => {
                    gotoPage(0);
                }}
            />
            {/* access previous page */}
            <Pagination.Prev
                onClick={() => {
                    previousPage();
                }}
                disabled={!canPreviousPage}
            />

            {/* to many buttons.... */}
            {from > 1 ? <Pagination.Ellipsis disabled /> : null}
            {items}
            {canNextPage && <Pagination.Ellipsis disabled />}
            <Pagination.Next
                onClick={() => {
                    nextPage();
                }}
                disabled={!canNextPage || pageSize > showAmount}
            />
            {pageCount > 1 && <Pagination.Last />}
        </Pagination>
    );
};
