import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectFormState } from '../ProjectFormState';

interface Props {}

export const DescriptionPreview = (props: Props) => {
    const { t } = useTranslation();
    const project = useProjectFormState();

    return (
        <div>
            {project.description
                ? project.description.substring(0, 20) + '...'
                : t('Please fill in')}
        </div>
    );
};
