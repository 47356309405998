import React from 'react';
import { Image, Stack } from 'react-bootstrap';
import { useProductFormState } from '../ProductFormState';

const style = {
    width: 40,
    height: 40,
    objectFit: 'cover',
    borderRadius: '8px',
};

export const ImagesPreview = () => {
    const { productImages } = useProductFormState();

    return (
        <Stack direction='horizontal' gap={2}>
            {productImages
                .slice(0, 3)
                .map(({ id, preview, updated_at }) =>
                    preview ? (
                        <Image
                            key={id}
                            src={`${preview}?${updated_at}`}
                            width={style.width}
                            height={style.height}
                            style={style as React.CSSProperties}
                        />
                    ) : null
                )}
        </Stack>
    );
};
