import React from 'react';
import { Navbar } from 'react-bootstrap';
import logo from './../assets/images/Dacke_Online-logotype.png';

export const SimpleNavBar: React.FC = ({ children }) => {
    const colorTheme = 'var(--bs-primary)';

    return (
        <Navbar
            fixed='top'
            style={{ backgroundColor: colorTheme }}
            expand='lg'
            className='shadow'
            variant='dark'
            collapseOnSelect
        >
            <div className='container-fluid pl-2 pl-sm-0 ps-sm-4'>
                <Navbar.Brand href='/' className='p-0'>
                    <img alt='' src={logo} height='50' />
                </Navbar.Brand>
                {children}
            </div>
        </Navbar>
    );
};
