import React from 'react';
import { Image } from 'react-bootstrap';
import { IImageMediaGroup } from '../types/IMediaType';

interface CoverImageProps {
    images?: IImageMediaGroup;
    shape?: 'rounded' | 'circle' | 'square';
    size?: 'xs' | 'sm' | 'md' | 'lg';
    shadow?: 'shadow' | 'shadow-sm' | 'shadow-lg';
}

export const Logo: React.FC<CoverImageProps> = ({
    images,
    shape,
    size,
    shadow,
}) => {
    const getImgUrl = () => {
        let imageUrls = images?.medias?.sort((a: any, b: any) =>
            a.sort_order < b.sort_order ? -1 : 1
        )[0]?.url;
        let imageUrl =
            imageUrls?.small ??
            imageUrls?.thumbnail ??
            imageUrls?.medium ??
            imageUrls?.large;
        if (imageUrl) imageUrl = process.env.REACT_APP_API_ENDPOINT + imageUrl;

        return imageUrl;
    };
    const imgSrc = getImgUrl();
    const dimension =
        size === 'lg'
            ? '4rem'
            : size === 'md'
            ? '3rem'
            : size === 'xs'
            ? '1.5rem'
            : '2rem';
    if (!images?.medias || !images?.medias?.length || !imgSrc) return <></>;
    return (
        <Image
            src={getImgUrl()}
            className={'me-2 float-start ' + (shadow ? shadow : '')}
            roundedCircle={shape === 'circle'}
            rounded={shape === 'rounded' || shape === undefined}
            fluid
            style={{
                objectFit: 'cover',
                height: dimension,
                width: dimension,
                userSelect: 'none',
            }}
        />
    );
};
