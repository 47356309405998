import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { InfoModal } from '../../../../components/Modals/InfoModal';
import { IProductItem } from '../../../../types/IProductItem';

interface Props {
    show: boolean;
    placement: IProductItem;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ZeroPlacementsReachedModal = ({
    show,
    placement,
    onConfirm,
    onCancel,
}: Props) => {
    const { t } = useTranslation();
    return (
        <InfoModal
            handleClose={onCancel}
            show={show}
            headerText={t('Do you want to delete this placement row?')}
            headerIcon={faTriangleExclamation}
            body={
                <>
                    <p>
                        {t('Description')}: {placement?.description}
                    </p>
                    <p>{placement?.placementLevel?.[3]?.label}</p>
                    <p>{placement?.placementLevel?.[2]?.label}</p>
                    <p>{placement?.placementLevel?.[1]?.label}</p>
                    <p>{placement?.placementLevel?.[0]?.label}</p>
                </>
            }
            footer={
                <>
                    <Button onClick={onCancel}>{t('Cancel')}</Button>
                    <Button onClick={onConfirm}>{t('Confirm')}</Button>
                </>
            }
        />
    );
};
