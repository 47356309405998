import { Row } from 'react-bootstrap';

import { useProjectFormState } from '../ProjectFormState';
import { CollapsibleText } from '../../../../../components/CollapsibleText';
interface Props {}

export const ShowDescriptionForm = (props: Props) => {
    const { setFormState, ...project } = useProjectFormState();

    return (
        <Row>
            <div className='d-flex flex-column flex-grow-1 gap-2'>
                
                <CollapsibleText
                    text={project?.description}
                    charLimit={1000}
                />
            </div>
        </Row>
    );
};
