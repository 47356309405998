import axios from 'axios';
import React, { useState } from 'react';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IOrganisationMetaType from '../../../types/IOrganisationMetaType';

interface Props {
    handleClose: (newModalWasCreated: boolean) => void;
    handleShow: () => void;
    show: boolean;
}

export const OrganisationCreateModal = ({
    handleClose,
    handleShow,
    show,
}: Props) => {
    const { t } = useTranslation();
    const [pairs, setPairs] = useState<{ [key: string]: any }>({});
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

    const formControl = (key: string, type: string, maxLength: number) => {
        return (
            <>
                <Form.Control
                    isInvalid={errors[key]}
                    type={type}
                    onChange={(e) => {
                        setPairs((prev) => {
                            return { ...prev, [key]: e.target.value };
                        });
                        setErrors((prev) => {
                            return { ...prev, [key]: false };
                        });
                    }}
                    value={pairs[key] || ''}
                    maxLength={maxLength}
                />
                {errors[key] && (
                    <Form.Control.Feedback type='invalid'>
                        Please provide a valid {key}.
                    </Form.Control.Feedback>
                )}
            </>
        );
    };
    const save = () => {
        const { name, ...meta } = pairs;
        if (!name) {
            setErrors((prev) => {
                return { ...prev, name: true };
            });
            return;
        }
        // Convert meta data to backend type
        let metaDTO = Object.keys(meta).map<IOrganisationMetaType>((key) => ({
            id: 0,
            meta_key: key,
            meta_value: meta[key],
        }));

        // Post new organisation object with meta in one go
        const post = axios.post(`/api/organisations`, {
            name,
            template_id: 1,
            organisationMetaArray: metaDTO,
        });
        post.then((res) => {
            setPairs({});
            handleClose(true);
        });
    };

    return (
        <Modal show={show} onHide={() => handleClose(false)} centered size='lg'>
            <Modal.Header>
                <h4>{t('New Organisation')}</h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12} xl={12}>
                        <Form>
                            <Row>
                                <Col sm={12} lg={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Organisation name') + ':'}
                                        </Form.Label>
                                        {formControl('name', 'text', 100)}
                                    </Form.Group>
                                </Col>
                                <Col sm={6} lg={3}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Region') + ':'}
                                        </Form.Label>
                                        {formControl('region', 'text', 50)}
                                    </Form.Group>
                                </Col>
                                <Col sm={6} lg={3}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Country') + ':'}
                                        </Form.Label>
                                        {formControl('country', 'text', 50)}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Address') + ' 1:'}
                                        </Form.Label>
                                        {formControl(
                                            'addressLine1',
                                            'text',
                                            100
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Phone number') + ':'}
                                        </Form.Label>
                                        {formControl('phoneNumber', 'text', 50)}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Address') + ' 2:'}
                                        </Form.Label>
                                        {formControl(
                                            'addressLine2',
                                            'text',
                                            100
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Website') + ':'}
                                        </Form.Label>
                                        {formControl('website', 'text', 100)}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} lg={3}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('ZIP code') + ':'}
                                        </Form.Label>
                                        {formControl(
                                            'postalNumber',
                                            'text',
                                            10
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col sm={6} lg={3}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('City') + ':'}
                                        </Form.Label>
                                        {formControl('city', 'text', 50)}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => handleClose(false)}>
                    Close
                </Button>
                <Button variant='primary' onClick={save}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
