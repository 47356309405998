import React from 'react';
import { Form, Tooltip, OverlayTrigger, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export interface IProportyFilterInputSwitch {
    property: any;
    onChange: (key: number, value: any) => void;
    value: any;
    keyName: number;
    update?: (id: number, value: any) => void;
}
const renderTooltip = (text: string, props: any) => (
    <Tooltip id='button-tooltip' {...props}>
        {text}
    </Tooltip>
);
const overlay = (property: any) => {
    return (
        <OverlayTrigger
            placement='right'
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(property.description, {})}
        >
            <div className='d-inline-block text-primary'>
                <FontAwesomeIcon icon={faInfoCircle} width='16' />
            </div>
        </OverlayTrigger>
    );
};

export const PropertyFilterInputSwitch: React.FC<IProportyFilterInputSwitch> =
    ({ property, value, onChange, keyName, update }) => {
        switch (property.data_type) {
            case 1:
                return (
                    <Form.Group controlId={keyName.toString()}>
                        <Form.Label>
                            {`${property?.name} `}
                            {overlay(property)}
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                name={keyName.toString()}
                                onChange={(e) =>
                                    onChange(keyName, e.target.value)
                                }
                                type='text'
                                placeholder={property.name}
                                value={value || ''}
                                onBlur={(e) => {
                                    update && update(keyName, e.target.value);
                                }}
                            ></Form.Control>
                            {property?.unit?.symbol && (
                                <InputGroup.Text>
                                    {property.unit?.symbol}
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </Form.Group>
                );

            case 2:
                return (
                    <Form.Group controlId={keyName.toString()}>
                        <Form.Label>
                            {`${property?.name} `}
                            {overlay(property)}
                        </Form.Label>
                        <div>
                            <Form.Check
                                inline
                                type='switch'
                                id={keyName.toString()}
                                checked={value === 'true'}
                                onChange={(e) => {
                                    onChange &&
                                        onChange(keyName, e.target.checked.toString());
                                    update && update(keyName, e.target.checked.toString());
                                }}
                            />
                        </div>
                    </Form.Group>
                );

            case 3:
                return (
                    <Form.Group controlId={keyName.toString()}>
                        <Form.Label>
                            {`${property.name} `}
                            {overlay(property)}
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                name={keyName.toString()}
                                onChange={(e) =>
                                    onChange(keyName, e.target.value)
                                }
                                type='number'
                                placeholder={`${property.name}`}
                                value={value || ''}
                                onBlur={(e) => {
                                    update && update(keyName, e.target.value);
                                }}
                            ></Form.Control>
                            {property?.unit?.symbol && (
                                <InputGroup.Text>
                                    {property.unit?.symbol}
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </Form.Group>
                );

            case 4:
                return (
                    <Form.Group controlId={keyName.toString()}>
                        <Form.Label>
                            {`${property.name} `}
                            {overlay(property)}
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                name={keyName.toString()}
                                onChange={(e) =>
                                    onChange(keyName, e.target.value)
                                }
                                type='number'
                                placeholder={`${property.name}`}
                                value={value || ''}
                                onBlur={(e) => {
                                    update && update(keyName, e.target.value);
                                }}
                            ></Form.Control>
                            {property?.unit?.symbol && (
                                <InputGroup.Text>
                                    {property.unit?.symbol}
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </Form.Group>
                );

            case 5:
                return (
                    <Form.Group controlId={keyName.toString()}>
                        <Form.Label>
                            {`${property.name} `}
                            {overlay(property)}
                        </Form.Label>
                        <Form.Select
                            name={property.name}
                            onChange={(e) =>
                                (update &&
                                    update(
                                        keyName,
                                        (e.target as HTMLSelectElement).value
                                    )) ||
                                onChange(
                                    keyName,
                                    (e.target as HTMLSelectElement).value
                                )
                            }
                            placeholder={`${property.name}`}
                            value={value || ''}
                        >
                            {/* TODO: how to deal with option.name === 'Ej angivet' translations? */}
                            {property?.property_options.map((option: any) => (
                                <option
                                    key={option.id}
                                    value={
                                        option.name === 'Ej angivet'
                                            ? ''
                                            : option.name
                                    }
                                >
                                    {option.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                );

            default:
                return null;
        }
    };
