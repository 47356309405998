import React from 'react';

interface Props {}

export const Error500 = (props: Props) => {
    return (
        <div>
            <h1>500 Internal Server Error</h1>
        </div>
    );
};
