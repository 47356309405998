import React, { useState } from 'react';

const initContext: {
    view: string;
    toggleView: any;
    handleChange: any;
    views: string[];
} = {
    view: 'card-view',
    toggleView: () => {},
    handleChange: () => {},
    views: ['card-view', 'list-view'],
};

const ViewSwitchContext = React.createContext(initContext);

interface ProviderProps {}

export const ViewSwitchProvider: React.FC<ProviderProps> = ({ children }) => {
    const [view, setView] = useState(
        localStorage.getItem('view-switch-mode') || 'card-view'
    );
    const handleChange = (val: 'card-view' | 'list-view') => {
        localStorage.setItem('view-switch-mode', val);
        return setView(val);
    };
    const toggleView = () =>
        view === 'card-view'
            ? handleChange('list-view')
            : handleChange('card-view');

    return (
        <ViewSwitchContext.Provider
            value={{ ...initContext, view, toggleView, handleChange }}
        >
            {children}
        </ViewSwitchContext.Provider>
    );
};

export const useViewSwitch = () => React.useContext(ViewSwitchContext);
