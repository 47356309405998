import React, { useCallback, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import axios from 'axios';
import { PlacementSelect } from './PlacementSelect';
import { SelectState } from './PlacementsForm';
import PlacementAmount from './PlacementAmount';
import { useBreakpoint } from '../../../../../hooks/useBreakpoint';
import { IProductStatus } from '../../../../../types/IProductStatus';
import { useParams } from 'react-router';
import { IProductItem } from '../../../../../types/IProductItem';
import { IWasteLadderOption } from '../../../../../types/IWasteLadderOption';

import DatePicker from 'react-datepicker';
import i18next from 'i18next';

export const defaultSelectState: SelectState = {
    defaultOptions: [],
    isLoading: undefined,
};

export interface Props {
    item: IProductItem;
    index: number;
    disabled: boolean;
    removeItem: (id: number) => void;
    removeQuantity: (id: number) => void;
    setQuantity: (id: number, quantity: number) => void;
    onBlurQuantity: (id: number, quantity: number) => void;
    addQuantity: (id: number) => void;
    addNewItem: (id: number) => void;
    setPlacementLevel: (
        item_id: number,
        placement_id: number | null,
        placement: string
    ) => void;
    setPreliminary: (item_id: number, preliminary: boolean) => void;
    setStatus: (item_id: number, product_status_id: number) => void;
    setWasteLadder: (item_id: number, waste_ladder: string) => void;
    setViewStatus: (item_id: number, view_status: number) => void;
    setDescription: (item_id: number, description: string) => void;
    setDeliveryDate: (item_id: number, dismantle_date: Date) => void;
    onBlurDescription: (item_id: number, description: string) => void;
    productStatuses: IProductStatus[];
    wasteLadderOptions: IWasteLadderOption[];
    productViwingStatuses: string[];
    projectStartDate: Date;
    disabledLevels: {
        firstPlacementTitle: boolean;
        secondPlacementTitle: boolean;
        thirdPlacementTitle: boolean;
        fourthPlacementTitle: boolean;
    };
    placeholders: string[];
    onEdit: (placement: any) => void;
}

export const PlacementItem: React.FC<Props> = ({
    item,
    index,
    disabled,
    removeItem,
    removeQuantity,
    setQuantity,
    onBlurQuantity,
    addQuantity,
    addNewItem,
    setPlacementLevel,
    setPreliminary,
    setStatus,
    setWasteLadder,
    setViewStatus,
    setDeliveryDate,
    setDescription,
    onBlurDescription,
    productStatuses,
    wasteLadderOptions,
    productViwingStatuses,
    projectStartDate,
    disabledLevels,
    placeholders,
    onEdit,
}) => {
    const { t } = useTranslation();
    const { organisation } = useAuth();
    const { projectId } = useParams();
    const isMobile = useBreakpoint('down', 'sm');

    const [level1State, setLevel1State] =
        useState<SelectState>(defaultSelectState);
    const [level2State, setLevel2State] =
        useState<SelectState>(defaultSelectState);
    const [level3State, setLevel3State] =
        useState<SelectState>(defaultSelectState);
    const [level4State, setLevel4State] =
        useState<SelectState>(defaultSelectState);

    const filtedOptions = useCallback(
        async (inputValue: string, parent_placement_id: number | null) => {
            const response = await axios.get(
                `/api/organisations/${
                    organisation?.id
                }/projects/${projectId}/placements?search=${inputValue}${
                    parent_placement_id
                        ? `&parent_placement_id=${parent_placement_id}`
                        : ''
                }`
            );

            if (response) {
                const {
                    data: { data: placements },
                } = response;
                return await placements?.map((item: any) => ({
                    value: item.id,
                    label: item.title,
                    parent: item.parent_placement_id,
                }));
            }
        },
        [organisation?.id, projectId]
    );

    const formatWasteLadder = (l: string, list: IWasteLadderOption[]) =>
        l
            .split('|')
            .filter((x) => !!x.length)
            .map((w) => list.find((l) => l.id === parseInt(w, 10)));

    return (
        <div
            className={`d-flex placements-table-item ${
                disabled ? 'disabled' : ''
            }`}
        >
            {/* <div className="d-flex ps-2 placements-table-radio">
                <Form.Check disabled={disabled} type={"radio"} id={`radio`} />
                </div> */}
            <div className='d-flex flex-column flex-lg-row flex-grow-1'>
                <PlacementAmount
                    {...{
                        item,
                        quantity: item.quantity,
                        removeItem,
                        removeQuantity,
                        setQuantity,
                        onBlurQuantity,
                        addQuantity,
                        addNewItem,
                        disabled,
                        index,
                    }}
                />
                <div id='row-1' className='flex-grow-1 p-1'>
                    <Row className='align-items-stretch align-items-sm-center'>
                        {!disabledLevels.firstPlacementTitle && (
                            <Col xs={12} sm={3}>
                                <PlacementSelect
                                    index={0}
                                    placeholder={placeholders[0]}
                                    setLevelState={setLevel1State}
                                    levelState={level1State}
                                    onChange={(value) => {
                                        setPlacementLevel(
                                            index,
                                            value,
                                            'level1'
                                        );
                                    }}
                                    placementLevels={item.placementLevel || []}
                                    disabled={
                                        disabled ||
                                        disabledLevels.firstPlacementTitle
                                    }
                                    filtedOptions={filtedOptions}
                                    onEdit={onEdit}
                                ></PlacementSelect>
                            </Col>
                        )}
                        {!disabledLevels.secondPlacementTitle && (
                            <Col xs={12} sm={3}>
                                <PlacementSelect
                                    index={1}
                                    placeholder={placeholders[1]}
                                    setLevelState={setLevel2State}
                                    levelState={level2State}
                                    onChange={(value) => {
                                        setPlacementLevel(
                                            index,
                                            value,
                                            'level2'
                                        );
                                    }}
                                    placementLevels={item.placementLevel || []}
                                    disabled={
                                        disabled ||
                                        disabledLevels.secondPlacementTitle
                                    }
                                    filtedOptions={filtedOptions}
                                    onEdit={onEdit}
                                ></PlacementSelect>
                            </Col>
                        )}
                        {!disabledLevels.thirdPlacementTitle && (
                            <Col xs={12} sm={3}>
                                <PlacementSelect
                                    index={2}
                                    placeholder={placeholders[2]}
                                    setLevelState={setLevel3State}
                                    levelState={level3State}
                                    onChange={(value) => {
                                        setPlacementLevel(
                                            index,
                                            value,
                                            'level3'
                                        );
                                    }}
                                    placementLevels={item.placementLevel || []}
                                    disabled={
                                        disabled ||
                                        disabledLevels.thirdPlacementTitle
                                    }
                                    filtedOptions={filtedOptions}
                                    onEdit={onEdit}
                                ></PlacementSelect>
                            </Col>
                        )}
                        {!disabledLevels.fourthPlacementTitle && (
                            <Col xs={12} sm={3}>
                                <PlacementSelect
                                    index={3}
                                    placeholder={placeholders[3]}
                                    setLevelState={setLevel4State}
                                    levelState={level4State}
                                    onChange={(value) => {
                                        setPlacementLevel(
                                            index,
                                            value,
                                            'level4'
                                        );
                                    }}
                                    placementLevels={item.placementLevel || []}
                                    disabled={
                                        disabled ||
                                        disabledLevels.fourthPlacementTitle
                                    }
                                    filtedOptions={filtedOptions}
                                    onEdit={onEdit}
                                ></PlacementSelect>
                            </Col>
                        )}
                    </Row>

                    <div
                        id='row-2'
                        className='d-flex align-items-sm-center flex-grow-1 flex-column flex-lg-row'
                    >
                        <Col className='p-1 py-2'>
                            <Form.Check
                                type='switch'
                                label={t('Preliminary')}
                                checked={item.preliminary || false}
                                onChange={(e) => {
                                    const { checked } = e.target;
                                    setPreliminary(index, checked);
                                }}
                            />
                        </Col>

                        <Row className='mx-0'>
                            <Col
                                xs={6}
                                sm={5}
                                id='status'
                                className='p-1 d-flex'
                            >
                                <Form.Select
                                    disabled={disabled}
                                    aria-label='Default select example'
                                    value={item.product_status_id || 0}
                                    onChange={(e) => {
                                        const product_status_id = parseInt(
                                            (e.target as HTMLSelectElement)
                                                .value,
                                            10
                                        );
                                        setStatus(index, product_status_id);
                                    }}
                                >
                                    {productStatuses?.map((item, index) => (
                                        <option value={item.id} key={index}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>

                            <Col
                                xs={6}
                                id='waste-ladder'
                                className='p-1 d-flex'
                            >
                                <Select
                                    placeholder={t('Waste ladder')}
                                    isClearable
                                    isMulti
                                    value={formatWasteLadder(
                                        item.waste_ladder || '',
                                        wasteLadderOptions
                                    )}
                                    getOptionLabel={(o) => o?.name || ''}
                                    getOptionValue={(o) =>
                                        o?.id?.toString() || ''
                                    }
                                    menuPortalTarget={document.body}
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    onChange={(v) => {
                                        const w = v
                                            .map((x) => x?.id)
                                            .filter(
                                                (x) => typeof x === 'number'
                                            )
                                            .join('|');
                                        setWasteLadder(index, `|${w}|`);
                                    }}
                                    components={{
                                        MultiValue: ({ index, getValue }) => {
                                            if (index > 0) {
                                                return null;
                                            }

                                            const title = getValue()
                                                .map((o) => o?.name)
                                                .join(', ');

                                            return (
                                                <div title={title}>
                                                    {getValue().length}{' '}
                                                    {t('selected')}
                                                </div>
                                            );
                                        },
                                    }}
                                    options={wasteLadderOptions}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                />
                            </Col>
                        </Row>

                        {isMobile ? (
                            <Col
                                xs={6}
                                id='available-from'
                                className='p-1 d-flex'
                            >
                                <DatePicker
                                    className='form-control'
                                    placeholderText={t('Del. date')}
                                    onChange={(date: Date) => {
                                        setDeliveryDate(index, date);
                                    }}
                                    selected={
                                        item?.delivery_date
                                            ? new Date(item?.delivery_date)
                                            : null
                                    }
                                    openToDate={projectStartDate}
                                    dateFormat='yyyy-MM-dd'
                                    locale={i18next.language}
                                />
                            </Col>
                        ) : (
                            <div
                                id='available-from'
                                className='p-1 d-flex'
                                style={{ width: 110 }}
                            >
                                <DatePicker
                                    className='form-control'
                                    placeholderText={t('Del. date')}
                                    onChange={(date: Date) => {
                                        setDeliveryDate(index, date);
                                    }}
                                    selected={
                                        item?.delivery_date
                                            ? new Date(item?.delivery_date)
                                            : null
                                    }
                                    openToDate={projectStartDate}
                                    dateFormat='yyyy-MM-dd'
                                    locale={i18next.language}
                                />
                            </div>
                        )}

                        <div id='notes' className='flex-grow-1 p-1 d-flex'>
                            <Form.Control
                                disabled={disabled}
                                type='text'
                                placeholder={t('Observation')}
                                onChange={(e) => {
                                    setDescription(index, e.target.value);
                                }}
                                value={item.description || ''}
                                onBlur={(e) => {
                                    onBlurDescription(index, e.target.value);
                                }}
                            />
                        </div>

                        <div id='view-status' className='p-1 d-flex'>
                            <Form.Check
                                type='switch'
                                label={t('Public')}
                                className='d-sm-flex flex-row flex-sm-column-reverse align-items-sm-center p-sm-0'
                                disabled={disabled}
                                checked={Boolean(item.view_status)}
                                onChange={(e) => {
                                    const viewStatusIndex = (
                                        e.target as HTMLInputElement
                                    ).checked
                                        ? 1
                                        : 0;
                                    setViewStatus(index, viewStatusIndex);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
