import React, { useEffect, useState } from 'react';
import { Form, Row, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Layout/Page';

import { Layout } from '../../../components/Layout/Layout';
import { Link } from 'react-router-dom';
import { WelcomeJumbotron } from '../components/WelcomeJumbotron';
import useAxios from 'axios-hooks';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { SEO } from '../../../components/SEO';

const PasswordRequirements = () => {
    const { t } = useTranslation();

    return (
        <div className='mb-3'>
            <h5>{t('Password requirements')}</h5>
            <ul>
                <li>
                    {t('Must be at least {{min}} characters long', { min: 8 })}
                </li>
                <li>{t('Must contain at least one uppercase letter')}</li>
                <li>{t('Must contain at least one number')}</li>
                <li>{t('Must contain at least one special character')}</li>
            </ul>
        </div>
    );
};

interface Props {}

export const CreatePasswordPage = (props: Props) => {
    const { clearAuthCache } = useAuth();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email') || '';

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const [, post] = useAxios<any>(
        {
            url: `${process.env.REACT_APP_API_ENDPOINT}/reset-password`,
            method: 'POST',
        },
        { manual: true }
    );

    const { token } = useParams();
    const navigate = useNavigate();
    const [serverError, setServerError] = useState('');
    const [serverSuccess, setServerSuccess] = useState('');
    const [serverFormErrors, setServerFormErrors] = useState<any>({});

    const onSubmit = (data: any) => {
        setServerError('');
        setServerSuccess('');
        post({ data: { ...data, token: token } })
            .then((data) => {
                setServerSuccess('Success!');
                navigate('/login');
            })
            .catch((err) => {
                const { errors, message } = err.response.data;
                setServerError(message);
                setServerFormErrors(errors);
            });
    };

    useEffect(() => {
        clearAuthCache();
    }, [clearAuthCache]);

    console.log(serverFormErrors);

    return (
        <Layout>
            <div id='page-content-wrapper'>
                <Page>
                    <Container>
                        <SEO title={t('Create password')}></SEO>
                        <WelcomeJumbotron>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                                onReset={reset}
                            >
                                <Form.Group className='mb-3' controlId='email'>
                                    <Form.Label>
                                        {t('Email address')}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name='email'
                                        defaultValue={email}
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Control
                                                required
                                                ref={ref}
                                                value={value}
                                                onChange={onChange}
                                                readOnly={Boolean(email)}
                                                type='email'
                                                isInvalid={errors.email}
                                                placeholder={t('Enter email')}
                                            />
                                        )}
                                    />

                                    <Form.Control.Feedback type='invalid'>
                                        {serverFormErrors?.email?.length
                                            ? serverFormErrors.email.join(', ')
                                            : errors.email?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <PasswordRequirements />

                                <Form.Group
                                    className='mb-3'
                                    controlId='password'
                                >
                                    <Form.Label>{t('Password')}</Form.Label>
                                    <Controller
                                        control={control}
                                        name='password'
                                        defaultValue=''
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Control
                                                required
                                                onChange={onChange}
                                                value={value}
                                                type='password'
                                                ref={ref}
                                                isInvalid={errors.password}
                                                placeholder={t(
                                                    'Enter password'
                                                )}
                                            />
                                        )}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {serverFormErrors?.password?.length
                                            ? serverFormErrors.password.join(
                                                  ', '
                                              )
                                            : errors.email?.password}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    className='mb-3'
                                    controlId='password_confirmation'
                                >
                                    <Form.Label>
                                        {t('Confirm Password')}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name='password_confirmation'
                                        defaultValue=''
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Control
                                                required
                                                onChange={onChange}
                                                value={value}
                                                type='password'
                                                ref={ref}
                                                isInvalid={errors.password}
                                                placeholder={t(
                                                    'Confirm password'
                                                )}
                                            />
                                        )}
                                    />

                                    <Form.Control.Feedback type='invalid'>
                                        {serverFormErrors?.password?.length
                                            ? serverFormErrors.password.join(
                                                  ', '
                                              )
                                            : errors.email?.password}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Row className='mb-3'>
                                    <Controller
                                        name='submit'
                                        control={control}
                                        render={({
                                            field: { ref },
                                            formState,
                                        }) => (
                                            <Button
                                                variant='primary'
                                                type='submit'
                                                disabled={
                                                    formState.isSubmitting
                                                }
                                            >
                                                {formState.isSubmitting && (
                                                    <span className='spinner-border spinner-border-sm mr-1' />
                                                )}
                                                {t('Create password')}
                                            </Button>
                                        )}
                                    />
                                    <div className='text-success'>
                                        {serverSuccess}
                                    </div>
                                    <div className='text-danger'>
                                        {t(serverError)}
                                    </div>
                                    <div className='text-danger'>
                                        {Object.values(serverFormErrors).map(
                                            (err: any) =>
                                                err?.length &&
                                                typeof err[0] === 'string'
                                                    ? err
                                                          .map((e: string) =>
                                                              t(e)
                                                          )
                                                          .map((e: string) => (
                                                              <div>{e}</div>
                                                          ))
                                                    : ''
                                        )}
                                    </div>
                                </Row>

                                <Row className='mt-3'>
                                    <Link to={'/login'}>
                                        {t('Go back to login')}
                                    </Link>
                                </Row>
                            </Form>
                        </WelcomeJumbotron>
                    </Container>
                </Page>
            </div>
        </Layout>
    );
};
