import i18n from 'i18next';
import Product from '../../../types/IProductType';

export function getQuantityLabel(product?: Product) {
    if (!product) return '';

    const { organisation } = product || {};

    if (product?.quantity) {
        if (
            !organisation?.has_stock ||
            product?.stock_type === 'show_quantity'
        ) {
            return `${product.quantity} ${product.category?.unit?.symbol}`;
        }
        if (product?.stock_type === 'in_stock') {
            return i18n.t('In stock');
        }
        if (product?.stock_type === 'order') {
            return i18n.t('Order item');
        }
        // else-clause?
    }
    return i18n.t('Sold out');
}

export function ProductQuantityLabel({ product }: { product: Product }) {
    return <span>{getQuantityLabel(product)}</span>;
}
