import React, { CSSProperties } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Legend } from 'recharts';

interface Props {
    nameValueData: { name: string; value: number }[];
    title: string;
    style?: CSSProperties;
    fills?: string[];
}

export const VolumeStat: React.FC<Props> = ({
    title,
    nameValueData,
    style,
    fills,
}) => {
    const { t } = useTranslation();

    const data = nameValueData.length
        ? nameValueData.map((d, i) => ({
              fill: fills ? fills[i % fills.length] : 'peru',
              ...d,
          }))
        : [{ name: '-', value: 0 }];

    const total = nameValueData
        .slice(2)
        .map((c) => c.value)
        .reduce((v, c) => v + c, 0);
    const size = 90;
    const height = size + 70; // Legend below
    const radiusSize = 15;
    const renderLegend = (props: any) => {
        const { payload } = props;
        return (
            <div>
                {payload.map(
                    (entry: any, i: number) =>
                        entry.value !== 'HIDDEN' && (
                            <div key={i} className='text-nowrap'>
                                {entry.value}
                            </div>
                        )
                )}
            </div>
        );
    };
    // The blue part of the circle diagram
    const offsets = [data[2]?.value || 0, data[2]?.value || 0, total, total];

    return (
        <div style={{ ...style }} className='border px-4 py-2 h-100'>
            <div className='fw-bold fs-3 border-bottom border-2 border-secondary my-3'>
                {title}
            </div>
            <div>
                {t('Distribution of the total volume')} ({total} {t('units')})
            </div>
            <Row>
                {data.map((d, i) => (
                    <Col key={i} className='d-flex justify-content-center'>
                        <PieChart width={size} height={height}>
                            <Pie
                                data={[
                                    d,
                                    {
                                        fill: fills
                                            ? fills[fills.length - 1]
                                            : 'peru',
                                        name: 'HIDDEN',
                                        value: Math.max(
                                            offsets[i] - d.value,
                                            0
                                        ),
                                    },
                                ]}
                                dataKey='value'
                                outerRadius={size / 2}
                                innerRadius={size / 2 - radiusSize}
                                paddingAngle={0}
                            />
                            <text
                                x={size / 2}
                                y={height / 2 - 3}
                                textAnchor='middle'
                                className='fs-5 fw-bold'
                            >
                                {d.value}
                            </text>
                            <Legend
                                content={renderLegend}
                                verticalAlign={'bottom'}
                                align={'center'}
                                layout='vertical'
                            />
                        </PieChart>
                        <div></div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};
