import React, { useMemo } from 'react';
import { Stack, Card } from 'react-bootstrap';
import { Points } from '../Points';
import { Column, Row as TRow, SortingRule } from 'react-table';
import { Link } from 'react-router-dom';
import { ProductQuantityLabel } from '../ProductQuantityLabel';
import { useNavigate } from 'react-router';
import IProduct from '../../../../types/IProductType';
import { useAuth } from '../../../Auth/hooks/useAuth';
import { Restricted } from '../../../Auth/components/Restricted';
import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import { useTranslation } from 'react-i18next';
import { RowAction, Table } from '../../../../components/Table';
import { ITableAction } from '../../../../hooks/useTableReducer';
import { useWindowSize } from '../../../Organisation/hooks/useWindowSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { IImageMediaGroup } from '../../../../types/IMediaType';
import { CoverImage } from '../../../../components/CoverImage';
import { ReactComponent as UNUSEDIcon } from '../../../../assets/icones/Dacke_oanvand.svg';
import { Logo } from '../../../../components/Logo';
import { HeartProducts } from '../../../Marketplace/components/HeartProducts';
import { useTableSelectedProducts } from '../SelectedProductsContext';
import { useCategories } from '../../../../routing/marketplaceRoutes';
import getCategoryTree from '../../../../utils/get-category-tree';

interface Props {
    products: ResourcePermissionsType<IProduct>[] | IProduct[];
    queryPageIndex?: number;
    queryPageSize?: number;
    sortBy?: SortingRule<string>;
    totalCount?: number;
    dispatch?: React.Dispatch<ITableAction>;
    loading?: boolean;
    elevatedActions?: boolean;
    isMarketPlace?: boolean;
}

export const ProductListView = ({
    products,
    queryPageIndex,
    queryPageSize,
    sortBy,
    totalCount,
    dispatch,
    loading,
    elevatedActions,
    isMarketPlace,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { categories } = useCategories();
    const { organisation } = useAuth();

    const { setSelectedItems } = useTableSelectedProducts();

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    let hiddenColumns: string[] = [];

    if (!isMarketPlace) {
        hiddenColumns = [...hiddenColumns, 'seller'];
    } else {
        hiddenColumns = [...hiddenColumns, 'selection'];
    }

    if (width && width < 1170) {
        hiddenColumns = [
            ...hiddenColumns,
            'unused',
            'category',
            'quantity',
            'sales_price',
            'seller',
        ];
    }

    if (width && width < 1504) {
        hiddenColumns = [
            ...hiddenColumns,
            'function_points',
            'condition_points',
            'seller',
        ];
    }

    const columns = useMemo<Column<ResourcePermissionsType<IProduct>>[]>(
        () => [
            {
                Header: '',
                id: 'product_images',
                noRedirect: true, // Don't enter product-form
                accessor: (product: IProduct) => (
                    <div
                        style={{
                            width: '80px',
                            margin: 'auto',
                        }}
                    >
                        <CoverImage
                            images={product?.product_images as IImageMediaGroup}
                            lightbox
                        />
                    </div>
                ),
            },
            {
                Header: t('Name') as string,
                id: 'title',
                canSort: true,
                accessor: (product: IProduct) => {
                    return (
                        <Stack
                            className={elevatedActions ? '' : 'cursor-pointer'}
                        >
                            <div className='fw-bold' style={{maxWidth: 200}}>
                                {product.title || (product as any).name}
                            </div>
                            <div>
                                {product.barcode &&
                                    t('Product-ID') + ': ' + product.barcode}
                            </div>
                        </Stack>
                    );
                },
            },
            {
                Header: t('Unused') as string,
                id: 'unused',
                canSort: true,
                accessor: (product: IProduct) => {
                    return (
                        <Stack>
                            {product.unused && (
                                <UNUSEDIcon width='26' className='ms-1' />
                            )}
                        </Stack>
                    );
                },
            },
            {
                Header: t('Category') as string,
                id: 'categories.name',
                canSort: true,
                accessor: (product: IProduct) => {
                    return (
                        <Stack>
                            <div>{product.category?.name}</div>
                        </Stack>
                    );
                },
            },
            {
                Header: t('Condition') as string,
                id: 'condition_points',
                canSort: true,
                accessor: (product: IProduct) => {
                    return (
                        <Stack>
                            <Points
                                criteria={{ title: 'condition' }}
                                value={product.condition_points || 0}
                            />
                        </Stack>
                    );
                },
            },
            {
                Header: t('Function') as string,
                id: 'function_points',
                canSort: true,
                accessor: (product: IProduct) => {
                    return (
                        <Stack>
                            <Points
                                criteria={{ title: 'function' }}
                                value={product.function_points || 0}
                            />
                        </Stack>
                    );
                },
            },
            {
                Header: t('Quantity') as string,
                id: 'quantity',
                canSort: true,
                accessor: (product: IProduct) => (
                    <ProductQuantityLabel product={product} />
                ),
            },
            {
                Header: t('Price') as string,
                id: 'sales_price',
                canSort: true,
                accessor: (product: IProduct) => {
                    return (
                        <Stack>
                            {product.sales_price
                                ? `${product.sales_price} kr/${product.category?.unit?.symbol}`
                                : t('Price upon request')}
                        </Stack>
                    );
                },
            },
            {
                Header: t('Seller') as string,
                id: 'seller',
                accessor: (product: IProduct) => {
                    return (
                        <Stack>
                            <div className='ms-1'>
                                <Logo
                                    shape='circle'
                                    size='sm'
                                    images={
                                        product?.organisation?.organisation_logo
                                    }
                                />
                            </div>
                        </Stack>
                    );
                },
            },
        ],
        [elevatedActions, t]
    );
    const actions: RowAction<ResourcePermissionsType<IProduct>>[] = useMemo(
        () => [
            {
                name: 'Edit product',
                render: (instance: TRow<ResourcePermissionsType<IProduct>>) =>
                    instance.original.organisation_id === organisation?.id ? (
                        <Restricted
                            to='edit single product'
                            resource={instance.original}
                        >
                            <Link
                                onClick={(e) => e.stopPropagation()}
                                to={`/admin/projects/${instance.original.project_id}/product-form/${instance.original.id}`}
                            >
                                <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    width='15'
                                    className='cursor-pointer'
                                />
                            </Link>
                        </Restricted>
                    ) : (
                        <></>
                    ),
            },
            {
                name: 'Favorite',
                render: (instance: TRow<ResourcePermissionsType<IProduct>>) =>
                    isMarketPlace ? (
                        <HeartProducts product={instance.original} />
                    ) : (
                        <></>
                    ),
            },
        ],
        [isMarketPlace, organisation?.id]
    );
    return (
        <>
            <Card className='shadow rounded-0'>
                <Table<ResourcePermissionsType<IProduct>>
                    name={'products'}
                    columns={columns}
                    data={products as ResourcePermissionsType<IProduct>[]}
                    total={totalCount}
                    initialPageSize={queryPageSize || 0}
                    initialPageIndex={queryPageIndex || 0}
                    initialSortBy={sortBy || { id: 'title', desc: false }}
                    hiddenColumns={hiddenColumns}
                    loading={loading}
                    actions={actions}
                    dispatch={dispatch || (() => {})}
                    selectHooks={true}
                    setSelectedItems={setSelectedItems}
                    getCellTooltip={(cell: any) => {
                        if (
                            cell.column.id !== 'categories.name' ||
                            !categories
                        ) {
                            return null;
                        }

                        const { category } = cell.row.original;

                        return (
                            getCategoryTree(category, categories)
                                .map(({ name }) => name)
                                .join(' > ') || null
                        );
                    }}
                    onCellClick={(cell: any) => {
                        if (
                            cell.column.id === 'selection' ||
                            cell.column.noRedirect
                        )
                            return;

                        const {
                            row: {
                                original: { project_id, id },
                            },
                        } = cell;

                        if (isMarketPlace) navigate(`/products/${id}`);
                        else if (elevatedActions)
                            navigate(
                                `/admin/projects/${project_id}/product-form/${id}`
                            );
                        else navigate(`/admin/products/${id}`);
                    }}
                />
            </Card>
        </>
    );
};
