import axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { Row, Col, Form, Modal, Button, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDebounce } from '@react-hook/debounce';
import { useTranslation } from 'react-i18next';

import { getUserExists } from '../../../../../API/user';

import IUserType from '../../../../../types/IUserType';

import { ReactComponent as EDITIcon } from '../../../../../assets/icones/Dacke_redigera.svg';
import { ReactComponent as ADDIcon } from '../../../../../assets/icones/Dacke_nytt.svg';

interface Props {
    handleClose: (newModalWasCreated: boolean) => void;
    handleShow: () => void;
    show: boolean;
    projectId: number;
    organisationId: number;
    roles: any[];
    editUser?: IUserType;
}

export const UserCreateModal = ({
    handleClose,
    handleShow,
    show,
    editUser,
    projectId,
    organisationId,
    roles,
}: Props) => {
    const { t } = useTranslation();

    const [userExists, setUserExists] = React.useState<boolean | null>(null);
    const [isCheckingUserEmail, setIsCheckingUserEmail] =
        React.useState<boolean>(false);
    const [email, setEmail] = useDebounce<string>('', 500);

    useEffect(() => {
        if (show) {
            setUserExists(null);
        }
    }, [show]);

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue,
    } = useForm();

    const setFormValues = useCallback(
        (user?: IUserType) => {
            const roleId =
                user?.roles?.find(
                    (r) => Number(r?.pivot?.project_id) === Number(projectId)
                )?.pivot?.role_id || 1;

            setValue('name', user?.name ?? '');
            setValue('email', user?.email ?? '');
            setValue('phone', user?.phone ?? '');
            setValue('role_id', roleId);
        },
        [setValue, projectId]
    );

    const deleteUser = () => {
        if (editUser) {
            const del = axios.delete<IUserType>(
                `/api/organisations/${organisationId}/projects/${projectId}/users/${editUser.id}`
            );
            del.then(() => {
                handleClose(true);
            });
        }
    };
    const onSubmit = (data: any) => {
        if (!editUser) {
            // New user
            const post = axios.post<IUserType>(
                `/api/organisations/${organisationId}/projects/${projectId}/users`,
                {
                    name: data['name'],
                    email: data['email'],
                    phone: data['phone'],
                    role_id: data['role_id'],
                }
            );
            post.then(() => {
                setFormValues();
                handleClose(true);
            });
        } else {
            // Edit existing user
            const put = axios.put<IUserType>(
                `/api/organisations/${organisationId}/projects/${projectId}/users/${editUser.id}`,
                {
                    id: editUser.id,
                    name: data['name'],
                    email: data['email'],
                    phone: data['phone'],
                    role_id: data['role_id'],
                }
            );
            put.then(() => {
                handleClose(true);
            });
        }
    };

    const onEmailSearch = React.useCallback(
        (emailValue: string) => {
            if (emailValue.length < 5) {
                setEmail('');
                setUserExists(null);
                return;
            }
            setIsCheckingUserEmail(true);
            getUserExists(emailValue).then((b) => {
                setUserExists(b);
                setIsCheckingUserEmail(false);
            });
            return () => {
                setEmail('');
                setUserExists(null);
            };
        },
        [setEmail]
    );

    useEffect(() => {
        onEmailSearch(email);
    }, [email, onEmailSearch]);

    // Reset form if editUser changes
    useEffect(() => {
        setFormValues(editUser);
    }, [setFormValues, editUser]);

    return (
        <Modal show={show} onHide={() => handleClose(false)} centered>
            <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                <Modal.Header className='border-0 pb-0' closeButton>
                    {editUser ? (
                        <h5 className='text-center w-100 ps-2 mt-2 text-uppercase fw-bold m-0'>
                            <EDITIcon width='20' className='me-1' />
                            {t('Edit user')}
                        </h5>
                    ) : (
                        <h5 className='text-center w-100 ps-2 mt-2 text-uppercase fw-bold m-0'>
                            <ADDIcon width='20' className='me-1' />
                            {t('New user')}
                        </h5>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} xl={12}>
                            <Row>
                                <Col sm={12} lg={12}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='text-muted'>
                                            {t('Email')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name='email'
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <>
                                                    <Form.Control
                                                        required
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            setEmail(
                                                                e.target.value
                                                            );
                                                        }}
                                                        value={value}
                                                        type='email'
                                                        ref={ref}
                                                        isInvalid={errors.email}
                                                        placeholder={t(
                                                            'Enter email'
                                                        )}
                                                    />
                                                    {userExists && (
                                                        <Form.Text muted>
                                                            {t(
                                                                'Email found in system'
                                                            )}
                                                        </Form.Text>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                {isCheckingUserEmail && (
                                    <Spinner animation='border' />
                                )}
                            </Row>
                            {(userExists === false || editUser) && (
                                <>
                                    <Row>
                                        <Col sm={12} lg={12}>
                                            <Form.Group className='mb-3'>
                                                <Form.Label className='text-muted'>
                                                    {t('Name')}
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name='name'
                                                    rules={{ required: true }}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <Form.Control
                                                            required
                                                            onChange={onChange}
                                                            value={value}
                                                            type='text'
                                                            ref={ref}
                                                            isInvalid={
                                                                errors.name
                                                            }
                                                            placeholder={t(
                                                                'Enter name'
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} lg={12}>
                                            <Form.Group className='mb-3'>
                                                <Form.Label className='text-muted'>
                                                    {t('Phone number')}
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name='phone'
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <Form.Control
                                                            onChange={onChange}
                                                            value={value}
                                                            type='text'
                                                            ref={ref}
                                                            isInvalid={
                                                                errors.phone
                                                            }
                                                            placeholder={t(
                                                                'Enter phone number'
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {(typeof userExists === 'boolean' || editUser) && (
                                <Row>
                                    <Col sm={12} lg={12}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label className='text-muted'>
                                                {t('Projects')}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name='role_id'
                                                rules={{ required: false }}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        value,
                                                        ref,
                                                    },
                                                }) => (
                                                    <Form.Select
                                                        aria-label='Choose permissions for user'
                                                        onChange={onChange}
                                                        value={value}
                                                        ref={ref}
                                                    >
                                                        {roles.map((r) => {
                                                            return (
                                                                <option
                                                                    key={r.id}
                                                                    value={r.id}
                                                                >
                                                                    {r.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Select>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                {editUser ? (
                    <Modal.Footer>
                        <Controller
                            name='submit'
                            control={control}
                            render={({ field: { ref }, formState }) => (
                                <Button
                                    className='d-block w-100 text-uppercase'
                                    variant='secondary'
                                    type='submit'
                                    disabled={formState.isSubmitting}
                                >
                                    {formState.isSubmitting && (
                                        <span className='spinner-border spinner-border-sm mr-1' />
                                    )}
                                    {t('Save changes')}
                                </Button>
                            )}
                        />
                        <Button
                            onClick={deleteUser}
                            className='d-block w-100 text-uppercase'
                            variant='primary'
                        >
                            {t('Delete user')}
                        </Button>
                    </Modal.Footer>
                ) : (
                    <Modal.Footer>
                        <Controller
                            name='submit'
                            control={control}
                            render={({ field: { ref }, formState }) => (
                                <Button
                                    className='d-block w-100 text-uppercase'
                                    variant='secondary'
                                    type='submit'
                                    disabled={formState.isSubmitting}
                                >
                                    {formState.isSubmitting && (
                                        <span className='spinner-border spinner-border-sm mr-1' />
                                    )}
                                    {t('Add new user')}
                                </Button>
                            )}
                        />
                    </Modal.Footer>
                )}
            </Form>
        </Modal>
    );
};
