import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './PlacementsForm.scss';
import { PlacementItem } from './PlacementItem';
import { ButtonToolbar } from 'react-bootstrap';
import { useThrottle } from '@react-hook/throttle';
import { usePlacementsForm } from './usePlacementsForm';
import { IProductItem } from '../../../../../types/IProductItem';
import IProduct from '../../../../../types/IProductType';
import { SearchInput } from '../../../../../components/SearchInput';
import { PlacementEditModal } from '../../Modals/PlacementEditModal';
import { CannotPublishToMarketplaceModal } from '../../Modals/CannotPublishToMarketplaceModal';
import { ZeroPlacementsReachedModal } from '../../Modals/ZeroPlacementsReachedModal';

export type SelectState = {
    defaultOptions: any[];
    isLoading: boolean | undefined;
};

export interface Props {
    firstPlacementTitle: string;
    secondPlacementTitle: string;
    thirdPlacementTitle: string;
    fourthPlacementTitle: string;
    setProductItems?: (items: IProductItem[]) => void;
    onTotalQuantityChanged?: (quantity: number) => void;
    product?: IProduct;
}

export const PlacementsForm = ({
    firstPlacementTitle,
    secondPlacementTitle,
    thirdPlacementTitle,
    fourthPlacementTitle,
    setProductItems,
    onTotalQuantityChanged,
    product,
}: Props) => {
    const { t } = useTranslation();

    const [
        showCannotPublishToMarketplaceModal,
        setShowCannotPublishToMarketplaceModal,
    ] = useState<boolean>(false);
    const [showZeroPlacementsReachedModal, setShowZeroPlacementsReachedModal] =
        useState<boolean>(false);

    const {
        onBlurDescription,
        addQuantity,
        removeQuantity,
        setQuantity,
        onBlurQuantity,
        addNewItem,
        fetchItems,
        removeItem,
        setPlacementLevel,
        setPreliminary,
        setStatus,
        setWasteLadder,
        setViewStatus,
        setDeliveryDate,
        setDescription,
        productItems,
        productStatuses,
        projectStartDate,
        productViwingStatuses,
        wasteLadderOptions,
    } = usePlacementsForm({
        setCannotPublish: setShowCannotPublishToMarketplaceModal,
    });

    const unitSymbol = product?.category?.unit?.symbol || null;

    const [search, setSearch] = useThrottle('', 2);

    const [currentPlacement, setCurrentPlacement] = useState(null);

    const [showPlacementEdit, setShowPlacementEdit] = useState<boolean>(false);
    const [toBeDeletedIdx, setToBeDeletedIdx] = useState<number>(-1);

    const onEditPlacement = useCallback((placement: any) => {
        setCurrentPlacement(placement);
        setShowPlacementEdit(true);
    }, []);

    useEffect(() => {
        setProductItems && setProductItems(productItems);
    }, [productItems, setProductItems]);

    const placementItemPlaceholders = [
        firstPlacementTitle,
        secondPlacementTitle,
        thirdPlacementTitle,
        fourthPlacementTitle,
    ];

    const totalQuantity = useMemo(() => {
        return (
            (productItems as any[])?.reduce((acc, item) => {
                return acc + (item.quantity || 0);
            }, 0) || 0
        );
    }, [productItems]);

    useEffect(() => {
        if (onTotalQuantityChanged) {
            onTotalQuantityChanged(totalQuantity);
        }
    }, [onTotalQuantityChanged, totalQuantity]);

    // fetsh project

    function filterPlacementFn(item: IProductItem) {
        if (!search?.length) return true;

        const searchLower = search.toLowerCase();
        // check if any placment level labels matches search
        if (
            item?.placementLevel &&
            item.placementLevel.filter((placement: any) =>
                placement.label.toLowerCase().includes(searchLower)
            ).length > 0
        )
            return true;

        if (item?.description && item.description.includes(searchLower))
            return true;

        return false;
    }

    const onRemoveItem = (i: number) => {
        setToBeDeletedIdx(i);
        setShowZeroPlacementsReachedModal(true);
    };
    const onConfirmRemove = () => {
        removeItem(toBeDeletedIdx);
        setToBeDeletedIdx(-1);
        setShowZeroPlacementsReachedModal(false);
    };
    const onCancelRemove = () => {
        setToBeDeletedIdx(-1);
        setShowZeroPlacementsReachedModal(false);
    };

    return (
        <div>
            <PlacementEditModal
                show={showPlacementEdit}
                handleClose={() => {
                    setShowPlacementEdit(false);
                    fetchItems();
                }}
                placement={currentPlacement}
            />
            <CannotPublishToMarketplaceModal
                show={showCannotPublishToMarketplaceModal}
                onClose={() => setShowCannotPublishToMarketplaceModal(false)}
            />
            <ZeroPlacementsReachedModal
                show={showZeroPlacementsReachedModal}
                placement={toBeDeletedIdx ? productItems[toBeDeletedIdx] : {}}
                onCancel={onCancelRemove}
                onConfirm={() => onConfirmRemove()}
            />
            <ButtonToolbar className='d-flex justify-content-center justify-content-sm-end mb-3'>
                <SearchInput
                    placeholder={t('Search')}
                    onChange={(value) => setSearch(value)}
                ></SearchInput>
            </ButtonToolbar>
            <div className='placements-table'>
                <div className='d-none d-lg-flex placements-table-header border-bottom'>
                    {/*
                    <div className="d-flex placements-table-radio"></div>
                    */}

                    <div className='d-none d-lg-flex px-3 placements-table-amount'>
                        {t('Quantity')} {unitSymbol ? `(${unitSymbol})` : ''}
                    </div>
                    <div className='flex-grow-1 p-1'>
                        <div className='d-flex d-none d-lg-flex'>
                            {firstPlacementTitle && (
                                <div className='flex-grow-1 p-1'>
                                    {firstPlacementTitle}
                                </div>
                            )}
                            {secondPlacementTitle && (
                                <div className='flex-grow-1 p-1'>
                                    {secondPlacementTitle}
                                </div>
                            )}
                            {thirdPlacementTitle && (
                                <div className='flex-grow-1 p-1'>
                                    {thirdPlacementTitle}
                                </div>
                            )}
                            {fourthPlacementTitle && (
                                <div className='flex-grow-1 p-1'>
                                    {fourthPlacementTitle}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='placements-table-body'>
                    {[
                        ...productItems.filter(filterPlacementFn),
                        {
                            quantity: 0,
                            goast: true, // ghost
                        },
                    ].map((item, i) => {
                        const disabled = item.goast;
                        return (
                            <PlacementItem
                                disabled={disabled}
                                {...{
                                    addQuantity,
                                    removeQuantity,
                                    setQuantity,
                                    onBlurQuantity,
                                    addNewItem,
                                    removeItem: onRemoveItem,
                                    setPlacementLevel,
                                    setPreliminary,
                                    setStatus,
                                    setWasteLadder,
                                    setViewStatus,
                                    setDescription,
                                    setDeliveryDate,
                                    onBlurDescription,
                                    item,
                                    disabledLevels: {
                                        firstPlacementTitle:
                                            !firstPlacementTitle ||
                                            firstPlacementTitle.length <= 0,
                                        secondPlacementTitle:
                                            !secondPlacementTitle ||
                                            secondPlacementTitle.length <= 0,
                                        thirdPlacementTitle:
                                            !thirdPlacementTitle ||
                                            thirdPlacementTitle.length <= 0,
                                        fourthPlacementTitle:
                                            !fourthPlacementTitle ||
                                            fourthPlacementTitle.length <= 0,
                                    },
                                }}
                                index={i}
                                key={i}
                                projectStartDate={projectStartDate}
                                productStatuses={productStatuses}
                                wasteLadderOptions={wasteLadderOptions}
                                productViwingStatuses={productViwingStatuses}
                                placeholders={placementItemPlaceholders}
                                onEdit={onEditPlacement}
                            ></PlacementItem>
                        );
                    })}
                </div>

                <div className='d-flex placements-table-footer'>
                    <div className='d-flex pe-1 placements-table-amount'>
                        {t('Quantity')}: {totalQuantity} {unitSymbol || ''}
                    </div>
                    <div className='flex-grow-1 p-1'></div>
                </div>
            </div>
        </div>
    );
};
