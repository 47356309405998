import { useMemo } from 'react';
import { Card, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import type { Column, Row as TRow } from 'react-table';

import { CoverImage } from '../../../../components/CoverImage';
import { Table } from '../../../../components/Table';
import { Restricted } from '../../../Auth/components/Restricted';
import { useWindowSize } from '../../../Organisation/hooks/useWindowSize';
import { tryFormatDefaultDate } from '../../../../utils/helper';

import type { RowAction } from '../../../../components/Table';
import type { IImageMediaGroup } from '../../../../types/IMediaType';
import type IOrganisation from '../../../../types/IOrganisationType';
import type IProjectType from '../../../../types/IProjectType';
import type ResourcePermissionsType from '../../../../types/ResourcePermissionsType';

interface Props {
    projects: ResourcePermissionsType<IProjectType>[];
    organisation: ResourcePermissionsType<IOrganisation> | null;

    loading: boolean;
    queryPageIndex: number;
    queryPageSize: number;
    total: number;
    dispatch: any;
}

const ListView = ({
    projects,
    organisation,
    loading,
    queryPageIndex,
    queryPageSize,
    total,
    dispatch,
}: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns =
        width && width < 992
            ? ['project_start', 'project_finish', 'is_active']
            : [];

    const columns = useMemo<Column<ResourcePermissionsType<IProjectType>>[]>(
        () => [
            {
                Header: '',
                id: 'project_images',
                accessor: (project) => (
                    <div
                        style={{
                            width: '80px',
                            margin: 'auto',
                        }}
                    >
                        <CoverImage
                            images={project?.project_images as IImageMediaGroup}
                            lightbox
                        />
                    </div>
                ),
            },
            {
                Header: t('Title') as string,
                id: 'title',
                canSort: true,
                accessor: (project) => {
                    return (
                        <Restricted
                            to='view single project'
                            resource={project}
                            fallBackCompoonet={
                                <Stack>
                                    <div className='fw-bold'>
                                        {project.title}
                                    </div>
                                    <div>
                                        {t('Projectnumber')}: {project.id}
                                    </div>
                                </Stack>
                            }
                        >
                            <Stack
                                onClick={() => {
                                    navigate(`${project.id}`);
                                }}
                                className='cursor-pointer'
                            >
                                <div className='fw-bold'>{project.title}</div>
                                <div>
                                    {t('Projectnumber')}: {project.id}
                                </div>
                            </Stack>
                        </Restricted>
                    );
                },
            },
            {
                Header: t('Start date') as string,
                id: 'project_start',
                canSort: true,
                accessor: (project) =>
                    tryFormatDefaultDate(project.project_start),
            },
            {
                Header: t('End date') as string,
                id: 'project_finish',
                canSort: true,
                accessor: (project) =>
                    tryFormatDefaultDate(project.project_finish),
            },
            {
                Header: t('Active') as string,
                id: 'is_active',
                canSort: true,
                accessor: (project) => (
                    <div>
                        {/* green dot */}
                        <span
                            style={{
                                backgroundColor: project.is_active
                                    ? 'var(--bs-success)'
                                    : 'var(--bs-danger)',
                                borderRadius: '50%',
                                width: '13px',
                                height: '13px',
                                marginTop: '4px',
                                position: 'absolute',
                                border: '2px solid #fff',
                                boxShadow: '3px 3px 4px rgba(0,0,0,0.2)',
                            }}
                        ></span>
                        <span className='ps-3'>
                            {project.is_active ? t('Active') : t('Inactive')}
                        </span>
                    </div>
                ),
            },
        ],
        [navigate, t]
    );
    const actions: RowAction<ResourcePermissionsType<IProjectType>>[] = useMemo(
        () => [
            {
                name: 'Edit project',
                render: (
                    instance: TRow<ResourcePermissionsType<IProjectType>>
                ) => (
                    <Restricted
                        to='edit single project'
                        resource={instance.original}
                    >
                        <Link
                            to={`/admin/projects/${instance.original.id}/project-form`}
                            className='cursor-pointer'
                        >
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                width='15'
                                className='cursor-pointer'
                            />
                        </Link>
                    </Restricted>
                ),
            },
            {
                name: 'View products',
                render: (
                    instance: TRow<ResourcePermissionsType<IProjectType>>
                ) => (
                    <Restricted
                        to='view any product'
                        resource={instance.original}
                    >
                        <Link
                            to={`${instance.original.id}/products`}
                            className='cursor-pointer'
                        >
                            <FontAwesomeIcon
                                icon={faList}
                                width='15'
                                className='cursor-pointer'
                            />
                        </Link>
                    </Restricted>
                ),
            },
            {
                name: 'View product placements',
                render: (
                    instance: TRow<ResourcePermissionsType<IProjectType>>
                ) => (
                    <Restricted
                        to='view any product'
                        resource={instance.original}
                    >
                        <Link
                            to={`${instance.original.id}/product-items`}
                            className='cursor-pointer'
                        >
                            <FontAwesomeIcon
                                icon={faList}
                                width='15'
                                className='cursor-pointer'
                            />
                        </Link>
                    </Restricted>
                ),
            },
            {
                name: 'View environment data',
                render: (
                    instance: TRow<ResourcePermissionsType<IProjectType>>
                ) =>
                    organisation?.has_environment_data ? (
                        <Restricted
                            to='view any product'
                            resource={instance.original}
                        >
                            <Link
                                to={`${instance.original.id}/product-environment-data`}
                                className='cursor-pointer'
                            >
                                <FontAwesomeIcon
                                    icon={faList}
                                    width='15'
                                    className='cursor-pointer'
                                />
                            </Link>
                        </Restricted>
                    ) : (
                        <div></div>
                    ),
            },
        ],
        [organisation]
    );
    return (
        <Card className='shadow rounded-0'>
            <Table<ResourcePermissionsType<IProjectType>>
                name={'projects'}
                columns={columns}
                data={projects}
                initialPageSize={queryPageSize || 0}
                initialPageIndex={queryPageIndex || 0}
                initialSortBy={{ id: 'title', desc: false }}
                hiddenColumns={hiddenColumns}
                loading={loading}
                actions={actions}
                dispatch={dispatch}
                total={total}
            />
        </Card>
    );
};
export default ListView;
