import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useProductItemModals } from './ProductItemModals/ProductItemModals';
import { useTranslation } from 'react-i18next';
import { useTableSelectedProductItems } from './SelectedProductItemsContext';

export const ProductItemManageButton = () => {
    const { t } = useTranslation();
    const {
        setShowExcelExportModal,
        setShowStatusModal,
        setShowViewingStatusModal,
    } = useProductItemModals();

    const { selectedItems } = useTableSelectedProductItems();

    return (
        <DropdownButton
            id='dropdown-basic-button'
            title={<>{t('With selected')}...</>}
            variant='primary'
            disabled={!selectedItems.length}
            className='d-inline-block'
        >
            <Dropdown.Item
                onClick={() => {
                    setShowStatusModal(selectedItems);
                }}
            >
                {t('Update status')}
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => {
                    setShowViewingStatusModal(selectedItems);
                }}
            >
                {t('Update viewing status')}
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => {
                    setShowExcelExportModal(selectedItems);
                }}
            >
                {t('Export to excel')}
            </Dropdown.Item>
        </DropdownButton>
    );
};
