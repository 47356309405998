import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import { ProductThumbnail } from './ProductThumbnail';
import IProduct from '../../../types/IProductType';
import { PageCountPicker } from '../../../components/PageCountPicker';
import {
    ITableAction,
    PAGE_SIZE_CHANGED,
} from '../../../hooks/useTableReducer';

interface Props {
    products: ResourcePermissionsType<IProduct>[] | IProduct[];
    queryPageSize?: number;
    totalCount?: number;
    dispatch?: React.Dispatch<ITableAction>;
    isMarketPlace?: boolean;
    hidePageCount?: boolean;
}

export const ProductThumbnailView = ({
    products,
    queryPageSize,
    totalCount,
    dispatch,
    isMarketPlace,
    hidePageCount,
}: Props) => {
    return (
        <>
            <Row xs={1} md={2} xl={3} xxl={4} className='g-4'>
                {products.map((product, i) => {
                    return (
                        <Col key={product.id}>
                            <ProductThumbnail
                                product={product}
                                isMarketPlace={isMarketPlace}
                            ></ProductThumbnail>
                        </Col>
                    );
                })}
            </Row>

            {!hidePageCount ? (
                <Row>
                    <Col className='mt-4'>
                        <div className='d-flex flex-column justify-content-center align-content-center align-items-center'>
                            <div>
                                <PageCountPicker
                                    pageSize={queryPageSize || 0}
                                    onChangePageSize={(size) => {
                                        dispatch &&
                                            dispatch({
                                                type: PAGE_SIZE_CHANGED,
                                                payload: size,
                                            });
                                    }}
                                    totalCount={totalCount || 0}
                                ></PageCountPicker>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : null}
        </>
    );
};
