import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useAxios from 'axios-hooks';
import { Container, Card, ButtonToolbar } from 'react-bootstrap';
import { Page } from '../../components/Layout/Page';
import { FloatingProductNameHeader } from './components/FloatingProductNameHeader';

import { ICategory } from '../../types/ICategory';
import IProduct from '../../types/IProductType';
import IServerResponseType from '../../types/IServerResponseType';
import ResourcePermissionsType from '../../types/ResourcePermissionsType';
import { useAuth } from '../Auth/hooks/useAuth';
import useScrollY from '../../hooks/useScrollY';
import { ProductsBreadCrumbs } from '../../components/ProductsBreadCrumbs';
import axios from 'axios';
import { useCategories } from '../../routing/productsPrivateRoutes';
import { ProductCard } from './components/ProductCard';

interface Props {}

export const ProductPage = (props: Props) => {
    const { setSelectedCategory } = useCategories();
    const { t } = useTranslation();

    const { productId } = useParams<any>();
    const { organisation } = useAuth();
    const scrollY = useScrollY();
    const [enableFloatingTitle, setEnableFloatingTitle] = useState(true);

    const [{ data, loading }, refetch] = useAxios<
        IServerResponseType<ResourcePermissionsType<IProduct>>
    >(`/api/organisations/${organisation?.id}/products/${productId}`, {
        manual: true,
    });

    const product = data?.data;

    useEffect(() => {
        if (organisation) refetch();
    }, [organisation, refetch]);

    const [category, setCategory] = useState<ICategory>();
    useEffect(() => {
        if (product)
            axios
                .get<IServerResponseType<ICategory>>(
                    `/api/categories/${product?.category_id}`
                )
                .then((res) => setCategory(res.data.data));
    }, [product]);

    const Breadcrumbs = () => (
        <ProductsBreadCrumbs
            to='/admin/products'
            lastCategoryClickable={true}
            category={category}
            setSelectedCategory={setSelectedCategory}
        />
    );

    return (
        <Page>
            <Container fluid className='px-0 px-sm-inherit'>
                <FloatingProductNameHeader
                    title={
                        productId
                            ? `${product?.title} (${product?.barcode})`
                            : t('New')
                    }
                    show={enableFloatingTitle && scrollY > 90}
                />

                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    {category ? <Breadcrumbs /> : null}
                </ButtonToolbar>
                <Card className='shadow'>
                    <ProductCard
                        product={product}
                        loading={loading}
                        toggleFloatingTitle={setEnableFloatingTitle}
                    />
                </Card>
            </Container>

            {/* <Auth requiredPermissions={["edit any product"]} admin>
                <EditproductModal
                show={show}
                handleClose={handleClose}
                model={product}
                />
                </Auth> */}
        </Page>
    );
};

interface ProductCardBreadcrumbProps {
    category: ICategory;
}

// TODO: sould be moved to its own component and used on products page
const ProductCardBreadcrumb: React.FC<ProductCardBreadcrumbProps> = ({
    category,
}) => (
    <span className='mb-2'>
        {category.parent ? (
            <span>
                <ProductCardBreadcrumb category={category.parent} /> {'> '}
            </span>
        ) : null}
        <Link to={`/admin/products?category_id=${category.id}`}>
            {category.name}
        </Link>
    </span>
);
