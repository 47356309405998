import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getQuantityLabel } from './ProductQuantityLabel';
import { fmtNumber, tryFormatDefaultDate } from '../../../utils/helper';

import { IMessurementType as IMeassurement } from '../../../types/IMessurementType';
import IProduct from '../../../types/IProductType';

interface Props {
    product?: IProduct;
    fluid?: boolean;
    extraProps?: IKeyVal[];
}
interface IKeyVal {
    key?: string;
    val?: string;
}
export const ProductCardProperties = ({ product, extraProps }: Props) => {
    const { t } = useTranslation();

    const favoriteMetaTypeIds: Number[] =
        product?.organisation?.meta_types
            ?.filter((m) => m?.pivot?.favorite)
            ?.map((m) => m.id) || [];

    const formatMeassurements = React.useCallback(
        (ms: IMeassurement | null | undefined) =>
            !ms
                ? []
                : [
                      { key: t('Width'), val: ms.width },
                      { key: t('Height'), val: ms.height },
                      { key: t('Length'), val: ms.length },
                      { key: t('Thickness'), val: ms.thickness },
                      { key: t('Depth'), val: ms.depth },
                      { key: t('Diameter'), val: ms.diamter },
                  ]
                      .filter((x) => x?.val)
                      .map((x) => ({
                          ...x,
                          val: `${fmtNumber(x.val)} ${ms?.unit?.symbol || ''}`,
                      })),
        [t]
    );

    const {
        unused,
        package_quantity,
        delivery_date,
        manufacture_code,
        model_code,
        messurement,
        weight,
        weight_unit,
    } = product || {};

    const productProperties: IKeyVal[] = [
        // simple product values to display
        ...(unused
            ? [{ key: t('Unused'), val: `${unused ? t('Yes') : t('No')}` }]
            : []),
        {
            key: t('Quantity'),
            val: getQuantityLabel(product),
        },
        { key: t('Package quantity'), val: package_quantity },
        { key: t('Available from'), val: tryFormatDefaultDate(delivery_date) },
        { key: t('Manufacture code'), val: manufacture_code },
        { key: t('Model code'), val: model_code },
        ...formatMeassurements(messurement),
        ...(weight
            ? [
                  {
                      key: t('Weight'),
                      val: `${fmtNumber(weight)} ${weight_unit?.symbol}`,
                  },
              ]
            : []),
        // Opt. extra props, used by /src/pages/Products/ProductPage.tsx
        ...(extraProps || []),
        // Favorite custom organisation product meta types
        ...((product?.product_meta || [])
            .filter((m) => !!m.meta_value)
            .filter((m) => favoriteMetaTypeIds.includes(m.meta_type_id))
            .map((m) => ({ key: m.meta_type?.name, val: m.meta_value })) || []),
        // Standard product meta types
        ...((product?.product_properties || [])
            .filter((p) => !!p.value)
            .map((p) => ({
                key: p.property?.name,
                val: `${p.value} ${p.property?.unit?.symbol || ''}`,
            })) || []),
        // Non-favorite custom organisation product meta types
        ...(product?.product_meta || [])
            .filter((m) => !!m.meta_value)
            .filter((m) => !favoriteMetaTypeIds.includes(m.meta_type_id))
            .map((m) => ({ key: m.meta_type?.name, val: m.meta_value })),
    ].filter(({ val }) => !!val);

    if (!productProperties || !productProperties.length) return <></>;

    return (
        <section>
            <Row>
                <Col>
                    <div className='fw-bold fs-5 mb-1'>{t('Properties')}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table size='sm' striped>
                        <thead>
                            <tr>
                                <th>{t('Name')}</th>
                                <th>{t('Value')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productProperties?.map((pair: IKeyVal, i) => (
                                <tr key={i}>
                                    <td>{pair.key}</td>
                                    <td>{pair.val}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </section>
    );
};
