import axios from 'axios';
import { IImageMedia } from '../types/IMediaType';
import { imageSortFn } from '../utils/helper';

export const createImages = async <T = never>(
    images: any[],
    apiUrl: string,
    mediaGroup: any
) => {
    const imagesFormData = new FormData();

    images.forEach((image: any, index: number) => {
        imagesFormData.append(`${mediaGroup.title}[${index}]`, images[index]);

        imagesFormData.append(
            `info[${mediaGroup.title}][${index}][title]`,
            image?.title || ''
        );
        imagesFormData.append(
            `info[${mediaGroup.title}][${index}][description]`,
            image?.description || ''
        );
        imagesFormData.append(
            `info[${mediaGroup.title}][${index}][sort_order]`,
            image?.sort_order.toString()
        );
        imagesFormData.append(
            `info[${mediaGroup.title}][${index}][is_public]`,
            (image?.is_public ? 1 : 0).toString()
        );
    });

    const imagesResponse = await axios
        .post<T>(`${apiUrl}/media`, imagesFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => {
            return axios.get(`${apiUrl}`).then((response) => {
                // Finding response.data.data.X.title -> Example: response.data.data.product_images
                let obj = Object.values(response.data.data).find(
                    (d: any) =>
                        d !== null &&
                        typeof d === 'object' &&
                        d.title === mediaGroup.title
                );
                if (obj) return obj;
                return { medias: [] };
            });
        });

    if (imagesResponse) {
        return imagesResponse;
    }
};

export const updateImages = async (
    images: any[],
    apiUrl: string,
    mediaGroup: any
) => {
    const imagesFormData = new FormData();

    images.forEach((image: any, index: number) => {
        imagesFormData.append(
            `info[${mediaGroup.title}][${image.id}][title]`,
            image?.title || ''
        );
        imagesFormData.append(
            `info[${mediaGroup.title}][${image.id}][description]`,
            image?.description || ''
        );
        imagesFormData.append(
            `info[${mediaGroup.title}][${image.id}][sort_order]`,
            image.sort_order.toString()
        );
        imagesFormData.append(
            `info[${mediaGroup.title}][${image.id}][is_public]`,
            (image?.is_public ? 1 : 0).toString()
        );
    });

    imagesFormData.append('_method', 'PUT');

    const imagesResponse = await axios.post(
        `${apiUrl}/media/${mediaGroup.id}`,
        imagesFormData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );

    if (imagesResponse) {
        return imagesResponse.data;
    }
};

export const updateImage = async (
    image: any,
    apiUrl: string,
    mediaGroup: any
) => {
    const imagesFormData = new FormData();

    imagesFormData.append(
        `info[${mediaGroup.title}][${image.id}][title]`,
        image?.title || ''
    );
    imagesFormData.append(
        `info[${mediaGroup.title}][${image.id}][description]`,
        image?.description || ''
    );
    imagesFormData.append(
        `info[${mediaGroup.title}][${image.id}][sort_order]`,
        image.sort_order.toString()
    );
    imagesFormData.append(
        `info[${mediaGroup.title}][${image.id}][is_public]`,
        (image?.is_public ? 1 : 0).toString()
    );

    imagesFormData.append('_method', 'PUT');

    const imagesResponse = await axios.post(
        `${apiUrl}/media/${mediaGroup.id}`,
        imagesFormData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );

    if (imagesResponse) {
        return imagesResponse.data;
    }
};

export const deleteImage = async (
    image: any,
    apiUrl: string,
    mediaGroup: any
) => {
    const imagesResponse = await axios.delete(
        `${apiUrl}/media/media-${image.id}`
    );

    if (imagesResponse) {
        return imagesResponse.data;
    }
};

export function createImagesFromMedias(medias: IImageMedia[]): any[] | never[] {
    return medias.sort(imageSortFn).map((media) => ({
        id: media.id,
        preview: process.env.REACT_APP_API_ENDPOINT + media.url.thumbnail,
        url: media.url,
        title: media.title,
        is_public: media.is_public,
        sort_order: media.sort_order,
    }));
}
