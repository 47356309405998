import React from 'react';
import {} from 'react-bootstrap';
import {
    BarChart,
    Bar,
    CartesianGrid,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
} from 'recharts';

interface Props {
    nameValueData: { name: string; value: number }[];
    title: string;
    fills?: string[];
}

export const BarStat: React.FC<Props> = ({ title, nameValueData, fills }) => {
    const data = nameValueData.length
        ? nameValueData.map((d, i) => ({
              fill: fills ? fills[i % fills.length] : 'peru',
              ...d,
              value: parseInt(d.value as any, 10),
          }))
        : [{ name: '-' }];

    return (
        <div className='d-flex justify-content-center flex-column'>
            <div className='fw-bold fs-5 my-3 text-center'>{title}</div>

            <div style={{ marginLeft: '-1rem' }}>
                {/* Little hack fix for mobile view */}
                <ResponsiveContainer width='100%' aspect={4.0 / 3.0}>
                    <BarChart data={data}>
                        <XAxis dataKey='name' reversed />
                        <YAxis dataKey='value' allowDecimals={false} />
                        <Tooltip />

                        <Bar dataKey='value' />
                        <CartesianGrid vertical={false} strokeWidth={0.5} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};
