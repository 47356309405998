import React from 'react';
import { Accordion } from 'react-bootstrap';
import { ICategory } from '../../../types/ICategory';
import { CategorieToggler } from './CategorieToggler';
import { categorySortFn } from '../../../utils/helper';

import './RecursiveCategoryList.scss';
interface RecursiveCategoryListProps {
    categories: ICategory[];
    select: (category: ICategory) => void;
    onlyEnds?: boolean;
    selectedId?: number;
    renderLabel?: (c: ICategory) => string;
}

export const RecursiveCategoryList: React.FC<RecursiveCategoryListProps> = ({
    categories,
    select,
    onlyEnds,
    selectedId,
    renderLabel = (c) => c.name,
}) => {
    return (
        <Accordion className='recursive-category-list' alwaysOpen>
            {categories.sort(categorySortFn).map((c) => (
                <ul key={c.id}>
                    {c.children.length > 0 ? (
                        <li className='cursor-pointer p-0'>
                            <CategorieToggler eventKey={c.name}>
                                <span
                                    className={
                                        'categorie-name ' +
                                        (selectedId === c.id
                                            ? 'text-secondary text-decoration-underline'
                                            : '')
                                    }
                                    onClick={() => {
                                        !onlyEnds && select(c);
                                    }}
                                >
                                    {renderLabel(c)}
                                </span>
                            </CategorieToggler>
                            <ul>
                                <Accordion.Collapse eventKey={c.name}>
                                    <RecursiveCategoryList
                                        categories={c.children}
                                        select={select}
                                        onlyEnds={onlyEnds}
                                        selectedId={selectedId}
                                        renderLabel={renderLabel}
                                    />
                                </Accordion.Collapse>
                            </ul>
                        </li>
                    ) : (
                        // im a end node...
                        <li
                            className={
                                'categorie-name cursor-pointer ' +
                                (selectedId === c.id
                                    ? 'text-secondary text-decoration-underline'
                                    : '')
                            }
                            onClick={() => {
                                select(c);
                            }}
                        >
                            {renderLabel(c)}
                        </li>
                    )}
                </ul>
            ))}
        </Accordion>
    );
};
