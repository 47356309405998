import React from 'react';

//             Page Header. Content columns are below.

export const Page: React.FC<{
    className?: string;
    style?: React.CSSProperties;
}> = ({ children, className = '', style = {} }) => {
    // used in MarketplacePage.tsx, DAO-182 mobile view
    const containsTopPadding = className
        .split(/\s/)
        .some((s) => s.match(/^pt-\d$/));
    const cn = `page ${containsTopPadding ? '' : 'pt-4 '}${className}`;
    return (
        <main className={cn} style={style}>
            {children}
        </main>
    );
};
