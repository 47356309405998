import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from './components/ErrorBoundary';

import './i18n';
import { SimpleReactLightboxExtended } from './components/SimpleReactLightboxExtended';

ReactDOM.render(
    <ErrorBoundary>
        <BrowserRouter>
            <SimpleReactLightboxExtended>
                <App />
            </SimpleReactLightboxExtended>
        </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
