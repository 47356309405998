import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import useAxios from 'axios-hooks';
import { useParams } from 'react-router';
import { format as formatDate } from 'date-fns';
import { IProductStatus } from '../../../../../types/IProductStatus';
import IServerResponseType from '../../../../../types/IServerResponseType';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import IProjectType from '../../../../../types/IProjectType';
import { IWasteLadderOption } from '../../../../../types/IWasteLadderOption';

const recursivePlacement = (placement: any, placementLevels: any[]): any[] => {
    placementLevels = [placement, ...placementLevels];
    if (placement.parent) {
        return recursivePlacement(placement.parent, placementLevels);
    }

    return placementLevels;
};

interface IusePlacementForm {
    setCannotPublish: (b: boolean) => void;
}

export const usePlacementsForm = ({ setCannotPublish }: IusePlacementForm) => {
    const { projectId, productId } = useParams();

    const { organisation } = useAuth();

    const [productItems, setProductItems] = useState<any[]>([]);

    const fetchItems = useCallback(async () => {
        const response = await axios.get(
            `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems`
        );

        if (response.data.data) {
            let items = response.data.data;

            // handle placments
            items = items.map((item: any) => {
                let placementLevel: any[] = [];
                // If Item's placement is null, dont set up placement level
                if (!item.placement) return item;

                // recursive placement
                placementLevel = recursivePlacement(
                    item.placement,
                    placementLevel
                );

                placementLevel = placementLevel.map((placement: any) => {
                    return {
                        ...placement,
                        label: placement.title,
                        value: placement.id,
                        parent: placement.parent_placement_id,
                    };
                });
                return { ...item, placementLevel };
            });

            setProductItems(items);
        }

        return response.data;
    }, [organisation?.id, projectId, productId]);

    const addQuantity = useCallback(
        (index) => {
            const item = productItems[index];
            if (productId) {
                axios
                    .put(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`,
                        {
                            quantity: item.quantity + 1,
                        }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index].quantity++;
                                return [...newItems];
                            });
                        }
                    });
            } else {
                setProductItems((prev) => {
                    const newItems = [...prev];
                    newItems[index].quantity++;
                    return [...newItems];
                });
            }
        },
        [organisation?.id, productId, productItems, projectId, setProductItems]
    );

    const removeQuantity = useCallback(
        (index) => {
            const item = productItems[index];
            if (productId) {
                axios
                    .put(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}}/productitems/${item.id}`,
                        {
                            quantity: item.quantity - 1,
                        }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index].quantity--;
                                return [...newItems];
                            });
                        }
                    });
            } else {
                setProductItems((prev) => {
                    const newItems = [...prev];
                    newItems[index].quantity--;
                    return [...newItems];
                });
            }
        },
        [organisation?.id, productId, productItems, projectId, setProductItems]
    );

    const setQuantity = useCallback(
        (index, quantity) => {
            setProductItems((prev) => {
                const newItems = [...prev];
                newItems[index].quantity = quantity;
                return [...newItems];
            });
        },
        [setProductItems]
    );

    const onBlurQuantity = useCallback(
        (index, quantity) => {
            const item = productItems[index];
            if (productId) {
                axios
                    .put(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`,
                        {
                            quantity,
                        }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index].quantity = quantity;
                                return [...newItems];
                            });
                        }
                    });
            } else {
                setProductItems((prev) => {
                    const newItems = [...prev];
                    newItems[index].quantity = quantity;
                    return [...newItems];
                });
            }
        },
        [organisation?.id, productId, productItems, projectId, setProductItems]
    );

    const addNewItem = useCallback(
        (quantity = 1) => {
            if (productId) {
                axios
                    .post(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems`,
                        {
                            quantity,
                        }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => [
                                ...prev,
                                {
                                    ...res.data.data,
                                    placementLevel: [],
                                },
                            ]);
                        }
                    });
            } else {
                setProductItems((prev) => [
                    ...prev,
                    {
                        quantity: 1,
                        placementLevel: [],
                    },
                ]);
            }
        },
        [organisation?.id, productId, projectId, setProductItems]
    );

    const removeItem = useCallback(
        (index: number) => {
            if (productId) {
                const item = productItems[index];

                axios
                    .delete(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`
                    )
                    .then((res) => {
                        if (res.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems.splice(index, 1);
                                return newItems;
                            });
                        }
                    });
            } else {
                setProductItems((prev) => {
                    const newItems = [...prev];
                    newItems.splice(index, 1);
                    return newItems;
                });
            }
        },
        [organisation?.id, productId, productItems, projectId, setProductItems]
    );

    const updatePlacement = useCallback(
        (index: number, placement_id: number | null) => {
            if (productId) {
                // find item
                const item = productItems[index];
                axios
                    .put(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`,
                        { placement_id: placement_id }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index] = {
                                    ...newItems[index],
                                    ...res.data.data,
                                };
                                return [...newItems];
                            });
                        }
                    });
            }
        },
        [organisation?.id, productId, productItems, projectId, setProductItems]
    );

    const setPlacementLevel = useCallback(
        (index: number, placement_id: number | null, level: string) => {
            setProductItems((prev) => {
                const newItems = [...prev];
                if (placement_id === null) {
                    // Somebody pressed the clear button
                    switch (level) {
                        case 'level1':
                            newItems[index].placementLevel = [];
                            break;
                        case 'level2':
                            newItems[index].placementLevel = [
                                newItems[index].placementLevel[0],
                            ];
                            break;
                        case 'level3':
                            newItems[index].placementLevel = [
                                newItems[index].placementLevel[0],
                                newItems[index].placementLevel[1],
                            ];
                            break;
                        case 'level4':
                            newItems[index].placementLevel = [
                                newItems[index].placementLevel[0],
                                newItems[index].placementLevel[1],
                                newItems[index].placementLevel[2],
                            ];
                            break;
                    }

                    if (productId) {
                        if (level === 'level1') {
                            updatePlacement(index, null);
                        } else {
                            const reversing = [
                                ...newItems[index].placementLevel,
                            ];
                            updatePlacement(
                                index,
                                reversing.reverse()[0].value
                            );
                        }
                    }
                } else {
                    switch (level) {
                        case 'level1':
                            newItems[index].placementLevel = [placement_id];
                            break;
                        case 'level2':
                            newItems[index].placementLevel = [
                                newItems[index].placementLevel[0],
                                placement_id,
                            ];
                            break;
                        case 'level3':
                            newItems[index].placementLevel = [
                                newItems[index].placementLevel[0],
                                newItems[index].placementLevel[1],
                                placement_id,
                            ];
                            break;
                        case 'level4':
                            newItems[index].placementLevel = [
                                newItems[index].placementLevel[0],
                                newItems[index].placementLevel[1],
                                newItems[index].placementLevel[2],
                                placement_id,
                            ];
                            break;
                    }

                    if (productId) {
                        const reversing = [...newItems[index].placementLevel];
                        updatePlacement(index, reversing.reverse()[0].value);
                    }
                }

                return [...newItems];
            });
        },
        [productId, setProductItems, updatePlacement]
    );

    const setPreliminary = useCallback(
        (index, preliminary) => {
            if (productId) {
                const item = productItems[index];
                axios
                    .patch(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`,
                        { preliminary }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index] = {
                                    ...newItems[index],
                                    preliminary,
                                };
                                return [...newItems];
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status === 400) {
                            setCannotPublish(true);
                        }
                    });
            } else {
                setProductItems((prev) => {
                    const newItems = [...prev];
                    newItems[index] = {
                        ...newItems[index],
                        preliminary,
                    };
                    return [...newItems];
                });
            }
        },
        [
            organisation?.id,
            projectId,
            productId,
            productItems,
            setProductItems,
            setCannotPublish,
        ]
    );

    const setStatus = useCallback(
        (index, product_status_id) => {
            if (productId) {
                const item = productItems[index];

                axios
                    .patch(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`,
                        {
                            product_status_id,
                        }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index] = {
                                    ...newItems[index],
                                    product_status_id,
                                };
                                return [...newItems];
                            });
                        }
                    });
            } else {
                setProductItems((prev) => {
                    const newItems = [...prev];
                    newItems[index] = {
                        ...newItems[index],
                        product_status_id,
                    };
                    return [...newItems];
                });
            }
        },
        [organisation?.id, productId, productItems, projectId, setProductItems]
    );

    const setWasteLadder = useCallback(
        (index, waste_ladder) => {
            if (productId) {
                const item = productItems[index];

                axios
                    .patch(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`,
                        { waste_ladder }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index] = {
                                    ...newItems[index],
                                    waste_ladder,
                                };
                                return [...newItems];
                            });
                        }
                    });
            } else {
                setProductItems((prev) => {
                    const newItems = [...prev];
                    newItems[index] = { ...newItems[index], waste_ladder };
                    return [...newItems];
                });
            }
        },
        [organisation?.id, productId, productItems, projectId, setProductItems]
    );

    const setViewStatus = useCallback(
        (index, view_status) => {
            if (productId) {
                const item = productItems[index];

                axios
                    .patch(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`,
                        {
                            view_status,
                        }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index] = {
                                    ...newItems[index],
                                    view_status,
                                };
                                return [...newItems];
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status === 400) {
                            setCannotPublish(true);
                        }
                    });
            } else {
                setProductItems((prev) => {
                    const newItems = [...prev];
                    newItems[index] = {
                        ...newItems[index],
                        view_status,
                    };
                    return [...newItems];
                });
            }
        },
        [
            organisation?.id,
            productId,
            productItems,
            projectId,
            setProductItems,
            setCannotPublish,
        ]
    );

    const setDeliveryDate = useCallback(
        (index, delivery_date) => {
            if (productId) {
                const item = productItems[index];

                axios
                    .patch(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`,
                        {
                            delivery_date: delivery_date
                                ? formatDate(delivery_date, 'yyyy-MM-dd')
                                : null,
                        }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index] = {
                                    ...newItems[index],
                                    delivery_date,
                                };
                                return [...newItems];
                            });
                        }
                    });
            } else {
                setProductItems((prev) => {
                    const newItems = [...prev];
                    newItems[index] = {
                        ...newItems[index],
                        delivery_date,
                    };
                    return [...newItems];
                });
            }
        },
        [organisation?.id, productId, productItems, projectId, setProductItems]
    );

    const setDescription = useCallback(
        (index, description) => {
            setProductItems((prev) => {
                const newItems = [...prev];
                newItems[index] = {
                    ...newItems[index],
                    description,
                };
                return [...newItems];
            });
        },
        [setProductItems]
    );

    const onBlurDescription = useCallback(
        (index, description) => {
            if (productId) {
                const item = productItems[index];

                axios
                    .patch(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productitems/${item.id}`,
                        {
                            description,
                        }
                    )
                    .then((res) => {
                        if (res.data.data) {
                            setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index] = {
                                    ...newItems[index],
                                    description,
                                };
                                return [...newItems];
                            });
                        }
                    });
            }
        },
        [organisation?.id, productId, productItems, projectId, setProductItems]
    );

    const [{ data: projectData }, refetchProject] = useAxios<
        IServerResponseType<IProjectType>
    >(`/api/organisations/${organisation?.id}/projects/${projectId}`, {
        manual: true,
    });

    const [{ data: productStatusData }, refetchProductStatus] = useAxios<
        IServerResponseType<IProductStatus[]>
    >(`/api/productstatuses`, {
        manual: true,
    });

    useEffect(() => {
        if (organisation) {
            refetchProductStatus();
            if (projectId) {
                refetchProject();
            }
            if (productId) {
                fetchItems();
            }
        }
    }, [
        fetchItems,
        organisation,
        productId,
        projectId,
        refetchProductStatus,
        refetchProject,
    ]);

    const projectStartDate: Date = projectData?.data.project_start
        ? new Date(projectData?.data.project_start)
        : new Date();

    const productStatuses: IProductStatus[] = productStatusData?.data || [];

    const productViwingStatuses: string[] = ['Privat', 'Public'];
    const wasteLadderOptions: IWasteLadderOption[] = [
        { id: 0, name: 'Behålla' },
        { id: 1, name: 'Återbruka' },
        { id: 2, name: 'Återvinna' },
        { id: 3, name: 'Energiutvinna' },
        { id: 4, name: 'Deponi' },
    ];

    return {
        onBlurDescription,
        fetchItems,
        addQuantity,
        removeQuantity,
        addNewItem,
        removeItem,
        setPlacementLevel,
        setPreliminary,
        setStatus,
        setWasteLadder,
        setViewStatus,
        setDeliveryDate,
        setDescription,
        setQuantity,
        onBlurQuantity,
        productItems,
        productStatuses,
        productViwingStatuses,
        projectStartDate,
        wasteLadderOptions,
    };
};
