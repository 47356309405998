import React from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ICriteriaType from '../../../types/ICriteriaType';

import IProduct from '../../../types/IProductType';
import { Points } from './Points';

interface Props {
    product?: IProduct;
    fluid?: boolean;
    hideCustomCriteries?: boolean;
}
interface ICriteriaTypeWithValue extends ICriteriaType {
    value: number;
}

export const ProductCriterias = ({
    product,
    fluid,
    hideCustomCriteries,
}: Props) => {
    const { t } = useTranslation();
    const criterias: ICriteriaTypeWithValue[] = [
        { title: 'Condition', value: product?.condition_points || 0 },
        { title: 'Function', value: product?.function_points || 0 },
        ...(hideCustomCriteries
            ? []
            : product?.product_points
                  ?.filter((c) => c.criteria && c.points > 0)
                  .map((c) => ({ ...c.criteria!, value: c.points })) || []),
    ];

    return (
        <Stack
            direction='horizontal'
            className={'me-auto mt-1 ' + (fluid ? 'd-none d-sm-flex' : '')}
        >
            <Stack
                className={
                    'me-2 justify-content-center' +
                    (fluid ? 'd-none d-sm-flex' : '')
                }
                gap={1}
            >
                {criterias.map((c, i) => (
                    <b key={i} style={{ flex: 1 }}>
                        {t(c.title)}:
                    </b>
                ))}
            </Stack>
            <Stack gap={1}>
                {criterias.map((c, i) => (
                    <div key={i}>
                        {fluid && (
                            <span className='me-2 d-sm-none'>
                                {t(c.title)}:
                            </span>
                        )}
                        <Points criteria={c} value={c.value} />
                    </div>
                ))}
            </Stack>
        </Stack>
    );
};
