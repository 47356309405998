import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { cover, thumb, isPublicDot } from './Styles';

export interface DnDImageSlotProps {
    move: (dragIndex: number, hoverIndex: number) => void;
    select: (index: number) => void;
    selected: boolean;
    isFirst: boolean;
    index: number;
    accept: string;
    forbidDrag: boolean;
    isPublic: boolean;
    onDrop: (file: any) => void;
}

export const DnDImageSlot: React.FC<DnDImageSlotProps> = ({
    move,
    select,
    selected,
    isFirst,
    children,
    index,
    accept,
    forbidDrag,
    isPublic,
    onDrop,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop({
        accept: accept,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item, monitor) {
            // trigger onDrop
            onDrop(item);
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Time to actually perform the action
            move(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.

            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: accept,
        item: () => {
            select(index);
            return { index };
        },
        canDrag: !forbidDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.3 : 1;
    drag(drop(ref));

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            style={{
                ...(isFirst ? cover : thumb),
                opacity,
                cursor: forbidDrag ? 'default' : 'move',
            }}
            onClick={() => !forbidDrag && select(index)}
            className={`bg-light ${
                selected
                    ? 'border border-3 border-warning'
                    : 'border border-3 border-light'
            }`}
        >
            {children}

            {isPublic && <span style={isPublicDot as React.CSSProperties} />}
        </div>
    );
};
