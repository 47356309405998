import { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';

import { Breadcrumb, ButtonToolbar, Container } from 'react-bootstrap';

import { useThrottle } from '@react-hook/throttle';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Page } from '../../components/Layout/Page';
import { SearchInput } from '../../components/SearchInput';
import { useTableReducer } from '../../hooks/useTableReducer';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { IProductItem } from '../../types/IProductItem';
import IProjectType from '../../types/IProjectType';
import IServerResponseWithTotalType from '../../types/IServerResponseWithTotalType';
import ResourcePermissionsType from '../../types/ResourcePermissionsType';
import { useAuth } from '../Auth/hooks/useAuth';
import { ProductItemsListView } from './components/ListViews/ProductItemsListView';
import { ProductItemsMobileView } from './components/ListViews/ProductItemsMobileView';
import { ProductItemModals } from './components/ProductItemModals/ProductItemModals';
import { SEO } from '../../components/SEO';
import { TableSelectedProductItemsProvider } from './components/SelectedProductItemsContext';
import { ProductItemManageButton } from './components/ProductItemManageButton';

export const ProductItemsPage = () => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useThrottle('', 2);

    const { projectId } = useParams();
    const { pathname } = useLocation();

    const { t } = useTranslation();

    const { organisation } = useAuth();
    const displayListView = useBreakpoint('up', 'md');

    // Pagination
    const [{ queryPageIndex, queryPageSize, sortBy }, dispatch] =
        useTableReducer();

    // API fetch project
    const [{ data: projectData }, refetchProject] = useAxios<
        IServerResponseWithTotalType<ResourcePermissionsType<IProjectType>>
    >(`/api/organisations/${organisation?.id}/projects/${projectId}`, {
        manual: true,
    });
    const project = projectData?.data;

    // API fetch products
    const [{ data, error }, refetch] = useAxios<
        IServerResponseWithTotalType<ResourcePermissionsType<IProductItem>[]>
    >(
        `/api/organisations/${organisation?.id}${
            projectId ? '/projects/' + projectId : ''
        }/productitems?skip=${
            queryPageSize * queryPageIndex
        }&limit=${queryPageSize}${
            sortBy?.id
                ? `&sortBy=${sortBy.id}&direction=${
                      sortBy.desc ? 'desc' : 'asc'
                  }`
                : ''
        }&search=${search}`,
        {
            manual: true,
        }
    );
    const productItems: ResourcePermissionsType<IProductItem>[] =
        data?.data ?? [];
    const totalCount = data?.total ?? -1;

    useEffect(() => {
        if (projectId) {
            refetchProject();
        }
    }, [projectId, refetchProject]);

    useEffect(() => {
        if (organisation) {
            setLoading(true);
            refetch()
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [organisation, refetch, sortBy, queryPageSize, queryPageIndex]);

    if (error) return <p>Error!</p>;
    return (
        <TableSelectedProductItemsProvider>
            <ProductItemModals refetch={refetch}>
                <Page className='px-0 px-sm-inherit'>
                    <Container fluid className='px-0 px-sm-inherit'>
                        <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                            <Breadcrumb className='d-flex align-content-center'>
                                {pathname.includes('/projects') && (
                                    <>
                                        <Breadcrumb.Item
                                            linkAs={Link}
                                            linkProps={{
                                                to: `/admin/projects`,
                                            }}
                                        >
                                            {t('Projects')}
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item
                                            linkAs={Link}
                                            linkProps={{
                                                to: `/admin/projects/${project?.id}`,
                                            }}
                                        >
                                            {project?.title}
                                        </Breadcrumb.Item>
                                    </>
                                )}
                                <Breadcrumb.Item active>
                                    <SEO
                                        title={t('Product placements')}
                                        asText
                                    ></SEO>
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            <div className='d-flex flex-row align-content-md-center justify-content-between gap-md-4'>
                                <SearchInput
                                    onChange={(value: string) =>
                                        setSearch(value)
                                    }
                                    placeholder={t('Search')}
                                ></SearchInput>
                            </div>
                        </ButtonToolbar>

                        <ButtonToolbar className='d-flex flex-md-row-reverse align-content-center gap-2 mb-4'>
                            <ProductItemManageButton />
                        </ButtonToolbar>

                        <div className='d-sm-none'>
                            <ProductItemsMobileView
                                {...{
                                    project,
                                    productItems,
                                    queryPageIndex,
                                    queryPageSize,
                                    sortBy,
                                    totalCount,
                                    dispatch,
                                    loading,
                                }}
                            />
                        </div>
                        <div className='d-none d-sm-block'>
                            {displayListView && (
                                <ProductItemsListView
                                    {...{
                                        project,
                                        productItems,
                                        queryPageIndex,
                                        queryPageSize,
                                        sortBy,
                                        totalCount,
                                        dispatch,
                                        loading,
                                    }}
                                />
                            )}
                        </div>
                    </Container>
                </Page>
            </ProductItemModals>
        </TableSelectedProductItemsProvider>
    );
};
