import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { useProductFormState } from '../ProductFormState';

import { useParams } from 'react-router';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import {
    createDocuments,
    createDocumentsFromMedias,
    deleteDocument,
} from '../../../../../API/product/documents';
import { SimpleDropZonePreview } from '../../../../../components/SimpleDropZone/SimpleDropZonePreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import './DocumentsForm.scss';

interface Props {}

export const DocumentsForm = (props: Props) => {
    const { projectId, productId } = useParams();
    const { organisation } = useAuth();

    const { t } = useTranslation();

    const {
        setProductDocuments: setFiles,
        productDocuments: files,
        setFormState,
        product_documents,
    } = useProductFormState();

    const [, setFileSelected] = useState<number>(-1);

    const onDrop = useCallback(
        (acceptedFiles) => {
            const newFiles: any[] =
                acceptedFiles.map((file: any, index: number) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        index: files.length + index,
                    })
                ) || [];

            if (organisation?.id && projectId && productId) {
                createDocuments(
                    organisation?.id,
                    parseInt(projectId, 10),
                    parseInt(productId, 10),
                    newFiles
                ).then((res) => {
                    setFormState((prev) => ({
                        ...prev,
                        productDocuments: createDocumentsFromMedias(res.medias),
                        product_documents: res,
                    }));
                });
            } else {
                setFiles((prev: any[]) => {
                    return [...prev, ...newFiles];
                });
            }
        },
        [
            files.length,
            organisation?.id,
            productId,
            projectId,
            setFiles,
            setFormState,
        ]
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf', //, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, ",
        onDrop,
    });

    const handleRemove = useCallback(
        (fileId: number) => {
            if (
                organisation?.id &&
                projectId &&
                productId &&
                product_documents
            ) {
                deleteDocument(
                    organisation?.id,
                    parseInt(projectId, 10),
                    parseInt(productId, 10),
                    product_documents.id,
                    files.find((f) => f.id === fileId)
                ).then((res) => {
                    setFileSelected(-1);
                    setFiles((prev: any[]) => {
                        return [...prev.filter((f) => f.id !== fileId)];
                    });
                });
            }
            setFileSelected(-1);
            setFiles((prev: any[]) => {
                return [...prev.filter((f) => f.id !== fileId)];
            });
        },
        [
            files,
            organisation?.id,
            productId,
            product_documents,
            projectId,
            setFiles,
        ]
    );
    const maxNumFiles = 5;
    const allowedToUploadFiles = files.length < maxNumFiles;

    const classes = [
        'dropzone',
        'd-flex',
        'flex-grow-1',
        'flex-column',
        'card',
        'bg-light',
        'text-center',
        'border-0',
        'm-2',
        allowedToUploadFiles ? 'pointer' : '',
    ];

    return (
        <Row className='d-flex flex-row gap-3'>
            <Col
                {...getRootProps({
                    className: classes.join(' '),
                })}
                md={12}
                xl={3}
            >
                <input {...getInputProps()} disabled={!allowedToUploadFiles} />
                <div className='m-sm-4 fs-1'>
                    <FontAwesomeIcon icon={faCloudUpload} />
                </div>
                <h5>{t('Upload files')}</h5>
                <p className='d-none d-sm-block'>
                    {t('Drag one or more files here, or click to select files')}
                </p>
            </Col>

            <Col md={12} xl={6}>
                <SimpleDropZonePreview
                    {...{ files, handleRemove }}
                    title={
                        t('Uploaded documents') +
                        ` ${files.length}/${maxNumFiles}`
                    }
                    type='Documents'
                />
            </Col>
        </Row>
    );
};
