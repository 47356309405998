import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProductFormState } from '../ProductFormState';

interface Props {}

export const DocumentsPreview = (props: Props) => {
    const { t } = useTranslation();
    const { productDocuments } = useProductFormState();

    return (
        <div>
            {t('{{ amount }} documents uploaded', {
                amount: productDocuments?.length || 0,
            })}
        </div>
    );
};
