import React from 'react';
import { useTranslation } from 'react-i18next';
import { DnDImageSlot } from './DnDImageSlot';
import { DnDImage } from './DnDImage';
import { Spinner } from 'react-bootstrap';

export interface ThumbsProps {
    files: any[];
    selected: number;
    select: (index: number) => void;
    handleMove: (index: number, item: number) => void;
    onDrop: (file: any) => void;
    loading?: boolean;
    hideIsPublic?: boolean;
}

export const Thumbs: React.FC<ThumbsProps> = ({
    files,
    selected,
    select,
    handleMove,
    onDrop,
    loading,
    hideIsPublic,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {[
                ...files,
                ...(files.length < 4
                    ? Array.apply(null, Array(4 - files.length))
                    : []),
            ].map((file: any, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                            ...(index === 0
                                ? {
                                      flex: '100%',
                                  }
                                : {}),
                        }}
                    >
                        <DnDImageSlot
                            key={index}
                            selected={selected === file?.id}
                            isFirst={index === 0}
                            index={index}
                            move={(item) => handleMove(index, item)}
                            select={select}
                            accept='file'
                            forbidDrag={!file}
                            isPublic={
                                !hideIsPublic && (file?.is_public || false)
                            }
                            onDrop={onDrop}
                        >
                            <DnDImage
                                preview={file?.preview}
                                name={file?.name || ''}
                                type={'file'}
                                index={index}
                            />
                            <div className='float-end'>
                                {loading && (
                                    <Spinner
                                        animation='grow'
                                        variant='primary'
                                        size='sm'
                                    />
                                )}
                            </div>
                        </DnDImageSlot>
                        <div className='text-center'>
                            {`${
                                !file?.title && index === 0
                                    ? t('Cover image')
                                    : file?.title || ''
                            }`}
                        </div>
                    </div>
                );
            })}
        </>
    );
};
