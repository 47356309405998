import { useEffect, useState } from 'react';

import { useAuth } from './useAuth';
import { useIsSysAdmin } from './useIsSysAdmin';
import { useIsAuth } from './useIsAuth';

export const useIsAdmin = () => {
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState<boolean>();

    const { isAdmin } = useAuth();

    const [loadingAuth, isAuth] = useIsAuth();
    const [loadingSysAdmin, isSysAdmin] = useIsSysAdmin();

    useEffect(() => {
        const checkPermission = async () => {
            setLoading(true);

            if (loadingAuth || loadingSysAdmin) {
                setLoading(true);
                return;
            }

            if (isSysAdmin) {
                setAllowed(true);
                setLoading(false);
                return;
            }

            if (isAuth) {
                const hasAdmin = await isAdmin();
                if (hasAdmin) {
                    setAllowed(true);
                    setLoading(false);
                    return;
                } else {
                    setAllowed(false);
                    setLoading(false);
                    return;
                }
            } else {
                setAllowed(false);
                setLoading(false);
                return;
            }
        };

        checkPermission();
    }, [isAdmin, isAuth, isSysAdmin, loadingAuth, loadingSysAdmin]);

    return [loading, allowed];
};

export default useIsAdmin;
