import React, { createContext, useContext, useState } from 'react';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import IProduct from '../../../types/IProductType';

interface ISelectedContext {
    selectedItems: ResourcePermissionsType<IProduct>[];
    setSelectedItems: React.Dispatch<
        React.SetStateAction<ResourcePermissionsType<IProduct>[]>
    >;
}

const TableSelectedContext = createContext<ISelectedContext>(
    {} as ISelectedContext
);

export const useTableSelectedProducts = () =>
    useContext<ISelectedContext>(TableSelectedContext);

export const TableSelectedProductsProvider: React.FC = ({ children }) => {
    const [selectedItems, setSelectedItems] = useState<
        ResourcePermissionsType<IProduct>[]
    >([]);

    return (
        <TableSelectedContext.Provider
            value={{ selectedItems, setSelectedItems }}
        >
            {children}
        </TableSelectedContext.Provider>
    );
};
