import IRoute, { IPrivateRoute } from '../types/IRouteType';

import { organisationPrivateRoutes } from './organisationRoutes';
import { marketplacePublicRoutes } from './marketplaceRoutes';
import { productsPrivateRoutes } from './productsPrivateRoutes';
import { cmsPublicRoutes } from './cmsRoutes';
import { systemadminPrivetRouter } from './systemadminRouter';
import { projectsPrivateRoutes } from './projectsRoutes';
import { authPublicRoutes, authPrivateRoutes } from './authRoutes';
import { dashboardPrivetRoutes } from './dashboardRoutes';

import { Error401 } from '../pages/Error401';
import { Error403 } from '../pages/Error403';
import { Error404 } from '../pages/Error404';
import { Error500 } from '../pages/Error500';
import { AdminSideNavLayout } from '../components/Layout/AdminSideNavLayout';

export const makeRoutsPrivet = (routes: IPrivateRoute[]) => {
    return routes.map((route) => {
        let newRoute = route;

        if (newRoute && newRoute.children) {
            newRoute.children = makeRoutsPrivet(newRoute.children);
        }

        return {
            ...newRoute,
            auth: true,
        };
    });
};

export const routesPrivate: IPrivateRoute[] = makeRoutsPrivet([
    ...projectsPrivateRoutes,
    ...dashboardPrivetRoutes,
    ...productsPrivateRoutes,
    ...organisationPrivateRoutes,
    ...authPrivateRoutes,
]);

export const systemadminRoutes: IPrivateRoute[] = makeRoutsPrivet([
    ...systemadminPrivetRouter,
]);

export const makeRoutsPublic = (routes: IRoute[]) => {
    return routes.map((route) => {
        let newRoute = route;

        if (newRoute && newRoute.children) {
            newRoute.children = makeRoutsPublic(newRoute.children);
        }

        return {
            ...newRoute,
            auth: false,
        };
    });
};

export const routes: IRoute[] = makeRoutsPublic([
    ...marketplacePublicRoutes,
    ...cmsPublicRoutes,
    ...authPublicRoutes,

    {
        path: '/401',
        element: (
            <AdminSideNavLayout>
                <Error401 />,
            </AdminSideNavLayout>
        ),
    },
    {
        path: '/403',
        element: (
            <AdminSideNavLayout>
                <Error403 />
            </AdminSideNavLayout>
        ),
    },
    {
        path: '/404',
        element: (
            <AdminSideNavLayout>
                <Error404 />
            </AdminSideNavLayout>
        ),
    },
    {
        path: '/500',
        element: (
            <AdminSideNavLayout>
                <Error500 />
            </AdminSideNavLayout>
        ),
    },
]);
