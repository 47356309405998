import React from 'react';
import { Row, Col, Form, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useProductFormState } from '../ProductFormState';

interface Props {}

export const VideoForm = (props: Props) => {
    const { t } = useTranslation();

    const { setBasicInformation, video_url, video_description, updateProduct } =
        useProductFormState();

    // Converts a youtube URL to a youtube thumbnail URL
    const getThumbnailSrc = (youtubeLink: string) => {
        const pre = 'https://i.ytimg.com/vi/';
        const suf = '/hqdefault.jpg';

        // Extract video ID from youtube link
        var regExp =
            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = youtubeLink?.match(regExp);
        if (match && match[7].length === 11) return pre + match![7] + suf;

        return '';
    };

    const thumbnailUrl = getThumbnailSrc(video_url);
    return (
        <div>
            <Row>
                <Col sm={12} xl={12 - 7}>
                    {thumbnailUrl ? (
                        <a
                            href={video_url}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <Image
                                className='card-img'
                                src={thumbnailUrl}
                                rounded
                            />
                        </a>
                    ) : (
                        <></>
                    )}
                </Col>
                <Col sm={12} xl={7}>
                    <Form>
                        <Row>
                            <Col sm={12}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('Video URL')}</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder={t('Video URL')}
                                        onChange={(e) => {
                                            setBasicInformation(
                                                'video_url',
                                                e.target.value
                                            );
                                        }}
                                        onBlur={() => updateProduct()}
                                        value={video_url ?? ''}
                                        isValid={
                                            video_url && thumbnailUrl
                                                ? true
                                                : false
                                        }
                                        isInvalid={
                                            video_url && !thumbnailUrl
                                                ? true
                                                : false
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('Description')}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={5}
                                        placeholder={t('Description')}
                                        onChange={(e) => {
                                            setBasicInformation(
                                                'video_description',
                                                e.target.value
                                            );
                                        }}
                                        onBlur={() => updateProduct()}
                                        value={video_description ?? ''}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
