import React from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useProductSearch } from './ProductSearch';

import regions from '../../../utils/se_regions';

interface Props {}

export const ProductTextFilters = (props: Props) => {
    const { queryState, setQueryState } = useProductSearch();

    const onChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        switch (e.target.name) {
            case 'title':
                setQueryState('title', e.target.value);
                break;
            case 'barcode':
                setQueryState('barcode', e.target.value);
                break;
            case 'region':
                setQueryState('region', e.target.value);
                break;
        }
    };

    return (
        <>
            <Row>
                <Col xs={12} lg={6} className='mb-3'>
                    <FloatingLabel controlId={'title'} label={`Namn`}>
                        <Form.Control
                            name={`title`}
                            onChange={onChange}
                            type='text'
                            size='sm'
                            placeholder='Name'
                            value={queryState.title || ''}
                        ></Form.Control>
                    </FloatingLabel>
                </Col>

                <Col xs={12} lg={6} className='mb-3'>
                    <FloatingLabel controlId={'barcode'} label={`Märknings-id`}>
                        <Form.Control
                            name={'barcode'}
                            onChange={onChange}
                            type='text'
                            size='sm'
                            placeholder='Märknings-id'
                            value={queryState.barcode || ''}
                        ></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} className='mb-3'>
                    <FloatingLabel controlId='region' label='Region'>
                        <Form.Select
                            name='region'
                            value={queryState.region || ''}
                            onChange={onChange}
                        >
                            <option value=''>-</option>
                            {regions.map((s: string) => (
                                <option key={s} value={s}>
                                    {s}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
        </>
    );
};
