import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import { IImageMediaGroup } from '../../../types/IMediaType';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../Auth/hooks/useAuth';
import { Restricted } from '../../Auth/components/Restricted';
import IProduct from '../../../types/IProductType';
import { CoverImage } from '../../../components/CoverImage';
import { ProductQuantityLabel } from './ProductQuantityLabel';
import { ReactComponent as STARTDATEIcon } from '../../../assets/icones/Dacke_startdatum.svg';

import { ReactComponent as UNUSEDIcon } from '../../../assets/icones/Dacke_oanvand.svg';
import { Logo } from '../../../components/Logo';
import { ProductCriterias } from './ProductCriterias';
import { HeartProducts } from '../../Marketplace/components/HeartProducts';
import { tryFormatDefaultDate } from '../../../utils/helper';

interface ProductThumbnailProps {
    product: ResourcePermissionsType<IProduct> | IProduct;
    isMarketPlace?: boolean;
}

export const ProductThumbnail = ({
    product,
    isMarketPlace,
}: ProductThumbnailProps) => {
    const { t } = useTranslation();
    const { organisation } = useAuth();

    return (
        <Card className='shadow'>
            <Row className='g-2 p-2 p-sm-3'>
                <Col
                    xs='5'
                    md='12'
                    className='position-relative d-flex justify-content-center align-content-center align-items-center'
                >
                    <CoverImage
                        maxSize
                        images={product?.product_images as IImageMediaGroup}
                        lightbox
                    />
                    {isMarketPlace && (
                        <div className='d-none d-sm-block position-absolute top-0 start-0 ps-3 pt-2'>
                            <HeartProducts product={product} />
                        </div>
                    )}
                    <div className='d-none d-sm-block position-absolute top-0 end-0 pe-1 pt-2'>
                        <Logo
                            shape='circle'
                            size='md'
                            shadow='shadow-sm'
                            images={product?.organisation?.organisation_logo}
                        />
                    </div>
                    <div className='d-sm-none position-absolute top-0 end-0 pe-1 pt-2'>
                        <Logo
                            shape='circle'
                            size='xs'
                            shadow='shadow-sm'
                            images={product?.organisation?.organisation_logo}
                        />
                    </div>
                </Col>

                <Col className='d-flex flex-column'>
                    <Card.Body className='d-flex flex-column flex-fill p-0'>
                        <div className='d-flex flex-column flex-fill justify-content-between'>
                            <div className='d-flex flex-column gap-1'>
                                <Card.Title className='fw-bold fs-6 m-0 p-0 pb-0 pb-sm-2'>
                                    {product.title || (product as any).name}
                                    {', '}
                                    <ProductQuantityLabel product={product} />
                                    {product.unused && (
                                        <>
                                            <UNUSEDIcon
                                                width='18'
                                                className='mb-1 ms-1 d-sm-none'
                                            />
                                            <UNUSEDIcon
                                                width='26'
                                                className='ms-1 d-none d-sm-inline'
                                            />
                                        </>
                                    )}
                                </Card.Title>
                                <Card.Subtitle className='fw-normal mb-1'>
                                    <small className='d-none d-sm-inline me-1'>
                                        {t('Available from')}:
                                    </small>
                                    {product.delivery_date ? (
                                        <small>
                                            <STARTDATEIcon
                                                width='13'
                                                className='me-1'
                                            />
                                            {tryFormatDefaultDate(
                                                product.delivery_date
                                            )}
                                        </small>
                                    ) : (
                                        <small>-</small>
                                    )}
                                </Card.Subtitle>
                                <Card.Subtitle className='text-danger'>
                                    {!product?.quantity
                                        ? t('Sold out')
                                        : product?.sales_price
                                        ? `${product.sales_price} kr/${
                                              product.category?.unit?.symbol ||
                                              'st'
                                          }`
                                        : t('Price upon request')}
                                </Card.Subtitle>
                                <ProductCriterias fluid product={product} />
                            </div>

                            <div className='d-flex mt-1 mt-sm-3 flex-column gap-1'>
                                {product.organisation_id ===
                                organisation?.id ? (
                                    <Restricted
                                        to='edit single product'
                                        resource={
                                            product as ResourcePermissionsType<IProduct>
                                        }
                                    >
                                        <Link
                                            className='btn btn-primary btn-sm'
                                            to={`/admin/projects/${product?.project_id}/product-form/${product.id}`}
                                        >
                                            {t('Edit product')}
                                        </Link>
                                    </Restricted>
                                ) : (
                                    <></>
                                )}
                                {!isMarketPlace ? (
                                    <Restricted
                                        to='view single product'
                                        resource={
                                            product as ResourcePermissionsType<IProduct>
                                        }
                                    >
                                        <Link
                                            className='btn btn-primary btn-sm'
                                            to={`/admin/products/${product.id}`}
                                        >
                                            {t('Show product')}
                                        </Link>
                                    </Restricted>
                                ) : (
                                    <Link
                                        className='btn btn-primary btn-sm'
                                        to={`/products/${product.id}`}
                                    >
                                        {t('Show product')}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
};
