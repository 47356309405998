import React from 'react';
import { Ratio, Image } from 'react-bootstrap';
import { ReactComponent as DackeIcon } from '../assets/icones/dacke.svg';
import { IImageMediaGroup } from '../types/IMediaType';
import { useLightbox } from './SimpleReactLightboxExtended';
import { imageSortFn } from '../utils/helper';

interface CoverImageProps {
    images: IImageMediaGroup;
    fullSized?: boolean;
    maxSize?: boolean;
    lightbox?: boolean;
}

export const CoverImage: React.FC<CoverImageProps> = ({
    images,
    fullSized,
    maxSize,
    lightbox,
}) => {
    const { showLightboxImages } = useLightbox();
    const getImgUrl = () => {
        const imgs = images?.medias.sort(imageSortFn) || [];
        const imageTypes = imgs[0]?.url;
        let imageUrl =
            fullSized || maxSize
                ? imageTypes?.large ||
                  imageTypes?.medium ||
                  imageTypes?.small ||
                  imageTypes?.thumbnail
                : imageTypes?.thumbnail;
        if (imageUrl) imageUrl = process.env.REACT_APP_API_ENDPOINT + imageUrl;

        return {
            url: imageUrl,
            caption: imgs[0]?.title || '',
            updated_at: imgs[0]?.updated_at,
        };
    };

    const onImageClick = (e: React.MouseEvent) => {
        if (!lightbox) return;
        e.preventDefault();
        e.stopPropagation();
        showLightboxImages(images);
    };

    const imgData = getImgUrl();
    const img = () => (
        <Image
            src={`${imgData.url}?${imgData?.updated_at}`}
            rounded
            fluid
            style={{
                objectFit: fullSized ? 'cover' : 'contain',
            }}
            alt={imgData.caption}
            onClick={onImageClick}
            className={lightbox ? 'cursor-pointer' : ''}
        />
    );

    if (fullSized) return <div>{img()}</div>;

    return (
        <>
            <Ratio aspectRatio={'1x1'} className='d-flex d-md-none'>
                {images?.medias && images?.medias?.length > 0 ? (
                    img()
                ) : (
                    <div
                        className='d-flex flex-fill justify-content-center align-items-center text-white rounded'
                        style={
                            {
                                /* background: '#F8F9FA' */
                            }
                        }
                    >
                        <DackeIcon />
                    </div>
                )}
            </Ratio>
            <Ratio aspectRatio={'4x3'} className='d-none d-md-flex'>
                {images?.medias && images?.medias?.length > 0 ? (
                    img()
                ) : (
                    <div
                        className='d-flex flex-fill justify-content-center align-items-center text-white rounded'
                        style={
                            {
                                /* background: '#F8F9FA' */
                            }
                        }
                    >
                        <DackeIcon />
                    </div>
                )}
            </Ratio>
        </>
    );
};
