import React from 'react';
import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import IOrganisationType from '../../../../types/IOrganisationType';
import IServerResponseType from '../../../../types/IServerResponseType';
import IResourceType from '../../../../types/IResourceType';

export enum AuthenticatedState {
    NOT_CHECKED = 'NOT_CHECKED',
    LOADING = 'LOADING',
    AUTHENTICATED = 'AUTHENTICATED',
    NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
}

export interface AuthContextProps {
    getOrganisations: () => Promise<
        IServerResponseType<ResourcePermissionsType<IOrganisationType>[]>
    >;
    setActiveOrganisation: (
        id: number
    ) => Promise<
        IServerResponseType<ResourcePermissionsType<IOrganisationType>>
    >;
    organisations: IOrganisationType[];
    organisation: ResourcePermissionsType<IOrganisationType> | null;

    isAllowedTo: (
        permissions: string[],
        resource: ResourcePermissionsType<IResourceType>
    ) => boolean;
    isSysAdmin: () => Promise<boolean>;
    isAdmin: () => Promise<boolean>;

    loading: boolean | null;
    clearAuthCache: () => void;

    isAuthenticatedState: AuthenticatedState;
}

const AuthContext = React.createContext<AuthContextProps>(
    {} as AuthContextProps
);

export default AuthContext;
