import { faPlay, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { isOpen } from './utils';

interface AccordionCarrotProps {
    eventKey: string;
    openKeys: string[];
}

export const AccordionCarrot = ({
    eventKey,
    openKeys,
}: AccordionCarrotProps) => {
    return (
        <>
            {isOpen(openKeys, eventKey) ? (
                <span>
                    <FontAwesomeIcon
                        size={'lg'}
                        color={'orange'}
                        icon={faSortDown}
                    />
                </span>
            ) : (
                <span>
                    <FontAwesomeIcon
                        size={'xs'}
                        color={'slategrey'}
                        icon={faPlay}
                    />
                </span>
            )}
        </>
    );
};
