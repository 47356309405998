import React from 'react';
import { Carousel, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { INewsBannerItem } from '../types/INewsBannerItem';

const imageStyle: React.CSSProperties = {
    objectFit: 'cover',
    height: '450px',
    width: '100%',
};

export function NewsBanner({ data }: { data: INewsBannerItem[] }) {
    const { t } = useTranslation();

    return (
        <Carousel className='w-100'>
            {data.map((nb, i) => {
                return (
                    <Carousel.Item key={i}>
                        <div className='overlay'>
                            <Image
                                src={nb.image_url}
                                fluid
                                style={imageStyle}
                            />
                        </div>

                        <Carousel.Caption className='d-none d-sm-block'>
                            <h4 className='fw-bold'>{nb.title1}</h4>
                            <h3 className='display-5 fw-bold'>{nb.title2}</h3>
                            <p>{nb.title3}</p>
                            {nb.button_url ? (
                                <a
                                    className='fw-bolder text-uppercase text-white btn btn-secondary btn-lg'
                                    href={nb.button_url}
                                >
                                    {nb.button_text || t('Read More')}
                                </a>
                            ) : null}
                        </Carousel.Caption>
                        <Carousel.Caption className='d-sm-none text-center start-0'>
                            <h4 className='fw-bold fs-5'>{nb.title1}</h4>
                            <h3 className='fw-bold fs-3'>{nb.title2}</h3>
                            <p className='px-5'>{nb.title3}</p>
                            {nb.button_url ? (
                                <a
                                    className='fw-bolder text-uppercase text-white btn btn-secondary btn-lg'
                                    href={nb.button_url}
                                >
                                    {nb.button_text || t('Read More')}
                                </a>
                            ) : null}
                        </Carousel.Caption>
                    </Carousel.Item>
                );
            })}
        </Carousel>
    );
}
