import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { useOrganisationFormState } from '../OrganisationFormState';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Table } from '../../../../../components/Table';
import { Column, Row as TRow } from 'react-table';
import { useThrottle } from '@react-hook/throttle';
import IUserType from '../../../../../types/IUserType';

import { ReactComponent as ADDIcon } from '../../../../../assets/icones/Dacke_nytt.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { UserCreateModal } from './UserCreateModal';
import { useHandleModal } from '../../../../../hooks/useHandleModal';
import { ReInviteUserModal } from '../../../../../components/Modals/ReInviteUserModal';
import { useTableReducer } from '../../../../../hooks/useTableReducer';
import { SearchInput } from '../../../../../components/SearchInput';
import { Restricted } from '../../../../Auth/components/Restricted';

interface Props {}

export const UsersForm = (props: Props) => {
    const { t } = useTranslation();
    const {
        // setUsers,
        ...organisation
    } = useOrganisationFormState();

    const [{ queryPageIndex, queryPageSize, sortBy }, dispatch] =
        useTableReducer();

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useThrottle('', 2);

    const [editUser, setEditUser] = useState<IUserType>();

    const [users, setUsers] = useState<IUserType[]>([]);
    const [total, setTotal] = useState<number>(-1);

    // Create User Modal
    const [showUserCreateModal, setShowUserCreateModal] = useState(false);

    const handleUserCreateModalClose = (newUserWasCreated: boolean) => {
        if (newUserWasCreated) fetchAPIData(queryPageSize, queryPageIndex);
        setShowUserCreateModal(false);
    };
    const handleUserCreateModalShow = (user?: IUserType) => {
        setEditUser(user);
        setShowUserCreateModal(true);
    };

    // Reset form if editUser changes
    useEffect(() => {
        if (!showUserCreateModal) setEditUser(undefined);
    }, [setEditUser, showUserCreateModal]);

    const userRoleToString = useCallback(
        (user: IUserType) => {
            if (user.isOrganisationAdmin) {
                return t('Organisation admin');
            }

            return t('Organisation user');
        },
        [t]
    );

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns =
        width && width < 992 ? ['id', 'email', 'is_active', 'phone'] : [];

    const columns = React.useMemo<Column<IUserType>[]>(
        () => [
            {
                Header: t('Name') as string,
                accessor: 'name',
            },
            {
                Header: t('Email') as string,
                accessor: 'email',
            },
            {
                Header: t('Phone number') as string,
                accessor: 'phone',
            },
            {
                Header: t('Role') as string,
                accessor: (row) => userRoleToString(row),
            },
            {
                Header: t('Active') as string,
                id: 'is_active',
                accessor: (row) =>
                    row.is_active ? (
                        <span className='text-success'>{t('Yes')}</span>
                    ) : (
                        <span className='text-danger'>{t('No')}</span>
                    ),
            },
        ],
        [t, userRoleToString]
    );

    const fetchAPIData = useCallback(
        async (pageSize: number, pageIndex: number) => {
            try {
                setLoading(true);

                const response = await axios.get(
                    `/api/organisations/${
                        organisation.id
                    }/users?limit=${pageSize}&skip=${
                        pageIndex * pageSize
                    }&search=${searchTerm}`
                );

                if (response && response.data.data) {
                    const { data: d, total: t } = response.data;
                    setUsers(d);
                    setTotal(t);

                    setLoading(false);
                }
            } catch (e) {
                console.log('Error while fetching', e);
                setLoading(false);
            }
        },
        [organisation.id, searchTerm]
    );

    // Re Invite user Modal
    const reInviteModal = useHandleModal<IUserType>();

    const actions = React.useMemo(
        () => [
            {
                name: 'Re-invite',
                render: (instance: TRow<IUserType>) => (
                    <div
                        onClick={() => {
                            reInviteModal.setItem(instance.original);
                            reInviteModal.showModal();
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon icon={faPaperPlane} width='12' />
                    </div>
                ),
            },
            {
                name: 'Edit',
                render: (instance: TRow<IUserType>) => (
                    <div
                        onClick={() => {
                            handleUserCreateModalShow(instance.original);
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            width='15'
                            className='cursor-pointer'
                        />
                    </div>
                ),
            },
        ],
        [reInviteModal]
    );

    useEffect(() => {
        if (organisation.id) fetchAPIData(queryPageSize, queryPageIndex);
    }, [fetchAPIData, queryPageSize, queryPageIndex, organisation.id]);

    return (
        <Row>
            <Col sm={12} xl={12}>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-end'>
                    <div className='d-flex align-items-center '>
                        <div className='d-flex gap-3 flex-column flex-md-row flex-fill d-none d-sm-block'>
                            <div className='d-flex justify-content-end'>
                                <SearchInput
                                    onChange={setSearchTerm}
                                    placeholder={t('Search')}
                                    size='sm'
                                />

                                <Restricted toAdmin toSysAdmin>
                                    <Button
                                        onClick={() =>
                                            handleUserCreateModalShow()
                                        }
                                        variant='secondary'
                                        size='sm'
                                        className='m-1 d-none d-sm-block'
                                    >
                                        <ADDIcon width='12' className='me-1' />
                                        {t('Add new user')}
                                    </Button>
                                </Restricted>
                            </div>
                        </div>
                    </div>
                </ButtonToolbar>
                <Table<IUserType>
                    name={'User Table'}
                    columns={columns}
                    data={users}
                    initialPageSize={queryPageSize || 0}
                    initialPageIndex={queryPageIndex || 0}
                    initialSortBy={sortBy || { id: 'name', desc: false }}
                    loading={loading}
                    hiddenColumns={hiddenColumns}
                    actions={actions}
                    dispatch={dispatch}
                    size='sm'
                    total={total}
                />
                <Restricted toAdmin toSysAdmin>
                    <Button
                        onClick={() => handleUserCreateModalShow()}
                        variant='secondary'
                        className='d-sm-none m-1 w-100'
                    >
                        <ADDIcon width='12' className='me-1' />
                        {t('Add new user')}
                    </Button>
                </Restricted>
            </Col>

            <UserCreateModal
                show={showUserCreateModal}
                handleClose={handleUserCreateModalClose}
                handleShow={handleUserCreateModalShow}
                organisationId={organisation.id}
                editUser={editUser}
            ></UserCreateModal>
            <ReInviteUserModal
                show={reInviteModal.show}
                user={reInviteModal.item}
                handleClose={reInviteModal.closeModal}
            ></ReInviteUserModal>
        </Row>
    );
};
