import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IProductItem from '../../../../types/IProductType';
import { useAuth } from '../../../Auth/hooks/useAuth';
import axios from 'axios';
import { AlertModal } from '../Modals/AlertModal';
import { useProductItemModals } from './ProductItemModals';

export const ProductItemViewingStatusModal: React.FC<any> = ({
    selected,
    onClose,
    onSave,
}) => {
    const { t } = useTranslation();

    const { setShowViewingStatusModal, showViewingStatusModal } =
        useProductItemModals();
    const [showAlertModal, setShowAlertModal] = useState<any>(null);

    const productViwingStatuses: string[] = ['Privet', 'Public'];

    const [viewStatus, setStatus] = useState(0);

    const { organisation } = useAuth();

    const [productsToUpdate, setProductsToUpdate] = useState([]);

    const updataAllProducts = async () => {
        try {
            const productItemIds = productsToUpdate.map(
                (pi: IProductItem) => pi.id
            );

            const updatedData: any = {
                productItemIds,
                viewStatusId: viewStatus,
            };

            const request = await axios.patch(
                `/api/organisations/${organisation?.id}/updateitemviewstatus`,
                updatedData
            );

            if (request) {
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Modal
                show={showViewingStatusModal}
                onHide={() => {
                    setShowViewingStatusModal(false);
                    onClose();
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Change viewing status')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('This will update {{amount}} product placements.', {
                            amount: selected?.length || 0,
                        })}
                    </p>

                    <Form.Select
                        aria-label='Default select example'
                        defaultValue={viewStatus}
                        onChange={(e) => {
                            const index = parseInt(
                                (e.target as HTMLSelectElement).value,
                                10
                            );
                            setStatus(index);
                        }}
                    >
                        {productViwingStatuses.map((item, index) => (
                            <option value={index} key={index}>
                                {t(item)}
                            </option>
                        ))}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => onClose()}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            setProductsToUpdate(selected);
                            setShowViewingStatusModal(false);
                            setShowAlertModal({
                                title: 'Change viewing status',
                                message:
                                    'This will update {{amount}} product placements.',
                                messageData: {
                                    amount: showViewingStatusModal?.length,
                                },
                            });
                        }}
                    >
                        {t('Save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <AlertModal
                showAlertModal={showAlertModal}
                onClose={() => {
                    setShowAlertModal(false);
                }}
                onSave={() => {
                    updataAllProducts().then((data) => {
                        setShowAlertModal(false);
                    });
                }}
            ></AlertModal>
        </>
    );
};
