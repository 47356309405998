import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';
import { CollapsibleText } from '../../../components/CollapsibleText';
import IProduct from '../../../types/IProductType';
import { useAuth } from '../../Auth/hooks/useAuth';
import { ProductCriterias } from './ProductCriterias';
import { useTranslation } from 'react-i18next';
import { PageCardTitle } from '../../../components/PageCardTitle';
import { ProductPrice } from './ProductPrice';
import { HeartProducts } from '../../Marketplace/components/HeartProducts';
import { useBreakpoint } from '../../../hooks/useBreakpoint';

interface Props {
    product?: IProduct;
}

export const ProductCardBasicInfo: React.FC<Props> = ({ product }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { organisation } = useAuth();

    return (
        <>
            <PageCardTitle
                description={product?.description}
                title={product?.title}
                titleClassName='text-break'
                descriptionClassName={useBreakpoint('down', 'sm') ? 'h2' : ''}
            ></PageCardTitle>
            <Card.Subtitle className='position-relative'>
                <b>{t('Product-ID')}</b>:{' '}
                <span className='fw-light'>{product?.barcode}</span>
                <div className='d-flex gap-2 position-absolute end-0 top-0 m-0 pe-2'>
                    {product?.organisation_id === organisation?.id ? (
                        <Link
                            to={`/admin/projects/${product?.project_id}/product-form/${product?.id}`}
                        >
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                width='15'
                                className='cursor-pointer'
                            />
                        </Link>
                    ) : (
                        <></>
                    )}
                    <HeartProducts product={product} />
                </div>
            </Card.Subtitle>
            <div className='mt-2 mb-2'>
                <CollapsibleText
                    text={product?.description || ''}
                    charLimit={250}
                />
            </div>
            <ProductPrice
                isSoldOut={!product?.quantity}
                price={product?.sales_price ?? 0}
                symbol={product?.category?.unit?.symbol || 'stk'}
            />
            <ProductCriterias
                product={product}
                hideCustomCriteries={!pathname.includes('/admin')}
            />
        </>
    );
};
