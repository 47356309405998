import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import IProduct from '../../../types/IProductType';

interface Props {
    product?: IProduct;
    fluid?: boolean;
}
export const ProductCardDocuments = ({ product, fluid }: Props) => {
    const { t } = useTranslation();
    if (!product?.product_documents?.medias?.length) return <></>;
    return (
        <Stack gap={2} className='mb-3'>
            <div className='fw-bold fs-5'>{t('Documents')}</div>
            {product?.product_documents?.medias?.map((doc) => (
                <a
                    key={doc.id}
                    href={process.env.REACT_APP_API_ENDPOINT + doc.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-decoration-none fw-bolder'
                >
                    <div className='text-break'>
                        {doc.title}
                        <FontAwesomeIcon
                            className='ms-2 text-secondary'
                            icon={faExternalLink}
                        />
                    </div>
                </a>
            ))}
        </Stack>
    );
};
