import React from 'react';
import { Spinner } from 'react-bootstrap';
import { SimpleNavBar } from '../components/SimpleNavBar';

export const Loading = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Spinner animation='border' role='status' variant='primary'>
                <span className='visually-hidden'>Loading...</span>
            </Spinner>
        </div>
    );
};

export const FullLoadingPage = () => {
    return (
        <>
            <SimpleNavBar></SimpleNavBar>
            <Loading></Loading>
        </>
    );
};
