import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import IEnvironmentData from '../../../../../types/IEnvironmentDataType';
import { EnvironmentDataInfoModal } from './Modals/EnvironmentDataInfoModal';

interface Props {
    selected: IEnvironmentData[];
}

export const ViewEnvironmentData = (props: Props) => {
    const [view, setView] = useState<IEnvironmentData>();

    const { selected } = props;

    const getLabel = (o: IEnvironmentData) => {
        const { substance, material, waste_code, is_hazardous } = o;
        const sm = `${substance}, ${material}`;
        if (sm.length > 20) return `${sm.substr(0, 20)}... (${waste_code})`;
        return `${substance}, ${material} (${waste_code})${
            is_hazardous ? '*' : ''
        }`;
    };

    return (
        <Row>
            {selected.map((o) => (
                <Col key={o?.id} md={12} lg={4}>
                    <Button className='w-100 m-1' onClick={() => setView(o)}>
                        {getLabel(o)}
                    </Button>
                </Col>
            ))}
            <EnvironmentDataInfoModal
                envData={view}
                onClose={() => setView(undefined)}
            />
        </Row>
    );
};
