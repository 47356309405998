import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Spinner, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Logo } from '../../../components/Logo';

import IOrganisationType from '../../../types/IOrganisationType';
import IContactPersonType from '../../../types/IContactPersonType';

interface Props {
    organisation?: IOrganisationType;
    projectContact?: IContactPersonType;
    loading?: boolean;
}

export const OrganisationCard = ({
    organisation,
    projectContact,
    loading,
}: Props) => {
    const { t } = useTranslation();

    const meta = useCallback(
        (key: string) =>
            organisation?.organisation_meta?.find((m) => m.meta_key === key)
                ?.meta_value,
        [organisation]
    );

    return (
        <Stack className='p-3 pb-4'>
            <div className='fs-5 fw-bold'>
                <FontAwesomeIcon
                    className='text-secondary me-2'
                    icon={faCoins}
                />
                {t('Seller')}
            </div>
            <hr />
            {loading && (
                <div className='py-5 my-4'>
                    <Spinner animation={'border'} />
                </div>
            )}
            <Stack className='mx-auto text-break'>
                <Stack className='mb-1'>
                    <div className='fw-bold'>{organisation?.name}</div>
                    <div>{meta('addressLine1')}</div>
                    <div>{meta('addressLine2')}</div>
                    <div>
                        {meta('postalNumber')} {meta('city')}
                    </div>
                    <div>
                        {meta('country')} {meta('region')}
                    </div>
                    <div className='py-3'>
                        <Logo
                            shape='circle'
                            images={organisation?.organisation_logo}
                            size='md'
                        />
                    </div>
                </Stack>
                {!!projectContact && (
                    <Stack>
                        <div className='fw-bold text-muted'>{t('Contact')}</div>
                        <div>{projectContact?.name}</div>
                        <div>{projectContact?.phone}</div>
                        <div>{projectContact?.email}</div>
                    </Stack>
                )}
                {!projectContact && meta('contactPerson') && (
                    <Stack>
                        <div className='fw-bold text-muted'>{t('Contact')}</div>
                        <div>{meta('contactPerson')}</div>
                        <div>{meta('contactPersonPhone')}</div>
                        <div>{meta('contactPersonMail')}</div>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
