import React, { useState } from 'react';
import { Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import { useCallback } from 'react';
import { useProjectFormState } from '../ProjectFormState';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare,
    faTrashCan,
    faStar,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { Column, Row as TRow } from 'react-table';
import { ReactComponent as ADDIcon } from '../../../../../assets/icones/Dacke_nytt.svg';
import { ContactPersonCreateModal } from './ContactPersonCreateModal';
import { ContactPersonDeleteModal } from './ContactPersonDeleteModal';
import { useHandleModal } from '../../../../../hooks/useHandleModal';
import { useTableReducer } from '../../../../../hooks/useTableReducer';
import IContactPersonType from '../../../../../types/IContactPersonType';
import { useWindowSize } from '../../../../Organisation/hooks/useWindowSize';
import { Table } from '../../../../../components/Table';

interface Props {}

export const ContactPersonForm = (props: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const { setFormState, ...project } = useProjectFormState();

    const [{ queryPageIndex, queryPageSize }, dispatch] = useTableReducer();

    const refetchContacts = useCallback(() => {
        setLoading(true);
        axios
            .get(
                `/api/organisations/${project?.organisation_id}/projects/${project?.id}` // /contacts
            )
            .then((res) => {
                if (res.data) {
                    setFormState((prev) => ({
                        ...prev,
                        contacts: res.data.data.contacts,
                    }));
                }
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [project, setFormState]);

    const setPrimaryContact = useCallback(
        (primary_contact) => {
            setLoading(true);
            axios
                .put(
                    `/api/organisations/${project?.organisation_id}/projects/${project?.id}`,
                    { ...project, primary_contact }
                )
                .then((res) => {
                    if (res.data) {
                        setFormState((prev) => ({
                            ...prev,
                            primary_contact: res.data.data.primary_contact,
                        }));
                    }
                })
                .finally(() => setLoading(false));
        },
        [project, setFormState]
    );

    const columns = React.useMemo<Column<IContactPersonType>[]>(
        () => [
            {
                Header: t('Name') as string,
                accessor: 'name',
            },
            {
                Header: t('Email') as string,
                accessor: 'email',
            },
            {
                Header: t('Phone number') as string,
                accessor: 'phone',
            },
            {
                Header: t('Position/Role') as string,
                accessor: 'role',
            },
        ],
        [t]
    );
    const editModal = useHandleModal<IContactPersonType>({
        onClose: (refetch) => refetch && refetchContacts(),
    });
    const createModal = useHandleModal<IContactPersonType>({
        onClose: (refetch) => refetch && refetchContacts(),
    });
    const deleteModal = useHandleModal<IContactPersonType>({
        onClose: (refetch) => refetch && refetchContacts(),
    });

    const actions = React.useMemo(() => {
        return [
            {
                name: 'Project sales contact',
                render: (instance: TRow<IContactPersonType>) => {
                    const isPrimary =
                        project?.primary_contact === instance.original.id;

                    return (
                        <div
                            onClick={() =>
                                setPrimaryContact(
                                    isPrimary ? null : instance.original.id
                                )
                            }
                            className='cursor-pointer'
                        >
                            {isPrimary ? (
                                <FontAwesomeIcon icon={faStar} width='12' />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faStarRegular}
                                    width='12'
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                name: 'Delete',
                render: (instance: TRow<IContactPersonType>) => (
                    <div
                        onClick={() => {
                            deleteModal.setItem(instance.original);
                            deleteModal.showModal();
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon icon={faTrashCan} width='12' />
                    </div>
                ),
            },
            {
                name: 'Edit',
                render: (instance: TRow<IContactPersonType>) => (
                    <div
                        onClick={() => {
                            editModal.setItem(instance.original);
                            editModal.showModal();
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            width='15'
                            className='cursor-pointer'
                        />
                    </div>
                ),
            },
        ];
    }, [deleteModal, editModal, project, setPrimaryContact]);

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns =
        width && width < 992 ? ['email', 'phone', 'role'] : [];

    return (
        <Row>
            <Col sm={12} xl={12}>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-end'>
                    <div className='d-flex align-items-center '>
                        <div className='d-flex gap-3 flex-column flex-md-row flex-fill d-none d-sm-block'>
                            <div className='d-flex justify-content-end'>
                                <Button
                                    onClick={createModal.showModal}
                                    variant='secondary'
                                    size='sm'
                                    className='m-1 ms-4 d-none d-sm-block'
                                >
                                    <ADDIcon width='12' />
                                    {t('Add new')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </ButtonToolbar>
                <Table<IContactPersonType>
                    name={'testTable'}
                    columns={columns}
                    data={project?.contacts}
                    initialPageSize={queryPageSize || 0}
                    initialPageIndex={queryPageIndex || 0}
                    loading={loading}
                    hiddenColumns={hiddenColumns}
                    actions={actions}
                    dispatch={dispatch}
                    size='sm'
                />
                <Button
                    onClick={createModal.showModal}
                    variant='secondary'
                    className='d-sm-none m-1 w-100'
                >
                    <ADDIcon width='12' className='me-1' />
                    {t('Add new')}
                </Button>
            </Col>

            <ContactPersonCreateModal
                show={editModal.show}
                handleClose={editModal.closeModal}
                projectId={project.id}
                organisationId={project.organisation_id}
                editContactPerson={editModal.item}
            ></ContactPersonCreateModal>
            <ContactPersonCreateModal
                show={createModal.show}
                handleClose={createModal.closeModal}
                projectId={project.id}
                organisationId={project.organisation_id}
            ></ContactPersonCreateModal>
            <ContactPersonDeleteModal
                show={deleteModal.show}
                handleClose={deleteModal.closeModal}
                projectId={project.id}
                organisationId={project.organisation_id}
                contactPerson={deleteModal.item}
            ></ContactPersonDeleteModal>
        </Row>
    );
};
