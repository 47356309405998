import { ICategory } from '../types/ICategory';

const memo = new Map<ICategory[], Map<number, ICategory>>();

function flattenTree(categories: ICategory[]): Map<number, ICategory> {
    let s = [...categories];

    const rv = new Map<number, ICategory>();

    while (s.length) {
        const cur = s.pop();

        if (cur?.children) {
            s = s.concat(cur.children);
        }

        if (cur) {
            rv.set(cur.id, cur);
        }
    }

    memo.set(categories, rv);

    return rv;
}

export default function getCategoryTree(
    category: ICategory,
    categories: ICategory[]
): ICategory[] {
    if (!categories?.length) return [];
    const categoryIdToCategory = memo.has(categories)
        ? memo.get(categories)
        : flattenTree(categories);

    if (!categoryIdToCategory) {
        return [];
    }

    let current = category;
    const tree: ICategory[] = [];

    while (current.parent_category_id) {
        tree.unshift(current);

        current = categoryIdToCategory.get(
            current.parent_category_id
        ) as ICategory;
    }

    tree.unshift(current);

    return tree;
}
