import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';

import { ButtonToolbar, Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Layout/Page';

import { useTableReducer } from '../../hooks/useTableReducer';
import IProduct from '../../types/IProductType';
import IServerResponseWithTotalType from '../../types/IServerResponseWithTotalType';
import ResourcePermissionsType from '../../types/ResourcePermissionsType';

// Move ?
import { ProductListView } from '../Products/components/ListViews/ProductListView';
import { ProductMobileView } from '../Products/components/ListViews/ProductMobileView';
import { useProductSearch } from '../Products/components/ProductSearch';
import { ProductThumbnailView } from '../Products/components/ProductThumbnailView';
import { useViewSwitch } from '../Products/components/ViewSwitch';
import { useCategories } from '../../routing/marketplaceRoutes';
import { ProductsBreadCrumbs } from '../../components/ProductsBreadCrumbs';
import { SearchInput } from '../../components/SearchInput';
import { ViewToggler } from '../Products/components/ViewToggler';

interface Props {}

const DEFAULT_PAGE_SIZE = 200;

export const MarketplaceProductsPage = (props: Props) => {
    const [loading, setLoading] = useState(false);

    const { selectedCategory, setSelectedCategory, filterButton } =
        useCategories();

    const { t } = useTranslation();

    const { queryState, setQueryState, queryString } = useProductSearch();

    const initialState = {
        queryPageIndex: 0,
        queryPageSize: parseInt(queryState['limit'] ?? DEFAULT_PAGE_SIZE),
        totalCount: -1,
    };

    // Pagination
    const [{ queryPageIndex, queryPageSize, sortBy }, dispatch] =
        useTableReducer(initialState);

    const { view } = useViewSwitch();

    // Update product search query with useTableReducer limits / skips
    useEffect(() => {
        const oldLimit = parseInt(queryState['limit'] ?? 0);
        if (oldLimit !== queryPageSize) setQueryState('limit', queryPageSize);

        const oldSkip = parseInt(queryState['skip'] ?? 0);
        const newSkip =
            queryPageSize * queryPageIndex * (view === 'card-view' ? 0 : 1);
        if (oldSkip !== newSkip) setQueryState('skip', newSkip);

        const oldSortBy = queryState['sortBy'];
        const oldDir = queryState['direction'];
        if (oldSortBy !== sortBy?.id) {
            setQueryState('sortBy', sortBy?.id);
            setQueryState('direction', sortBy?.desc ? 'desc' : 'asc');
        }
        if ((oldDir === 'desc') !== sortBy?.desc) {
            setQueryState('direction', sortBy?.desc ? 'desc' : 'asc');
        }
    }, [
        queryState,
        queryPageSize,
        setQueryState,
        queryPageIndex,
        sortBy,
        view,
    ]);

    // API fetch products
    const [{ data, error }, refetch] = useAxios<
        IServerResponseWithTotalType<ResourcePermissionsType<IProduct>[]>
    >(`/api/products?${queryString}`, {
        manual: true,
    });
    const products: ResourcePermissionsType<IProduct>[] = data?.data ?? [];
    const totalCount: number = data?.total ?? -1;

    useEffect(() => {
        setLoading(true);
        refetch()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [queryString, refetch]);

    if (error) return <p>Error!</p>;
    return (
        <Page className='px-0'>
            <Container fluid className='px-0 px-sm-inherit'>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    <div className='d-flex justify-content-between flex-grow-1'>
                        <ProductsBreadCrumbs
                            to='/products'
                            category={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                        />

                        {/* display filter button top right */}
                        <div className='d-flex d-md-none align-items-center'>
                            {filterButton()}
                        </div>
                    </div>

                    <div className='d-flex d-lg-none flex-column flex-md-row align-items-md-center '>
                        <SearchInput
                            onChange={(value: string) =>
                                setQueryState('search', value)
                            }
                            placeholder={t('Search')}
                        ></SearchInput>

                        {/* display filter button behind search only on md */}
                        <div className='ms-5 d-none d-md-flex d-lg-none d-flex align-items-center'>
                            {filterButton()}
                        </div>
                    </div>

                    <div className='d-none d-sm-flex align-items-center'>
                        <ViewToggler />
                    </div>
                </ButtonToolbar>

                <div className='d-sm-none'>
                    <ProductMobileView
                        products={products}
                        isMarketPlace
                        queryPageIndex={queryPageIndex}
                        queryPageSize={queryPageSize}
                        dispatch={dispatch}
                        loading={loading}
                        totalCount={totalCount}
                    />
                </div>
                <div className='d-none d-sm-block'>
                    {view === 'card-view' ? (
                        <ProductThumbnailView
                            {...{
                                products,
                                queryPageSize,
                                totalCount,
                                dispatch,
                                isMarketPlace: true,
                            }}
                        />
                    ) : (
                        <ProductListView
                            {...{
                                products,
                                queryPageIndex,
                                queryPageSize,
                                totalCount,
                                dispatch,
                                loading,
                                isMarketPlace: true,
                            }}
                        />
                    )}
                </div>
            </Container>
        </Page>
    );
};
