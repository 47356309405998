import React, { forwardRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';

export const useCombinedRefs = (
    ...refs: any[]
): React.MutableRefObject<any> => {
    const targetRef = React.useRef();

    React.useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};
export interface IIndeterminateInputProps {
    indeterminate?: boolean;
    name: string;
}

export const IndeterminateCheckbox = forwardRef<
    HTMLInputElement,
    IIndeterminateInputProps
>(({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
    const defaultRef = React.useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
        if (combinedRef?.current) {
            combinedRef.current.indeterminate = indeterminate ?? false;
        }
    }, [combinedRef, indeterminate]);

    return (
        <React.Fragment>
            <Form.Check type='checkbox' ref={combinedRef} {...rest} />
        </React.Fragment>
    );
});
