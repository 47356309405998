import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Layout/Page';
import useAxios from 'axios-hooks';

import { Link, useParams } from 'react-router-dom';
import {
    IOrganisationFormState,
    OrganisationFormState,
    useOrganisationFormState,
} from '../components/OrganisationForm/OrganisationFormState';
import { Accordion } from '../../../components/Accordion/Accordion';
import { BasicInformationPreview } from '../components/OrganisationForm/BasicInformation/BasicInformationPreview';
import { BasicInformationFrom } from '../components/OrganisationForm/BasicInformation/BasicInformationForm';
import IServerResponseType from '../../../types/IServerResponseType';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import IOrganisationType from '../../../types/IOrganisationType';
import { ContactPersonPreview } from '../components/OrganisationForm/ContactPerson/ContactPersonPreview';
import { ContactPersonForm } from '../components/OrganisationForm/ContactPerson/ContactPersonForm';
import { UsersForm } from '../components/OrganisationForm/Users/UsersForm';
import { UsersPreview } from '../components/OrganisationForm/Users/UsersPreview';
import { Breadcrumb, ButtonToolbar, Container } from 'react-bootstrap';
import { ThemeForm } from '../components/OrganisationForm/Theme/ThemeForm';
import { ThemePreview } from '../components/OrganisationForm/Theme/ThemePreview';
import { createLogosFromMedias } from '../../../API/organisation/logo';
import useForceBlur from '../../../hooks/useForceBlur';
import { useAuth } from '../../Auth/hooks/useAuth';
import { useLocation } from 'react-router';
import { ProductFieldsPreview } from '../components/OrganisationForm/ProductFields/ProductFieldsPreview';
import { ProductFieldsForm } from '../components/OrganisationForm/ProductFields/ProductFieldsForm';
import { CriteriasForm } from '../components/OrganisationForm/Criterias/CriteriasForm';
import { CriteriasPreview } from '../components/OrganisationForm/Criterias/CriteriasPreview';
import { SEO } from '../../../components/SEO';

interface Props {}

export const NoStateNewOrganisationFormPage = (props: Props) => {
    const { pathname } = useLocation();

    const { t } = useTranslation();
    const organisation = useOrganisationFormState();
    const accordionItems = useMemo(() => {
        return [
            {
                title: t('Basic Information'),
                PreviewCompnent: BasicInformationPreview,
                FormCompnent: BasicInformationFrom,
            },
            {
                title: t('Contact person'),
                PreviewCompnent: ContactPersonPreview,
                FormCompnent: ContactPersonForm,
            },
            {
                title: t('Theme'),
                PreviewCompnent: ThemePreview,
                FormCompnent: ThemeForm,
            },
            {
                title: t('Product fields'),
                PreviewCompnent: ProductFieldsPreview,
                FormCompnent: ProductFieldsForm,
            },
            {
                title: t('Custom criterias'),
                PreviewCompnent: CriteriasPreview,
                FormCompnent: CriteriasForm,
            },
            {
                title: t('Users'),
                PreviewCompnent: UsersPreview,
                FormCompnent: UsersForm,
            },
        ];
    }, [t]);

    // Place the meta data in a more easier to use container
    const meta: { [key: string]: any } = {};
    organisation?.organisation_meta?.forEach(
        (m) => (meta[m.meta_key] = m.meta_value)
    );

    return (
        <Page>
            <Container fluid>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    <Breadcrumb>
                        {/* handles breadcrumb to change between system admin page and orgadmin page */}
                        {pathname.includes('system') ? (
                            <>
                                <Breadcrumb.Item>
                                    {t('System admin')}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={`/systemadmin/organisation/${organisation.id}`}
                                    linkAs={Link}
                                    linkProps={{
                                        to: `/system-admin/organisations`,
                                    }}
                                >
                                    <SEO
                                        title={t('Organisations')}
                                        asText
                                    ></SEO>
                                </Breadcrumb.Item>
                            </>
                        ) : (
                            <Breadcrumb.Item>
                                <SEO
                                    title={t('Organisation admin')}
                                    asText
                                ></SEO>
                            </Breadcrumb.Item>
                        )}

                        <Breadcrumb.Item active>
                            {String(organisation?.id).padStart(3, '0')},{' '}
                            {organisation?.name}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </ButtonToolbar>

                <Accordion disabled={false} {...{ accordionItems }} />
            </Container>
        </Page>
    );
};

export const OrganisationFormPage: React.FC = (props) => {
    useForceBlur();
    const auth = useAuth();
    // If the organisationId query parameter is present, Edit that Organisation
    // Otherwise - Edit the Active Organisation
    let { organisationId } = useParams<any>();
    if (!organisationId) organisationId = auth.organisation?.id.toString();

    // fetch organisation data
    const [{ data: organisationData }, refetchorganisation] = useAxios<
        IServerResponseType<ResourcePermissionsType<IOrganisationType>>
    >(`/api/organisations/${organisationId}`, {
        manual: true,
    });
    const organisation = organisationData?.data;

    useEffect(() => {
        if (!organisation) {
            refetchorganisation();
        }
    }, [organisation, organisationId, refetchorganisation]);

    return organisationId && !organisation ? (
        <Page>Loading....</Page>
    ) : (
        <OrganisationFormState
            initialState={
                {
                    id: organisation?.id,
                    name: organisation?.name,
                    template: organisation?.template || 1,
                    is_active: organisation?.is_active,
                    has_environment_data: organisation?.has_environment_data,
                    has_stock: organisation?.has_stock,
                    organisation_meta: organisation?.organisation_meta,
                    organisationLogos: organisation?.organisation_logo?.medias
                        ? createLogosFromMedias(
                              organisation.organisation_logo.medias
                          )
                        : [],
                } as IOrganisationFormState
            }
        >
            <NoStateNewOrganisationFormPage {...props} />
        </OrganisationFormState>
    );
};
