import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import ICriteriaType from '../../../../types/ICriteriaType';
import { IProductPoint } from '../../../../types/IProductPoint';
import { IProductProperty } from '../../../../types/IProductProperty';
import IProduct from '../../../../types/IProductType';
import { useAuth } from '../../../Auth/hooks/useAuth';

export interface IProductFormState extends IProduct {
    status: string; // just used to set defult status of productItems
    productItems: any[] | never[];
    productImages: any[] | never[];

    productVideo: {
        link: string;
        description: string;
    };

    points: IProductPoint[] | never[];

    messures: {
        [key: string]: any;
    };

    productProperies: IProductProperty[];

    productDocuments: any[] | never[];
}
const isValidDate = function (date: string): boolean {
    return new Date(date).toString() !== 'Invalid Date';
};

interface IProductFormStateContext extends IProductFormState {
    setFormState: (
        callBack: (prev: IProductFormState) => IProductFormState
    ) => void;
    setProjectId: (project_id: number) => void;
    setCategoryId: (category_id: number) => void;
    setBasicInformation: (key: string, value: any) => void;
    setProductItems: (callback: (prev: any[]) => any[]) => void;
    setProductImages: (callBack: (prev: any[]) => any[]) => void;
    setMessures: (key: string, value: any) => void;
    setPoints: (criteria: ICriteriaType, value: any) => void;
    setProductProperies: (key: number, value: any) => void;
    setProductMeta: (meta_key_id: number, meta_value: any, id?: number) => void;
    setProductDocuments: (callBack: (prev: any[]) => any[]) => void;
    updateProduct: (key?: number, value?: any) => void;
}

export const ProductFormStateContext =
    React.createContext<IProductFormStateContext>(
        {} as IProductFormStateContext
    );

interface ProductFormStateProps {
    initialState: IProductFormState;
}

export const ProductFormState: React.FC<ProductFormStateProps> = ({
    initialState,
    children,
}) => {
    const { projectId, productId } = useParams();
    const { organisation } = useAuth();

    const [state, setFormState] = useState<IProductFormState>(initialState);

    const setProjectId = useCallback(
        (project_id: number) =>
            setFormState((pre) => ({
                ...pre,
                project_id,
            })),
        []
    );
    const setCategoryId = useCallback(
        (category_id: number) =>
            setFormState((pre) => ({
                ...pre,
                category_id,
            })),
        []
    );

    const setBasicInformation = useCallback((key: string, value: any) => {
        setFormState((prev) => {
            return {
                ...prev,
                [key]: value,
            };
        });
    }, []);

    const setPoints = useCallback((criteria: ICriteriaType, value: any) => {
        setFormState((prev) => {
            const newpoints = prev.points.map((point) => {
                if (point.criteria_id === criteria.id) {
                    return {
                        ...point,
                        points: value,
                    };
                }
                return point;
            });
            return {
                ...prev,
                points: newpoints,
            };
        });
    }, []);

    const setProductItems = useCallback(
        (callback) => {
            setFormState((prev) => {
                return {
                    ...prev,
                    productItems: callback(state.productItems),
                };
            });
        },
        [state.productItems]
    );
    const setProductImages = useCallback((callBack: (prev: any[]) => any[]) => {
        setFormState((prev) => {
            return {
                ...prev,
                productImages: callBack(prev.productImages),
            };
        });
    }, []);

    const setMessures = useCallback((key: string, value: any) => {
        setFormState((prev) => {
            return {
                ...prev,
                messures: {
                    ...prev.messures,
                    [key]: value,
                },
            };
        });
    }, []);

    const setProductProperies = useCallback((key: number, value: any) => {
        setFormState((prev) => {
            const newProperties = [...prev.productProperies];

            // find propertie
            const proptery = prev.productProperies.find(
                (propterty) => propterty.property_id === key
            );

            // if propertie found update it
            if (proptery) {
                newProperties.splice(newProperties.indexOf(proptery), 1, {
                    ...proptery,
                    value,
                });
            }
            // else add new propertie
            else {
                newProperties.push({
                    property_id: key,
                    value,
                });
            }

            return {
                ...prev,
                productProperies: newProperties,
            };
        });
    }, []);

    const setProductMeta = useCallback(
        (meta_type_id: number, meta_value: any, id?: number) => {
            setFormState((prev) => {
                const newMetas = [...prev.product_meta];

                // find
                const meta = newMetas.find(
                    (m) => m.meta_type_id === meta_type_id
                );
                // Already exists?
                if (meta) {
                    newMetas.splice(newMetas.indexOf(meta), 1, {
                        ...meta,
                        meta_value,
                        id: meta.id ?? id,
                    });
                }
                // else add new
                else {
                    newMetas.push({
                        id,
                        meta_type_id,
                        meta_value,
                    });
                }

                return {
                    ...prev,
                    product_meta: newMetas,
                };
            });
        },
        []
    );

    const setProductDocuments = useCallback(
        (callBack: (prev: any[]) => any[]) => {
            setFormState((prev) => {
                return {
                    ...prev,
                    productDocuments: callBack(prev.productDocuments),
                };
            });
        },
        []
    );

    const updateProduct = useCallback(
        (key?, value?) => {
            // Only if is a alredy existing product
            if (productId) {
                const productData = {
                    project_id: state.project_id,
                    // organisation_id: organisation?.id,
                    category_id: state.category_id,

                    title: state.title,
                    description: state.description,
                    internal_description: state.internal_description,
                    manufacture_code: state.manufacture_code,
                    model_code: state.model_code,
                    year: state.year,
                    stock_type: state?.stock_type,

                    search_words: state.search_words,
                    condition_points: state.condition_points,
                    function_points: state.function_points,
                    unused: state.unused,

                    video_url: state.video_url,
                    video_description: state.video_description,

                    package_quantity: state.package_quantity,
                    package_unit: state.package_unit,
                    internal_value: state.internal_value,
                    sales_price: state.sales_price,
                    market_price: state.market_price,

                    weight: state.weight,
                    weight_unit: state.weight_unit,

                    co2: state.co2,
                } as IProduct;

                if (key === 'year') {
                    productData.year = value;
                } else if (key === 'function_points') {
                    productData.function_points = value;
                } else if (key === 'condition_points') {
                    productData.condition_points = value;
                } else if (key === 'category_id') {
                    productData.category_id = value;
                }

                const put = axios.put(
                    `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}`,
                    productData
                );

                put.then((res: any) => {
                    if (res.data?.data) {
                        const product = res.data?.data;

                        setFormState((prev) => ({
                            ...prev,
                            title: product?.title,
                            description: product?.description,
                            internal_description: product?.internal_description,
                            manufacture_code: product?.manufacture_code,
                            model_code: product?.model_code,
                            year:
                                product?.year && isValidDate(product?.year)
                                    ? product?.year
                                    : null,

                            package_quantity: product?.package_quantity,
                            stock_type: product?.stock_type,
                            package_unit: product?.package_unit,
                            estimated_value: product?.estimated_value,
                            price: product?.price,
                            sales_price: product?.sales_price,
                            co2: product?.co2,
                        }));
                    }
                });
            }
        },
        [state, productId, organisation?.id, projectId]
    );

    return (
        <ProductFormStateContext.Provider
            value={{
                ...state,
                setFormState,
                setProjectId,
                setCategoryId,
                setBasicInformation,
                setPoints,
                setProductItems,
                setProductImages,
                setMessures,
                setProductProperies,
                setProductDocuments,
                setProductMeta,
                updateProduct,
            }}
        >
            {children}
        </ProductFormStateContext.Provider>
    );
};

export const useProductFormState = () =>
    React.useContext(ProductFormStateContext);
