import { useState, useEffect, useCallback } from 'react';

import { Container, Button, ButtonToolbar, Breadcrumb } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useThrottle } from '@react-hook/throttle';
import axios from 'axios';

import { ProjectCreateModal } from './Modals/ProjectCreateModal';
import { ListView, MobileView, CardView } from './components/ListViews';

import { useViewSwitch } from '../Products/components/ViewSwitch';
import { Page } from '../../components/Layout/Page';
import { SearchInput } from '../../components/SearchInput';
import { useTableReducer } from '../../hooks/useTableReducer';
import IProjectType from '../../types/IProjectType';
import ResourcePermissionsType from '../../types/ResourcePermissionsType';
import { Restricted } from '../Auth/components/Restricted';
import { useAuth } from '../Auth/hooks/useAuth';

import { ReactComponent as ADDIcon } from '../../assets/icones/Dacke_nytt.svg';
import { SEO } from '../../components/SEO';
import { ViewToggler } from '../Products/components/ViewToggler';

export const ProjectsPage = () => {
    const navigate = useNavigate();

    const [{ queryPageIndex, queryPageSize, totalCount, sortBy }, dispatch] =
        useTableReducer();

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useThrottle('', 2);

    const [projects, setProjects] = useState<
        ResourcePermissionsType<IProjectType>[]
    >([]);
    const [total, setTotal] = useState<number>(-1);

    const { t } = useTranslation();
    const { organisation } = useAuth();

    const { view } = useViewSwitch();

    const fetchAPIData = useCallback(
        async (pageSize: number, pageIndex: number) => {
            try {
                setLoading(true);

                const sortByStr = sortBy?.id
                    ? `&sortBy=${sortBy.id}&direction=${
                          sortBy.desc ? 'desc' : 'asc'
                      }`
                    : '';

                const response = await axios.get(
                    `/api/organisations/${
                        organisation?.id
                    }/projects?limit=${pageSize}&skip=${
                        pageIndex * pageSize
                    }${sortByStr}&search=${searchTerm}`
                );

                if (response && response.data.data) {
                    const { data: d, total: t } = response.data;
                    setProjects(d);
                    setTotal(t);
                    setLoading(false);
                }
            } catch (e) {
                console.error('Error while fetching', e);
                setLoading(false);
            }
        },
        [organisation?.id, searchTerm, sortBy]
    );

    useEffect(() => {
        if (organisation) fetchAPIData(queryPageSize, queryPageIndex);
    }, [organisation, fetchAPIData, queryPageSize, queryPageIndex]);

    const [show, setShow] = useState(false);

    const handleClose = (id?: number) => {
        setShow(false);
        if (id) {
            navigate({
                pathname: `${id}/project-form`,
            });
        }
    };

    const handleShow = () => setShow(true);

    return (
        <Page>
            <Container fluid className='px-0 px-sm-inherit'>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    <div className='d-flex justify-content-between flex-grow-1'>
                        <Breadcrumb className='d-flex align-content-center'>
                            <Breadcrumb.Item active>
                                <SEO title={t('Projects')} asText></SEO>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    <div className='d-flex flex-column flex-md-row align-items-md-center '>
                        <SearchInput
                            onChange={setSearchTerm}
                            placeholder={t('Search')}
                        ></SearchInput>
                    </div>

                    <div className='d-none d-sm-block'>
                        <ViewToggler />
                    </div>
                </ButtonToolbar>

                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-end justify-content-end gap-2 mb-2'>
                    <div>
                        <Restricted toAdmin>
                            <Button
                                variant={'secondary'}
                                className='d-flex flex justify-content-center align-items-center flex-fill text-nowrap'
                                onClick={handleShow}
                            >
                                <ADDIcon width='12' className='me-1' />
                                {t('Add new')}
                            </Button>
                        </Restricted>
                    </div>
                </ButtonToolbar>

                <div className='d-sm-none'>
                    <MobileView
                        {...{
                            projects,
                            organisation,
                            queryPageSize,
                            totalCount,
                            dispatch,
                        }}
                    />
                </div>
                <div className='d-none d-sm-block'>
                    {view === 'card-view' ? (
                        <CardView
                            {...{
                                projects,
                                organisation,
                                queryPageSize,
                                totalCount,
                                dispatch,
                            }}
                        />
                    ) : (
                        <ListView
                            {...{
                                projects,
                                organisation,
                                loading,
                                queryPageSize,
                                queryPageIndex,
                                total,
                                dispatch,
                            }}
                        />
                    )}
                </div>
            </Container>

            <Restricted toAdmin>
                <ProjectCreateModal show={show} handleClose={handleClose} />
            </Restricted>
        </Page>
    );
};
