import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Card, Spinner, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageSRLImage } from '../../../components/PageSRLImage';

import IProduct from '../../../types/IProductType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ProductCardBasicInfo } from './ProductCardBasicInfo';
import { useHandleModal } from '../../../hooks/useHandleModal';
import { fmtNumber, tryFormat } from '../../../utils/helper';
import { ProductCardDocuments } from './ProductCardDocuments';
import { ProductCardProperties } from './ProductCardProperties';
import { ShareModal } from './Modals/ShareModal';
import { RequestModal } from './Modals/RequestModal';

interface Props {
    product?: IProduct;
    loading?: boolean;
    toggleFloatingTitle?: (show: boolean) => void;
}

export const ProductCard = ({
    product,
    loading,
    toggleFloatingTitle = () => {},
}: Props) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const requestModal = useHandleModal<IProduct | undefined>();
    const shareModal = useHandleModal<IProduct | undefined>();
    useEffect(() => {
        requestModal.setItem(product);
        shareModal.setItem(product);
    }, [product, requestModal, shareModal]);

    const adminProps = [
        product?.market_price
            ? {
                  key: t('Market price'),
                  val: `${fmtNumber(product.market_price)};-`,
              }
            : {},
        product?.internal_value
            ? {
                  key: t('Internal value'),
                  val: `${fmtNumber(product.internal_value)};-`,
              }
            : {},
        product?.year
            ? { key: t('Product year'), val: tryFormat(product.year, 'yyyy') }
            : {},
    ].filter(({ val }) => val);

    return (
        <Row className='g-0'>
            <PageSRLImage
                images={product?.product_images}
                youtubeVideoUrl={product?.video_url}
            />

            <Col md={6} className='d-flex'>
                <Card.Body className='position-relative d-flex flex-column pt-0 pt-md-3'>
                    {loading && <Spinner animation={'border'} />}
                    <ProductCardBasicInfo product={product} />
                    <div className='d-flex flex-column flex-grow-1 gap-2'>
                        <Row className='gap-2 gap-xl-0 mt-3'>
                            <Col className='d-flex' xl={7}>
                                <Button
                                    className='text-uppercase flex-grow-1'
                                    onClick={() => {
                                        toggleFloatingTitle(false);
                                        requestModal.showModal();
                                    }}
                                >
                                    {t('Place a request')}
                                </Button>
                            </Col>
                            <Col className='d-flex' xl={5}>
                                <Button
                                    variant='secondary'
                                    className='text-light text-uppercase flex-grow-1'
                                    onClick={() => {
                                        toggleFloatingTitle(false);
                                        shareModal.showModal();
                                    }}
                                >
                                    {t('Share')}{' '}
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </Button>
                            </Col>
                        </Row>
                        <hr />
                        <ProductCardProperties
                            product={product}
                            extraProps={adminProps}
                        />
                        <ProductCardDocuments product={product} />
                        <div className='d-flex mt-3 d-flex flex-column flex-md-row mt-3 justify-content-between gap-1'>
                            {pathname.includes('/admin') && (
                                <Button
                                    onClick={() =>
                                        navigate(
                                            `/admin/projects/${product?.project_id}/product-form/${product?.id}`
                                        )
                                    }
                                >
                                    {t('Redigera')}
                                </Button>
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Col>
            <RequestModal
                handleClose={() => {
                    toggleFloatingTitle(true);
                    requestModal.closeModal();
                }}
                show={requestModal.show}
                item={requestModal.item}
            />
            <ShareModal
                handleClose={() => {
                    toggleFloatingTitle(true);
                    shareModal.closeModal();
                }}
                show={shareModal.show}
                item={shareModal.item}
            />
        </Row>
    );
};
