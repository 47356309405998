import React from 'react';
import { img, thumbInner } from './Styles';

export interface DnDImageProps {
    preview?: string;
    name: string;
    type: string;
    index: number;
}

export const DnDImage: React.FC<DnDImageProps> = ({
    preview,
    name,
    type,
    index,
}) => {
    return (
        <div style={{ ...thumbInner }}>
            {preview ? (
                <>
                    <img src={preview} alt='preview' style={{ ...img }} />
                </>
            ) : (
                <span style={img} />
            )}
        </div>
    );
};
