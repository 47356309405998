import React, { CSSProperties } from 'react';
import {} from 'react-bootstrap';
import { PieChart, Pie, Legend } from 'recharts';

interface Props {
    size: number;
    radiusSize: number;
    layout: 'landscape' | 'portrait';
    nameValueData: { name: string; value: number }[];
    title: string;
    style?: CSSProperties;
    fills?: string[];
}

export const QualityStat: React.FC<Props> = ({
    size,
    radiusSize,
    layout,
    title,
    nameValueData,
    style,
    fills,
}) => {
    const data = nameValueData.length
        ? nameValueData.map((d, i) => ({
              fill: fills ? fills[i % fills.length] : 'peru',
              ...d,
          }))
        : [{ name: '-' }];

    const width = size + (layout === 'landscape' ? 250 : 0); //Extra space for legend
    const height = size + (layout === 'portrait' ? 275 : 0); //Extra space for legend

    const total = nameValueData.map((c) => c.value).reduce((v, c) => v + c, 0);

    const renderLegend = (props: any) => {
        const { payload } = props;
        return (
            <div>
                <div className='fw-bold fs-3 border-bottom border-2 border-secondary my-2'>
                    {title}
                </div>
                {payload.map(
                    (entry: any, i: number) =>
                        entry.value !== 'HIDDEN' && (
                            <div key={i}>
                                <span
                                    className='d-inline-block text-center py-1 my-1 text-light fw-bold me-2'
                                    style={{
                                        backgroundColor: entry.color,
                                        width: '2.5rem',
                                    }}
                                >
                                    {Math.round(entry.payload.percent * 100)}%
                                </span>
                                {entry.value}
                            </div>
                        )
                )}
            </div>
        );
    };

    return (
        <div
            style={{ ...style }}
            className='border px-2 py-4 d-flex flex-grow-1'
        >
            <div className='mx-auto mx-md-0'>
                <PieChart width={width} height={height}>
                    <Pie
                        data={
                            total
                                ? data
                                : [...data, { name: 'HIDDEN', value: 1 }]
                        }
                        dataKey='value'
                        outerRadius={size / 2}
                        innerRadius={size / 2 - radiusSize}
                    />
                    <Legend
                        content={renderLegend}
                        verticalAlign={
                            layout === 'portrait' ? 'bottom' : 'middle'
                        }
                        align={layout === 'portrait' ? 'center' : 'right'}
                        layout='vertical'
                    />
                </PieChart>
            </div>
        </div>
    );
};
