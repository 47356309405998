import { InputGroup, Button, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export type PageCountPickerProps = {
    className?: string;
    pageSize: number;
    onChangePageSize: (count: number) => void;
    pageStep?: number;
    totalCount: number;
    size?: 'sm' | undefined;
};
export const PageCountPicker: React.FC<PageCountPickerProps> = ({
    className,
    pageSize,
    onChangePageSize,
    pageStep = 10,
    totalCount,
    size,
}) => {
    return (
        <InputGroup size={size} className={className}>
            <Button
                size='sm'
                disabled={pageSize - pageStep < pageStep}
                onClick={() =>
                    onChangePageSize(Math.max(0, (pageSize ?? 0) - pageStep))
                }
                variant='primary'
            >
                <FontAwesomeIcon icon={faMinus} width={!size ? 15 : 6} />
            </Button>

            <FormControl
                aria-describedby='text-search-products'
                onChange={(e) =>
                    !isNaN(parseInt(e.target.value)) &&
                    onChangePageSize(Math.max(0, parseInt(e.target.value)))
                }
                value={pageSize}
                style={{
                    maxWidth: !size ? '3.6rem' : '3rem',
                    textAlign: 'center',
                }}
                size={size}
            />
            <Button
                size='sm'
                disabled={totalCount < pageSize}
                onClick={() => onChangePageSize(pageSize + pageStep)}
                variant='primary'
            >
                <FontAwesomeIcon icon={faPlus} width={!size ? 15 : 6} />
            </Button>
        </InputGroup>
    );
};
