import React, { createContext, useContext, useState } from 'react';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import IProductEnvironmentData from '../../../types/IProductEnvironmentDataType';

interface ISelectedContext {
    selectedItems: ResourcePermissionsType<IProductEnvironmentData>[];
    setSelectedItems: React.Dispatch<
        React.SetStateAction<ResourcePermissionsType<IProductEnvironmentData>[]>
    >;
}

const TableSelectedContext = createContext<ISelectedContext>(
    {} as ISelectedContext
);

export const useTableSelectedProductEnvironmentData = () =>
    useContext<ISelectedContext>(TableSelectedContext);

export const TableSelectedProductEnvironmentDataProvider: React.FC = ({
    children,
}) => {
    const [selectedItems, setSelectedItems] = useState<
        ResourcePermissionsType<IProductEnvironmentData>[]
    >([]);

    return (
        <TableSelectedContext.Provider
            value={{ selectedItems, setSelectedItems }}
        >
            {children}
        </TableSelectedContext.Provider>
    );
};
