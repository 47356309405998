import React, { useEffect } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useProductSearch } from './ProductSearch';
import DatePicker from 'react-datepicker';
import IServerResponseType from '../../../types/IServerResponseType';
import useAxios from 'axios-hooks';
import { useAuth } from '../../Auth/hooks/useAuth';
import i18next from 'i18next';

interface Props {
    isMarketPlace?: boolean;
}
export const ProductPlacementFilters = ({ isMarketPlace }: Props) => {
    const { organisation } = useAuth();

    const { queryState, setQueryState } = useProductSearch();

    const [{ data: productStatus }, refetch] = useAxios<
        IServerResponseType<any>
    >(`/api/productstatuses`, {
        manual: !isMarketPlace,
    });

    useEffect(() => {
        if (organisation) refetch();
    }, [organisation, refetch]);

    return (
        <>
            <Row>
                <Col xs={12} lg={6}>
                    {!isMarketPlace && (
                        <Row className='mb-3'>
                            <Col>
                                <FloatingLabel
                                    controlId={'status'}
                                    label={`Status`}
                                >
                                    <Form.Select
                                        name={'status'}
                                        onChange={(e) => {
                                            setQueryState(
                                                'status',
                                                (e.target as HTMLSelectElement)
                                                    .value
                                            );
                                        }}
                                        placeholder={`Status`}
                                        value={queryState.status}
                                    >
                                        {productStatus?.data.map(
                                            (option: any) => (
                                                <option
                                                    key={option.id}
                                                    value={option.name}
                                                >
                                                    {option.name}
                                                </option>
                                            )
                                        )}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    )}
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel
                                controlId={'quantity'}
                                label={`Kvantitet`}
                            >
                                <Form.Control
                                    name={'quantity'}
                                    onChange={(e) => {
                                        setQueryState(
                                            'quantity',
                                            (e.target as HTMLInputElement).value
                                        );
                                    }}
                                    type='number'
                                    size='sm'
                                    placeholder='Kvantitet'
                                    value={
                                        queryState.quantity
                                            ? queryState.quantity
                                            : ''
                                    }
                                ></Form.Control>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel
                                controlId={'delivery_date_from'}
                                label={`Tillgänglig från`}
                                className='datepicker-label'
                            >
                                <DatePicker
                                    className='form-control form-control-sm'
                                    id='delivery_date_from'
                                    name='delivery_date_from'
                                    onChange={(date: Date) =>
                                        setQueryState(
                                            'delivery_date[from]',
                                            date.toISOString()
                                        )
                                    }
                                    selected={
                                        queryState['delivery_date[from]']
                                            ? new Date(
                                                  queryState[
                                                      'delivery_date[from]'
                                                  ]
                                              )
                                            : null
                                    }
                                    dateFormat='yyyy-MM-dd'
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel
                                controlId={'delivery_date_to'}
                                label={`Tillgänglig till`}
                                className='datepicker-label'
                            >
                                <DatePicker
                                    className='form-control form-control-sm'
                                    id='delivery_date_to'
                                    name='delivery_date_to'
                                    onChange={(date: Date) =>
                                        setQueryState(
                                            'delivery_date[to]',
                                            date.toISOString()
                                        )
                                    }
                                    selected={
                                        queryState['delivery_date[to]']
                                            ? new Date(
                                                  queryState[
                                                      'delivery_date[to]'
                                                  ]
                                              )
                                            : null
                                    }
                                    locale={i18next.language}
                                    dateFormat='yyyy-MM-dd'
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
