import React, { useState } from 'react';
import { IProductItem } from '../../../../types/IProductItem';
import { ProductItemExcelExportModal } from './ProductItemExcelExportModal';
import { ProductItemStatusModal } from './ProductItemStatusModal';
import { ProductItemViewingStatusModal } from './ProductItemViewingStatusModal';

export const ProductItemModalsContext = React.createContext<any>({} as any);

export const ProductItemModals: React.FC<any> = ({ children, refetch }) => {
    const [showExcelExportModal, setShowExcelExportModal] = useState<
        IProductItem[] | null
    >(null);
    const [showStatusModal, setShowStatusModal] = useState<
        IProductItem[] | null
    >(null);
    const [showViewingStatusModal, setShowViewingStatusModal] = useState<
        IProductItem[] | null
    >(null);

    return (
        <ProductItemModalsContext.Provider
            value={{
                setShowExcelExportModal,
                showExcelExportModal,
                setShowStatusModal,
                showStatusModal,
                setShowViewingStatusModal,
                showViewingStatusModal,
            }}
        >
            <ProductItemExcelExportModal
                selected={showExcelExportModal}
                onClose={() => {
                    setShowExcelExportModal(null);
                }}
                onSave={() => {}}
            ></ProductItemExcelExportModal>
            <ProductItemStatusModal
                selected={showStatusModal}
                onClose={() => {
                    setShowStatusModal(null);
                }}
                onSave={() => {}}
            ></ProductItemStatusModal>
            <ProductItemViewingStatusModal
                selected={showViewingStatusModal}
                onClose={() => {
                    setShowViewingStatusModal(null);
                }}
                onSave={() => {}}
            ></ProductItemViewingStatusModal>
            {children}
        </ProductItemModalsContext.Provider>
    );
};

export const useProductItemModals = () =>
    React.useContext(ProductItemModalsContext);
