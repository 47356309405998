import React from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IProductEnvironmentData from '../../../../types/IProductEnvironmentDataType';
import { useAuth } from '../../../Auth/hooks/useAuth';
import axios from 'axios';
import { useProductEnvironmentDataModals } from './ProductEnvironmentDataModals';
import { saveAs } from 'file-saver';

const SELECTED_GROUPS = 'dacke-selected-product-environment-data-excel-groups';

export const ProductEnvironmentDataExcelExportSelection: React.FC<any> = ({
    selected,
    toggle,
}) => {
    const { t } = useTranslation();
    return (
        <Form.Group>
            <div>
                <h5>
                    {t('Please select the information you wish to include.')}
                </h5>
                <h6>
                    {t(
                        'Excel export will always include product-ID, name, project, category, amount, unit, placement field 1-4, hazardous material, waste code, cost, handling (law and business), related comments.'
                    )}
                </h6>
            </div>
            <Form.Check
                type='checkbox'
                checked={selected.includes('description')}
                onChange={() => toggle('description')}
                label={t('Description')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('internalDescription')}
                onChange={() => toggle('internalDescription')}
                label={t('Internal Description')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('economicalValues')}
                onChange={() => toggle('economicalValues')}
                label={t('Economical values')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('weight')}
                onChange={() => toggle('weight')}
                label={t('Weight')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('environmentalData')}
                onChange={() => toggle('environmentalData')}
                label={t('Environmental data')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('criterias')}
                onChange={() => toggle('criterias')}
                label={t('Criterias')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('productInfo')}
                onChange={() => toggle('productInfo')}
                label={t('Product Info')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('measurements')}
                onChange={() => toggle('measurements')}
                label={t('Measurements')}
            />
        </Form.Group>
    );
};

export const ProductEnvironmentDataExcelExportModal: React.FC<any> = ({
    selected,
    onClose,
    onSave,
}) => {
    const { t } = useTranslation();

    const { setShowExcelExportModal, showExcelExportModal } =
        useProductEnvironmentDataModals();

    const { organisation } = useAuth();

    const storedSelected = localStorage.getItem(SELECTED_GROUPS);
    const [selectedGroups, setSelectedGroups] = React.useState<string[]>(
        storedSelected ? JSON.parse(storedSelected) : []
    );

    const exportAllItems = async () => {
        try {
            const ids = showExcelExportModal.map(
                (productEnvironmentData: IProductEnvironmentData) => [
                    productEnvironmentData.id,
                    productEnvironmentData?.environment_data_id,
                    productEnvironmentData.product_item_id,
                ]
            );

            const idStr = ids
                .map(
                    (
                        [id, environmentDataId, productItemId]: number[],
                        index: number
                    ) =>
                        `productEnvironmentDataIds[${index}]=${id},${environmentDataId},${productItemId}`
                )
                .join('&');
            const colStr = selectedGroups
                .map((id: string, idx: number) => `columnGroups[${idx}]=${id}`)
                .join('&');

            const response = await axios.get(
                `/api/organisations/${
                    organisation?.id
                }/exportproductenvironmentdata?${idStr}${
                    colStr ? `&${colStr}` : ''
                }`,
                {
                    responseType: 'blob',
                }
            );

            if (response) {
                saveAs(response.data, 'product-environment-data.xlsx');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const toggleSelected = (id: string) => {
        let s = [...selectedGroups];
        if (selectedGroups.indexOf(id) > -1) {
            s = selectedGroups.filter((x) => x !== id);
        } else {
            s.push(id);
        }
        localStorage.setItem(SELECTED_GROUPS, JSON.stringify(s));
        setSelectedGroups(s);
    };

    return (
        <>
            <Modal
                show={showExcelExportModal}
                onHide={() => {
                    setShowExcelExportModal(false);
                    onClose();
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Export product environment data as Excel sheet')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t(
                            'This will export {{amount}} product environment data to an excel file',
                            { amount: showExcelExportModal?.length || 0 }
                        )}
                    </p>
                    <ProductEnvironmentDataExcelExportSelection
                        selected={selectedGroups}
                        toggle={toggleSelected}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => onClose()}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            exportAllItems().then((data) => {
                                setShowExcelExportModal(false);
                            });
                        }}
                    >
                        {t('Save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
