import React, { Suspense } from 'react';
import axios from 'axios';

// import logo from "./logo.svg";
import './App.scss';

import { RootRouter } from './routing/RootRouter';

import { Sanctum } from 'react-sanctum';
import { ViewSwitchProvider } from './pages/Products/components/ViewSwitch';
import { AuthProvider } from './pages/Auth/contexts/Auth/AuthProvider';
import { ProductSearch } from './pages/Products/components/ProductSearch';
import { FullLoadingPage } from './pages/FullLoadingPage';
import { ScrollToTop } from './components/ScrollToTop';

import { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import en from 'date-fns/locale/en-GB';

registerLocale('sv', sv);
registerLocale('en', en);

// axios.defaults.baseURL = "http://localhost:3000/api/";
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!window.location.href.includes('login')) {
            if (error?.response?.status === 419) {
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

const sanctumConfig = {
    apiUrl: process.env.REACT_APP_API_ENDPOINT || '',
    csrfCookieRoute: 'sanctum/csrf-cookie',
    signInRoute: 'login',
    signOutRoute: 'logout',
    userObjectRoute: 'api/me',
};

function App() {
    return (
        <Sanctum config={sanctumConfig}>
            <AuthProvider config={{ apiUrl: `${sanctumConfig.apiUrl}/api` }}>
                <ViewSwitchProvider>
                    <Suspense fallback={<FullLoadingPage />}>
                        <div className='App'>
                            <ProductSearch>
                                <ScrollToTop>
                                    <RootRouter />
                                </ScrollToTop>
                            </ProductSearch>
                        </div>
                    </Suspense>
                </ViewSwitchProvider>
            </AuthProvider>
        </Sanctum>
    );
}

export default App;
