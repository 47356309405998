import { Row, Col, Stack } from 'react-bootstrap';
import { generatePath, Link } from 'react-router-dom';
import IRoute from '../../types/IRouteType';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/icones/dacke-color-01.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelopeSquare,
    faPhoneSquare,
    faPlay,
} from '@fortawesome/free-solid-svg-icons';

interface Props {
    links: IRoute[];
    className?: string;
}

export const Footer = ({ links, className }: Props) => {
    const colorTheme = 'var(--bs-primary)';
    const { t } = useTranslation();

    const heading = (text: string) => (
        <h6 className='pt-2 text-uppercase border-bottom border-secondary pb-1'>
            {text}
        </h6>
    );

    const info = () => (
        <p className='fw-light'>
            {t(
                'Dacke.Online is a professional tool for stock-taking with an aim for sustainability by enabling trade of re-used products of the highest quality. Developed for users by users.'
            )}
        </p>
    );

    const linkClass =
        'fw-light text-light text-uppercase text-decoration-none mb-1';

    const navlinks = () => {
        return links
            .filter((route) => route.name)
            .map((route, i) =>
                route.children && route.children.length > 0 ? (
                    route.children
                        .filter((route) => route.name)
                        .map((croute, ii) => (
                            <Link
                                key={i + '-' + ii}
                                className={linkClass}
                                // https://reactrouter.com/web/api/generatePath
                                to={generatePath(
                                    `${route.path}${
                                        (!croute.index && croute.path) || ''
                                    }`
                                )}
                            >
                                <small>
                                    <FontAwesomeIcon
                                        size='xs'
                                        icon={faPlay}
                                        className='me-2'
                                    />
                                </small>
                                {croute.name && t(croute.name)}
                            </Link>
                        ))
                ) : (
                    <Link
                        key={i}
                        className={linkClass}
                        // https://reactrouter.com/web/api/generatePath
                        to={generatePath(`${route.path}`)}
                    >
                        <small>
                            <FontAwesomeIcon
                                size='xs'
                                icon={faPlay}
                                className='me-2'
                            />
                        </small>
                        {route.name && t(route.name)}
                    </Link>
                )
            );
    };
    const contact = () => (
        <Stack className='fw-light'>
            <div>Dacke App AB</div>
            <div className='p-1'></div>
            <div>Själlandsgatan 60 SE-254 40 Helsingborg</div>
            <div className='p-1'></div>
            <div>
                <FontAwesomeIcon icon={faEnvelopeSquare} className='me-2' />
                info@dacke.online
            </div>
            <div className='p-1'></div>
            <div>
                <FontAwesomeIcon icon={faPhoneSquare} className='me-2' />
                +46 840 025 990
            </div>
        </Stack>
    );

    return (
        <div
            style={{
                backgroundColor: colorTheme,
            }}
            className={`text-light px-3 py-4 m-0 ${className || ''}`}
        >
            <Row>
                <Col
                    xs={12}
                    md={3}
                    className='d-none d-md-flex justify-content-center align-items-center px-sm-5'
                >
                    <img alt='dacke-logo' src={logo} height='130rem' />
                </Col>
                <Col
                    xs={12}
                    md={3}
                    className='d-flex justify-content-center px-sm-5'
                >
                    <Stack>
                        {heading(t('About Dacke Online'))}
                        {info()}
                    </Stack>
                </Col>
                <Col
                    xs={12}
                    md={3}
                    className='d-none d-md-flex justify-content-center px-sm-5'
                >
                    <Stack>
                        {heading(t('Links'))}
                        {navlinks()}
                        <Link className={linkClass} to={'/privacy-policy'}>
                            <small>
                                <FontAwesomeIcon
                                    size='xs'
                                    icon={faPlay}
                                    className='me-2'
                                />
                            </small>
                            {t('Privacy policy')}
                        </Link>
                    </Stack>
                </Col>
                <Col
                    xs={12}
                    md={3}
                    className='d-flex justify-content-center px-sm-5'
                >
                    <Stack>
                        {heading(t('Contact us'))}
                        {contact()}
                    </Stack>
                </Col>
            </Row>
        </div>
    );
};
