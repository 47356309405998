import axios from 'axios';
import { IDocumentMedia } from '../../types/IMediaType';

export const createDocuments = async <T = never>(
    organisationId: number,
    projectId: number,
    projectDocuments: any[]
) => {
    const projectDocumentsFormData = new FormData();

    projectDocuments.forEach((document: any, index: number) => {
        projectDocumentsFormData.append(
            `project_documents[${index}]`,
            projectDocuments[index]
        );

        projectDocumentsFormData.append(
            `info[project_documents][${index}][title]`,
            document?.name || ''
        );

        projectDocumentsFormData.append(
            `info[project_documents][${index}][label]`,
            'Ospecificerat'
        );
    });

    const documentsResponse = await axios
        .post<T>(
            `/api/organisations/${organisationId}/projects/${projectId}/media`,
            projectDocumentsFormData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
        .then((response) => {
            return axios
                .get(
                    `/api/organisations/${organisationId}/projects/${projectId}`
                )
                .then((response) => {
                    return response.data.data.project_documents;
                });
        });

    if (documentsResponse) {
        return documentsResponse;
    }
};

export const updateDocuments = async (
    organisationId: number,
    projectId: number,
    mediaGroup: number,
    projectDocuments: any[]
) => {
    const projectDocumentsFormData = new FormData();

    projectDocuments.forEach((document: any, index: number) => {
        projectDocumentsFormData.append(
            `info[project_documents][${document.id}][title]`,
            document?.name || document?.title || ''
        );
        projectDocumentsFormData.append(
            `info[project_documents][${document.id}][is_unprotected]`,
            document?.is_unprotected ? '1' : '0'
        );
        projectDocumentsFormData.append(
            `info[project_documents][${document.id}][label]`,
            document?.label ? document.label : 'Ospecificerat'
        );
    });

    projectDocumentsFormData.append('_method', 'PUT');

    const documentsResponse = await axios.post(
        `/api/organisations/${organisationId}/projects/${projectId}/media/${mediaGroup}`,
        projectDocumentsFormData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );

    if (documentsResponse) {
        return documentsResponse.data;
    }
};

export const deleteDocument = async (
    organisationId: number,
    projectId: number,
    mediaGroup: number,
    document: any
) => {
    const documentResponse = await axios.delete(
        `/api/organisations/${organisationId}/projects/${projectId}/media/media-${document.id}`
    );

    if (documentResponse) {
        return documentResponse.data;
    }
};

export function createDocumentsFromMedias(
    medias: IDocumentMedia[]
): any[] | never[] {
    return medias.map((media) => {
        return {
            id: media.id,
            preview: process.env.REACT_APP_API_ENDPOINT + media.url,
            title: media.title,
            is_unprotected: media.is_unprotected,
            label: media.label
        };
    });
}
