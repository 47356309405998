import React from 'react';

interface Props {}

export const Error404 = (props: Props) => {
    return (
        <div>
            <h1>404 Not Found</h1>
        </div>
    );
};
