import React, { useCallback, useEffect, useState } from 'react';
import { CloseButton, Card } from 'react-bootstrap';
import { AccordionHeader } from './AccordionHeader';
import { AccordionItem } from './AccordionItem';
import { isOpen } from './utils';

// Because the accordion is used at many places and sometimes have same titles
// like 'Contact person' and 'Users' this method tries to address that by looking at route
const makeUnique = (title: string) => {
    if (window.location.href.includes('organisation'))
        return 'orga-accordion-' + title;
    if (window.location.href.includes('product'))
        return 'prod-accordion-' + title;
    if (window.location.href.includes('project'))
        return 'proj-accordion-' + title;
    return 'accordion-' + title; //fallback
};

interface Props {
    accordionItems: any[];
    disabled: boolean;
    initialOpenKeys?: string[];
    className?: string;
}

export const Accordion: React.FC<Props> = ({
    accordionItems,
    disabled,
    initialOpenKeys = null,
    className,
}) => {
    let DEFAULT_ACCORDION_STATE: 'OPEN' | 'CLOSED' = 'CLOSED';
    // init open Accordions
    // desktop all except video
    // mobile only one can be open at the time

    const [openKeys, setOpenKeys] = useState<string[]>([]);

    // Saves the state of accordion item to local storage (open/closed)
    const saveLocalStorage = useCallback(
        (key: string, open: boolean) => {
            const index = Number.parseInt(key);
            const { title } = accordionItems[index];
            localStorage.setItem(makeUnique(title), JSON.stringify(open));
        },
        [accordionItems]
    );

    // Loads accordion item states (open/close) when the component is mounted
    useEffect(() => {
        if (initialOpenKeys) {
            return setOpenKeys(initialOpenKeys);
        }

        let storedOpenKeys: string[] = [];
        accordionItems.forEach((a, i) => {
            const open = localStorage.getItem(makeUnique(a.title));

            // If undefined -> it should be DEFAULT_ACCORDION_STATE.
            // If 'true' -> it should be open.
            if (DEFAULT_ACCORDION_STATE === 'OPEN') {
                if (!open || open === 'true') storedOpenKeys.push(i.toString());
            } else if (DEFAULT_ACCORDION_STATE === 'CLOSED') {
                if (open === 'true') storedOpenKeys.push(i.toString());
            }
        });
        setOpenKeys(storedOpenKeys);
    }, [initialOpenKeys, accordionItems, DEFAULT_ACCORDION_STATE]);

    const toggle = useCallback(
        (key: string) =>
            setOpenKeys((pre) => {
                if (isOpen(pre, key)) {
                    saveLocalStorage(key, false);
                    // remove from array
                    return [...pre.filter((k) => k !== key)];
                } else {
                    saveLocalStorage(key, true);
                    // add to array
                    return [...pre, key];
                }
            }),
        [saveLocalStorage]
    );

    return (
        <Card className={className}>
            <Card.Body>
                {accordionItems.map(
                    (
                        {
                            title,
                            PreviewCompnent,
                            previewComponentProps,
                            FormCompnent,
                            formCompnentProps,
                        },
                        index
                    ) => {
                        return (
                            <Card key={title} className='border-0'>
                                <AccordionHeader
                                    title={title}
                                    eventKey={index.toString()}
                                    {...{ openKeys, toggle, disabled }}
                                >
                                    <PreviewCompnent
                                        {...previewComponentProps}
                                    />
                                </AccordionHeader>
                                <AccordionItem
                                    eventKey={index.toString()}
                                    openKeys={openKeys}
                                >
                                    <Card.Body className='p-0'>
                                        <FormCompnent {...formCompnentProps} />
                                    </Card.Body>
                                    <div className='d-flex justify-content-end'>
                                        <CloseButton
                                            onClick={() =>
                                                toggle(index.toString())
                                            }
                                        />
                                    </div>
                                </AccordionItem>
                            </Card>
                        );
                    }
                )}
            </Card.Body>
        </Card>
    );
};
