import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ViewToggler } from '../pages/Products/components/ViewToggler';

export const SortAndViewToggle = ({
    setOrder,
    sort,
    order,
}: {
    setOrder: (_sort: any) => void;
    sort: any[];
    order: {
        key: string;
        name: string;
        direction: string;
    };
}) => {
    const { t } = useTranslation();

    return (
        <div className='d-flex flex-row align-content-md-end justify-content-xxl-end gap-md-4'>
            <Form className='d-flex align-items-center'>
                <Form.Label className='d-none d-sm-block d-md-none d-lg-block text-nowrap m-0 me-1'>
                    {t('Order by')}:
                </Form.Label>

                <Form.Select
                    aria-label='Default select example'
                    defaultValue={sort.indexOf(order)}
                    onChange={(e) => {
                        const index = parseInt(
                            (e.target as HTMLSelectElement).value,
                            10
                        );
                        setOrder(sort[index]);
                    }}
                >
                    {sort.map((item, index) => (
                        <option value={index} key={index}>
                            {item.name}{' '}
                            {item.direction === 'desc' ? '(v)' : '(^)'}
                        </option>
                    ))}
                </Form.Select>
            </Form>

            <div className='d-none d-sm-flex align-items-center'>
                <ViewToggler />
            </div>
        </div>
    );
};
