import { IPrivateRoute } from '../types/IRouteType';

import { AdminSideNavLayout } from '../components/Layout/AdminSideNavLayout';
import { Outlet, useOutletContext } from 'react-router';
import { Navbar, Col, Row, Container } from 'react-bootstrap';
import CategoryNavList from '../components/CategoryNavList';

import { routesPrivate } from './routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollyBox } from '@fortawesome/free-solid-svg-icons';
import { ProductPage } from '../pages/Products/ProductPage';
import { ProductsPage } from '../pages/Products/ProductsPage';
import { ICategory } from '../types/ICategory';
import IServerResponseType from '../types/IServerResponseType';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useProductSearch } from '../pages/Products/components/ProductSearch';
import { useAuth } from '../pages/Auth/hooks/useAuth';
import axios from 'axios';

const Products = () => {
    const { queryState } = useProductSearch();
    const { organisation } = useAuth();

    // Categories
    const [{ data }, refetch] = useAxios<IServerResponseType<ICategory[]>>(
        `/api/organisations/${organisation?.id}/categories`,
        { manual: true }
    );
    const parentize = (category: ICategory, parent: ICategory) => {
        // Can probably do this in BE instead
        category.parent = parent;
        category.children.forEach((c) => parentize(c, category));
        return category;
    };
    const categories = data?.data?.map((c) => parentize(c, c.parent));
    useEffect(() => {
        refetch();
    }, [refetch]);

    const [selectedCategory, setSelectedCategory] = useState<ICategory>();
    useEffect(() => {
        if (queryState.category_id && !selectedCategory) {
            axios
                .get<IServerResponseType<ICategory>>(
                    `/api/categories/${queryState.category_id}`
                )
                .then((res) => {
                    if (res.data.data) {
                        setSelectedCategory(res.data.data);
                    }
                });
        }
    }, [queryState.category_id, selectedCategory]);

    return (
        <AdminSideNavLayout routesPrivate={routesPrivate}>
            <Row>
                <Col
                    lg={1}
                    id='inner-sidebar-wrapper'
                    style={{
                        left: 0,
                        right: 0,
                    }}
                >
                    <Navbar
                        bg='light'
                        expand='lg'
                        className='align-items-start inner-sidebar py-0 pe-lg-4'
                        style={{
                            left: '0px',
                        }}
                    >
                        <Navbar.Collapse className='mt-4'>
                            <Container className='align-items-start'>
                                <CategoryNavList
                                    categories={categories}
                                    setSelectedCategory={setSelectedCategory}
                                />
                            </Container>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
                <Col>
                    <Outlet
                        context={{
                            categories,
                            selectedCategory,
                            setSelectedCategory,
                        }}
                    />
                </Col>
            </Row>
        </AdminSideNavLayout>
    );
};

export function useCategories() {
    return useOutletContext<{
        categories: ICategory[];
        selectedCategory: ICategory;
        setSelectedCategory: (category?: ICategory) => void;
    }>();
}

export const productsPrivateRoutes: IPrivateRoute[] = [
    {
        name: 'Products',
        path: 'products',
        icon: <FontAwesomeIcon size='2x' icon={faDollyBox} />,
        element: <Products />,
        children: [
            {
                name: 'Products',
                index: true,
                element: <ProductsPage />,
            },

            {
                path: ':productId',
                element: <ProductPage />,
            },
        ],
    },
];
