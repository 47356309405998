import IRoute from '../types/IRouteType';
import { ContactPage } from '../pages/CMS/pages/ContactPage';
import { AboutUsPage } from '../pages/CMS/pages/AboutUsPage';
import { PartnerServicesPage } from '../pages/CMS/pages/PartnerServicesPage';
import { PrivacyPolicy } from '../pages/CMS/pages/PrivacyPolicy';
import { Footer } from '../components/Layout/Footer';
import { marketplacePublicRoutes } from './marketplaceRoutes';
import { Outlet } from 'react-router';
import { Layout } from '../components/Layout/Layout';

const CMSPages = () => {
    return (
        <>
            <Layout>
                <div id='page-content-wrapper'>
                    <Outlet />
                </div>
            </Layout>
            <Footer
                links={[...marketplacePublicRoutes, ...cmsPublicRoutes]}
            ></Footer>
        </>
    );
};
export const cmsPublicRoutes: IRoute[] = [
    {
        name: 'Start',
        path: '/',
        element: <CMSPages />,
        children: [
            {
                name: 'About us',
                path: 'about-us',
                element: <AboutUsPage />,
            },
            {
                name: 'Partner services',
                path: 'partner-services',
                element: <PartnerServicesPage />,
            },
            {
                name: 'Contact us',
                path: 'contact',
                element: <ContactPage />,
            },
            {
                path: 'privacy-policy',
                element: <PrivacyPolicy />,
            },
        ],
    },
];
