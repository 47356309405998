import React, { useState } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ADDIcon } from '../../../assets/icones/Dacke_nytt.svg';
import axios from 'axios';
import IProjectType from '../../../types/IProjectType';
import IServerResponseType from '../../../types/IServerResponseType';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import { useAuth } from '../../Auth/hooks/useAuth';

interface Props {
    show: boolean;
    handleClose: (id?: number) => void;
}

export const ProjectCreateModal: React.FC<Props> = ({ show, handleClose }) => {
    const { t } = useTranslation();

    const {
        reset,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm();

    const { organisation } = useAuth();

    const [projectTitle, setProjectTitle] = useState('');

    const onSubmit = (data: any) => {
        axios
            .post<IServerResponseType<ResourcePermissionsType<IProjectType>>>(
                `/api/organisations/${organisation?.id}/projects`,
                {
                    ...data,
                    organisation_id: organisation?.id,
                }
            )
            .then((res) => {
                delete data.submit;
                Object.keys(data)?.forEach(
                    (key) => typeof data[key] === 'string' && setValue(key, '')
                );
                handleClose(res.data.data.id);
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    return (
        <Modal show={show} onHide={() => handleClose()} centered>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='w-100 text-uppercase d-flex align-items-center justify-content-center'>
                    <ADDIcon width='18' className='me-1' />
                    {t('New project')}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                <Modal.Body>
                    <Row>
                        <Col xs={9}>
                            <Form.Group className='mb-3' controlId='formTitle'>
                                <Form.Label>
                                    {t('Project name')}
                                    <Form.Text>
                                        {' (' +
                                            t('{{inputLength}}/50 characters', {
                                                inputLength:
                                                    projectTitle?.length || 0,
                                            }) +
                                            ')'}
                                    </Form.Text>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='title'
                                    defaultValue=''
                                    rules={{
                                        required: t(
                                            'Project name must be entered'
                                        ) as string,
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <Form.Control
                                            onChange={(e) => {
                                                onChange(e);
                                                setProjectTitle(e.target.value);
                                            }}
                                            value={value}
                                            ref={ref}
                                            isInvalid={errors.title}
                                            placeholder={'Enter name'}
                                        />
                                    )}
                                />

                                <Form.Control.Feedback type='invalid'>
                                    {errors.title?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={3}>
                            <Form.Group
                                className='mb-3'
                                controlId='formIsActive'
                            >
                                <Form.Label>{t('Active')}</Form.Label>
                                <Controller
                                    control={control}
                                    name='is_active'
                                    defaultValue={true}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <Form.Check
                                            type='switch'
                                            onChange={onChange}
                                            checked={value}
                                            ref={ref}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group
                                className='mb-3'
                                controlId='formDescription'
                            >
                                <Form.Label>
                                    {t('Project description')}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='description'
                                    defaultValue=''
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            isInvalid={errors.description}
                                            placeholder=''
                                            as='textarea'
                                            rows={6}
                                        />
                                    )}
                                />

                                <Form.Control.Feedback type='invalid'>
                                    {errors.description?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group
                                className='mb-3'
                                controlId='formProjectStart'
                            >
                                <Form.Label>{t('Start date')}</Form.Label>
                                <Controller
                                    control={control}
                                    name='project_start'
                                    defaultValue=''
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            isInvalid={errors.description}
                                            type='date'
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.project_start?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group
                                className='mb-3'
                                controlId='formProjectFinish'
                            >
                                <Form.Label>{t('End date')}</Form.Label>
                                <Controller
                                    control={control}
                                    name='project_finish'
                                    defaultValue=''
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            isInvalid={errors.description}
                                            type='date'
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.project_start?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Controller
                        name='submit'
                        control={control}
                        render={({ field: { ref }, formState }) => (
                            <Button
                                type='submit'
                                disabled={formState.isSubmitting}
                                variant='secondary'
                                className='w-100'
                            >
                                {formState.isSubmitting && (
                                    <span className='spinner-border spinner-border-sm mr-1' />
                                )}
                                {t('Add new project')}
                            </Button>
                        )}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
