import { useTranslation } from 'react-i18next';
import { Col, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { CoverImage } from '../../../../components/CoverImage';
import { Logo } from '../../../../components/Logo';
import { PageCountPicker } from '../../../../components/PageCountPicker';
import { Restricted } from '../../../Auth/components/Restricted';
import { PAGE_SIZE_CHANGED } from '../../../../hooks/useTableReducer';

import { IImageMediaGroup } from '../../../../types/IMediaType';
import IProject from '../../../../types/IProjectType';
import IOrganisation from '../../../../types/IOrganisationType';
import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';

import './MobileView.scss';

const CardImage = ({
    project,
    organisation,
}: {
    project: IProject;
    organisation: IOrganisation | null;
}) => (
    <div className='position-relative'>
        <CoverImage
            maxSize
            images={project?.project_images as IImageMediaGroup}
            lightbox
        />
        <div className='position-absolute top-0 end-0 pe-1 pt-2'>
            <Logo
                shape='circle'
                size='xs'
                shadow='shadow-sm'
                images={organisation?.organisation_logo}
            />
        </div>
    </div>
);

interface CardProps {
    project: ResourcePermissionsType<IProject>;
    organisation: ResourcePermissionsType<IOrganisation> | null;
}

const Dot = ({ color }: { color: string }) => (
    <span
        style={{
            backgroundColor: color,
            borderRadius: '50%',
            width: '13px',
            height: '13px',
            position: 'absolute',
            border: '2px solid #fff',
            boxShadow: '3px 3px 4px rgba(0,0,0,0.2)',
            marginLeft: '5px',
        }}
    />
);

const ProjectMobileCard = ({ project, organisation }: CardProps) => {
    const { t } = useTranslation();
    return (
        <Link
            to={`${project.id}`}
            className='px-2'
            style={{ textDecoration: 'none' }}
        >
            <Row className='py-2'>
                <Col xs={5} className='m-auto'>
                    <CardImage project={project} organisation={organisation} />
                </Col>
                <Col
                    xs={7}
                    className='d-flex flex-column justify-content-center'
                >
                    <div className='flex-column'>
                        <p className='mb-1'>
                            <span className='fs-5 fw-bold me-1 text-break'>
                                {project.title}
                            </span>
                            (
                            <small className='mb-1'>{`${t('Project')}: ${
                                project.id
                            }`}</small>
                            )
                        </p>
                        <div className='position-relative'>
                            <span className='pe-1'>{`${t('Status')}: ${
                                project.is_active ? t('Active') : t('Inactive')
                            }`}</span>
                            <Dot
                                color={
                                    project?.is_active
                                        ? 'var(--bs-success)'
                                        : 'var(--bs-danger)'
                                }
                            />
                        </div>
                        <small className='mb-2'>
                            Region: {project?.region || '-'}
                        </small>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className='mb-1'>
                    <Restricted to='view single project' resource={project}>
                        <Link
                            className='btn btn-primary w-100'
                            to={`${project.id}/project-form`}
                        >
                            {t('Edit')}
                        </Link>
                    </Restricted>
                </Col>
                <Col className='mb-1'>
                    <Restricted to='view any product' resource={project}>
                        <Link
                            className='btn btn-secondary w-100'
                            to={`${project.id}/products`}
                        >
                            {t('Products')}
                        </Link>
                    </Restricted>
                </Col>
            </Row>
        </Link>
    );
};

interface Props {
    projects: ResourcePermissionsType<IProject>[];
    organisation: ResourcePermissionsType<IOrganisation> | null;

    queryPageSize: number;
    totalCount: number;
    dispatch: any;
}

const MobileView = ({
    projects,
    organisation,
    queryPageSize,
    totalCount,
    dispatch,
}: Props) => (
    <Stack gap={3}>
        <Stack id='project-mobile-view' className='overflow-hidden'>
            {projects.map((project) => (
                <ProjectMobileCard
                    key={project.id}
                    project={project}
                    organisation={organisation}
                />
            ))}
        </Stack>
        <PageCountPicker
            className='d-flex justify-content-center'
            pageSize={queryPageSize}
            onChangePageSize={(size) => {
                dispatch({
                    type: PAGE_SIZE_CHANGED,
                    payload: size,
                });
            }}
            totalCount={totalCount}
        />
    </Stack>
);
export default MobileView;
