import useAxios from 'axios-hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { Page } from '../../components/Layout/Page';
import IProjectType from '../../types/IProjectType';
import IServerResponseType from '../../types/IServerResponseType';
import ResourcePermissionsType from '../../types/ResourcePermissionsType';
import { useAuth } from '../Auth/hooks/useAuth';
import useForceBlur from '../../hooks/useForceBlur';

import { CategorySelectionModal } from './components/Modals/CategorySelectionModal';
import { ProjectSelectionModal } from './components/Modals/ProjectSelectionModal';

import { ICategory } from '../../types/ICategory';
import {
    ProductFormState,
    useProductFormState,
    IProductFormState,
} from './components/ProductForm/ProductFormState';
import { BasicInformationFrom } from './components/ProductForm/BasicInformation/BasicInformationFrom';
import { MessuresForm } from './components/ProductForm/Messures/MessuresForm';

import { PropertiesForm } from './components/ProductForm/Properties/PropertyForm';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FloatingProductNameHeader } from './components/FloatingProductNameHeader';
import { ImagesForm } from './components/ProductForm/Images/ImagesForm';
import { VideoForm } from './components/ProductForm/Video/VideoForm';
import { BasicInformationPreview } from './components/ProductForm/BasicInformation/BasicInformationPreview';
import { EnvironmentDataPreview } from './components/ProductForm/EnvironmentData/EnvironmentDataPreview';
import { EnvironmentDataForm } from './components/ProductForm/EnvironmentData/EnvironmentDataForm';
import { PlacementsForm } from './components/ProductForm/Placements/PlacementsForm';
import { PlacementsPreview } from './components/ProductForm/Placements/PlacementsPreview';
import { VideoPreview } from './components/ProductForm/Video/VideoPreview';
import { MessuresPreview } from './components/ProductForm/Messures/MessuresPreview';
import { PropertiesPreview } from './components/ProductForm/Properties/PropertiesPreview';
import { DocumentsForm } from './components/ProductForm/Documents/DocumentsForm';
import { DocumentsPreview } from './components/ProductForm/Documents/DocumentsPreview';
import { SaveProductButton } from './components/ProductForm/SaveProductButton';
import { ImagesPreview } from './components/ProductForm/Images/ImagesPreview';
import { Accordion } from '../../components/Accordion/Accordion';
import useScrollY from '../../hooks/useScrollY';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IProduct from '../../types/IProductType';
import IProductEnvironmentData from '../../types/IProductEnvironmentDataType';
import { createImagesFromMedias } from '../../API/product/images';
import { createDocumentsFromMedias } from '../../API/product/documents';
import { Container, ButtonToolbar, Breadcrumb } from 'react-bootstrap';
import IProductFieldType from '../../types/IProductFieldType';
import { IProductItem } from '../../types/IProductItem';
import { Loading } from '../FullLoadingPage';
import './ProductFormPage.scss';

const isValidDate = function (date: string): boolean {
    return new Date(date).toString() !== 'Invalid Date';
};

interface Props {
    PlacementsFormMemo: React.MemoExoticComponent<typeof PlacementsForm>;
    product?: IProduct;
    props?: any;
}

const NoStateNewProductPage = (props: Props) => {
    const { isNewProduct } = props?.props || {};

    const { productId, projectId } = useParams();

    const { t } = useTranslation();
    const { organisation } = useAuth();
    const navigate = useNavigate();
    const scrollY = useScrollY();

    // A delegate passed from SaveProductButton to PlacementsForm
    const [setProductItems, setSetProductItems] =
        useState<(items: IProductItem[]) => void>();
    const [setProductEnvironmentData, setSetProductEnvironmentData] =
        useState<(item: IProductEnvironmentData) => void>();

    const product_data = useProductFormState();

    const {
        project_id,
        setProjectId,
        category_id,
        setCategoryId,
        setBasicInformation,
    } = product_data;

    const [{ data: projectsData }, refetchProjects] = useAxios<
        IServerResponseType<ResourcePermissionsType<IProjectType>[]>
    >(`/api/organisations/${organisation?.id}/projects`, { manual: true });

    const [showProjectSelection, setShowProjectSelection] =
        useState<boolean>(false);

    useEffect(() => {
        if (!project_id) {
            setShowProjectSelection(true);
        }
    }, [project_id]);

    const [{ data: categoriesData }, refetchCategories] = useAxios<
        IServerResponseType<ICategory[]>
    >(`/api/categories`, {
        manual: true,
    });

    const [showCategorySelection, setShowCategorySelection] =
        useState<boolean>(false);
    const [categorySelectionState, setCategorySelectionState] = useState<
        'new' | 'edit'
    >('new');
    const categorySelectionTitle = useMemo(
        () =>
            categorySelectionState === 'new' ? 'New product' : 'Edit product',
        [categorySelectionState]
    );

    useEffect(() => {
        if (project_id && !category_id) {
            setCategorySelectionState('new');
            setShowCategorySelection(true);
        }
    }, [category_id, project_id]);

    const [lockAccordian, setLockAccordian] = useState(true);

    useEffect(() => {
        if (project_id && category_id) {
            setLockAccordian(false);
        }
    }, [category_id, project_id]);

    useEffect(() => {
        if (organisation) {
            refetchProjects();
            refetchCategories();
        }
    }, [organisation, refetchCategories, refetchProjects]);

    // autofill product name
    useEffect(() => {
        const fetch = async () => {
            if (organisation?.id && category_id) {
                const request = await axios.get(
                    `/api/categories/${category_id}`
                );

                if (request?.data?.data) {
                    setBasicInformation(
                        'title',
                        request.data.data.description || ''
                    );
                }
            }
        };
        if (!productId) {
            fetch();
        }
    }, [organisation, category_id, setBasicInformation, productId]);

    const [{ data: projectData }, refetchProject] = useAxios<
        IServerResponseType<ResourcePermissionsType<IProjectType>>
    >(`/api/organisations/${organisation?.id}/projects/${projectId}`, {
        manual: true,
    });

    const project = projectData?.data;

    useEffect(() => {
        if (projectId) {
            refetchProject();
        }
    }, [projectId, refetchProject]);

    const [customProductFields, setCustomProductFields] = useState<
        IProductFieldType[]
    >([]);
    useEffect(() => {
        axios
            .get(`/api/organisations/${organisation?.id}/organisationmetatypes`)
            .then((res) => {
                if (res?.data?.data) {
                    setCustomProductFields(res.data.data);
                }
            });
    }, [organisation?.id]);

    const [totalQuantity, setTotalQuantity] = useState<number>(0);
    const [enableFloatingTitle, setEnableFloatingTitle] =
        useState<boolean>(true);

    useEffect(() => {
        setEnableFloatingTitle(!showCategorySelection);
    }, [showCategorySelection]);

    const accordionItems = useMemo(() => {
        return [
            {
                title: t('Quick form'),
                PreviewCompnent: BasicInformationPreview,
                FormCompnent: BasicInformationFrom,
                formCompnentProps: {
                    customProductFields,
                    categories: categoriesData?.data || [],
                    showCategorySelect: () => {
                        setCategorySelectionState('edit');
                        setShowCategorySelection(true);
                    },
                },
            },
            {
                title: t('Placement'),
                PreviewCompnent: PlacementsPreview,
                previewComponentProps: {
                    product: props.product,
                    totalQuantity,
                },
                FormCompnent: props.PlacementsFormMemo,
                formCompnentProps: {
                    firstPlacementTitle: project?.firstPlacementTitle,
                    secondPlacementTitle: project?.secondPlacementTitle,
                    thirdPlacementTitle: project?.thirdPlacementTitle,
                    fourthPlacementTitle: project?.fourthPlacementTitle,
                    setProductItems,
                    onTotalQuantityChanged: setTotalQuantity,
                    product: props.product,
                },
            },
            {
                title: t('Images'),
                PreviewCompnent: ImagesPreview,
                FormCompnent: ImagesForm,
            },
            ...(organisation?.has_environment_data
                ? [
                      {
                          title: t('Environment data'),
                          PreviewCompnent: EnvironmentDataPreview,
                          FormCompnent: EnvironmentDataForm,
                          formCompnentProps: {
                              setProductEnvironmentData,
                          },
                      },
                  ]
                : []),
            {
                title: t('Measure'),
                PreviewCompnent: MessuresPreview,
                FormCompnent: MessuresForm,
            },
            {
                title: t('Product properties'),
                PreviewCompnent: PropertiesPreview,
                FormCompnent: PropertiesForm,
                formCompnentProps: {
                    customProductFields,
                },
            },
            {
                title: t('Product documents'),
                PreviewCompnent: DocumentsPreview,
                FormCompnent: DocumentsForm,
            },
            {
                title: t('Video'),
                PreviewCompnent: VideoPreview,
                FormCompnent: VideoForm,
            },
        ];
    }, [
        setProductItems,
        setProductEnvironmentData,
        props.PlacementsFormMemo,
        customProductFields,
        categoriesData?.data,
        project?.firstPlacementTitle,
        project?.fourthPlacementTitle,
        project?.secondPlacementTitle,
        project?.thirdPlacementTitle,
        t,
        props.product,
        totalQuantity,
        organisation,
    ]);

    return (
        <Page>
            <CategorySelectionModal
                show={showCategorySelection}
                handleClose={(category_id) => {
                    if (category_id) setCategoryId(category_id);
                    else if (categorySelectionState === 'new') navigate(-1);
                    setShowCategorySelection(false);
                }}
                categories={categoriesData?.data || []}
                title={t(categorySelectionTitle)}
            ></CategorySelectionModal>
            <ProjectSelectionModal
                show={showProjectSelection}
                handleClose={(project_id) => {
                    if (project_id) setProjectId(project_id);
                    setShowProjectSelection(false);
                }}
                projects={projectsData?.data || []}
            ></ProjectSelectionModal>

            <Container fluid className='px-0 px-sm-inherit'>
                <FloatingProductNameHeader
                    title={
                        productId
                            ? `${product_data.title} (${product_data.barcode})`
                            : t('New')
                    }
                    show={enableFloatingTitle && scrollY > 90}
                />

                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/admin/projects`,
                            }}
                        >
                            {t('Projects')}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/admin/projects/${project?.id}`,
                            }}
                        >
                            {project?.title}
                        </Breadcrumb.Item>

                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/admin/projects/${project?.id}/products`,
                            }}
                        >
                            {t('Products')}
                        </Breadcrumb.Item>

                        {productId ? (
                            <>
                                <Breadcrumb.Item className='text-break'>
                                    {product_data.title}{' '}
                                    <small>({product_data.barcode})</small>
                                </Breadcrumb.Item>
                            </>
                        ) : (
                            <Breadcrumb.Item active>{t('New')}</Breadcrumb.Item>
                        )}
                    </Breadcrumb>

                    {!productId && (
                        <div className='d-flex justify-content-end'>
                            <SaveProductButton
                                setSetProductItems={setSetProductItems}
                                setSetProductEnvironmentData={
                                    setSetProductEnvironmentData
                                }
                            />
                        </div>
                    )}
                </ButtonToolbar>

                <Accordion
                    initialOpenKeys={isNewProduct ? ['0'] : undefined}
                    disabled={lockAccordian}
                    className='form-accordion'
                    {...{ accordionItems }}
                />

                {!productId ? (
                    <div className='d-flex'>
                        <SaveProductButton
                            setSetProductItems={setSetProductItems}
                            setSetProductEnvironmentData={
                                setSetProductEnvironmentData
                            }
                        />
                    </div>
                ) : null}
            </Container>
        </Page>
    );
};

export const ProductFormPage: React.FC<any> = (props) => {
    const { projectId, productId } = useParams();
    const { organisation } = useAuth();
    useForceBlur();

    // fetch product data
    const [{ data: productData }, refetchProduct] = useAxios<
        IServerResponseType<ResourcePermissionsType<IProduct>>
    >(`/api/organisations/${organisation?.id}/products/${productId}`, {
        manual: true,
    });

    useEffect(() => {
        if (organisation?.id && productId) {
            refetchProduct();
        }
    }, [organisation, productId, refetchProduct]);

    const product = productData?.data;

    // Moved placement outside the context so it doesnt rerender every time
    // (Also: ProductForm - delegate talking between SaveButton / PlacementsForm)
    // ->> Changing placementForm doesnt rerender the ProductForm (Only SaveProductButton)
    // ->> Changing the ProductForm doesnt rerender the PlacementsForm
    const PlacementsFormMemo: React.MemoExoticComponent<typeof PlacementsForm> =
        React.memo(PlacementsForm);

    return productId && !product ? (
        <Loading />
    ) : (
        <ProductFormState
            initialState={
                {
                    category_id: product?.category_id,
                    project_id: projectId ? parseInt(projectId) : null,
                    barcode: product?.barcode,
                    title: product?.title,
                    description: product?.description,
                    internal_description: product?.internal_description,
                    manufacture_code: product?.manufacture_code,
                    model_code: product?.model_code,
                    year:
                        product?.year && isValidDate(product?.year)
                            ? product?.year
                            : null,

                    package_quantity: product?.package_quantity,
                    stock_type: product?.stock_type,
                    package_unit: product?.package_unit,
                    internal_value: product?.internal_value,
                    market_price: product?.market_price,
                    sales_price: product?.sales_price,

                    search_words: product?.search_words,
                    function_points: product?.function_points,
                    condition_points: product?.condition_points,
                    product_meta: product?.product_meta || [],
                    video_url: product?.video_url,
                    video_description: product?.video_description,
                    unused: product?.unused,

                    // TODO: move Weight to messure?
                    weight: product?.weight,
                    weight_unit_id: product?.weight_unit_id,

                    co2: product?.co2,

                    // deal with product_points
                    points: product?.product_points || [],
                    messures: product?.messurement || {},

                    productProperies: product?.product_properties || [],

                    // all thees can be set in respective components
                    productItems: [],
                    productImages: product?.product_images?.medias
                        ? createImagesFromMedias(product.product_images.medias)
                        : [],
                    product_images: {
                        id: product?.product_images?.id || null,
                    },

                    product_environment_data: product?.product_environment_data,

                    productDocuments: product?.product_documents?.medias
                        ? createDocumentsFromMedias(
                              product.product_documents.medias
                          )
                        : [],
                    product_documents: {
                        id: product?.product_documents?.id || null,
                    },

                    productVideo: {},
                } as IProductFormState
            }
        >
            <NoStateNewProductPage
                {...{ props, product, PlacementsFormMemo }}
            />
        </ProductFormState>
    );
};
