import React, { useCallback } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useOrganisationFormState } from '../OrganisationFormState';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import IOrganisationType from '../../../../../types/IOrganisationType';
import { Restricted } from '../../../../Auth/components/Restricted';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import regions from '../../../../../utils/se_regions';

interface Props {}

export const BasicInformationFrom = (props: Props) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    const { setMeta, updateMeta, setFormState, ...organisation } =
        useOrganisationFormState();

    const getMetaValue = (key: string) =>
        organisation?.organisation_meta?.find((m) => m.meta_key === key)
            ?.meta_value;

    const updateBasicInformation = useCallback(
        (active?, environmentData?, hasStock?) => {
            if (active !== undefined) {
                organisation.is_active = active;
            }
            if (environmentData !== undefined) {
                organisation.has_environment_data = environmentData;
            }
            if (hasStock !== undefined) {
                organisation.has_stock = hasStock;
            }
            axios
                .put<IOrganisationType>(
                    `/api/organisations/${organisation?.id}`,
                    {
                        ...organisation,
                        template_id: organisation.template,
                    }
                )
                .then((res) => {
                    if (res.data) {
                        setFormState((prev) => ({
                            ...prev,
                            ...res.data,
                        }));
                    }
                });
        },
        [organisation, setFormState]
    );

    const formControl = (
        key: string,
        type: string,
        maxLength: number,
        className?: string
    ) => {
        return (
            <Form.Control
                type={type}
                onChange={(e) => {
                    setMeta(key, e.target.value);
                }}
                className={className ?? ''}
                value={getMetaValue(key) || ''}
                maxLength={maxLength}
                onBlur={() => updateMeta(key)}
            />
        );
    };

    const formSelect = (key: string, options: string[]) => (
        <Form.Select
            value={getMetaValue(key) || ''}
            onChange={(e) => {
                setMeta(key, e.target.value);
                updateMeta(key);
            }}
        >
            <option value=''>-</option>
            {options.map((s) => (
                <option key={s} value={s}>
                    {s}
                </option>
            ))}
        </Form.Select>
    );

    return (
        <Row>
            <Col sm={12} xl={12}>
                <Form>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    {t('Organisation name') + ':'}
                                </Form.Label>
                                <Form.Control
                                    disabled={!user?.is_sys_admin}
                                    value={organisation?.name}
                                    onChange={(e) =>
                                        setFormState((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }))
                                    }
                                    onBlur={() => updateBasicInformation()}
                                />
                            </Form.Group>
                        </Col>
                        <Restricted toSysAdmin>
                            <Col
                                xs={12}
                                sm={6}
                                className='d-flex justify-content-around'
                            >
                                <Form.Group className='mb-3 d-flex flex-column align-items-center'>
                                    <Form.Label>{t('Active')}</Form.Label>
                                    <Form.Check
                                        style={{
                                            transform:
                                                'scale(1.5) translate(17%, 17%)',
                                            maxWidth: '100px', // needs to have a width to not scale out of containers
                                        }}
                                        type='switch'
                                        checked={organisation?.is_active}
                                        onChange={(e) => {
                                            setFormState((prev) => ({
                                                ...prev,
                                                is_active: e.target.checked,
                                            }));
                                            updateBasicInformation(
                                                e.target.checked
                                            );
                                        }}
                                    ></Form.Check>
                                </Form.Group>

                                <Form.Group className='mb-3 d-flex flex-column align-items-center'>
                                    <Form.Label>
                                        {t('Include environment data')}
                                    </Form.Label>
                                    <Form.Check
                                        style={{
                                            transform:
                                                'scale(1.5) translate(17%, 17%)',
                                            maxWidth: '100px', // needs to have a width to not scale out of containers
                                        }}
                                        type='switch'
                                        checked={
                                            organisation?.has_environment_data
                                        }
                                        onChange={(e) => {
                                            setFormState((prev) => ({
                                                ...prev,
                                                has_environment_data:
                                                    e.target.checked,
                                            }));
                                            updateBasicInformation(
                                                undefined,
                                                e.target.checked
                                            );
                                        }}
                                    ></Form.Check>
                                </Form.Group>

                                <Form.Group className='mb-3 d-flex flex-column align-items-center'>
                                    <Form.Label>{t('Stock items')}</Form.Label>
                                    <Form.Check
                                        style={{
                                            transform:
                                                'scale(1.5) translate(17%, 17%)',
                                            maxWidth: '100px', // needs to have a width to not scale out of containers
                                        }}
                                        type='switch'
                                        checked={organisation?.has_stock}
                                        onChange={(e) => {
                                            setFormState((prev) => ({
                                                ...prev,
                                                has_stock: e.target.checked,
                                            }));
                                            updateBasicInformation(
                                                undefined,
                                                undefined,
                                                e.target.checked
                                            );
                                        }}
                                    ></Form.Check>
                                </Form.Group>
                            </Col>
                        </Restricted>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Adress') + ' 1:'}</Form.Label>
                                {formControl('addressLine1', 'text', 100)}
                            </Form.Group>
                        </Col>
                        <Col sm={6} lg={3}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Region') + ':'}</Form.Label>
                                {formSelect('region', regions)}
                            </Form.Group>
                        </Col>
                        <Col sm={6} lg={3}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Country') + ':'}</Form.Label>
                                {formControl('country', 'text', 50)}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Address') + ' 2:'}</Form.Label>
                                {formControl('addressLine2', 'text', 100)}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    {t('Phone number') + ':'}
                                </Form.Label>
                                {formControl('phoneNumber', 'text', 50)}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} lg={3}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('ZIP code') + ':'}</Form.Label>
                                {formControl('postalNumber', 'text', 10)}
                            </Form.Group>
                        </Col>
                        <Col sm={6} lg={3}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('City') + ':'}</Form.Label>
                                {formControl('city', 'text', 50)}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Website') + ':'}</Form.Label>
                                {formControl('website', 'text', 100)}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
