import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { StockItemSelect } from './StockItemSelect';
import { Points } from '../../Points';
import { RichTextEditor } from '../../../../../components/RichTextEditor';
import { useProductFormState } from '../ProductFormState';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import { ICategory } from '../../../../../types/ICategory';
import IProduct from '../../../../../types/IProductType';
import ICriteriaType from '../../../../../types/ICriteriaType';
import IProductFieldType from '../../../../../types/IProductFieldType';
import IProductMetaType from '../../../../../types/IProductMetaType';
import { ReactComponent as UNUSEDIcon } from '../../../../../assets/icones/Dacke_oanvand.svg';
import i18next from 'i18next';

const isValidDate = function (date: string): boolean {
    return new Date(date).toString() !== 'Invalid Date';
};

interface Props {
    customProductFields: IProductFieldType[];
    categories: ICategory[];
    showCategorySelect: () => void;
}

export const BasicInformationFrom = (props: Props) => {
    const { projectId, productId } = useParams();
    const { organisation } = useAuth();
    const { categories, showCategorySelect } = props;

    const { t } = useTranslation();

    const {
        setBasicInformation,
        setPoints,
        setFormState,
        setProductMeta,
        ...basicInformation
    } = useProductFormState();

    const updateProduct = useCallback(
        (key?, value?) => {
            // Only if is a alredy existing product
            if (productId) {
                const productData = {
                    project_id: basicInformation.project_id,
                    category_id: basicInformation.category_id,

                    title: basicInformation.title,
                    description: basicInformation.description,
                    internal_description: basicInformation.internal_description,
                    manufacture_code: basicInformation.manufacture_code,
                    model_code: basicInformation.model_code,
                    year: basicInformation.year,

                    search_words: basicInformation.search_words,
                    condition_points: basicInformation.condition_points,
                    function_points: basicInformation.function_points,
                    unused: basicInformation.unused,

                    package_quantity: basicInformation.package_quantity,
                    package_unit: basicInformation.package_unit,
                    internal_value: basicInformation.internal_value,
                    market_price: basicInformation.market_price,
                    sales_price: basicInformation.sales_price,

                    weight: basicInformation.weight,
                    weight_unit: basicInformation.weight_unit,

                    co2: basicInformation.co2,
                } as IProduct;

                if (key === 'year') {
                    productData.year = value;
                } else if (key === 'function_points') {
                    productData.function_points = value;
                } else if (key === 'condition_points') {
                    productData.condition_points = value;
                } else if (key === 'unused') {
                    productData.unused = value;
                } else if (key === 'category_id') {
                    productData.category_id = value;
                } else if (key === 'stock_type') {
                    productData.stock_type = value;
                }

                const put = axios.put(
                    `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}`,
                    productData
                );

                put.then((res) => {
                    if (res.data?.data) {
                        const product = res.data?.data;

                        setFormState((prev) => ({
                            ...prev,
                            category_id: product?.category_id,
                            title: product?.title,
                            description: product?.description,
                            internal_description: product?.internal_description,
                            manufacture_code: product?.manufacture_code,
                            model_code: product?.model_code,
                            year:
                                product?.year && isValidDate(product?.year)
                                    ? product?.year
                                    : null,

                            package_quantity: product?.package_quantity,
                            stock_type: product?.stock_type,
                            package_unit: product?.package_unit,
                            internal_value: product?.internal_value,
                            market_price: product?.market_price,
                            sales_price: product?.sales_price,
                        }));
                    }
                });
            }
        },
        [
            basicInformation.category_id,
            basicInformation.description,
            basicInformation.internal_description,
            basicInformation.internal_value,
            basicInformation.manufacture_code,
            basicInformation.model_code,
            basicInformation.package_quantity,
            basicInformation.package_unit,
            basicInformation.market_price,
            basicInformation.project_id,
            basicInformation.sales_price,
            basicInformation.search_words,
            basicInformation.function_points,
            basicInformation.condition_points,
            basicInformation.title,
            basicInformation.weight,
            basicInformation.weight_unit,
            basicInformation.year,
            basicInformation.unused,
            basicInformation.co2,
            organisation?.id,
            productId,
            projectId,
            setFormState,
        ]
    );

    useEffect(() => {
        if (!basicInformation?.category_id) return;
        updateProduct('category_id', basicInformation.category_id);
    }, [basicInformation?.category_id]); // eslint-disable-line react-hooks/exhaustive-deps

    const [criterias, setCriterias] = useState<ICriteriaType[]>([]);
    useEffect(() => {
        axios
            .get(`/api/organisations/${organisation?.id}/organisationcriterias`)
            .then((res) =>
                setCriterias(
                    res.data.data.map(
                        (oc: { criteria: ICriteriaType }) => oc.criteria
                    )
                )
            );
    }, [organisation?.id]);

    const updatePointes = useCallback(
        (criteria: ICriteriaType, value) => {
            // get the points
            const points = basicInformation.points;
            // find point by name
            const point = points.find(
                (point) => point.criteria_id === criteria.id
            );

            if (productId) {
                if (!point) {
                    // add new point
                    const post = axios.post(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productpoints`,
                        { criteria_id: criteria.id, points: value }
                    );

                    post.then((res) => {
                        if (res.data?.data) {
                            setFormState((prev) => ({
                                ...prev,
                                points: [...points, res.data?.data],
                            }));
                        }
                    });
                } else {
                    // update point
                    if (point) {
                        const put = axios.put(
                            `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productpoints/${point.id}`,
                            { ...point, points: value }
                        );

                        put.then((res) => {
                            if (res.data?.data) {
                                setPoints(criteria, res.data?.data.points);
                            }
                        });
                    }
                }
            } else {
                if (!point) {
                    setFormState((prev) => ({
                        ...prev,
                        points: [
                            ...points,
                            {
                                criteria_id: criteria.id!,
                                points: value,
                                product_id: 0,
                            },
                        ],
                    }));
                } else {
                    setPoints(criteria, value);
                }
            }
        },
        [
            basicInformation.points,
            organisation?.id,
            productId,
            projectId,
            setFormState,
            setPoints,
        ]
    );

    const updateProductMeta = useCallback(
        (meta_type_id: number) => {
            if (!productId)
                // For create product form, they post this in a big request
                return;
            const meta = basicInformation.product_meta?.find(
                (m: IProductMetaType) => m.meta_type_id === meta_type_id
            );
            if (meta && meta.id) {
                axios
                    .put(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productmetas/${meta.id}`,
                        meta
                    )
                    .then((res) => {
                        let newMeta: IProductMetaType = res.data.data;
                        setProductMeta(
                            newMeta.meta_type_id,
                            newMeta.meta_value,
                            newMeta.id
                        );
                    });
            } else {
                axios
                    .post(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productmetas`,
                        meta
                    )
                    .then((res) => {
                        let newMeta: IProductMetaType = res.data.data;
                        setProductMeta(
                            newMeta.meta_type_id,
                            newMeta.meta_value,
                            newMeta.id
                        );
                    });
            }
        },
        [
            basicInformation.product_meta,
            organisation?.id,
            productId,
            projectId,
            setProductMeta,
        ]
    );

    const favoriteProductFields = () => {
        return props.customProductFields.map(
            (p, i) =>
                p.favorite && (
                    <Form.Group className='mb-3' key={i}>
                        <Form.Label>{p.meta_type.name}</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder={p.meta_type.name}
                            onChange={(e) => {
                                setProductMeta(p.meta_type.id, e.target.value);
                            }}
                            onBlur={() => updateProductMeta(p.meta_type.id)}
                            value={
                                basicInformation.product_meta?.find(
                                    (m: IProductMetaType) =>
                                        m.meta_type_id === p.meta_type.id
                                )?.meta_value || ''
                            }
                        />
                    </Form.Group>
                )
        );
    };

    const recursiveGetCategories = useCallback(
        (list: ICategory[], category_id: number): ICategory[] => {
            if (!list.length) return [];
            const a = list.find((x) => x.id === category_id);
            if (a) return [a];

            const b = list
                .map((x) => {
                    const c = recursiveGetCategories(x.children, category_id);
                    if (c.length) return [x, ...c];
                    return [];
                })
                .filter((x) => x?.length);

            return b.reduce(
                (acc, x) => (x ? acc.concat(x) : acc),
                [] as ICategory[]
            );
        },
        []
    );

    const categoryList = useMemo(
        () => recursiveGetCategories(categories, basicInformation.category_id),
        [recursiveGetCategories, categories, basicInformation.category_id]
    );

    return (
        <Row>
            <Col sm={12} xl={10}>
                <Form>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Product-ID')}</Form.Label>
                                <Form.Control
                                    type='text'
                                    readOnly
                                    placeholder='*****-*****-*****'
                                    onChange={(e) => {
                                        setBasicInformation(
                                            'product_id',
                                            e.target.value
                                        );
                                    }}
                                    value={basicInformation?.barcode || ''}
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Category')}</Form.Label>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <span>
                                        {categoryList
                                            .map((x) => x.name)
                                            .join(' > ')}
                                    </span>
                                    <div className='ps-1'>
                                        <Button
                                            size='sm'
                                            className='d-sm-none'
                                            onClick={showCategorySelect}
                                        >
                                            {t('Edit')}
                                        </Button>
                                        <Button
                                            className='d-none d-sm-block'
                                            onClick={showCategorySelect}
                                        >
                                            {t('Edit')}
                                        </Button>
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    {t(
                                        'Product title {{inputLength}}/50 characters',
                                        {
                                            inputLength:
                                                basicInformation?.title
                                                    ?.length || 0,
                                        }
                                    )}
                                </Form.Label>
                                {/* TODO: Wants auto-complate of product titels, maby a auto-complate dropdown? */}
                                <Form.Control
                                    type='text'
                                    placeholder={t('Product title')}
                                    onChange={(e) => {
                                        setBasicInformation(
                                            'title',
                                            e.target.value
                                        );
                                    }}
                                    value={basicInformation?.title || ''}
                                    maxLength={50}
                                    onBlur={updateProduct}
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Description')}</Form.Label>
                                <RichTextEditor
                                    value={basicInformation?.description || ''}
                                    onChange={(e) =>
                                        setBasicInformation('description', e)
                                    }
                                    onBlur={updateProduct}
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    {t('Internal Description')}
                                </Form.Label>
                                <RichTextEditor
                                    value={
                                        basicInformation?.internal_description ||
                                        ''
                                    }
                                    onChange={(e) =>
                                        setBasicInformation(
                                            'internal_description',
                                            e
                                        )
                                    }
                                    onBlur={updateProduct}
                                />
                            </Form.Group>
                            <div className='d-sm-none'>
                                {favoriteProductFields()}
                            </div>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Search words')}
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder={
                                                t('Search word') +
                                                ' 1, ' +
                                                t('Search word') +
                                                ' 2, ...'
                                            }
                                            onChange={(e) => {
                                                setBasicInformation(
                                                    'search_words',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                basicInformation?.search_words ||
                                                ''
                                            }
                                            onBlur={updateProduct}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Manufacturer code')}
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder={t('Manufacturer code')}
                                            onChange={(e) => {
                                                setBasicInformation(
                                                    'manufacture_code',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                basicInformation?.manufacture_code ||
                                                ''
                                            }
                                            onBlur={updateProduct}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Model code')}
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder={t('Model code')}
                                            onChange={(e) => {
                                                setBasicInformation(
                                                    'model_code',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                basicInformation?.model_code ||
                                                ''
                                            }
                                            onBlur={updateProduct}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Product year')}
                                        </Form.Label>

                                        <DatePicker
                                            className='form-control'
                                            id='year'
                                            name='year'
                                            placeholderText={t('Product year')}
                                            isClearable
                                            onChange={(date: Date) => {
                                                const value = date
                                                    ? new Date(
                                                          date.getFullYear(),
                                                          1
                                                      ).toISOString()
                                                    : null;

                                                setBasicInformation(
                                                    'year',
                                                    value
                                                );

                                                updateProduct('year', value);
                                            }}
                                            selected={
                                                basicInformation?.year
                                                    ? new Date(
                                                          basicInformation?.year
                                                      )
                                                    : null
                                            }
                                            showYearPicker
                                            dateFormat='yyyy'
                                            locale={i18next.language}
                                            maxDate={new Date()}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='d-none d-sm-block'>
                                            {t('Package quantity')}
                                        </Form.Label>
                                        <Form.Label className='d-sm-none'>
                                            {t('Pack. qty.')}
                                        </Form.Label>
                                        {/* TODO: Wants auto-complate of product titels, maby a auto-complate dropdown? */}
                                        <Form.Control
                                            type='number'
                                            placeholder={t('Package quantity')}
                                            onChange={(e) => {
                                                e.currentTarget.focus();
                                                setBasicInformation(
                                                    'package_quantity',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                basicInformation?.package_quantity ||
                                                ''
                                            }
                                            onBlur={updateProduct}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {organisation?.has_stock && (
                                <Row className='mb-3'>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                {t('Stock type')}
                                            </Form.Label>
                                            <StockItemSelect
                                                value={
                                                    basicInformation?.stock_type ||
                                                    ''
                                                }
                                                onChange={(e: any) =>
                                                    updateProduct(
                                                        'stock_type',
                                                        e.target.value || ''
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col xs={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('CO2 level')}
                                        </Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder={t('CO2 level')}
                                            value={basicInformation?.co2 || ''}
                                            onChange={(e) => {
                                                e.currentTarget.focus();
                                                setBasicInformation(
                                                    'co2',
                                                    parseFloat(e.target.value)
                                                );
                                            }}
                                            onBlur={updateProduct}
                                            min={0}
                                            step={0.1}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Internal value')}
                                        </Form.Label>
                                        {/* TODO: Wants auto-complate of product titels, maby a auto-complate dropdown? */}
                                        <Form.Control
                                            type='number'
                                            placeholder={t('Internal value')}
                                            onChange={(e) => {
                                                e.currentTarget.focus();
                                                setBasicInformation(
                                                    'internal_value',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                basicInformation?.internal_value ||
                                                ''
                                            }
                                            onBlur={updateProduct}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Market price')}
                                        </Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder={t('Price')}
                                            onChange={(e) => {
                                                e.currentTarget.focus();
                                                setBasicInformation(
                                                    'market_price',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                basicInformation?.market_price ||
                                                ''
                                            }
                                            onBlur={updateProduct}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('Sales price')}
                                        </Form.Label>
                                        {/* TODO: Wants auto-complate of product titels, maby a auto-complate dropdown? */}
                                        <Form.Control
                                            type='number'
                                            placeholder={t('Sales price')}
                                            onChange={(e) => {
                                                e.currentTarget.focus();
                                                setBasicInformation(
                                                    'sales_price',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                basicInformation?.sales_price ||
                                                ''
                                            }
                                            onBlur={updateProduct}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='d-none d-lg-block'>
                            {favoriteProductFields()}
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col>
                <Row>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            {t('Unused')}
                            <UNUSEDIcon width='16' className='ms-2 pb-1' />
                        </Form.Label>
                        <br />
                        <Form.Check
                            checked={!!basicInformation?.unused}
                            style={{
                                transform: 'scale(1.5) translate(17%, 17%)',
                                display: 'inline-block',
                            }}
                            type='switch'
                            onChange={(e) => {
                                setFormState((prev) => ({
                                    ...prev,
                                    unused: e.target.checked,
                                }));
                                updateProduct('unused', e.target.checked);
                            }}
                        ></Form.Check>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>{t('Condition')}</Form.Label>
                        <div>
                            <Points
                                value={basicInformation?.condition_points || 0}
                                criteria={{
                                    title: 'condition',
                                }}
                                onChange={(criteria, value) => {
                                    setBasicInformation(
                                        'condition_points',
                                        value
                                    );
                                    updateProduct('condition_points', value);
                                }}
                            ></Points>
                        </div>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>{t('Function')}</Form.Label>
                        <div>
                            <Points
                                value={basicInformation?.function_points || 0}
                                criteria={{
                                    title: 'function',
                                }}
                                onChange={(criteria, value) => {
                                    setBasicInformation(
                                        'function_points',
                                        value
                                    );
                                    updateProduct('function_points', value);
                                }}
                            ></Points>
                        </div>
                    </Form.Group>
                    {criterias.map((c, i) => (
                        <Form.Group className='mb-3' key={i}>
                            <Form.Label>{c.title}</Form.Label>
                            <div>
                                <Points
                                    value={
                                        basicInformation.points.find(
                                            (p) => p.criteria_id === c.id
                                        )?.points || 0
                                    }
                                    criteria={c}
                                    onChange={(criteria, value) =>
                                        updatePointes(c, value)
                                    }
                                ></Points>
                            </div>
                        </Form.Group>
                    ))}
                </Row>
            </Col>
        </Row>
    );
};
