import { useState } from 'react';
interface Modalias<Type> {
    closeModal: () => void;
    showModal: () => void;
    show: boolean;
    item?: Type;
    setItem: (item: Type) => void;
}
interface Props {
    onClose?: (needRefetch?: boolean) => void;
}

export function useHandleModal<Type>(props?: Props): Modalias<Type> {
    const [show, setShow] = useState(false);
    const [item, setItem] = useState<Type>();
    const closeModal = (needRefetch?: boolean) => {
        setShow(false);
        props?.onClose && props.onClose(needRefetch);
    };
    const showModal = () => setShow(true);

    return {
        closeModal: closeModal,
        showModal: showModal,
        show: show,
        item: item,
        setItem: setItem,
    };
}
