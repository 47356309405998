import React from 'react';
import { Col, Container, Row, Stack, Table } from 'react-bootstrap';
import { TableOfContents, Section } from './components/TableOfContents';
import { ReactComponent as BoxesIcon } from '../../../assets/icones/boxes.svg';
import { Page } from '../../../components/Layout/Page';
import { SEO } from '../../../components/SEO';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AboutUsPage.scss';

const Services: React.FC<{ id: string; className: string }> = ({
    id,
    className,
}) => (
    <Row id={id} className={className}>
        <Col
            xs
            lg={6}
            className='d-none d-md-flex align-items-center justify-content-center'
        >
            <BoxesIcon />
        </Col>
        <Col xs lg={6}>
            <h2 style={{ marginBottom: 24 }}>
                <strong>Tjänster</strong>
            </h2>
            <ul>
                <li>
                    <h4 className='fw-light'>Inventeringsverktyg</h4>
                </li>
                <li>
                    <h4 className='fw-light'>
                        Marknadsplats för professionellt återbruk
                    </h4>
                </li>
                <li>
                    <h4 className='fw-light'>Snabbregistrering</h4>
                </li>
                <li>
                    <h4 className='fw-light'>Lagersystem</h4>
                </li>
                <li>
                    <h4 className='fw-light'>Dashboard</h4>
                </li>
            </ul>
        </Col>
    </Row>
);

const ForMoreInformation = () => (
    <div
        className='text-center d-flex flex-column align-items-center justify-content-center'
        style={{ background: '#003B4D0A', height: 200 }}
    >
        <h2
            style={{
                marginBottom: 24,
                paddingLeft: 74,
                paddingRight: 74,
            }}
        >
            <strong>
                För mer information om våra tjänster, innehåller i paketet,
                avtal och priser
            </strong>
        </h2>
        <Link to='/contact' className='btn btn-primary'>
            Kontakta oss
        </Link>
    </div>
);

const ByUsersForUsers: React.FC<{ id: string }> = ({ id }) => (
    <Row id={id}>
        <h2 style={{ marginBottom: 24 }}>
            <strong>Utvecklat av användare – för användare</strong>
        </h2>

        <p>
            Har man som vi gjort över 1 000 återbruksinventeringar och jobbat
            med förmedling av återbrukade byggprodukter i snart 20 år så vet man
            hur ett inventeringsverktyg ska fungera ute på fältet. Därför byggde
            vi ett eget system – precis som vi vill ha det när vi gör våra
            inventeringar.{' '}
        </p>
        <p>
            Med Dacke.online får du ett inventeringsverktyg, lagersystem och
            marknadsplats på samma ställe. Vi erbjuder en helhet – tillsammans
            med Dacke.online får du tillgång till vår kunskap, erfarenhet,
            återbruksinventeringar och förmedling av återbrukade produkter.
        </p>

        <ForMoreInformation />

        <Col xs md={6}>
            <p style={{ marginBottom: 24 }}>
                <strong>
                    Det är helheten som gör skillnaden och skapar resultatet -
                    att gå från ett linjärt – till cirkulärt byggande genom:
                </strong>
            </p>
        </Col>
        <ul>
            <li>
                att vi hjälper er komma igång med inventeringen av återbrukbara
                produkter
            </li>
            <li>
                att vi utbildar er att själva inventera återbrukbara produkter
            </li>
            <li>
                att vi hjälper er förmedla återbrukade produkter – antingen
                internt i den egna organisationen eller till externa aktörer (ni
                slipper sköta försäljningen själva).
            </li>
            <li>
                att vi hjälper er med demonteringsspecialist och kontakter för
                demontering, frakt och logistik.
            </li>
        </ul>
        <p>
            Med vår erfarenhet vet vi att ett projekt kan se väldigt olika ut
            och ha helt olika förutsättningar. Det kan också vara svårt att
            exakt definiera vad ett projekt är. Därför har man, oavsett vilket
            paket man väljer, obegränsat antal projekt att jobba med i systemet.
            Det som styr är ditt behov och hur många ni är som behöver tillgång
            till systemet. Det finns två olika paket. Ett Baspaket och ett
            Premiumpaket.{' '}
            <Link to='/contact' title='Kontakta oss'>
                Kontakta oss
            </Link>{' '}
            för mer information om våra tjänster, innehållet i paketen, avtal
            och priser.{' '}
        </p>
    </Row>
);

const PackageInformation: React.FC<{ id: string }> = ({ id }) => {
    const basColor = '#E4EAEC';
    const medColor = '#F9F1E6';
    const preColor = '#ECE6F9';
    const yes = '✔️';

    const packets = [
        ['Dacke.Online, inkl. 1 användare', yes, yes, yes],
        ['Pris/användare 199 kr/mån', yes, yes, yes],
        ['Obegränsat antal projekt', yes, yes, yes],
        ['Obebränsat antal projekt', yes, yes, yes],
        ['Obegränsat antal artiklar', yes, yes, yes],
        [
            <div>
                <h5>Support för:</h5>
                <h6>- användare</h6>
                <h6>- förmedling</h6>
                <h6>- teknik</h6>
            </div>,
            yes,
            yes,
            yes,
        ],
        ['Återbrukssupport', '-', '5 tim/mån', '10 tim/mån'],
        ['Återbruksinventering', '-', '-', '1 dag/mån'],
        ['Pris/abonnemang/mån', 'På begäran', 'På begäran', 'På begäran'],
    ];
    return (
        <Row id={id}>
            <h2 style={{ marginBottom: 24 }}>
                <strong>Paketinformation</strong>
            </h2>

            <Row className='align-items-center'>
                <Col xs md={3}>
                    <p>
                        <strong>Innehåll</strong>
                    </p>
                </Col>
                <Col xs md={3} className='text-center'>
                    <p
                        className='rounded p-1'
                        style={{
                            background: basColor,
                            border: '1px solid #003B4D',
                        }}
                    >
                        <strong>Bas</strong>
                    </p>
                </Col>
                <Col xs md={3} className='text-center'>
                    <p
                        className='rounded p-1'
                        style={{
                            background: medColor,
                            border: '1px solid #FF9300',
                        }}
                    >
                        <strong>Medium</strong>
                    </p>
                </Col>
                <Col xs md={3} className='text-center'>
                    <p
                        className='rounded p-1'
                        style={{
                            background: preColor,
                            border: '1px solid #6610F2',
                        }}
                    >
                        <strong>Premium</strong>
                    </p>
                </Col>
            </Row>
            <div
                style={{
                    overflow: 'hidden',
                    border: '1px solid black',
                    borderRadius: '0.5rem',
                }}
            >
                <Table
                    bordered
                    hover
                    style={{
                        tableLayout: 'fixed',
                        marginBottom: 0,
                        borderColor: 'black',
                    }}
                >
                    <tbody>
                        {packets.map(([x1, x2, x3, x4]) => (
                            <tr>
                                <td style={{ borderLeftWidth: 0 }}>{x1}</td>
                                <td
                                    className='text-center'
                                    style={{ background: basColor }}
                                >
                                    {x2}
                                </td>
                                <td
                                    className='text-center'
                                    style={{ background: medColor }}
                                >
                                    {x3}
                                </td>
                                <td
                                    className='text-center'
                                    style={{
                                        background: preColor,
                                        borderRightWidth: 0,
                                    }}
                                >
                                    {x4}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Row>
    );
};

const ContactUsFooter = () => (
    <div
        id='wrapper'
        className='text-white'
        style={{
            backgroundImage:
                "url('https://www.kompanjonen.se/wp-content/uploads/2022/02/pexels-pixabay-271667-scaled.jpg')",
            backgroundSize: 'cover',
            padding: '100px auto',
            marginTop: 112,
            marginLeft: -12,
            marginRight: -12,
            height: 400,
        }}
    >
        <Container
            fluid='sm'
            className='text-center d-flex flex-column align-items-center'
            style={{ margin: '116px auto', marginTop: 117 }}
        >
            <h2 style={{ maxWidth: 652, fontSize: 32, marginBottom: 42 }}>
                <strong>
                    För mer information om våra tjänster, innehållet i paketen,
                    avtal och priser.
                </strong>
            </h2>
            <Link to='/contact' className='btn btn-secondary text-black'>
                Kontakta oss
            </Link>
        </Container>
    </div>
);

interface Props {}

export const AboutUsPage = (props: Props) => {
    const { t } = useTranslation();

    const sections: Section[] = [
        { id: '#tjanster', title: 'Tjänster' },
        {
            id: '#av-anvandare-for-anvandare',
            title: 'Av användare för användare',
        },
        { id: '#paketinformation', title: 'Paketinformation' },
    ];

    return (
        <Page className='mb-0'>
            <Container>
                <Row className='jspace-between'>
                    <Col xxl={2} className='mt-4 my-4 d-none d-xxl-block'>
                        <TableOfContents
                            sections={sections}
                            className='position-fixed top-1 fw-bold'
                        />
                    </Col>
                    <Col xxl={1} className='d-none d-xxl-block' />

                    <Col xxl={9}>
                        <Stack style={{ gap: 42 }}>
                            <div>
                                <SEO
                                    title={t('About Dacke.online')}
                                    className='fw-light mb-3'
                                ></SEO>
                                <h4 className='fw-light'>
                                    Dacke.Online är ett lättarbetat digitalt
                                    hållbarhetsverktyg för inventering, köp och
                                    sälj av återbrukade produkter av hög
                                    kvalitet.
                                </h4>
                            </div>

                            <Services id='tjanster' className='py-4' />

                            <ByUsersForUsers id='av-anvandare-for-anvandare' />

                            <PackageInformation id='paketinformation' />
                        </Stack>
                    </Col>
                </Row>
            </Container>

            <ContactUsFooter />
        </Page>
    );
};
