import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectFormState } from '../ProjectFormState';

interface Props {}

export const ContactPersonPreview = (props: Props) => {
    const { t } = useTranslation();
    const project = useProjectFormState();

    const contact =
        project?.contacts.find((x) => x.id === project?.primary_contact) ||
        project?.contacts?.[0];

    return <div>{contact?.name || t('Please fill in')}</div>;
};
