import React, { useState } from 'react';
import IProduct from '../../../../types/IProductType';
import { ProductDuplicateModal } from './ProductDuplicateModal';
import { ProductExcelExportModal } from './ProductExcelExportModal';
import { ProductMoveBetweenProjectsModal } from './ProductMoveBetweenProjectsModal';
import { ProductPDFExportModal } from './ProductPDFExportModal';
import { ProductStatusModal } from './ProductStatusModal';
import { ProductViewingStatusModal } from './ProductViewingStatusModal';

export const ProductModalsContext = React.createContext<any>({} as any);

export const ProductModals: React.FC<any> = ({ children, refetch }) => {
    const [showStatusModal, setShowStatusModal] = useState<IProduct[] | null>(
        null
    );
    const [showViewingStatusModal, setViewingShowStatusModal] = useState<
        IProduct[] | null
    >(null);

    const [showMoveBetweenProjectsModal, setShowMoveBetweenProjectsModal] =
        useState<IProduct[] | null>(null);

    const [showExcelExportProductsModal, setShowExcelExportProductsModal] =
        useState<IProduct[] | null>(null);

    const [showPDFExportProductsModal, setShowPDFExportProductsModal] =
        useState<IProduct[] | null>(null);

    const [showDuplicateModal, setShowDuplicateModal] = useState<
        IProduct[] | null
    >(null);

    return (
        <ProductModalsContext.Provider
            value={{
                setShowStatusModal,
                showStatusModal,
                setViewingShowStatusModal,
                showViewingStatusModal,
                setShowMoveBetweenProjectsModal,
                showMoveBetweenProjectsModal,
                setShowDuplicateModal,
                showDuplicateModal,
                setShowExcelExportProductsModal,
                showExcelExportProductsModal,
                setShowPDFExportProductsModal,
                showPDFExportProductsModal,
            }}
        >
            <ProductStatusModal
                products={showStatusModal}
                onClose={() => {
                    setShowStatusModal(null);
                }}
                onSave={() => {}}
            ></ProductStatusModal>
            <ProductViewingStatusModal
                products={showViewingStatusModal}
                onClose={() => {
                    setViewingShowStatusModal(null);
                }}
                onSave={() => {}}
            ></ProductViewingStatusModal>
            <ProductMoveBetweenProjectsModal
                products={showMoveBetweenProjectsModal}
                onClose={() => {
                    setShowMoveBetweenProjectsModal(null);
                    refetch && refetch();
                }}
                onSave={() => {}}
            ></ProductMoveBetweenProjectsModal>
            <ProductExcelExportModal
                products={showExcelExportProductsModal}
                onClose={() => {
                    setShowExcelExportProductsModal(null);
                }}
                onSave={() => {}}
            ></ProductExcelExportModal>
            <ProductPDFExportModal
                products={showPDFExportProductsModal}
                onClose={() => {
                    setShowPDFExportProductsModal(null);
                }}
                onSave={() => {}}
            ></ProductPDFExportModal>
            <ProductDuplicateModal
                products={showDuplicateModal}
                onClose={() => {
                    setShowDuplicateModal(null);
                    refetch && refetch();
                }}
                onSave={() => {}}
            ></ProductDuplicateModal>
            {children}
        </ProductModalsContext.Provider>
    );
};

export const useProductModals = () => React.useContext(ProductModalsContext);
