import axios from 'axios';
import React, { useCallback, useState } from 'react';
import IOrganisationMetaType from '../../../../types/IOrganisationMetaType';
import IOrganisation from '../../../../types/IOrganisationType';

export interface IOrganisationFormState extends IOrganisation {
    organisationLogos: any[] | never[];
}

interface IOrganisationFormStateContext extends IOrganisationFormState {
    setFormState: (
        callBack: (prev: IOrganisationFormState) => IOrganisationFormState
    ) => void;
    setMeta: (key: string, value: any) => void;
    updateMeta: (key: string) => void;
    setOrganisationLogos: (callBack: (prev: any[]) => any[]) => void;
}

export const OrganisationFormStateContext =
    React.createContext<IOrganisationFormStateContext>(
        {} as IOrganisationFormStateContext
    );

interface OrganisationFormStateProps {
    initialState: IOrganisationFormState;
}

export const OrganisationFormState: React.FC<OrganisationFormStateProps> = ({
    initialState,
    children,
}) => {
    const [state, setFormState] =
        useState<IOrganisationFormState>(initialState);

    // Update metafield on clientside
    const setMeta = useCallback((key: string, value: any, id?: number) => {
        setFormState((prev) => {
            let metas = prev.organisation_meta;
            let meta = metas.find((m) => m.meta_key === key);
            if (meta) {
                meta.id = id ?? meta.id;
                meta.meta_value = value;
            } else metas.push({ id: -1, meta_key: key, meta_value: value });

            return { ...prev, organisation_meta: metas };
        });
    }, []);

    // Tell backend to update (Or create) a metafield (Using data from the client-side state of metafields)
    const updateMeta = useCallback(
        (key: string) => {
            // Update formstate with response from backend
            const setUpdatedMeta = (updatedMeta: IOrganisationMetaType) =>
                updatedMeta &&
                setMeta(
                    updatedMeta.meta_key,
                    updatedMeta.meta_value,
                    updatedMeta.id
                );

            let meta = state.organisation_meta.find((m) => m.meta_key === key);
            if (meta) {
                if (meta.id < 0) {
                    // Organisation didn't have metafield before
                    const post = axios.post(
                        `/api/organisations/${state.id}/organisationmetas`,
                        meta
                    );
                    post.then((res) => setUpdatedMeta(res.data?.data));
                } else {
                    // Update old Organisation metafield

                    // Backend doesnt allow to erase metaValue if already created
                    // So let's 'clear' with a -
                    if (meta.meta_value === '') meta.meta_value = '-';

                    const put = axios.put(
                        `/api/organisations/${state.id}/organisationmetas/${meta.id}`,
                        meta
                    );
                    put.then((res) => setUpdatedMeta(res.data?.data));
                }
            }
        },
        [state, setMeta]
    );

    const setOrganisationLogos = useCallback(
        (callBack: (prev: any[]) => any[]) => {
            setFormState((prev) => {
                return {
                    ...prev,
                    organisationLogos: callBack(prev.organisationLogos),
                };
            });
        },
        []
    );

    return (
        <OrganisationFormStateContext.Provider
            value={{
                ...state,
                setFormState,
                setMeta,
                updateMeta,
                setOrganisationLogos,
            }}
        >
            {children}
        </OrganisationFormStateContext.Provider>
    );
};

export const useOrganisationFormState = () =>
    React.useContext(OrganisationFormStateContext);
