import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';

import { CoverImage } from '../../../../components/CoverImage';
import { Restricted } from '../../../Auth/components/Restricted';
import { PageCountPicker } from '../../../../components/PageCountPicker';
import { PAGE_SIZE_CHANGED } from '../../../../hooks/useTableReducer';

import { ReactComponent as STARTDATEIcon } from '../../../../assets/icones/Dacke_startdatum.svg';
import { ReactComponent as ENDDATEIcon } from '../../../../assets/icones/Dacke_slutdatum.svg';
import { tryFormatDefaultDate } from '../../../../utils/helper';

import { IImageMediaGroup } from '../../../../types/IMediaType';
import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import IOrganisation from '../../../../types/IOrganisationType';
import IProjectType from '../../../../types/IProjectType';

interface ProjectThumnailProps {
    organisation: ResourcePermissionsType<IOrganisation> | null;
    project: ResourcePermissionsType<IProjectType>;
}

const ProjectThumnail: React.FC<ProjectThumnailProps> = ({
    organisation,
    project,
}) => {
    const { t } = useTranslation();

    return (
        <Card className='shadow'>
            <Row className='g-0 p-3'>
                <Col xs='5' md='12' className='pe-2 p-md-0'>
                    <CoverImage
                        maxSize
                        images={project?.project_images as IImageMediaGroup}
                        lightbox
                    />
                </Col>

                <Col className='d-flex flex-column'>
                    <Card.Body className='d-flex flex-column flex-fill p-0'>
                        <div className='d-flex flex-column flex-fill justify-content-between'>
                            <div className='d-flex flex-column gap-1'>
                                <Card.Title className='my-2 fs-5 fw-bold'>
                                    {project.title}
                                </Card.Title>
                                <Card.Subtitle>
                                    {t('Projectnumber')}: {project.id}
                                </Card.Subtitle>
                                <div>
                                    <span className='pe-1'>{t('Status')}:</span>
                                    <span className='pe-1'>
                                        {project.is_active
                                            ? t('Active')
                                            : t('Inactive')}
                                    </span>
                                    {/* green dot */}
                                    <span
                                        style={{
                                            backgroundColor: project.is_active
                                                ? 'var(--bs-success)'
                                                : 'var(--bs-danger)',
                                            borderRadius: '50%',
                                            width: '13px',
                                            height: '13px',
                                            marginTop: '2px',
                                            position: 'absolute',
                                            border: '2px solid #fff',
                                            boxShadow:
                                                '3px 3px 4px rgba(0,0,0,0.2)',
                                        }}
                                    ></span>
                                </div>

                                <div className='d-flex justify-content-between flex-row'>
                                    <span>
                                        <STARTDATEIcon
                                            width='13'
                                            className='me-1'
                                        />
                                        <span>
                                            {tryFormatDefaultDate(
                                                project.project_start
                                            )}
                                        </span>
                                    </span>
                                    <span>
                                        <ENDDATEIcon
                                            width='12'
                                            className='me-1'
                                        />
                                        <span>
                                            {tryFormatDefaultDate(
                                                project.project_finish
                                            )}
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div className='d-flex mt-3 flex-column gap-1'>
                                <Restricted
                                    to='view single project'
                                    resource={project}
                                >
                                    <Link
                                        className='btn btn-primary btn-sm'
                                        to={`${project.id}`}
                                    >
                                        {t('Show project')}
                                    </Link>
                                </Restricted>
                                <Restricted
                                    to='view any product'
                                    resource={project}
                                >
                                    <Link
                                        className='btn btn-secondary btn-sm'
                                        to={`${project.id}/products`}
                                    >
                                        {t('View products')}
                                    </Link>
                                </Restricted>
                                <Restricted
                                    to='view any product'
                                    resource={project}
                                >
                                    <Link
                                        className='btn btn-secondary btn-sm'
                                        to={`${project.id}/product-items`}
                                    >
                                        {t('View product placements')}
                                    </Link>
                                </Restricted>
                                {organisation?.has_environment_data && (
                                    <Restricted
                                        to='view any product'
                                        resource={project}
                                    >
                                        <Link
                                            className='btn btn-success text-white btn-sm'
                                            to={`${project.id}/product-environment-data`}
                                        >
                                            {t('View environment data')}
                                        </Link>
                                    </Restricted>
                                )}
                            </div>
                        </div>
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
};

interface Props {
    projects: ResourcePermissionsType<IProjectType>[];
    organisation: ResourcePermissionsType<IOrganisation> | null;

    queryPageSize: number;
    totalCount: number;
    dispatch: any;
}

const CardView = ({
    projects,
    organisation,
    queryPageSize,
    totalCount,
    dispatch,
}: Props) => {
    return (
        <>
            <Row xs={1} md={2} xl={3} xxl={4} className='g-4'>
                {projects?.map((project) => {
                    return (
                        <Col key={project.id}>
                            <ProjectThumnail
                                project={project}
                                organisation={organisation}
                            ></ProjectThumnail>
                        </Col>
                    );
                })}
            </Row>

            <Row>
                <Col className='mt-4'>
                    <div className='d-flex flex-column justify-content-center align-content-center align-items-center'>
                        <div>
                            <PageCountPicker
                                pageSize={queryPageSize}
                                onChangePageSize={(size) => {
                                    dispatch({
                                        type: PAGE_SIZE_CHANGED,
                                        payload: size,
                                    });
                                }}
                                totalCount={totalCount}
                            ></PageCountPicker>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};
export default CardView;
