import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IEnvironmentData from '../../../../../../types/IEnvironmentDataType';
import { InfoModal } from '../../../../../../components/Modals/InfoModal';

interface Props {
    envData?: IEnvironmentData;
    onClose: () => void;
}

export const EnvironmentDataInfoModal = ({ envData, onClose }: Props) => {
    const { t } = useTranslation();
    const {
        substance,
        material,
        waste_code,
        handling_law,
        handling_industry,
        other_comments,
    } = envData || {};
    const header = envData
        ? `${material?.substring(0, 30)}${
              (material?.length || 0) > 30 ? '...' : ''
          }`
        : '';
    return (
        <InfoModal
            show={!!envData}
            headerText={header}
            handleClose={onClose}
            body={
                <>
                    {!!substance && (
                        <Col xs={12} className='mb-3'>
                            <span className='fs-5'>
                                {t('Hazardous substance')}:
                            </span>{' '}
                            {substance}
                        </Col>
                    )}
                    {!!material && (
                        <Col xs={12} className='mb-3'>
                            <span className='fs-5'>{t('Material')}:</span>{' '}
                            {material}
                        </Col>
                    )}
                    {!!waste_code && (
                        <Col xs={12} className='mb-3'>
                            <span className='fs-5'>{t('Waste code')}:</span>{' '}
                            {waste_code}
                        </Col>
                    )}
                    <Row>
                        {!!handling_law && (
                            <Col xs={12} className='mb-3'>
                                <span className='fs-5'>
                                    {t('Handling, law requirements')}:
                                </span>{' '}
                                {handling_law}
                            </Col>
                        )}
                        {!!handling_industry && (
                            <Col xs={12} className='mb-3'>
                                <span className='fs-5'>
                                    {t('Handling, industry standard')}:
                                </span>{' '}
                                {handling_industry}
                            </Col>
                        )}
                    </Row>
                    <Row>
                        {!!other_comments && (
                            <Col xs={12} className='mb-3'>
                                <span className='fs-5'>
                                    {t('Other comments')}:
                                </span>{' '}
                                {other_comments}
                            </Col>
                        )}
                    </Row>
                </>
            }
        />
    );
};
