import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EnvironmentDataSelect } from './EnvironmentDataSelect';
import { ViewEnvironmentData } from './ViewEnvironmentData';
import IProductEnvironmentData from '../../../../../types/IProductEnvironmentDataType';
import { useEnvironmentDataForm } from './useEnvironmentDataForm';

interface Props {
    setProductEnvironmentData?: (item: IProductEnvironmentData) => void;
}

export const EnvironmentDataForm = ({
    setProductEnvironmentData: setProdEnvDataCommonState,
}: Props) => {
    const { t } = useTranslation();

    const {
        environmentData,
        productEnvironmentData,
        setProductEnvironmentData,
        onEnvironmentDataIdsChange,
        updateProductEnvironmentData,
    } = useEnvironmentDataForm();

    const {
        protocol,
        cost,
        comments,
        environment_data = [],
    } = productEnvironmentData || {};

    // set produt environment data in the Save button for creating product
    useEffect(() => {
        setProdEnvDataCommonState &&
            !!productEnvironmentData &&
            setProdEnvDataCommonState(productEnvironmentData);
    }, [productEnvironmentData, setProdEnvDataCommonState]);

    return (
        <Row>
            <Col xs={12}>
                <Form>
                    <Row className='mb-3'>
                        <Col xs={12} className='mb-3'>
                            <EnvironmentDataSelect
                                value={environment_data}
                                onChange={onEnvironmentDataIdsChange}
                                options={environmentData || []}
                            />
                        </Col>
                        <Col xs={12}>
                            <ViewEnvironmentData selected={environment_data} />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col xs={12} sm={6}>
                            <Row className='mb-3'>
                                <Form.Group
                                    id='sampling-protocol'
                                    className='mb-3'
                                >
                                    <Form.Label>
                                        {t('Sampling, protocol')}
                                    </Form.Label>
                                    <Form.Control
                                        type='text'
                                        onChange={(e) =>
                                            setProductEnvironmentData({
                                                ...productEnvironmentData,
                                                protocol: e.target.value,
                                            } as IProductEnvironmentData)
                                        }
                                        onBlur={() =>
                                            updateProductEnvironmentData()
                                        }
                                        value={protocol || ''}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group id='cost' className='mb-3'>
                                    <Form.Label>{t('Cost')}</Form.Label>
                                    <Form.Control
                                        type='number'
                                        onChange={(e) => {
                                            e.currentTarget.focus();
                                            setProductEnvironmentData({
                                                ...productEnvironmentData,
                                                cost: parseFloat(
                                                    e.target.value
                                                ),
                                            } as IProductEnvironmentData);
                                        }}
                                        onBlur={() =>
                                            updateProductEnvironmentData()
                                        }
                                        value={cost || ''}
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Group id='comments' className='mb-3'>
                                <Form.Label>{t('Comments')}</Form.Label>
                                <Form.Control
                                    as='textarea'
                                    onChange={(e) =>
                                        setProductEnvironmentData({
                                            ...productEnvironmentData,
                                            comments: e.target.value,
                                        } as IProductEnvironmentData)
                                    }
                                    onBlur={() =>
                                        updateProductEnvironmentData()
                                    }
                                    value={comments || ''}
                                    rows={6}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
