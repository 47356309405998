import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import IServerResponseType from '../../../types/IServerResponseType';
import { useAuth } from '../../Auth/hooks/useAuth';
import {
    findPropertyFilters,
    findProductMetaFilters,
    useProductSearch,
} from './ProductSearch';
import { PropertyFilterInputSwitch } from './PropertyFilterInputSwitch';
import { Col, Row, Form } from 'react-bootstrap';
import IProductFieldType from '../../../types/IProductFieldType';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface Props {
    isMarketPlace?: boolean;
}
export const PropertyFilters = ({ isMarketPlace }: Props) => {
    const { t } = useTranslation();
    const { organisation } = useAuth();

    const { queryState, setQueryState } = useProductSearch();

    const propertyFilter = findPropertyFilters(queryState);
    const productMetaFilter = findProductMetaFilters(queryState);

    const propertyFetchApi = `/api/organisations/${
        organisation?.id
    }/properties?category_id=${
        queryState.category_id || null
    }&recursive_category=1`;
    const marketPropertyFetchApi = `/api/properties?category_id=${queryState.category_id}&recursive_category=1`;
    const [{ data }, refetch] = useAxios<IServerResponseType<any[]>>(
        isMarketPlace ? marketPropertyFetchApi : propertyFetchApi,
        {
            manual: true,
        }
    );

    useEffect(() => {
        if ((organisation || isMarketPlace) && queryState.category_id) {
            refetch();
        }
    }, [isMarketPlace, organisation, queryState.category_id, refetch]);

    const [customProductFields, setCustomProductFields] = useState<
        IProductFieldType[]
    >([]);
    const metaTypesFetchApi = `/api/organisations/${organisation?.id}/organisationmetatypes`;
    const marketMetaTypesFetchApi = `/api/metatypes`;
    useEffect(() => {
        axios
            .get(isMarketPlace ? marketMetaTypesFetchApi : metaTypesFetchApi)
            .then((res: any) => {
                if (res?.data?.data) {
                    setCustomProductFields(res.data.data);
                }
            });
    }, [isMarketPlace, marketMetaTypesFetchApi, metaTypesFetchApi]);

    return (
        <Row className='px-1'>
            <Row>
                {(data?.data?.length || 0) > 0 && <hr className='m-1' />}
                {data?.data?.map((property: any) => (
                    <Col
                        className='p-2'
                        key={property.id}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                        <PropertyFilterInputSwitch
                            onChange={(key, value) =>
                                setQueryState(
                                    `propertyFilters[${key.toString()}]`,
                                    value
                                )
                            }
                            value={propertyFilter[property.id]}
                            keyName={property.id}
                            property={property}
                        />
                    </Col>
                ))}
            </Row>
            <Row>
                {(customProductFields?.length || 0) > 0 && (
                    <hr className='m-1' />
                )}
                {customProductFields?.map((p: IProductFieldType, i: number) => (
                    <Col className='p-2' key={i} sm={6} xl={3} xs={12}>
                        <Form.Group>
                            <Form.Label>
                                {isMarketPlace ? p.name : p.meta_type?.name}
                            </Form.Label>
                            <Form.Control
                                name={p.meta_type?.name}
                                onChange={(e) =>
                                    setQueryState(
                                        `product_meta[${
                                            isMarketPlace
                                                ? p.id
                                                : p.meta_type?.id
                                        }]`,
                                        e.target.value
                                    )
                                }
                                type='text'
                                placeholder={t('Search')}
                                value={
                                    productMetaFilter[
                                        isMarketPlace ? p.id : p.meta_type?.id
                                    ] ?? ''
                                }
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                ))}
            </Row>
        </Row>
    );
};
