import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectFormState } from '../ProjectFormState';

interface Props {}

export const BasicInformationPreview = (props: Props) => {
    const { t } = useTranslation();
    const project = useProjectFormState();

    return <div>{project?.title ?? t('Please fill in')}</div>;
};
