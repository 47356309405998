export const dropzone = {
    borderRadius: '15px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    minHeight: '200px',
} as React.CSSProperties;

export const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
} as React.CSSProperties;

export const thumb = {
    display: 'inline-flex',
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    boxSizing: 'border-box',
    borderRadius: '15px',
    overflow: 'hidden',
    justifyContent: 'center',
} as React.CSSProperties;

export const cover = {
    ...thumb,
    width: 150,
    height: 150,
} as React.CSSProperties;

export const thumbInner = {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
} as React.CSSProperties;

export const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
} as React.CSSProperties;

export const selectedImge = {
    border: '2px solid orange',
} as React.CSSProperties;

export const isPublicDot = {
    backgroundColor: '#4CAF50',
    borderRadius: '50%',
    width: '13px',
    height: '13px',
    marginLeft: '5px',
    marginTop: '5px',
    position: 'absolute',
    border: '2px solid #fff',
    boxShadow: '3px 3px 4px rgba(0,0,0,0.6)',
};
