import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useProjectFormState } from '../ProjectFormState';
import { ImageManager } from '../../../../../components/Images/ImageManager';
import { useHandleImageAPI } from '../../../../../components/Images/useHandleImageAPI';

interface Props {}

export const ImagesForm = (props: Props) => {
    const {
        setFormState,
        setProjectImages: setFiles,
        projectImages: files,
        project_images,
        ...project
    } = useProjectFormState();

    const { loading, ...imgApi } = useHandleImageAPI({
        apiUrl: `/api/organisations/${project.organisation_id}/projects/${project.id}`,
        mediaGroup: {
            id: project_images?.id,
            title: project_images?.title ?? 'project_gallery', // has to match BE
        },
    });

    // Add an image
    const handleAdd = useCallback(
        (addFiles: any[]) => {
            imgApi.create(addFiles).then((res) => {
                if (res && res.images)
                    setFormState((prev) => ({
                        ...prev,
                        projectImages: res.images,
                        project_images: res.mediaGroup,
                    }));
            });
        },
        [imgApi, setFormState]
    );

    // Update an image
    const handleUpdate = useCallback(
        (updateFiles: any[], finishedUpdating: boolean) => {
            const updateState = (newFiles: any[]) => {
                setFiles((prev: any[]) =>
                    prev.map((f) => {
                        let newFile = newFiles.find((uf) => uf.id === f.id);
                        if (newFile) return newFile;
                        return f;
                    })
                );
            };
            if (!project_images) return;

            if (finishedUpdating) {
                imgApi.update(updateFiles).then((res) => {
                    updateState(res);
                });
            } else {
                updateState(updateFiles);
            }
        },
        [imgApi, project_images, setFiles]
    );

    // Remove an image
    const handleRemove = useCallback(
        (deleteFiles: any[]) => {
            if (!project_images) return;

            imgApi.remove([deleteFiles[0]]).then(() => {
                setFiles((prev: any[]) =>
                    prev.filter((f) => f.id !== deleteFiles[0].id)
                );
            });
        },
        [imgApi, project_images, setFiles]
    );

    return (
        <Row>
            <Col>
                <ImageManager
                    files={files}
                    onAdd={handleAdd}
                    onDelete={handleRemove}
                    onUpdate={handleUpdate}
                    loading={loading}
                />
            </Col>
        </Row>
    );
};
