import React from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IProduct from '../../../../types/IProductType';
import { useAuth } from '../../../Auth/hooks/useAuth';
import axios from 'axios';
import { useProductModals } from './ProductModals';
import { saveAs } from 'file-saver';

const SELECTED_GROUPS = 'dacke-selected-excel-groups';

export const ProductExcelExportSelection: React.FC<any> = ({
    selected,
    toggle,
}) => {
    const { t } = useTranslation();
    return (
        <Form.Group>
            <div>
                <h5>
                    {t('Please select the information you wish to include.')}
                </h5>
                <h6>
                    {t(
                        'Excel export will always include product-ID, name, project, category, amount and unit.'
                    )}
                </h6>
            </div>
            <Form.Check
                type='checkbox'
                checked={selected.includes('description')}
                onChange={() => toggle('description')}
                label={t('Description')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('internalDescription')}
                onChange={() => toggle('internalDescription')}
                label={t('Internal Description')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('economicalValues')}
                onChange={() => toggle('economicalValues')}
                label={t('Economical values')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('weight')}
                onChange={() => toggle('weight')}
                label={t('Weight')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('environmentalData')}
                onChange={() => toggle('environmentalData')}
                label={t('Environmental data')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('criterias')}
                onChange={() => toggle('criterias')}
                label={t('Criterias')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('productInfo')}
                onChange={() => toggle('productInfo')}
                label={t('Product Info')}
            />
            <Form.Check
                type='checkbox'
                checked={selected.includes('measurements')}
                onChange={() => toggle('measurements')}
                label={t('Measurements')}
            />
        </Form.Group>
    );
};

export const ProductExcelExportModal: React.FC<any> = ({
    products,
    onClose,
    onSave,
}) => {
    const { t } = useTranslation();

    const { setShowExcelExportProductsModal, showExcelExportProductsModal } =
        useProductModals();

    const { organisation } = useAuth();

    const storedSelected = localStorage.getItem(SELECTED_GROUPS);
    const [selectedGroups, setSelectedGroups] = React.useState<string[]>(
        storedSelected ? JSON.parse(storedSelected) : []
    );

    const exportAllProducts = async () => {
        try {
            const productIds = showExcelExportProductsModal.map(
                (product: IProduct) => product.id
            );

            const response = await axios.get(
                `/api/organisations/${
                    organisation?.id
                }/exportproducts?${productIds
                    .map(
                        (id: number, index: number) =>
                            `productIds[${index}]=${id}`
                    )
                    .join('&')}&${selectedGroups
                    .map(
                        (id: string, idx: number) =>
                            `columnGroups[${idx}]=${id}`
                    )
                    .join('&')}`,
                {
                    responseType: 'blob',
                }
            );

            if (response) {
                saveAs(response.data, 'products.xlsx');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const toggleSelected = (id: string) => {
        let s = [...selectedGroups];
        if (selectedGroups.indexOf(id) > -1) {
            s = selectedGroups.filter((x) => x !== id);
        } else {
            s.push(id);
        }
        localStorage.setItem(SELECTED_GROUPS, JSON.stringify(s));
        setSelectedGroups(s);
    };

    return (
        <>
            <Modal
                show={showExcelExportProductsModal}
                onHide={() => {
                    setShowExcelExportProductsModal(false);
                    onClose();
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Export products as Excel sheet')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t(
                            'This will export {{amount}} products to an excel file',
                            {
                                amount:
                                    showExcelExportProductsModal?.length || 0,
                            }
                        )}
                    </p>
                    <ProductExcelExportSelection
                        selected={selectedGroups}
                        toggle={toggleSelected}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => onClose()}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            exportAllProducts().then((data) => {
                                setShowExcelExportProductsModal(false);
                            });
                        }}
                    >
                        {t('Save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
