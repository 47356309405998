import { useEffect, useState } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const DEBOUNCE_MS = 100;

const xs = 0;
const sm = 576;
const md = 768;
const lg = 992;
const xl = 1200;
const xxl = 1600;
const max = 999999;

export const useBreakpoint = (
    direction: 'up' | 'down',
    breakpoint: Breakpoint
) => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const update = useDebounceCallback(
        () => setScreenSize(window.innerWidth),
        DEBOUNCE_MS
    );

    useEffect(() => {
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
    }, [update]);

    if (direction === 'up') {
        switch (breakpoint) {
            case 'xs':
                return screenSize >= xs;
            case 'sm':
                return screenSize >= sm;
            case 'md':
                return screenSize >= md;
            case 'lg':
                return screenSize >= lg;
            case 'xl':
                return screenSize >= xl;
            case 'xxl':
                return screenSize >= xxl;
            default:
                return true;
        }
    }

    switch (breakpoint) {
        case 'xs':
            return screenSize < sm;
        case 'sm':
            return screenSize < md;
        case 'md':
            return screenSize < lg;
        case 'lg':
            return screenSize < xl;
        case 'xl':
            return screenSize < xxl;
        case 'xxl':
            return screenSize < max;
        default:
            return true;
    }
};
