import React, { useEffect, useMemo, useCallback, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Layout/Page';
import { SEO } from '../../components/SEO';
import { useAuth } from '../Auth/hooks/useAuth';
import { Row, Col, ButtonToolbar, Container } from 'react-bootstrap';

import { BasicStat } from './components/BasicStat';

import { ReactComponent as INTERNALVALUEIcon } from '../../assets/icones/eget-varde_orange.svg';
import { ReactComponent as SALESVALUEIcon } from '../../assets/icones/sales-varde_orange.svg';
import { ReactComponent as CO2Icon } from '../../assets/icones/c02_orange.svg';
import { ReactComponent as KILOIcon } from '../../assets/icones/kilo_orange.svg';
import { VolumeStat } from './components/VolumeStat';
import { QualityStat } from './components/QualityStat';
import { PeriodicStat } from './components/PeriodicStat';
import axios from 'axios';

interface Props {}

export const DashboardPage = (props: Props) => {
    const { t } = useTranslation();
    const { organisation } = useAuth();

    const [basicStats, setBasicStats] = useState<any[]>([]);
    const [qualityData, setQualityData] = useState<any[]>([]);
    const [volumeData, setVolumeData] = useState<any[]>([]);
    const [periodicData, setPeriodicData] = useState<any[]>([]);
    const [projects, setProjects] = useState<any[] | null>(null);
    const [selectedProjects, setSelectedProjects] = useState<any[] | null>(
        null
    );

    const fills = [
        'rgb(249, 186, 116)',
        'rgb(253, 155, 34)',
        'rgb(255, 93, 34)',
        'rgb(195, 88, 27)',
        'rgb(0, 74, 79)',
        'rgb(0, 54, 69)',
    ];
    const style = {
        backgroundColor: 'rgb(255,244,230)',
        borderColor: 'lightgrey',
        boxShadow: '5px 5px 5px lightgrey',
    };

    const fetchProjects = useCallback(async () => {
        if (organisation?.id) {
            const res = await axios.get(
                `/api/organisations/${organisation?.id}/projects`
            );

            setProjects(res?.data?.data || []);
        }
    }, [organisation?.id]);

    const projectOptions = useMemo(() => {
        const activeProjects = (projects || [])
            .filter(({ is_active }) => is_active)
            .map(({ title, id }) => ({ label: title, value: id }));

        const inactiveProjects = (projects || [])
            .filter(({ is_active }) => !is_active)
            .map(({ title, id }) => ({ label: title, value: id }));

        return [
            { label: t('Active projects'), options: activeProjects },
            { label: t('Inactive projects'), options: inactiveProjects },
        ];
    }, [projects, t]);

    // Basic statistics
    const fetchTotal = useCallback(
        async (projectIds) => {
            const res = await axios.get(
                `/api/organisations/${
                    organisation?.id
                }/statistics/total?project_ids=${projectIds.join(',')}`
            );

            if (res) {
                const basicData = [
                    (res.data.data as any)?.totalOfInternalValue,
                    (res.data.data as any)?.totalOfSalesPrice,
                    (res.data.data as any)?.totalOfCo2,
                    (res.data.data as any)?.totalOfWeight,
                ];
                setBasicStats([
                    {
                        name: t('Internal value'),
                        value: basicData[0],
                        icon: (
                            <INTERNALVALUEIcon
                                width='60'
                                className='text-secondary ms-3'
                            />
                        ),
                    },
                    {
                        name: t('Sales price'),
                        value: basicData[1],
                        icon: (
                            <SALESVALUEIcon
                                width='60'
                                className='text-secondary ms-3'
                            />
                        ),
                    },
                    {
                        name: 'Kg of CO2 equivalents',
                        value: basicData[2],
                        icon: (
                            <CO2Icon
                                width='60'
                                className='text-secondary ms-3'
                            />
                        ),
                    },
                    {
                        name: t('Kilo'),
                        value: basicData[3],
                        icon: (
                            <KILOIcon
                                width='60'
                                className='text-secondary ms-3'
                            />
                        ),
                    },
                ]);

                return res.data;
            }
        },
        [organisation?.id, t]
    );

    // Quality / Condition data
    const fetchQuality = useCallback(
        async (projectIds) => {
            const res = await axios.get(
                `/api/organisations/${
                    organisation?.id
                }/statistics/quality?project_ids=${projectIds.join(',')}`
            );

            if (res) {
                const qd = res.data.data as any;
                setQualityData([
                    {
                        name: t('Almost new'),
                        value: Number(qd?.fivePoints) || 0,
                    },
                    { name: t('Decent'), value: Number(qd?.fourPoints) || 0 },
                    { name: t('Neutral'), value: Number(qd?.threePoints) || 0 },
                    { name: t('Used'), value: Number(qd?.twoPoints) || 0 },
                    { name: t('Worn out'), value: Number(qd?.onePoint) || 0 },
                ]);
                return res.data;
            }
        },
        [organisation?.id, t]
    );

    // Volume data
    const fetchVolume = useCallback(
        async (projectIds) => {
            const res = await axios.get(
                `/api/organisations/${
                    organisation?.id
                }/statistics/volume?project_ids=${projectIds.join(',')}`
            );

            if (res) {
                const vd = res.data.data as any;
                setVolumeData([
                    {
                        name: t('Internal holding'),
                        value: Number(vd?.internalHolding) || 0,
                    },
                    {
                        name: t('External marketplace'),
                        value: Number(vd?.externalMarketplace) || 0,
                    },
                    { name: t('Available'), value: Number(vd?.available) || 0 },
                    { name: t('Sold'), value: Number(vd?.sold) || 0 },
                ]);
                return res.data;
            }
        },
        [organisation?.id, t]
    );

    // Periodic stats
    const fetchPeriodic = useCallback(
        async (projectIds) => {
            const res = await axios.get(
                `/api/organisations/${
                    organisation?.id
                }/statistics/periodic?project_ids=${projectIds.join(',')}`
            );

            if (res) {
                const pd_1 = (res.data.data as any)?.startedProjects;
                const pd_2 = (res.data.data as any)?.finishedProjects;
                const pd_3 = (res.data.data as any)?.inventoryProducts;
                const pd_4 = (res.data.data as any)?.soldProducts;
                setPeriodicData([
                    {
                        title: t('Started projects'),
                        data: pd_1?.map((d: any) => ({
                            name: d.months,
                            value: d.startedProjects,
                        })),
                    },
                    {
                        title: t('Finished projects'),
                        data: pd_2?.map((d: any) => ({
                            name: d.months,
                            value: d.finishedProjects,
                        })),
                    },
                    {
                        title: t('Inventory products'),
                        data: pd_3?.map((d: any) => ({
                            name: d.months,
                            value: d.inventoryProducts,
                        })),
                    },
                    {
                        title: t('Sold products'),
                        data: pd_4?.map((d: any) => ({
                            name: d.months,
                            value: d.soldProducts,
                        })),
                    },
                ]);
                return res.data;
            }
        },
        [organisation, t]
    );

    useEffect(() => {
        if (organisation?.id) {
            fetchProjects();
        }
    }, [organisation?.id, fetchProjects]);

    useEffect(() => {
        if (organisation?.id && projects) {
            const projectIds =
                selectedProjects && selectedProjects.length > 0
                    ? (selectedProjects || []).map(({ value }) => value)
                    : (projects || []).map(({ id }) => id);

            fetchTotal(projectIds);
            fetchQuality(projectIds);
            fetchVolume(projectIds);
            fetchPeriodic(projectIds);
        }
    }, [
        projects,
        selectedProjects,
        organisation?.id,
        fetchTotal,
        fetchQuality,
        fetchPeriodic,
        fetchVolume,
    ]);

    return (
        <Page>
            <Container fluid>
                <div className='d-none'>
                    {/* Don't question the logic, but if the icons aren't present
                    here they will be distorted in other places. */}
                    <INTERNALVALUEIcon />
                    <SALESVALUEIcon />
                    <CO2Icon />
                    <KILOIcon />
                </div>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <SEO title={t('Dashboard')} asH6></SEO>
                    </div>
                </ButtonToolbar>
                <Row className='gap-3 mt-3 mb-4'>
                    <Col>
                        <Select
                            placeholder={t('Select projects')}
                            isClearable
                            isSearchable
                            isMulti
                            value={selectedProjects}
                            onChange={(v: any) => setSelectedProjects(v)}
                            options={projectOptions}
                            className='react-select-container'
                            classNamePrefix='react-select'
                        />
                    </Col>
                </Row>
                <Row className='gap-3 mt-3 mb-4'>
                    {basicStats.map((s, i) => (
                        <Col key={i}>
                            <BasicStat {...s} style={style} />
                        </Col>
                    ))}
                </Row>
                <Row className='gap-3 mt-3 mb-4'>
                    <Col>
                        <div className='d-none d-md-block'>
                            <QualityStat
                                layout='landscape'
                                size={250}
                                radiusSize={45}
                                title={t('Quality')}
                                nameValueData={qualityData}
                                style={style}
                                fills={fills}
                            />
                        </div>
                        <div className='d-flex d-md-none justify-content-center'>
                            <QualityStat
                                layout='portrait'
                                size={250}
                                radiusSize={45}
                                title={t('Quality')}
                                nameValueData={qualityData}
                                style={style}
                                fills={fills}
                            />
                        </div>
                    </Col>
                    <Col>
                        <VolumeStat
                            title={t('Volume')}
                            nameValueData={volumeData}
                            style={style}
                            fills={fills}
                        />
                    </Col>
                </Row>
                <Row className='gap-3 mt-3 mb-4'>
                    <Col>
                        <PeriodicStat
                            title={t('Periodic statistics')}
                            style={style}
                            data={periodicData}
                            fills={fills}
                        />
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
