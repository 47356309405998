import axios from 'axios';
import React, { useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

import IUserType from '../../types/IUserType';

interface Props {
    handleClose: () => void;
    show: boolean;
    user?: IUserType;
    apiRoute: string;
}

export const DeleteUserModal = ({
    user,
    handleClose,
    show,
    apiRoute,
}: Props) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = () => {
        if (!user) return;
        setIsSubmitting(true);
        axios
            .delete(apiRoute)
            .then(() => {
                setIsSubmitting(false);
                handleClose();
            })
            .catch(() => setIsSubmitting(false));
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header className='border-0 pb-0' closeButton>
                <h5 className='text-center w-100 ps-2 mt-2 text-uppercase fw-bold m-0'>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        width='16'
                        className='me-2'
                    />
                    {t('Remove user')}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12} xl={12}>
                        <p>{t('Do you want to remove this user?')}</p>
                        <h6>{user?.name}</h6>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className='d-block w-100 text-uppercase'
                    variant='secondary'
                    onClick={onSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting && (
                        <span className='spinner-border spinner-border-sm me-1' />
                    )}
                    {t('Remove')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
