import React, { useCallback } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useOrganisationFormState } from '../OrganisationFormState';
import { useTranslation } from 'react-i18next';
import {
    createLogo,
    createLogosFromMedias,
    deleteLogo,
} from '../../../../../API/organisation/logo';
import { useDropzone } from 'react-dropzone';

import { IImageMediaGroup } from '../../../../../types/IMediaType';
import { SimpleDropZonePreview } from '../../../../../components/SimpleDropZone/SimpleDropZonePreview';
import { dropzone } from '../../../../../components/SimpleDropZone/Styles';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';

interface Props {}

export const ThemeForm = (props: Props) => {
    const { t } = useTranslation();
    const { setActiveOrganisation, organisation: activeOrganisation } =
        useAuth();

    const {
        setMeta,
        updateMeta,
        setFormState,
        organisationLogos,
        setOrganisationLogos,
        ...organisation
    } = useOrganisationFormState();

    const getMetaValue = (key: string) =>
        organisation?.organisation_meta?.find((m) => m.meta_key === key)
            ?.meta_value;

    const onDrop = useCallback(
        (acceptedFiles) => {
            const newFiles: any[] =
                acceptedFiles.map((file: any, index: number) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        index: organisationLogos.length + index,
                    })
                ) || [];

            if (organisation?.id) {
                createLogo<IImageMediaGroup>(organisation?.id, newFiles).then(
                    (res: any) => {
                        if (res) {
                            setFormState((prev) => ({
                                ...prev,
                                organisationLogos: createLogosFromMedias(
                                    res.data[0]?.medias
                                ),
                                organisation_logos: res.data,
                            }));
                            // Reload organisation so logo updates
                            if (organisation.id === activeOrganisation?.id)
                                setActiveOrganisation(organisation.id);
                        }
                    }
                );
            } else {
                setOrganisationLogos((prev: any[]) => {
                    return [...prev, ...newFiles];
                });
            }
        },
        [
            activeOrganisation?.id,
            organisation.id,
            organisationLogos.length,
            setActiveOrganisation,
            setFormState,
            setOrganisationLogos,
        ]
    );

    const formControl = (
        key: string,
        type: string,
        maxLength: number,
        className?: string
    ) => {
        return (
            <Form.Control
                type={type}
                onChange={(e) => {
                    setMeta(key, e.target.value);
                }}
                className={className ?? ''}
                value={getMetaValue(key) || (type === 'color' ? '#000' : '')}
                maxLength={maxLength}
                onBlur={() => updateMeta(key)}
            />
        );
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/png',
        onDrop,
        disabled: organisationLogos.length >= 1,
        multiple: false,
    });

    const handleRemove = useCallback(
        (fileId: number) => {
            if (organisation?.id) {
                deleteLogo(
                    organisation?.id,
                    organisationLogos.find((f) => f.id === fileId)
                ).then((res) => {
                    setOrganisationLogos((prev: any[]) => {
                        return [...prev.filter((f) => f.id !== fileId)];
                    });
                    // Reload organisation so logo updates
                    if (organisation.id === activeOrganisation?.id)
                        setActiveOrganisation(organisation.id);
                });
            }
            setOrganisationLogos((prev: any[]) => {
                return [...prev.filter((f) => f.id !== fileId)];
            });
        },
        [
            organisation.id,
            setOrganisationLogos,
            organisationLogos,
            activeOrganisation?.id,
            setActiveOrganisation,
        ]
    );

    return (
        <Row>
            <Col sm={12} xl={12}>
                <Row>
                    <Col xs={12} lg={1}>
                        <Form>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('Color theme')}</Form.Label>
                                <InputGroup>
                                    {formControl('color', 'color', 50)}
                                </InputGroup>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>

                <Row className='d-flex flex-row gap-3'>
                    <Col
                        {...getRootProps({
                            className: 'd-flex flex-grow-1',
                        })}
                        md={12}
                        xl={3}
                        style={dropzone}
                    >
                        <div className='d-flex flex-grow-1 flex-column dropzone cursor-pointer card bg-light text-center border-0'>
                            <input {...getInputProps()} />
                            <div className='m-4 fs-1'>
                                <FontAwesomeIcon icon={faCloudUpload} />
                            </div>
                            <h5>{t('Upload files')}</h5>
                            <p>
                                {t(
                                    'Drag one or more files here, or click to select files'
                                )}
                            </p>
                        </div>
                    </Col>

                    <Col md={12} xl={6}>
                        <SimpleDropZonePreview
                            {...{ files: organisationLogos, handleRemove }}
                            title={t('Uploaded logo')}
                            type='Images'
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
