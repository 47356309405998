import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ProductPriceProps {
    isSoldOut: boolean;
    price: number;
    symbol: string;
}

export const ProductPrice: React.FC<ProductPriceProps> = ({
    isSoldOut,
    price,
    symbol,
}) => {
    const { t } = useTranslation();

    return (
        <Card.Subtitle className='text-danger fs-2 pt-1 mb-3'>
            {isSoldOut ? (
                <b>{t('Sold out')}</b>
            ) : (
                <b>
                    {price ? `${price}:- /${symbol}` : t('Price upon request')}
                </b>
            )}
        </Card.Subtitle>
    );
};
