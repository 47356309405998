import ReactGA from 'react-ga4';

import { useState, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

const useGaTracker = (onlyTrackedRoutes: string[]) => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (
            !window.location.href.includes('localhost') &&
            !window.location.href.includes('dev.dacke')
        ) {
            if (!initialized) {
                ReactGA.initialize('G-5Q7ZN8GL6R', {});
                setInitialized(true);
            }
        }
    }, [initialized]);

    useEffect(() => {
        if (initialized) {
            onlyTrackedRoutes.forEach((onlyTrackedRoute) => {
                if (matchPath(onlyTrackedRoute, location.pathname)) {
                    ReactGA.send({
                        hitType: 'pageview',
                        page: location.pathname + location.search,
                    });
                }
            });
        }
    }, [initialized, location, onlyTrackedRoutes]);
};

export default useGaTracker;
