import useAxios from 'axios-hooks';

import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ProductThumbnailView } from '../Products/components/ProductThumbnailView';
import { ProductMobileView } from '../Products/components/ListViews/ProductMobileView';
import { Page } from '../../components/Layout/Page';
import IProduct from '../../types/IProductType';
import { useHolderjs } from 'use-holderjs';
import IResourcePermissionsType from '../../types/ResourcePermissionsType';
import IServerResponseType from '../../types/IServerResponseType';
import { INewsBannerItem } from '../../types/INewsBannerItem';
import { useTranslation } from 'react-i18next';
import { NewsBanner } from '../../components/NewsBanner';

interface Props {}

export const MarketplacePage = (props: Props) => {
    useHolderjs();

    const { t } = useTranslation();

    const [{ data: productsData }] =
        useAxios<IServerResponseType<IResourcePermissionsType<IProduct>[]>>(
            `/api/latestproduct`
        );

    const [{ data: newsbannersData }] =
        useAxios<IServerResponseType<INewsBannerItem[]>>(`/api/news_banners`);

    return (
        <Page className='pt-0 pt-sm-4'>
            <Container fluid className='px-0 px-sm-inherit'>
                {newsbannersData?.data ? (
                    <Row>
                        <Col className='d-inline text-uppercase px-0 px-sm-inherit'>
                            <NewsBanner data={newsbannersData?.data} />
                        </Col>
                    </Row>
                ) : null}

                <Row>
                    <Col className='d-inline text-uppercase mb-3 mt-4'>
                        <span className='fw-bold me-3'>
                            {t('8 last added products')}
                        </span>
                        <Link to='/products'>{t('Show all')} </Link> {'>>'}
                    </Col>
                </Row>

                {productsData?.data ? (
                    <>
                        <div className='d-block d-sm-none'>
                            <ProductMobileView
                                products={productsData.data ?? []}
                                isMarketPlace
                            />
                        </div>
                        <div className='d-none d-sm-block'>
                            <Row>
                                <Col>
                                    <ProductThumbnailView
                                        {...{
                                            products: productsData.data ?? [],
                                            isMarketPlace: true,
                                            hidePageCount: true,
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </>
                ) : null}
            </Container>
        </Page>
    );
};
