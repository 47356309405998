import { Reducer, useReducer } from 'react';
import { SortingRule } from 'react-table';

interface IState {
    queryPageIndex: number;
    queryPageSize: number;
    totalCount: number;
    sortBy?: SortingRule<string>;
}

export interface ITableAction {
    type: string;
    payload: number;
    sortBy?: SortingRule<string>;
}

const initialState: IState = {
    queryPageIndex: 0,
    queryPageSize: 200,
    totalCount: -1,
};

export const PAGE_CHANGED = 'PAGE_CHANGED';
export const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
export const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';
export const SORT_BY_CHANGED = 'SORT_BY_CHANGED';

const reducer = (state: IState, { type, payload, sortBy }: ITableAction) => {
    switch (type) {
        case PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: payload,
            };
        case PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: payload,
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: payload,
            };
        case SORT_BY_CHANGED:
            return {
                ...state,
                sortBy,
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};

export const useTableReducer = (iState = initialState) => {
    return useReducer<Reducer<IState, ITableAction>>(reducer, iState);
};
