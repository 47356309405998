import axios from 'axios';
import { IDocumentMedia } from '../../types/IMediaType';

export const createDocuments = async <T = never>(
    organisationId: number,
    projectId: number,
    productId: number,
    productDocuments: any[]
) => {
    const productDocumentsFormData = new FormData();

    productDocuments.forEach((document: any, index: number) => {
        productDocumentsFormData.append(
            `product_documents[${index}]`,
            productDocuments[index]
        );

        productDocumentsFormData.append(
            `info[product_documents][${index}][title]`,
            document?.name || ''
        );

        productDocumentsFormData.append(
            `info[product_documents][${index}][is_public]`,
            '1'
        );
    });

    const documentsResponse = await axios
        .post<T>(
            `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}/media`,
            productDocumentsFormData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
        .then((response) => {
            return axios
                .get(
                    `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}`
                )
                .then((response) => {
                    return response.data.data.product_documents;
                });
        });

    if (documentsResponse) {
        return documentsResponse;
    }
};

export const updateDocuments = async (
    organisationId: number,
    projectId: number,
    productId: number,
    mediaGroup: number,
    productDocuments: any[]
) => {
    const productDocumentsFormData = new FormData();

    productDocuments.forEach((document: any, index: number) => {
        productDocumentsFormData.append(
            `product_documents[${index}]`,
            productDocuments[index]
        );

        productDocumentsFormData.append(
            `info[product_documents][${index}][title]`,
            document?.name || ''
        );

        productDocumentsFormData.append(
            `info[product_documents][${index}][is_public]`,
            '1'
        );
    });

    productDocumentsFormData.append('_method', 'PUT');

    const documentsResponse = await axios.post(
        `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}/media/${mediaGroup}`,
        productDocumentsFormData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );

    if (documentsResponse) {
        return documentsResponse.data;
    }
};

export const deleteDocument = async (
    organisationId: number,
    projectId: number,
    productId: number,
    mediaGroup: number,
    document: any
) => {
    const documentResponse = await axios.delete(
        `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}/media/media-${document.id}`
    );

    if (documentResponse) {
        return documentResponse.data;
    }
};

export function createDocumentsFromMedias(
    medias: IDocumentMedia[]
): any[] | never[] {
    return medias.map((media) => {
        return {
            id: media.id,
            preview: process.env.REACT_APP_API_ENDPOINT + media.url,
            title: media.title,
        };
    });
}
