import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { useOrganisationFormState } from '../OrganisationFormState';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Table } from '../../../../../components/Table';
import { Column, Row as TRow } from 'react-table';
import { useThrottle } from '@react-hook/throttle';
import { useHandleModal } from '../../../../../hooks/useHandleModal';
import IProductFieldType from '../../../../../types/IProductFieldType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrashCan,
    faStar,
    faStarHalfStroke,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ADDIcon } from '../../../../../assets/icones/Dacke_nytt.svg';
import { ConnectFieldModal } from './ConnectFieldModal';
import { DisconnectFieldModal } from './DisconnectFieldModal';
import { Restricted } from '../../../../Auth/components/Restricted';
import { useTableReducer } from '../../../../../hooks/useTableReducer';
import { SearchInput } from '../../../../../components/SearchInput';

interface Props {}

export const ProductFieldsForm = (props: Props) => {
    const { t } = useTranslation();

    const { ...organisation } = useOrganisationFormState();

    const [{ queryPageIndex, queryPageSize }, dispatch] = useTableReducer();

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useThrottle('', 2);

    const [fields, setFields] = useState<IProductFieldType[]>([]);

    // Let an organisation mark a product field as favorite / unfavorite
    const star = useCallback(
        (field: IProductFieldType) => {
            axios
                .put(
                    `/api/organisations/${organisation.id}/organisationmetatypes/${field.id}`,
                    {
                        favorite: !field.favorite,
                        meta_type_id: field.meta_type.id,
                    }
                )
                .then((res) => {
                    field.favorite = res.data.data.favorite;
                    setFields((o) =>
                        o.map((f) => (f.id !== field.id ? f : field))
                    );
                });
        },
        [organisation.id]
    );
    const disconnectFieldModal = useHandleModal<IProductFieldType>({
        onClose: () => fetchAPIData(queryPageSize, queryPageIndex),
    });

    const columns = React.useMemo<Column<IProductFieldType>[]>(
        () => [
            {
                Header: t('Field') as string,
                accessor: (row) => row.meta_type.name,
            },
        ],
        [t]
    );
    const actions = React.useMemo(
        () => [
            {
                name: 'Star',
                render: (instance: TRow<IProductFieldType>) => (
                    <div
                        onClick={() => star(instance.original)}
                        className='cursor-pointer'
                    >
                        {instance.original.favorite ? (
                            <FontAwesomeIcon
                                color={'darkorange'}
                                icon={faStar}
                                size='lg'
                            />
                        ) : (
                            <FontAwesomeIcon
                                color={'grey'}
                                icon={faStarHalfStroke}
                                size='lg'
                            />
                        )}
                    </div>
                ),
            },
            {
                name: 'Delete',
                render: (instance: TRow<IProductFieldType>) => (
                    <div
                        onClick={() => {
                            disconnectFieldModal.setItem(instance.original);
                            disconnectFieldModal.showModal();
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon icon={faTrashCan} width='15' />
                    </div>
                ),
            },
        ],
        [disconnectFieldModal, star]
    );

    const fetchAPIData = useCallback(
        async (pageSize: number, pageIndex: number) => {
            try {
                setLoading(true);

                const response = await axios.get(
                    `/api/organisations/${
                        organisation.id
                    }/organisationmetatypes?limit=${pageSize}&skip=${
                        pageIndex * pageSize
                    }&search=${searchTerm}`
                );

                if (response && response.data.data) {
                    setFields(response.data.data);
                    setLoading(false);
                }
            } catch (e) {
                console.error('Error while fetching', e);
                setLoading(false);
            }
        },
        [organisation.id, searchTerm]
    );

    useEffect(() => {
        if (organisation.id) fetchAPIData(queryPageSize, queryPageIndex);
    }, [fetchAPIData, queryPageSize, queryPageIndex, organisation.id]);

    const connectFieldModal = useHandleModal<IProductFieldType>({
        onClose: () => fetchAPIData(queryPageSize, queryPageIndex),
    });

    return (
        <Row>
            <Col sm={12} xl={12}>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-end'>
                    <div className='d-flex align-items-center '>
                        <div className='d-flex gap-3 flex-column flex-md-row flex-fill d-none d-sm-block'>
                            <div className='d-flex justify-content-end'>
                                <SearchInput
                                    onChange={setSearchTerm}
                                    placeholder={t('Search')}
                                    size='sm'
                                />

                                <Restricted toAdmin>
                                    <Button
                                        onClick={connectFieldModal.showModal}
                                        variant='secondary'
                                        size='sm'
                                        className='m-1 d-none d-sm-block'
                                    >
                                        <ADDIcon width='12' className='me-1' />
                                        {t('Add new field')}
                                    </Button>
                                </Restricted>
                            </div>
                        </div>
                    </div>
                </ButtonToolbar>
                <Table<IProductFieldType>
                    name={'Product Fields'}
                    columns={columns}
                    data={fields}
                    initialPageSize={queryPageSize || 0}
                    initialPageIndex={queryPageIndex || 0}
                    initialSortBy={{ id: 'name', desc: false }}
                    loading={loading}
                    actions={actions}
                    dispatch={dispatch}
                    size='sm'
                />
                <Restricted toSysAdmin>
                    <Button
                        onClick={connectFieldModal.showModal}
                        variant='secondary'
                        className='d-sm-none m-1 w-100'
                    >
                        <ADDIcon width='12' className='me-1' />
                        {t('Add new field')}
                    </Button>
                </Restricted>
                <ConnectFieldModal
                    handleClose={connectFieldModal.closeModal}
                    show={connectFieldModal.show}
                    organisationId={organisation.id}
                ></ConnectFieldModal>
                <DisconnectFieldModal
                    handleClose={disconnectFieldModal.closeModal}
                    show={disconnectFieldModal.show}
                    organisationId={organisation.id}
                    field={disconnectFieldModal.item}
                ></DisconnectFieldModal>
            </Col>
        </Row>
    );
};
