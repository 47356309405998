import React from 'react';
import { useTranslation } from 'react-i18next';

import IProduct from '../../../../../types/IProductType';

interface Props {
    product?: IProduct;
    totalQuantity: number;
}

export const PlacementsPreview = (props: Props) => {
    const { t } = useTranslation();
    const { product } = props;

    const unitSymbol = product?.category?.unit?.symbol || null;

    return (
        <div>
            {t('Total amount')}: {props.totalQuantity} {unitSymbol || ''}
        </div>
    );
};
