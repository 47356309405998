import { useEffect, useState } from 'react';
import IResourceType from '../../../types/IResourceType';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import { useAuth } from './useAuth';
import useIsAuth from './useIsAuth';
import useIsAdmin from './useIsAdmin';
import useIsSysAdmin from './useIsSysAdmin';

export const usePermission = (
    permissions?: string,
    resource?: ResourcePermissionsType<IResourceType>
) => {
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState<boolean>();

    const { isAllowedTo } = useAuth();

    const [loadingAuth, isAuth] = useIsAuth();
    const [loadingSysAdmin, isSysAdmin] = useIsSysAdmin();
    const [loadingAdmin, isAdmin] = useIsAdmin();

    useEffect(() => {
        const checkPermission = async () => {
            setLoading(true);

            if (loadingAuth || loadingSysAdmin || loadingAdmin) {
                setLoading(true);
                return;
            }

            if (isSysAdmin || isAdmin) {
                setAllowed(true);
                setLoading(false);
                return;
            }

            if (isAuth) {
                // check permission
                if (
                    resource &&
                    permissions &&
                    isAllowedTo([permissions], resource)
                ) {
                    setAllowed(true);
                    setLoading(false);
                } else {
                    setAllowed(false);
                    setLoading(false);
                }
            } else {
                setAllowed(false);
                setLoading(false);
                return;
            }
        };

        checkPermission();
    }, [
        isAdmin,
        isAllowedTo,
        isAuth,
        isSysAdmin,
        loadingAdmin,
        loadingAuth,
        loadingSysAdmin,
        permissions,
        resource,
    ]);

    return [loading, allowed];
};

export default usePermission;
