import { useEffect, useState } from 'react';
import { AuthenticatedState } from '../contexts/Auth/AuthContext';

import { useAuth } from './useAuth';

export const useIsAuth = () => {
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState<boolean>();

    const { isAuthenticatedState } = useAuth();

    useEffect(() => {
        const checkPermission = async () => {
            setLoading(true);

            // first need to check if the user is authenticated
            if (isAuthenticatedState === AuthenticatedState.NOT_CHECKED) {
                setLoading(true);
                return;
            }

            if (isAuthenticatedState === AuthenticatedState.LOADING) {
                setLoading(true);
                return;
            }

            if (isAuthenticatedState === AuthenticatedState.NOT_AUTHENTICATED) {
                setAllowed(false);
                setLoading(false);
                return;
            }

            if (isAuthenticatedState === AuthenticatedState.AUTHENTICATED) {
                setAllowed(true);
                setLoading(false);
                return;
            }
        };

        checkPermission();
    }, [isAuthenticatedState]);

    return [loading, allowed];
};

export default useIsAuth;
