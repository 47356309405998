import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
    Button,
    Modal,
    Card,
} from "react-bootstrap";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../Auth/hooks/useAuth";
import { Table } from "../../../../components/Table";

import { useParams } from "react-router";
import IServerResponseType from "../../../../types/IServerResponseType";
import ResourcePermissionsType from "../../../../types/ResourcePermissionsType";
import IProduct from "../../../../types/IProductType";
import ICriteriaType from "../../../../types/ICriteriaType";
import { useTableReducer } from "../../../../hooks/useTableReducer";
import { useCategories } from '../../../../routing/productsPrivateRoutes';

import useAxios from "axios-hooks";

import readXlsxFile, { Row } from 'read-excel-file'
import { IMessurementType } from "../../../../types/IMessurementType";

interface Props {
    show: boolean;
    handleClose: (e?: any) => void;
}

export const ImportExcelModal: React.FC<Props> = ({
    show,
    handleClose,
}) => {
    const { t } = useTranslation();

    const { organisation } = useAuth();
    const { projectId } = useParams();
    const { categories } = useCategories();

    function categoryStrToId(categoryStr: string) {
        function findCategory(category: any, name: string) {
            const stack = [category];

            while (stack.length > 0) {
                const current = stack.pop();
                if (current.name === name) {
                    return current;
                }
                stack.push(...current.children);
            }
            return null;
        }

        try {
            const parts = categoryStr.split('>').map((p) => p.trim());

            const parent = categories.find((c) => c.name === parts[0]);

            const child = findCategory(parent, parts[parts.length - 1]);

            if (child && child.children.length === 0) {
                return child.id;
            }

            return -1;
        } catch (error) {
            return -1;
        }
    }

    const [nrOfFiles, setNrOfFiles] = useState<number>(0);
    const [chosenFile, setChosenFile] = useState<File>();
    const [{ queryPageIndex, queryPageSize }, dispatch] = useTableReducer();
    const [total, setTotal] = useState<number>(-1);
    const [products, setProducts] = useState<any[]>([]);
    const [productsToShow, setProductsToShow] =  useState<IProduct[]>([]);
    const onClose = () => {
        setProducts([]);
        setTotal(-1);
        setChosenFile(undefined);
        setNrOfFiles(0);
        handleClose();
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [, createNewProduct] = useAxios<
        IServerResponseType<ResourcePermissionsType<IProduct>>
    >(
        {
            url: `/api/organisations/${organisation?.id}/projects/${projectId}/products`,
            method: "post",
        },
        { manual: true, autoCancel: false}
    );
    const [, updateMessure] = useAxios<
        IServerResponseType<ResourcePermissionsType<IMessurementType>>
        >(
            {
                method: "patch"
            },
            {manual: true, autoCancel: false}
        );

    const [, updateProductPoints] = useAxios<
        IServerResponseType<ResourcePermissionsType<ICriteriaType>>
    >(
        {
            url: `/api/organisations/${organisation?.id}/projects/${projectId}/products`,
            method: "patch",
        },
        { manual: true, autoCancel: false}
    );

    const [criteriaResponse,] = useAxios<IServerResponseType<any>>(`/api/organisations/${organisation?.id}/organisationcriterias`);
    const { data: { data: criteria } = {} } = criteriaResponse;

    useEffect(() => {
        const startPos = queryPageIndex * queryPageSize;
        setProductsToShow(products.slice(startPos, startPos + queryPageSize))
    }, [products, queryPageIndex, queryPageSize]);

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            const pp = await Promise.all(products.map((productData) => createNewProduct({data: productData})))

            pp.forEach(async (p) => {
                if (p.data.message.includes("updated")) {
                    const prod = products.find((prod) => p.data.data.id === prod.id);
                    if (prod?.id) {
                        const mes = prod.messurement[0];
                        await updateMessure({data: mes, url: `/api/organisations/${organisation?.id}/projects/${projectId}/products/${prod.id}/messurements/${mes.id}`})
                    }
                }
            })

            setIsSubmitting(false);
            onClose();
        } catch (error) {
            setIsSubmitting(false);
            onClose();
            console.error(error);
        }
    };

    const rowToProduct = (b: Row, headers: Row) => {
        const messurement: IMessurementType = {
            width: b[headers.indexOf("Bredd")]?.valueOf() as number,
            height: b[headers.indexOf("Höjd")]?.valueOf() as number,
            length: b[headers.indexOf("Längd")]?.valueOf() as number,
            depth: b[headers.indexOf("Djup")]?.valueOf() as number,
            diamter: b[headers.indexOf("Diameter")]?.valueOf() as number,
            thickness: b[headers.indexOf("Tjocklek")]?.valueOf() as number,
            id: b[headers.indexOf("Mått-ID")]?.valueOf() as number,
            unit_id: 3,
        };

        const productMessurement: IMessurementType[] = messurement
        ? [
              {
                  ...messurement,
              },
          ]
        : [];

        const productPoints: any = headers
            .filter((h) => criteria?.find((c: any) => c?.criteria?.title === h))
            .map((h) => {
                const crit = criteria?.find((c: any) => c?.criteria?.title === h);
                return {
                    criteria_id: crit?.criteria?.id,
                    points: b[headers.indexOf(h)]?.valueOf() as number,
                };
            });

        const barcode = b[headers.indexOf("Produkt-ID")]?.toString().split('-');
        const product_id: number = +barcode[barcode.length-1];

        const product = {
            id: product_id,
            title: b[headers.indexOf("Titel")]?.toString(),
            category_str: b[headers.indexOf("Kategori")]?.toString(),
            description: b[headers.indexOf("Beskrivning")]?.toString(),
            internal_description: b[headers.indexOf("Intern beskrivning")]?.toString(),
            manufacture_code: b[headers.indexOf("Tillverkare")]?.toString(),
            model_code: b[headers.indexOf("Modell")]?.toString(),
            year: b[headers.indexOf("År")]?.toString(),
            unused: b[headers.indexOf("Oanvänd (J/N)")]?.toString()?.toLowerCase() === 'j' ? true : false,
            unused_str: b[headers.indexOf("Oanvänd (J/N)")]?.toString(),
            condition_points: b[headers.indexOf("Skick")]?.valueOf() as number,
            function_points: b[headers.indexOf("Funktion")]?.valueOf() as number,
            weight: b[headers.indexOf("Vikt/enhet (kg)")]?.valueOf() as number,
            co2: b[headers.indexOf("CO2")]?.valueOf() as number,
            internal_value: b[headers.indexOf("Internt värde/enhet")]?.valueOf() as number,
            sales_price: b[headers.indexOf("Försäljningspris")]?.valueOf() as number,
            market_price: b[headers.indexOf("Sannolikt försäljningspris")]?.valueOf() as number,
            search_words: b[headers.indexOf("Sökord")]?.toString(),
            width: b[headers.indexOf("Bredd")]?.valueOf() as number,
            height: b[headers.indexOf("Höjd")]?.valueOf() as number,
            length: b[headers.indexOf("Längd")]?.valueOf() as number,
            depth: b[headers.indexOf("Djup")]?.valueOf() as number,
            diamter: b[headers.indexOf("Diameter")]?.valueOf() as number,
            thickness: b[headers.indexOf("Tjocklek")]?.valueOf() as number,
            unit: 3,
            weight_unit_id: -1,
            unit_str: 'mm',//b[headers.indexOf("Måttenhet")]?.toString(),
            project_id: 0, // post uses project_id of the api call instead
            category_id: categoryStrToId(b[headers.indexOf("Kategori")]?.toString()),
            product_meta: [],
            product_items: [],
            product_points: productPoints,
            product_properties: [],
            video_description: "",
            video_url: "",
            created_at: null,
            updated_at: null,
            MessurementInputArray: productMessurement,
            messurement: productMessurement
        }
        return product;
    }

    const onDrop = useCallback(
        async (acceptedFiles) => {
            setNrOfFiles(acceptedFiles.length)
            if (nrOfFiles > 1) return;
            setChosenFile(acceptedFiles[0]);

            const rows = await readXlsxFile(acceptedFiles[0]);
            const headers = rows.shift()
            if (headers) {
                setTotal(rows.length)
                const data = rows.map((row) => rowToProduct(row, headers))
                setProducts(data);
                setProductsToShow(data);
            }
        },
        [nrOfFiles, criteria, categories, rowToProduct]
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ,
        onDrop,
    });

    const columns = React.useMemo<Column<IProduct>[]>(
        () => [
            {
                Header: t("Title") as string,
                accessor: "title",
            },
            {
                Header: t("Category") as string,
                accessor: "category_str",
            },
            {
                Header: t("Description") as string,
                accessor: "description",
            },
            {
                Header: t("Internal description") as string,
                accessor: "internal_description",
            },
            {
                Header: t("Manufacturer code") as string,
                accessor: "manufacture_code",
            },
            {
                Header: t("Model code") as string,
                accessor: "model_code",
            },
            {
                Header: t("Year") as string,
                accessor: "year",
            },
            {
                Header: t("Unused") as string,
                accessor: "unused_str",
            },
            {
                Header: t("Condition") as string,
                accessor: "condition_points",
            },
            {
                Header: t("Function") as string,
                accessor: "function_points",
            },
            {
                Header: t("Weight (kg)") as string,
                accessor: "weight",
            },
            {
                Header: t("CO2") as string,
                accessor: "co2",
            },
            {
                Header: t("Internal value") as string,
                accessor: "internal_value",
            },
            {
                Header: t("Price") as string,
                accessor: "sales_price",
            },
            {
                Header: t("Market price") as string,
                accessor: "market_price",
            },
            {
                Header: t("Search words") as string,
                accessor: "search_words",
            },
            {
                Header: t("Width") as string,
                accessor: "width",
            },
            {
                Header: t("Height") as string,
                accessor: "height",
            },
            {
                Header: t("Length") as string,
                accessor: "length",
            },
            {
                Header: t("Depth") as string,
                accessor: "depth",
            },
            {
                Header: t("Diameter") as string,
                accessor: "diamter",
            },
            {
                Header: t("Thickness") as string,
                accessor: "thickness",
            },
            {
                Header: t("Measurement") as string,
                accessor: "unit_str",
            },
        ],
    [t]
    );

    return (
        <Modal size="xl" onHide={onClose} centered show={show}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("Import from excel")} {" > "} {t("Choose excel file")}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Card style={{ backgroundColor: "#f5f5f5", border: "none" }}>
                    <Card.Header
                        style={{ backgroundColor: "##e6e6e6", border: "none" }}
                    >
                    {nrOfFiles > 0 && (<p>
                        {t("Chosen file")} {":"} {nrOfFiles === 1 ? chosenFile?.name : t("None - You may only choose ONE file at a time")}
                    </p>)}
                    </Card.Header>
                    <Card.Body>
                        {nrOfFiles === 0 && (<div {...getRootProps({
                            className: "d-flex flex-grow-1",
                        })} className="d-flex flex-grow-1 flex-column dropzone card bg-light text-center border-0"
                            style={{cursor: 'pointer'}}
                        >
                            <input {...getInputProps()} />
                            <div className="m-4 fs-1"><FontAwesomeIcon icon={faCloudUpload} /></div>
                            <h5>{t("Upload files")}</h5>
                            <p>
                                {t(
                                    "Drag an xlsx or xsl file here, or click to choose a file"
                                )}
                            </p>
                        </div>)}
                        {nrOfFiles === 1 && (
                            <Table<IProduct>
                            name={"Products"}
                            columns={columns}
                            data={productsToShow}
                            initialPageSize={queryPageSize || 0}
                            initialPageIndex={queryPageIndex || 0}
                            loading={false}
                            dispatch={dispatch}
                            size="sm"
                            variant="regular"
                            isImport={true}
                            total={total}
                        />
                        )}
                        {nrOfFiles > 1 && (<div {...getRootProps({
                            className: "d-flex flex-grow-1",
                        })} className="d-flex flex-grow-1 flex-column dropzone card bg-light text-center border-0"
                            style={{cursor: 'pointer'}}
                        >
                            <input {...getInputProps()} />
                            <div className="m-4 fs-1"><FontAwesomeIcon icon={faCloudUpload} /></div>
                            <h5>{t("Upload files")}</h5>
                            <p>
                                {t(
                                    "Drag an xlsx or xsl file here, or click to choose a file"
                                )}
                            </p>
                        </div>)}
                    </Card.Body>
                </Card>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={onSubmit}
                    disabled={
                        isSubmitting
                    }
                >
                    {isSubmitting && (
                        <span className="spinner-border spinner-border-sm me-1" />
                    )}
                    {t("Continue")} {">>"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
