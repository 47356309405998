import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';

import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Button, ButtonToolbar, Container } from 'react-bootstrap';

import {
    IProjectFormState,
    ProjectFormState,
    useProjectFormState,
} from './components/ProjectForm/ProjectFormState';
import { BasicInformationPreview } from './components/ProjectForm/BasicInformation/BasicInformationPreview';
import { BasicInformationFrom } from './components/ProjectForm/BasicInformation/BasicInformationForm';
import { ContactPersonPreview } from './components/ProjectForm/ContactPerson/ContactPersonPreview';
import { ContactPersonForm } from './components/ProjectForm/ContactPerson/ContactPersonForm';
import { UsersForm } from './components/ProjectForm/Users/UsersForm';
import { UsersPreview } from './components/ProjectForm/Users/UsersPreview';
import { DescriptionPreview } from './components/ProjectForm/Description/DescriptionPreview';
import { DescriptionForm } from './components/ProjectForm/Description/DescriptionForm';
import { PlacementPreview } from './components/ProjectForm/Placement/PlacementPreview';
import { PlacementForm } from './components/ProjectForm/Placement/PlacementForm';
import { DocumentsPreview } from './components/ProjectForm/Documents/DocumentsPreview';
import { DocumentsForm } from './components/ProjectForm/Documents/DocumentsForm';
import { ImagesPreview } from './components/ProjectForm/Images/ImagesPreview';
import { ImagesForm } from './components/ProjectForm/Images/ImagesForm';
import { createImagesFromMedias } from '../../API/images';
import { createDocumentsFromMedias as createProjectDocumentsFromMedias } from '../../API/project/documents';
import { Page } from '../../components/Layout/Page';
import IProjectType from '../../types/IProjectType';
import IServerResponseType from '../../types/IServerResponseType';
import ResourcePermissionsType from '../../types/ResourcePermissionsType';
import { useAuth } from '../Auth/hooks/useAuth';
import useForceBlur from '../../hooks/useForceBlur';
import { Accordion } from '../../components/Accordion/Accordion';

interface Props {}

export const NoStateNewProjectFormPage = (props: Props) => {
    const { t } = useTranslation();
    const project = useProjectFormState();
    const accordionItems = useMemo(() => {
        return [
            {
                title: t('Basic Information'),
                PreviewCompnent: BasicInformationPreview,
                FormCompnent: BasicInformationFrom,
            },
            {
                title: t('Contact person'),
                PreviewCompnent: ContactPersonPreview,
                FormCompnent: ContactPersonForm,
            },
            {
                title: t('Description'),
                PreviewCompnent: DescriptionPreview,
                FormCompnent: DescriptionForm,
            },
            {
                title: t('Placement titles'),
                PreviewCompnent: PlacementPreview,
                FormCompnent: PlacementForm,
            },
            {
                title: t('Documents'),
                PreviewCompnent: DocumentsPreview,
                FormCompnent: DocumentsForm,
            },
            {
                title: t('Images'),
                PreviewCompnent: ImagesPreview,
                FormCompnent: ImagesForm,
            },
            {
                title: t('Users'),
                PreviewCompnent: UsersPreview,
                FormCompnent: UsersForm,
            },
        ];
    }, [t]);

    return (
        <Page className='px-0 px-sm-inherit'>
            <Container fluid className='px-0 px-sm-inherit'>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/admin/projects`,
                            }}
                        >
                            {t('Projects')}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/admin/projects/${project.id}`,
                            }}
                        >
                            {project?.title}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('Edit')}</Breadcrumb.Item>
                    </Breadcrumb>
                </ButtonToolbar>

                <Accordion
                    disabled={false}
                    className='form-accordion'
                    {...{ accordionItems }}
                />

                <Button variant='secondary' style={{ visibility: 'hidden' }}>
                    {t('Edit Project')}
                </Button>
            </Container>
        </Page>
    );
};

export const ProjectFormPage: React.FC = (props) => {
    useForceBlur();
    const auth = useAuth();
    // If the projectId query parameter is present, Edit that Project
    // Otherwise - Edit the Active Project
    let { projectId } = useParams<any>();

    // fetch project data
    const [{ data: projectData }, refetchproject] = useAxios<
        IServerResponseType<ResourcePermissionsType<IProjectType>>
    >(`/api/organisations/${auth?.organisation?.id}/projects/${projectId}`, {
        manual: true,
    });
    const project = projectData?.data;

    useEffect(() => {
        if (!project) {
            refetchproject();
        }
    }, [project, projectId, refetchproject]);

    return projectId && !project ? (
        <Page>Loading....</Page>
    ) : (
        <ProjectFormState
            initialState={
                {
                    id: project?.id,
                    title: project?.title,
                    project_start: project?.project_start
                        ?.toString()
                        .substring(0, 10),
                    project_finish: project?.project_finish
                        ?.toString()
                        .substring(0, 10),
                    is_active: project?.is_active,
                    firstPlacementTitle: project?.firstPlacementTitle,
                    secondPlacementTitle: project?.secondPlacementTitle,
                    thirdPlacementTitle: project?.thirdPlacementTitle,
                    fourthPlacementTitle: project?.fourthPlacementTitle,
                    contacts: project?.contacts,
                    primary_contact: project?.primary_contact,
                    description: project?.description,
                    region: project?.region,
                    organisation_id: project?.organisation_id,

                    projectImages: project?.project_images?.medias
                        ? createImagesFromMedias(project.project_images.medias)
                        : [],
                    project_images: {
                        id: project?.project_images?.id || null,
                    },

                    projectDocuments: project?.project_documents?.medias
                        ? createProjectDocumentsFromMedias(
                              project.project_documents.medias
                          )
                        : [],
                    project_documents: {
                        id: project?.project_documents?.id || null,
                    },
                } as IProjectFormState
            }
        >
            <NoStateNewProjectFormPage {...props} />
        </ProjectFormState>
    );
};
