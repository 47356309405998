import axios from 'axios';
import { IImageMedia, IImageMediaGroup } from '../../types/IMediaType';
import IServerResponseType from '../../types/IServerResponseType';
import { imageSortFn } from '../../utils/helper';

export const createLogo = async <T = IImageMediaGroup>(
    organisationId: number,
    logos: any[]
) => {
    const formData = new FormData();

    formData.append(`logo[0]`, logos[0]);
    formData.append(`info[logo][0][title]`, logos[0]?.title || 'test');
    formData.append(`info[logo][0][description]`, logos[0]?.description || '');
    formData.append(`info[logo][0][sort_order]`, '0');
    formData.append(`info[logo][0][is_public]`, '1');

    const response = await axios.post<IServerResponseType<T>>(
        `/api/organisations/${organisationId}/media`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );

    if (response) {
        return response.data;
    }
};

export const deleteLogo = async (organisationId: number, logo: any) => {
    const response = await axios.delete(
        `/api/organisations/${organisationId}/media/media-${logo.id}`
    );

    if (response) {
        return response;
    }
};

export function createLogosFromMedias(medias: IImageMedia[]): any[] | never[] {
    return medias.sort(imageSortFn).map((media) => {
        return {
            id: media.id,
            preview: process.env.REACT_APP_API_ENDPOINT + media.url.large,
            title: media.title,
            is_public: media.is_public,
            sort_order: media.sort_order,
        };
    });
}
