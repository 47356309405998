import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import IProjectType from '../../../../types/IProjectType';

interface Props {
    show: boolean;
    handleClose: (e?: any) => void;
    projects: IProjectType[];
}

export const ProjectSelectionModal: React.FC<Props> = ({
    show,
    handleClose,
    projects,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onClose = () => {
        handleClose();
    };

    const onSubmit = (data: any) => {
        navigate(`/admin/projects/${data.target.value}/product-form`);
        handleClose();
    };

    return (
        <Modal onHide={onClose} centered show={show}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('New product')} {' > '} {t('Choose project')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Select
                    onChange={onSubmit}
                    aria-label='Default select example'
                >
                    <option>{t('Choose project')}</option>

                    {projects.map((p) => {
                        return (
                            <option value={p.id} key={p.id}>
                                {p.title}
                            </option>
                        );
                    })}
                </Form.Select>
            </Modal.Body>
        </Modal>
    );
};
