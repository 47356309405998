import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const AlertModal: React.FC<any> = ({
    onClose,
    onSave,
    showAlertModal,
    cancelLabel = 'Cancel',
    saveLabel = 'Yes',
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={showAlertModal}
            onHide={() => {
                onClose();
            }}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t(showAlertModal?.title)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t(showAlertModal?.message, showAlertModal?.messageData)}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => onClose()}>
                    {t(cancelLabel)}
                </Button>
                <Button
                    variant='primary'
                    onClick={() => {
                        onSave();
                    }}
                >
                    {t(saveLabel)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
