import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import logo from './../../../assets/images/Dacke_Online-logotype.png';

export const WelcomeJumbotron: React.FC = ({ children }) => {
    return (
        <Card>
            <Row className='g-0'>
                <Col className='d-flex justify-content-center align-content-center align-items-center'>
                    <Card.Body>{children}</Card.Body>
                </Col>
                <Col className='d-none d-md-flex' md={8}>
                    <div className='bg-primary text-light p-5 rounded-end'>
                        <img
                            alt=''
                            src={logo}
                            width='100%'
                            className='img-fluid'
                        />
                    </div>
                </Col>
            </Row>
        </Card>
    );
};
