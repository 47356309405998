export const dropzone = {
    borderRadius: '15px',
    minHeight: '200px',
} as React.CSSProperties;

export const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
} as React.CSSProperties;

export const thumb = {
    position: 'relative',
    display: 'inline-flex',
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    boxSizing: 'border-box',
    borderRadius: '15px',
    overflow: 'hidden',
} as React.CSSProperties;

export const thumbDocument = {
    position: 'relative',
    display: 'inline-flex',
    boxSizing: 'border-box',
} as React.CSSProperties;

export const thumbInner = {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
} as React.CSSProperties;

export const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
} as React.CSSProperties;

export const removeButton = {
    width: '18px',
    height: '18px',
    lineHeight: 0,
    textAlign: 'center',
    boxShadow: '3px 3px 4px rgba(0,0,0,0.2)',
    padding: '0px',
    position: 'absolute',
    top: 0,
    right: 0,
};
