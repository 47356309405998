import axios from 'axios';
import { IUnitType } from '../types/IUnitType';
import IServerResponseType from '../types/IServerResponseType';

export const getUnits = async () => {
    const response = await axios.get<IServerResponseType<IUnitType[]>>(
        '/api/units'
    );
    return response.data;
};
