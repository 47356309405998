import useAxios from 'axios-hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../pages/Auth/hooks/useAuth';
import { useProductSearch } from '../pages/Products/components/ProductSearch';
import { RecursiveCategoryList } from '../pages/Products/components/RecursiveCategoryList';
import { ICategory } from '../types/ICategory';
import IServerResponseType from '../types/IServerResponseType';

import './CategoryNavList.scss';
interface Props {
    categories?: ICategory[];
    setSelectedCategory?: (category?: ICategory) => void;
}
export const CategoryNavList = ({
    categories: categoriesProps,
    setSelectedCategory,
}: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { organisation } = useAuth();
    const { queryState, setQueryState } = useProductSearch();

    const [{ data }, refetch] = useAxios<IServerResponseType<ICategory[]>>(
        `/api/categories`,
        {
            manual: true,
        }
    );
    const categories = categoriesProps || data?.data;
    useEffect(() => {
        if (!categories) refetch();
    }, [categories, organisation, refetch]);

    const categoryProductCount = (categories || []).reduce(
        (acc, c) => (c?.products_count || 0) + acc,
        0
    );

    const handleCategoryClick = (category?: ICategory) => {
        // This code snippet can probably be done in a nicer way. But it works!
        const isBrowsingAProductOrSomething =
            !isNaN(parseInt(window.location.href.split('/').pop() ?? '')) ||
            !window.location.href.includes('products') ||
            window.location.href.endsWith('products/me');

        setQueryState('category_id', category?.id.toString());
        setSelectedCategory && setSelectedCategory(category);
        if (isBrowsingAProductOrSomething) {
            const marketPlace = !window.location.href.includes('admin');
            navigate({
                pathname: marketPlace ? '/products' : '/admin/products',
            });
        }
    };

    return (
        <div className='category-nav-list text-uppercase lh-lg'>
            <div>
                <p className='fw-bold mb-1'>{t('Products')}</p>
                <span
                    className={
                        'cursor-pointer categorie-name ' +
                        (!queryState.category_id
                            ? 'text-secondary text-decoration-underline'
                            : '')
                    }
                    onClick={() => handleCategoryClick()}
                >
                    {`${t('All products')} (${categoryProductCount})`}
                </span>
            </div>
            {categories && (
                <RecursiveCategoryList
                    categories={categories}
                    select={handleCategoryClick}
                    selectedId={Number(queryState.category_id)}
                    renderLabel={(c: ICategory) =>
                        `${c.name}${
                            c?.products_count ? ` (${c.products_count})` : ''
                        }`
                    }
                ></RecursiveCategoryList>
            )}
        </div>
    );
};

export default CategoryNavList;
