import React, { useMemo } from 'react';
import { Stack, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Column, SortingRule } from 'react-table';
import { useTranslation } from 'react-i18next';
import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import { Table } from '../../../../components/Table';
import { ITableAction } from '../../../../hooks/useTableReducer';
import { IProductItem } from '../../../../types/IProductItem';
import IProjectType from '../../../../types/IProjectType';
import { CoverImage } from '../../../../components/CoverImage';
import { IImageMediaGroup } from '../../../../types/IMediaType';
import { useWindowSize } from '../../../Organisation/hooks/useWindowSize';
import { Points } from '../Points';
import { useTableSelectedProductItems } from '../SelectedProductItemsContext';
import { useCategories } from '../../../../routing/productsPrivateRoutes';
import getCategoryTree from '../../../../utils/get-category-tree';

interface Props {
    productItems: ResourcePermissionsType<IProductItem>[] | IProductItem[];
    queryPageIndex?: number;
    queryPageSize?: number;
    sortBy?: SortingRule<string>;
    totalCount?: number;
    dispatch?: React.Dispatch<ITableAction>;
    loading?: boolean;
    project?: ResourcePermissionsType<IProjectType>;
}

export const ProductItemsListView = ({
    project,
    productItems,
    queryPageIndex,
    queryPageSize,
    sortBy,
    totalCount,
    dispatch,
    loading,
}: Props) => {
    const { t } = useTranslation();

    const { setSelectedItems } = useTableSelectedProductItems();
    const { categories } = useCategories();

    const computeHiddenColumns = () => {
        if (project?.fourthPlacementTitle) return [];
        if (project?.thirdPlacementTitle) return ['fourthPlacementTitle'];
        if (project?.secondPlacementTitle)
            return ['thirdPlacementTitle', 'fourthPlacementTitle'];
        if (project?.firstPlacementTitle)
            return [
                'secondPlacementTitle',
                'thirdPlacementTitle',
                'fourthPlacementTitle',
            ];

        return [
            'firstPlacementTitle',
            'secondPlacementTitle',
            'thirdPlacementTitle',
            'fourthPlacementTitle',
        ];
    };

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns =
        width && width < 992
            ? [
                  'function_points',
                  'condition_points',
                  'observation',
                  'quantity',
                  'category',
                  'secondPlacementTitle',
                  'thirdPlacementTitle',
                  'fourthPlacementTitle',
              ]
            : [computeHiddenColumns()];

    const columns = useMemo<Column<ResourcePermissionsType<IProductItem>>[]>(
        () => [
            {
                Header: '',
                id: 'productItem_images',
                accessor: (productItem: IProductItem) => (
                    <div
                        style={{
                            width: '80px',
                            margin: 'auto',
                        }}
                    >
                        <CoverImage
                            images={
                                productItem?.product
                                    ?.product_images as IImageMediaGroup
                            }
                            lightbox
                        />
                    </div>
                ),
            },
            {
                Header: t('Name') as string,
                id: 'products.title',
                canSort: true,
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            <div className='fw-bold'>
                                {productItem.product?.title}
                            </div>
                            <div>
                                {productItem.product?.barcode &&
                                    t('Product-ID') +
                                        ': ' +
                                        productItem.product?.barcode}
                            </div>
                        </Stack>
                    );
                },
            },
            {
                Header: t('Category') as string,
                id: 'categories.name',
                canSort: true,
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            <div>{productItem.product?.category?.name}</div>
                        </Stack>
                    );
                },
            },
            {
                Header: t('Condition') as string,
                id: 'condition_points',
                canSort: true,
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            <Points
                                criteria={{ title: 'condition' }}
                                value={
                                    productItem.product?.condition_points || 0
                                }
                            />
                        </Stack>
                    );
                },
            },
            {
                Header: t('Function') as string,
                id: 'function_points',
                canSort: true,
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            <Points
                                criteria={{ title: 'function' }}
                                value={
                                    productItem.product?.function_points || 0
                                }
                            />
                        </Stack>
                    );
                },
            },
            {
                Header: t('Observation') as string,
                id: 'observation',
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            {(productItem.description?.length || 0) > 30 ? (
                                <OverlayTrigger
                                    placement='left'
                                    delay={{ show: 50, hide: 100 }}
                                    overlay={
                                        <Tooltip>
                                            {productItem.description}
                                        </Tooltip>
                                    }
                                >
                                    <div>
                                        {productItem.description?.substring(
                                            0,
                                            30
                                        ) + '...'}
                                    </div>
                                </OverlayTrigger>
                            ) : (
                                productItem.description?.substring(0, 30)
                            )}
                        </Stack>
                    );
                },
            },
            {
                Header: t('Quantity') as string,
                id: 'quantity',
                canSort: true,
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            <div>
                                {productItem.quantity}{' '}
                                {productItem.product?.category?.unit?.symbol}
                            </div>
                        </Stack>
                    );
                },
            },
            {
                Header: project?.firstPlacementTitle || '',
                id: 'firstPlacementValue',
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            <div>{productItem.firstPlacementValue}</div>
                        </Stack>
                    );
                },
            },
            {
                Header: project?.secondPlacementTitle || '',
                id: 'secondPlacementValue',
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            <div>{productItem.secondPlacementValue}</div>
                        </Stack>
                    );
                },
            },
            {
                Header: project?.thirdPlacementTitle || '',
                id: 'thirdPlacementValue',
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            <div>{productItem.thirdPlacementValue}</div>
                        </Stack>
                    );
                },
            },
            {
                Header: project?.fourthPlacementTitle || '',
                id: 'fourthPlacementValue',
                accessor: (productItem: IProductItem) => {
                    return (
                        <Stack>
                            <div>{productItem.fourthPlacementValue}</div>
                        </Stack>
                    );
                },
            },
        ],
        [
            t,
            project?.firstPlacementTitle,
            project?.fourthPlacementTitle,
            project?.secondPlacementTitle,
            project?.thirdPlacementTitle,
        ]
    );

    return (
        <Card className='shadow rounded-0'>
            <Table<ResourcePermissionsType<IProductItem>>
                name={'productItems'}
                columns={columns}
                data={productItems as ResourcePermissionsType<IProductItem>[]}
                initialPageSize={queryPageSize || 0}
                initialPageIndex={queryPageIndex || 0}
                initialSortBy={sortBy || { id: 'products.title', desc: false }}
                hiddenColumns={hiddenColumns}
                loading={loading}
                dispatch={dispatch || (() => {})}
                selectHooks={true}
                setSelectedItems={setSelectedItems}
                total={totalCount}
                getCellTooltip={(cell: any) => {
                    if (cell.column.id !== 'categories.name' || !categories) {
                        return null;
                    }

                    const { category } = cell.row.original.product;

                    return (
                        getCategoryTree(category, categories)
                            .map(({ name }) => name)
                            .join(' > ') || null
                    );
                }}
            />
        </Card>
    );
};
