import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import { AccordionCarrot } from './AccordionCarrot';
import { AccordionToggle } from './AccordionToggle';

interface AccordionHeaderProps {
    title: string;
    openKeys: string[];
    eventKey: string;
    toggle: (key: string) => void;
    disabled: boolean;
}
export const AccordionHeader: React.FC<AccordionHeaderProps> = ({
    children,
    title,
    openKeys,
    eventKey,
    toggle,
    disabled,
}) => {
    return (
        <AccordionToggle
            eventKey={eventKey}
            disabled={disabled}
            toggle={toggle}
        >
            <Card.Header
                className={`border-0 ${
                    disabled ? 'opacity-25' : ' bg-white'
                } px-0`}
            >
                <Stack direction='horizontal' gap={3}>
                    <h6 className='fw-bold mb-0 mb-sm-inherit'>{title}</h6>
                    <Stack className='ms-auto' direction='horizontal' gap={3}>
                        <Stack direction='horizontal' gap={2}>
                            {!openKeys.includes(eventKey) && children}
                        </Stack>
                        <AccordionCarrot
                            eventKey={eventKey}
                            openKeys={openKeys}
                        />
                    </Stack>
                </Stack>
            </Card.Header>
        </AccordionToggle>
    );
};
