import React, { useCallback, useEffect, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import AuthContext, { AuthContextProps } from './AuthContext';
import { useSanctum } from 'react-sanctum';
import IServerResponseType from '../../../../types/IServerResponseType';
import IOrganisationType from '../../../../types/IOrganisationType';
import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import IProjectType from '../../../../types/IProjectType';
import { useNavigate } from 'react-router';
import IResourceType from '../../../../types/IResourceType';

export const canFilter = (
    resource: ResourcePermissionsType<IResourceType>,
    permissions: string[]
) => resource.abilities.some((r) => permissions.includes(r));

interface Props {
    config: {
        apiUrl: string;
        axiosInstance?: AxiosInstance;
    };
}

axios.defaults.withCredentials = true;

const defualtAuthState = {
    organisations: [],
    organisation: null,
    projects: [],
    project: null,
};

export const AuthProvider: React.FC<Props> = ({ config, children }) => {
    const navigate = useNavigate();
    const { authenticated, user } = useSanctum();

    const [authState, setAuthState] = useState<{
        organisations: IOrganisationType[];
        organisation: ResourcePermissionsType<IOrganisationType> | null;
        projects: IProjectType[];
        project: ResourcePermissionsType<IProjectType> | null;
    }>(defualtAuthState);

    const [loading, setLoading] = useState<boolean | null>(null);

    const organisations = authState.organisations;
    const organisation = authState.organisation;

    const getOrganisations = useCallback(async () => {
        const { apiUrl } = config;
        let { data } = await axios.get<
            IServerResponseType<ResourcePermissionsType<IOrganisationType>[]>
        >(`${apiUrl}/me/organisations`);

        if (data.success) {
            const organisations = data.data;

            setAuthState((prev) => ({
                ...prev,
                organisations: organisations,
            }));

            return {
                success: data.success,
                data: organisations,
                message: data.message,
            };
        }

        return data;
    }, [config]);

    const setActiveOrganisation = useCallback(
        async (id: number) => {
            const { apiUrl } = config;

            try {
                const { data } = await axios.get<
                    IServerResponseType<
                        ResourcePermissionsType<IOrganisationType>
                    >
                >(`${apiUrl}/organisations/${id}`);

                if (data.success) {
                    setAuthState((prev) => ({
                        ...prev,
                        organisation: data.data,
                    }));

                    localStorage.setItem(
                        `organisation-${user?.id}`,
                        JSON.stringify(data.data.id)
                    );
                }
                return data;
            } catch (error) {
                localStorage.removeItem(`organisation-${user?.id}`);
                navigate('/401');
            }
        },
        [config, navigate, user]
    );

    const activeOrganisation = useCallback(
        () =>
            new Promise(async (resolve, reject) => {
                try {
                    const lastOrgId = localStorage.getItem(
                        `organisation-${user?.id}`
                    );

                    const organisations = await getOrganisations();
                    if (!organisations?.data?.length) {
                        return reject(new Error('No organisations'));
                    }

                    const organisation = await setActiveOrganisation(
                        lastOrgId
                            ? parseInt(lastOrgId)
                            : organisations.data[0].id
                    );

                    if (organisations && organisation) {
                        setLoading(false);
                    } else {
                        navigate('/401');
                    }

                    return resolve(true);
                } catch (error) {
                    return reject(error);
                }
            }),
        [getOrganisations, navigate, setActiveOrganisation, user]
    );

    const clearAuthCache = () => {
        setAuthState(defualtAuthState);
    };

    const isAllowedTo = (
        permissions: string[],
        resource: ResourcePermissionsType<IResourceType>
    ) => {
        return (resource?.abilities || []).some((r) =>
            (permissions || []).includes(r)
        );
    };

    const isSysAdmin = useCallback(() => {
        return new Promise<boolean>((resolve, reject) => {
            if (user) {
                resolve(user?.is_sys_admin === 1);
            }
        });
    }, [user]);

    const isAdmin = useCallback(() => {
        return new Promise<boolean>((resolve, reject) => {
            if (organisation) {
                resolve(organisation?.ability_role === 'Admin');
            }
        });
    }, [organisation]);

    useEffect(() => {
        if (authenticated && !organisation) {
            setLoading(true);
            activeOrganisation();
        }
    }, [activeOrganisation, authenticated, organisation]);

    return (
        <AuthContext.Provider
            value={
                {
                    getOrganisations,
                    setActiveOrganisation,
                    organisations,
                    organisation,
                    loading,
                    clearAuthCache,
                    isAllowedTo,
                    isSysAdmin,
                    isAdmin,
                } as AuthContextProps
            }
        >
            {children}
        </AuthContext.Provider>
    );
};
