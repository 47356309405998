import React from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { TableOfContents, Section } from './components/TableOfContents';
import { Page } from '../../../components/Layout/Page';
import { useTranslation } from 'react-i18next';
import { SEO } from '../../../components/SEO';

const Intro = () => (
    <p className='lh-md fs-4 fw-light pb-3'>
        Vi på Dacke.Online värnar om din integritet och vi ansvarar för de
        personuppgifter som vi samlar in och behandlar om dig. Här hittar du
        information om vårt arbete med integritet och dataskydd, inklusive hur
        vi samlar in och använder dina personuppgifter.
    </p>
);

const WhoAreWe = () => (
    <section>
        <h3 id='who-are-we' style={{ marginBottom: 24 }}>
            <strong>Vilka är Dacke.Online?</strong>
        </h3>

        <p>
            I denna policy kallas Dackeapp AB, Orgnr: 556547-3955 för
            Dacke.Online och är personuppgiftsansvarig.
        </p>

        <p>
            Dacke.Online ansvarar för samtliga personuppgifter som du lämnar i
            kontakt med oss vilket kan vara på hemsidan, vid
            kund/leverantörsmöten, via telefon, epost, chat eller när du besöker
            oss och deltar på nätverksträffar/seminarier.
        </p>

        <p>
            Personuppgifter är information som enskilt eller i kombination med
            andra uppgifter kan användas för att identifiera dig som fysisk
            individ. Det kan t.ex. vara ditt namn, din e-postadress eller ditt
            telefonnummer.
        </p>

        <p>
            Vårt främsta ändamål med att behandla dina personuppgifter är för
            att Dacke.Online ska kunna fullfölja åtaganden mot dig som
            kund/leverantör, men vi samlar även in uppgifter om dig som ännu
            inte är kund men som vi tänker kontakta för att erbjuda våra
            tjänster.
        </p>

        <p>
            Som personuppgiftsansvarig har Dacke.Online ansvaret för att dina
            personuppgifter behandlas på ett säkert sätt och att GDPR följs. Vi
            behandlar inte känsliga personuppgifter såvida det inte finns
            specifikt samtycke för att hantera dessa.
        </p>
    </section>
);

const PrivacyProtection = () => (
    <section>
        <h3 id='personal-data' style={{ marginBottom: 24 }}>
            <strong>Skydd av dina personuppgifter</strong>
        </h3>

        <p>
            Dacke.Online skyddar dina personuppgifter genom en kombination av
            tekniska och organisatoriska lösningar. Inloggning och specifik
            behörighet krävs för tillgång till samtliga av Dacke.Online system
            som hanterar personuppgifter. Anställda på Dacke.Online samt
            personuppgiftsbiträden och underbiträden ska följa Dacke.Onlines
            interna riktlinjer för dataskydd.
        </p>
    </section>
);

const RegRights = () => (
    <section>
        <h3 style={{ marginBottom: 24 }}>
            <strong>Som registrerad har du följande rättigheter</strong>
        </h3>
        <ul>
            <li>
                Du har rätt att få information om vilka
                personuppgiftsbehandlingar som vi gör.
            </li>

            <li>
                Du har rätt att begära ett registerutdrag där du kan se vilka
                personuppgiftsbehandlingar vi har om just dig.
            </li>

            <li>
                Du har rätt att begära rättning om vi har felaktigheter
                registrerade om dig.
            </li>

            <li>Du har rätt att åberopa rätten att bli glömd.</li>

            <li>
                Du har rätt till dataportabilitet (rätt att få dina
                personuppgifter flyttade) av personuppgifter som du själv
                tillhandahållit eller som genererats av dina
                handlingar/aktiviteter.
            </li>

            <li>
                Du har rätt att åberopa begränsning i behandlingen av dina
                personuppgifter.
            </li>

            <li>
                Du har rätt att invända mot en personuppgiftsbehandling och
                Dacke.Online kommer då att upphöra med behandlingen under tiden
                ärendet utreds.
            </li>

            <li>
                Du har rätt att framföra klagomål till tillsynsmyndigheten över
                behandlingar som utförs av oss. Det kan du göra på www.imy.se.
            </li>
        </ul>
        <p>
            Om du vill åberopa någon av dina rättigheter så kontaktar du vår
            Integritetskyddsansvarige via e-post info@dacke.online.
        </p>
    </section>
);

const Cookies = () => (
    <section>
        <h3 id='cookies' style={{ marginBottom: 24 }}>
            <strong>Cookies</strong>
        </h3>

        <p>
            En cookie (eller kaka) är en liten textfil som en webbplats sparar
            på besökarens dator. Cookies används på många webbplatser för att ge
            en besökare tillgång till olika funktioner. Informationen i en
            cookie kan också användas för att följa en användares surfande på
            webbplatser som använder samma cookie.
        </p>

        <h4 className='fw-light py-4'>Måste jag tillåta cookies?</h4>

        <p>
            Nej du behöver inte tillåta våra cookies. Webbplatsen kommer fungera
            även utan cookies men favoritmarkerade produkter kanske inte alltid
            visas som de ska. Om du inte vill att några cookies ska sparas på
            din dator när du stänger din webbläsare så kan du besöka sidan i
            privat läge. Detta läge kallas olika saker beroende på din
            webbläsare: "Inkognitofönster" (Chrome), "Privat fönster" (Firefox)
            eller "InPrivate-fönster" (Microsoft Edge). Alla cookies som satts
            under besöket i privat läge raderas då ifrån din dator när du
            stänger din webbläsare.
        </p>

        <h4 className='fw-light py-2'>Hur använder vi cookies?</h4>

        <p>
            Denna webbplats använder cookies för att hantera artiklar som du
            favoritmarkerat.
        </p>

        <h4 className='fw-light py-2'>Tredjepartscookies</h4>

        <p>
            Vi använder Google Analytics som också sätter cookies, detta för att
            få en bild av hur våra besökare använder webbplatsen samt för vår
            marknadsföring.{' '}
        </p>
    </section>
);

interface Props {}

export const PrivacyPolicy = (props: Props) => {
    const { t } = useTranslation();

    const sections: Section[] = [
        { id: '#who-are-we', title: t('Who are we?') },
        { id: '#personal-data', title: t('Personal data') },
        { id: '#cookies', title: t('Cookies') },
    ];

    return (
        <Page style={{ marginBottom: 112 }}>
            <Container>
                <Row>
                    <Col md={2}>
                        <TableOfContents
                            sections={sections}
                            className='position-fixed top-4 d-none d-lg-block'
                        />
                    </Col>
                    <Col md={1} className='d-none d-xxl-block' />
                    <Col md={9}>
                        <Container fluid style={{ fontSize: 'larger' }}>
                            <Stack style={{ gap: 42 }}>
                                <div>
                                    <SEO
                                        title={t('Privacy policy')}
                                        className='fw-light lh-lg mb-3'
                                    ></SEO>
                                    <Intro />
                                </div>

                                <WhoAreWe />

                                <PrivacyProtection />

                                <RegRights />

                                <Cookies />
                            </Stack>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
