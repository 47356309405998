import { useTranslation } from 'react-i18next';

export type Section = {
    id: string;
    title: string;
};

interface TableOfContentsProps {
    sections: Section[];
    className?: string;
}

export const TableOfContents: React.FC<TableOfContentsProps> = ({
    sections,
    className,
}) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <h3 className='pb-2'>
                <strong>{t('Contents')}</strong>
            </h3>
            <ul className='p-0 m-0' style={{ listStyleType: 'none' }}>
                {sections.map(({ id, title }, idx) => (
                    <li key={idx} className='py-2'>
                        <a
                            href={id}
                            className='font-weight-bold text-decoration-none fs-5'
                        >
                            {title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
