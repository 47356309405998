import React, { useCallback, useEffect, useState } from 'react';
import { Page } from '../../../components/Layout/Page';

import { Column, SortingRule } from 'react-table';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import {
    Button,
    ButtonToolbar,
    Card,
    Container,
    Breadcrumb,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ADDIcon } from '../../../assets/icones/Dacke_nytt.svg';

import IOrganisationType from '../../../types/IOrganisationType';
import { Table } from '../../../components/Table';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useThrottle } from '@react-hook/throttle';
import { Row as TRow } from 'react-table';
import { OrganisationCreateModal } from '../components/OrganisationCreateModal';
import { useWindowSize } from '../../Organisation/hooks/useWindowSize';
import { useNavigate } from 'react-router';
import { SearchInput } from '../../../components/SearchInput';
import { useTableReducer } from '../../../hooks/useTableReducer';
import { SEO } from '../../../components/SEO';

interface Props {}

export const SystemadminOrganisationsPage = (props: Props) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [{ queryPageIndex, queryPageSize, sortBy }, dispatch] =
        useTableReducer();

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useThrottle('', 2);

    const [organisations, setOrganisations] = useState<
        ResourcePermissionsType<IOrganisationType>[]
    >([]);
    const [total, setTotal] = useState<number>(-1);

    const [showOrganisationCreateModal, setShowOrganisationCreateModal] =
        useState(false);

    const handleOrganisationCreateModalClose = (
        newOrganisationWasCreated: boolean
    ) => {
        if (newOrganisationWasCreated)
            fetchAPIData(queryPageSize, queryPageIndex, sortBy);
        setShowOrganisationCreateModal(false);
    };
    const handleOrganisationCreateModalShow = () =>
        setShowOrganisationCreateModal(true);

    const getMetaData = useCallback(
        (header: string, metaKey: string, canSort: boolean = false) => {
            return {
                id: metaKey,
                Header: t(header),
                canSort,
                accessor: (row: IOrganisationType) =>
                    row.organisation_meta.find(
                        (meta) => meta.meta_key === metaKey
                    )?.meta_value,
            };
        },
        [t]
    );

    // Decide if hide table columns based on window size
    const { width } = useWindowSize();
    const hiddenColumns =
        width && width < 992
            ? ['contactPerson', 'contactPersonMail', 'contactPersonPhone']
            : [];

    const columns = React.useMemo<
        Column<ResourcePermissionsType<IOrganisationType>>[]
    >(
        () => [
            {
                Header: 'ID',
                canSort: true,
                accessor: (row) => (
                    <p className='m-0'>{String(row.id).padStart(3, '0')}</p>
                ),
            },
            {
                Header: t('Namn') as string,
                accessor: 'name',
                canSort: true,
            },
            getMetaData('Contact person', 'contactPerson'),
            getMetaData('Email', 'contactPersonMail'),
            getMetaData('Phone number', 'contactPersonPhone'),
            {
                Header: t('Active') as string,
                id: 'is_active',
                canSort: true,
                accessor: (row) =>
                    row.is_active ? (
                        <p className='text-success'>Yes</p>
                    ) : (
                        <p className='text-danger'>No</p>
                    ),
            },
        ],
        [getMetaData, t]
    );

    const fetchAPIData = useCallback(
        async (
            pageSize: number,
            pageIndex: number,
            sortBy?: SortingRule<string>
        ) => {
            try {
                setLoading(true);

                const sortStr = sortBy
                    ? `&sortBy=${sortBy.id}&direction=${
                          sortBy.desc ? 'desc' : 'asc'
                      }`
                    : '';

                const response = await axios.get(
                    `/api/organisations?limit=${pageSize}&skip=${
                        pageIndex * pageSize
                    }${sortStr}&search=${searchTerm}`
                );

                if (response) {
                    const { data: d, total: t } = response.data;
                    setOrganisations(d);
                    setTotal(t);

                    setLoading(false);
                }
            } catch (e) {
                console.error('Error while fetching', e);
                setLoading(false);
            }
        },
        [searchTerm]
    );

    const actions = React.useMemo(
        () => [
            {
                name: 'Edit',
                render: (
                    instance: TRow<ResourcePermissionsType<IOrganisationType>>
                ) => (
                    <div
                        onClick={() => {
                            navigate(`${instance.original.id}`);
                        }}
                        className='cursor-pointer'
                    >
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            width='15'
                            className='cursor-pointer'
                        />
                    </div>
                ),
            },
        ],
        [navigate]
    );

    useEffect(() => {
        fetchAPIData(queryPageSize, queryPageIndex, sortBy);
    }, [fetchAPIData, queryPageSize, queryPageIndex, sortBy]);

    return (
        <Page className='px-0 px-sm-inherit'>
            <Container fluid className='px-0 px-sm-inherit'>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    <Breadcrumb>
                        {/* handles breadcrumb to change between system admin page and orgadmin page */}
                        <Breadcrumb.Item>{t('System admin')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {t('Organisations')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </ButtonToolbar>

                <Card>
                    <Card.Body>
                        <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <SEO title={t('Organisations')} asH6></SEO>
                            </div>

                            <div className='d-flex align-items-center '>
                                <div className='d-flex gap-3 flex-column flex-md-row flex-fill'>
                                    <div className='d-flex justify-content-end'>
                                        <SearchInput
                                            onChange={setSearchTerm}
                                            placeholder={t('Search')}
                                            size='sm'
                                        />
                                    </div>
                                    <Button
                                        onClick={
                                            handleOrganisationCreateModalShow
                                        }
                                        variant='secondary'
                                        size='sm'
                                        className='m-1 d-none d-sm-block'
                                    >
                                        <ADDIcon width='12' />
                                        {t('Add new organisation')}
                                    </Button>
                                </div>
                            </div>
                        </ButtonToolbar>
                        <Table<ResourcePermissionsType<IOrganisationType>>
                            name={'Organisations'}
                            columns={columns}
                            data={organisations}
                            initialPageSize={queryPageSize || 0}
                            initialPageIndex={queryPageIndex || 0}
                            initialSortBy={
                                sortBy || { id: 'name', desc: false }
                            }
                            loading={loading}
                            hiddenColumns={hiddenColumns}
                            actions={actions}
                            dispatch={dispatch}
                            size='sm'
                            variant='sysadmin'
                            total={total}
                        />
                        <Button
                            onClick={handleOrganisationCreateModalShow}
                            variant='secondary'
                            className='d-sm-none m-1 w-100'
                        >
                            <ADDIcon width='12' />
                            {t('Add new organisation')}
                        </Button>
                    </Card.Body>
                </Card>

                <OrganisationCreateModal
                    show={showOrganisationCreateModal}
                    handleClose={handleOrganisationCreateModalClose}
                    handleShow={handleOrganisationCreateModalShow}
                ></OrganisationCreateModal>
            </Container>
        </Page>
    );
};
