import { useContext } from 'react';
import AuthContext, {
    AuthContextProps,
    AuthenticatedState,
} from '../contexts/Auth/AuthContext';
import { useSanctum } from 'react-sanctum';

export const useAuth = () => {
    const ctx = useContext<AuthContextProps>(AuthContext);

    let _authenticated = AuthenticatedState.NOT_CHECKED;

    const { authenticated, user } = useSanctum();
    const { organisation } = ctx;

    if (authenticated === null) {
        _authenticated = AuthenticatedState.NOT_CHECKED;
    } else if (!authenticated) {
        _authenticated = AuthenticatedState.NOT_AUTHENTICATED;
    } else if (authenticated && !organisation) {
        _authenticated = AuthenticatedState.LOADING;
    } else if (authenticated && organisation) {
        _authenticated = AuthenticatedState.AUTHENTICATED;
    }

    return {
        ...ctx,
        isAuthenticatedState: _authenticated,
        user,
    };
};
