import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiFullscreen as FullscreenIcon } from 'react-icons/bi';
import { ImageFlipButtons } from '../../../../../components/Images/ImageFlipButtons';

export interface ImageDataFormProps {
    selected: any;
    onBlurImageText: (e: any) => void;
    changeImageText: (data: any) => void;
    changeIsPublic: (data: any) => void;
    remove: () => void;
    flip: (direction: 'h' | 'v') => void;
    showLightbox: () => void;
}

export const ImageDataForm: React.FC<ImageDataFormProps> = ({
    selected,
    onBlurImageText,
    changeImageText,
    changeIsPublic,
    remove,
    flip,
    showLightbox,
}) => {
    const { t } = useTranslation();

    const titleMaxLen = 150;
    return (
        <>
            <Form.Group className='mb-3' controlId='imageText'>
                <Form.Label>
                    {t('Image text')} {selected?.title?.length || 0} /{' '}
                    {titleMaxLen}
                </Form.Label>
                <Form.Control
                    type='text'
                    placeholder=''
                    onChange={changeImageText}
                    onBlur={onBlurImageText}
                    value={selected?.title || ''}
                    maxLength={titleMaxLen}
                    disabled={!selected}
                />
            </Form.Group>

            <div className='d-flex gap-4 justify-content-between'>
                <div className='d-flex gap-2'>
                    <ImageFlipButtons flip={flip} disabled={!selected} />
                </div>
                <FullscreenIcon onClick={showLightbox} size='2rem' />
            </div>

            <Form.Group className='mb-3' controlId='public'>
                <Form.Label>{t('Visible on Dacke Marketplace')}</Form.Label>
                <Form.Check
                    type='switch'
                    id='custom-switch'
                    checked={!!selected?.is_public}
                    onChange={changeIsPublic}
                />
            </Form.Group>

            <Button
                size='sm'
                onClick={remove}
                disabled={!selected}
                variant='danger'
            >
                {t('Remove')}
            </Button>
        </>
    );
};
