import { PropsWithChildren, ReactElement, useEffect, useMemo } from 'react';
import {
    Table as BTable,
    Image,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import {
    Cell,
    Hooks,
    Row,
    TableOptions,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
    SortingRule,
} from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrashCan,
    faCaretUp,
    faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import EDITIcon from '../assets/icones/Dacke_redigera.svg';
import { useTranslation } from 'react-i18next';
import {
    ITableAction,
    PAGE_CHANGED,
    PAGE_SIZE_CHANGED,
    TOTAL_COUNT_CHANGED,
    SORT_BY_CHANGED,
} from '../hooks/useTableReducer';
import { PageCountPicker } from './PageCountPicker';
import { TablePagination } from './TablePagination';

import './Table.scss';

const noop = () => {};

export function selectionHook<T extends {}>(hooks: Hooks<T>) {
    hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
            id: 'selection',

            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className='align-items-center'>
                    <IndeterminateCheckbox
                        {...(getToggleAllRowsSelectedProps &&
                            (getToggleAllRowsSelectedProps() as any))}
                    />
                </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: Cell<T>) => {
                return (
                    <div>
                        <IndeterminateCheckbox
                            {...(row?.getToggleRowSelectedProps &&
                                (row.getToggleRowSelectedProps() as any))}
                        />
                    </div>
                );
            },
        },
        ...columns,
    ]);
}

export type RowAction<T extends {}> = {
    name: string;
    render?: (instance: Row<T>) => JSX.Element | HTMLImageElement;
};

export interface TableProperties<T extends {}> extends TableOptions<T> {
    name: string;
    onDelete?: (instance: Row<T>) => void;
    onEdit?: (instance: Row<T>) => void;
    initialPageSize: number;
    initialPageIndex: number;
    initialSortBy?: SortingRule<string>;

    actions?: RowAction<T>[];

    loading?: boolean;
    selectHooks?: boolean;

    dispatch: React.Dispatch<ITableAction>;

    size?: 'sm' | undefined;

    setSelectedItems?: React.Dispatch<React.SetStateAction<T[]>>;

    variant?: 'sysadmin' | 'regular';
    isImport?: boolean;
    total?: number;
}

export function Table<T extends {}>(
    props: PropsWithChildren<TableProperties<T>>
): ReactElement {
    const { t } = useTranslation();

    const {
        columns,
        onDelete,
        onEdit,
        initialPageSize,
        initialPageIndex,
        initialSortBy,
        hiddenColumns,
        selectHooks,
        actions,
        dispatch,
        data,
        size,
        setSelectedItems,
        onCellClick = noop,
        getCellTooltip = noop,
        variant = 'regular',
        isImport = false,
        total = -1,
    } = props;

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        setHiddenColumns,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            ...props,
            columns,
            manualPagination: true,
            pageCount: -1,
            manualSortBy: true,
            defaultCanSort: false,
            initialState: {
                hiddenColumns: hiddenColumns || [],
                pageSize: initialPageSize,
                pageIndex: initialPageIndex,
                sortBy: initialSortBy ? [initialSortBy] : [],
            },
            useControlledState: (state) => {
                return {
                    ...state,
                    actions,
                };
            },
        },

        useSortBy,

        usePagination,

        ...(selectHooks ? [useRowSelect, selectionHook] : []),

        (hooks: Hooks<T>) => {
            hooks.visibleColumns.push((columns) => [
                ...columns,

                {
                    id: 'action',
                    Cell: ({ row, state, ...rest }: Cell<T>) => {
                        return (
                            <div className='d-flex gap-2 justify-content-end'>
                                {(state as any).actions?.map(
                                    ({ name, render }: RowAction<T>) => (
                                        <div
                                            key={name}
                                            className='d-flex align-items-center'
                                        >
                                            <OverlayTrigger
                                                placement='top'
                                                delay={{ show: 50, hide: 100 }}
                                                overlay={
                                                    <Tooltip>{t(name)}</Tooltip>
                                                }
                                            >
                                                <div>
                                                    {render && render(row)}
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    )
                                )}
                                <div>
                                    {onDelete && (
                                        <FontAwesomeIcon
                                            icon={faTrashCan}
                                            width='15'
                                            onClick={() => onDelete(row)}
                                            className='cursor-pointer'
                                        />
                                    )}
                                </div>
                                <div>
                                    {onEdit && (
                                        <div>
                                            <Image
                                                src={EDITIcon}
                                                width='15'
                                                onClick={() => onEdit(row)}
                                                className='cursor-pointer'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    },
                },
            ]);
        }
    );

    // Manage selected row ids
    const newSelectedItems = rows
        .filter((r) => r.isSelected)
        .map((r) => r.original);
    useEffect(() => {
        if (!setSelectedItems) return;
        const arrayEquals = (a1: any[], a2: any[]) => {
            const a2Sorted = a2.slice().sort();
            return (
                a1.length === a2.length &&
                a1
                    .slice()
                    .sort()
                    .every((v, i) => v === a2Sorted[i])
            );
        };
        setSelectedItems((prev) =>
            arrayEquals(prev, newSelectedItems) ? prev : newSelectedItems
        );
    }, [newSelectedItems, setSelectedItems]);

    // Dynamically Hide columns
    useEffect(() => {
        if (hiddenColumns) {
            setHiddenColumns(hiddenColumns);
        }
    }, [setHiddenColumns, hiddenColumns]);

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [dispatch, pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage, dispatch]);

    useEffect(() => {
        const [s] = sortBy;
        dispatch({ type: SORT_BY_CHANGED, payload: 0, sortBy: s });
        gotoPage(0);
    }, [sortBy, gotoPage, dispatch]);

    useEffect(() => {
        if (data?.length) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data.length,
            });
        }
    }, [data, dispatch]);

    const dataRange: string = useMemo(
        () =>
            total === 0
                ? '0'
                : `${pageIndex * pageSize + 1}-${Math.min(
                      total,
                      (pageIndex + 1) * pageSize
                  )}`,
        [pageIndex, pageSize, total]
    );

    const isSysadmin = variant === 'sysadmin';
    // Render the UI for your table
    return (
        <>
            <div className='rounded-2'>
                <BTable
                    borderless
                    striped
                    hover
                    size='sm'
                    {...getTableProps({
                        className: (isSysadmin ? 'sys-admin' : '') + (isImport ? ' excel-import-table' : ''),
                    })}
                    style={{
                        borderSpacing: 0,
                        margin: 0,
                    }}
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    const c = columns.find(
                                        (x) => x.id === column.id
                                    ) as any;
                                    return (
                                        <th
                                            {...column.getHeaderProps({
                                                className: `fw-light text-uppercase ${
                                                    !isSysadmin
                                                        ? 'text-secondary'
                                                        : ''
                                                }`,
                                                ...(c?.canSort
                                                    ? column.getSortByToggleProps()
                                                    : {}),
                                            })}
                                        >
                                            {column.render('Header')}
                                            {c?.canSort ? (
                                                <span
                                                    className={
                                                        column.isSorted
                                                            ? 'ps-1'
                                                            : ''
                                                    }
                                                >
                                                    {!column.isSorted ? null : column.isSortedDesc ? (
                                                        <FontAwesomeIcon
                                                            icon={faCaretDown}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faCaretUp}
                                                        />
                                                    )}
                                                </span>
                                            ) : null}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps({})}>
                                    {row.cells.map((cell) => {
                                        const tooltip = getCellTooltip(cell);

                                        return (
                                            <td
                                                {...cell.getCellProps({
                                                    style: {
                                                        verticalAlign: 'middle',
                                                    },
                                                })}
                                                onClick={() =>
                                                    onCellClick(cell)
                                                }
                                                className={
                                                    onCellClick !== noop
                                                        ? 'cursor-pointer'
                                                        : ''
                                                }
                                            >
                                                {tooltip ? (
                                                    <OverlayTrigger
                                                        placement='top'
                                                        delay={{
                                                            show: 50,
                                                            hide: 100,
                                                        }}
                                                        overlay={
                                                            <Tooltip>
                                                                {tooltip}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div>
                                                            {cell.render(
                                                                'Cell'
                                                            )}
                                                        </div>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <div>
                                                        {cell.render('Cell')}
                                                    </div>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </BTable>
            </div>

            <div className={isSysadmin ? 'sys-admin-footer' : 'bg-light'}>
                <div
                    className={`d-flex flex-column-reverse flex-md-row justify-content-center justify-content-md-between align-items-center mx-4 gap-3   ${
                        !size ? 'py-3' : 'py-2'
                    }`}
                >
                    <div className='d-flex align-items-center gap-2'>
                        <div className='text-nowrap'>
                            {total > -1
                                ? `${t('Shows')}: ${dataRange}/${total}`
                                : ''}
                        </div>
                        <PageCountPicker
                            pageSize={initialPageSize}
                            onChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            totalCount={data.length}
                            size={size}
                        ></PageCountPicker>
                    </div>
                    <TablePagination
                        to={pageIndex || 0}
                        active={pageIndex}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        gotoPage={gotoPage}
                        pageCount={pageCount}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        showAmount={data.length}
                        pageSize={initialPageSize}
                        size={size}
                    />
                </div>
            </div>
        </>
    );
}
