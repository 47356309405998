import React, { useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { useDropzone } from 'react-dropzone';
import { dropzone, thumbsContainer } from './Styles';
import { useTranslation } from 'react-i18next';
import { ImageDataForm } from './ImageDataForm';
import { Thumbs } from './Thumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import useIsMobile from '../../hooks/useIsMobile';

interface Props {
    files: any[];
    onAdd: (files: any[]) => void;
    onUpdate: (files: any[], finishedUpdating: boolean) => void;
    onDelete: (files: any[]) => void;
    loading?: boolean;
}

export const ImageManager = ({
    files,
    onAdd,
    onUpdate,
    onDelete,
    loading,
}: Props) => {
    const { t } = useTranslation();

    const [selectedId, setSelectedId] = useState<number>(-1);
    const [dragging, setDragging] = useState(false);

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (loading) return;
            // Since removals etc. we make sure newest file has bigger integer value of sort_order
            // We will never overflow unless you add a gazillion images
            // If we dont do this, sort_order will be corrupted when removing images before the last
            // (0,1,2,3,4) or (4,52,67,1925,5555).  It doesn't matter when sorting.
            const highestSortOrder = files?.length
                ? files.reduce((a, b) => (b.sort_order > a.sort_order ? b : a))
                      .sort_order
                : -1;

            const newFiles: any[] =
                acceptedFiles.map((file: any, index: number) => {
                    return Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        index: files.length + index,
                        sort_order: highestSortOrder + 1 + index,
                        is_public: false,
                    });
                }) || [];

            // let them above deal with api and stuff
            onAdd(newFiles);
        },
        [files, loading, onAdd]
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/png, image/jpg, image/jpeg, image/webp',
        onDrop,
    });

    const selectImage = useCallback(
        (index: number) => {
            if (dragging) return;

            if (files[index]) setSelectedId(files[index].id);
        },
        [dragging, files]
    );

    const changeImageText = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            let file = files.find((f) => f.id === selectedId);
            if (file) {
                file.title = e.target.value;
                onUpdate([file], false);
            }
        },
        [selectedId, files, onUpdate]
    );

    const onBlurImageText = useCallback(() => {
        let file = files.find((f) => f.id === selectedId);
        if (file) {
            onUpdate([file], true);
        }
    }, [selectedId, files, onUpdate]);

    const changeImagePublic = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            let file = files.find((f) => f.id === selectedId);
            if (file) {
                file.is_public = e.target.checked;
                onUpdate([file], true);
            }
        },
        [files, onUpdate, selectedId]
    );
    const onDragThumb = useCallback(
        (toIndex: number, fromIndex: number) => {
            if (!dragging) {
                setDragging(true);
            }

            let from = files[fromIndex];
            let to = files[toIndex];
            if (from && to && from !== to) {
                const temp = from.sort_order;
                from.sort_order = to.sort_order;
                to.sort_order = temp;
                onUpdate([from, to], false);
            }
        },
        [dragging, files, onUpdate]
    );

    const onDropThumb = useCallback(
        ({ index }: any) => {
            if (dragging) setDragging(false);
            onUpdate(files, true);
        },
        [dragging, files, onUpdate]
    );

    const handleRemove = useCallback(
        (id: number) => {
            let file = files.find((f) => f.id === id);
            if (file) {
                onDelete([file]);
            }
        },
        [files, onDelete]
    );

    const isMobile = useIsMobile();
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

    return (
        <Row className='d-flex flex-row'>
            <Col
                {...getRootProps({
                    className:
                        'dropzone cursor-pointer m-2 card bg-light text-center border-0',
                })}
                md={12}
                xl={3}
                style={dropzone}
            >
                <input {...getInputProps()} />
                <div className='m-4 fs-1'>
                    <FontAwesomeIcon icon={faCloudUpload} />
                </div>
                <h5>{t('Upload images')}</h5>
                <p>
                    {t('Drag one or more files here, or click to select files')}
                </p>
            </Col>
            <Col
                className='d-flex flex-column align-items-center'
                md={12}
                xl={6}
            >
                <div style={thumbsContainer}>
                    <DndProvider
                        backend={
                            isMobile && isTouchDevice
                                ? TouchBackend
                                : HTML5Backend
                        }
                    >
                        <Thumbs
                            files={files.sort(
                                (a, b) => a.sort_order - b.sort_order
                            )}
                            selected={selectedId}
                            select={selectImage}
                            handleMove={loading ? () => {} : onDragThumb}
                            onDrop={loading ? () => {} : onDropThumb}
                            loading={loading}
                            hideIsPublic={true}
                        ></Thumbs>
                    </DndProvider>
                </div>
            </Col>
            <Col md={12} xl={3}>
                {
                    <ImageDataForm
                        selected={
                            loading
                                ? undefined
                                : files?.find((f) => f.id === selectedId)
                        }
                        changeImageText={changeImageText}
                        onBlurImageText={onBlurImageText}
                        changeIsPublic={changeImagePublic}
                        remove={() => handleRemove(selectedId)}
                        hideIsPublic={true}
                    ></ImageDataForm>
                }
            </Col>
        </Row>
    );
};
