import React, { useCallback, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IProduct from '../../../../types/IProductType';
import { useAuth } from '../../../Auth/hooks/useAuth';
import axios from 'axios';
import { AlertModal } from '../Modals/AlertModal';
import { useProductModals } from './ProductModals';

export const ProductDuplicateModal: React.FC<any> = ({
    products,
    onClose,
    onSave,
}) => {
    const { t } = useTranslation();

    const { showDuplicateModal, setShowDuplicateModal } = useProductModals();
    const [showAlertModal, setShowAlertModal] = useState<any>(null);

    const { organisation } = useAuth();

    const [productsToDuplicate, setProductsToDuplicate] = useState([]);
    const [includeItems, setIncludeItems] = useState<boolean>(false);
    const [includeImages, setIncludeImages] = useState<boolean>(false);
    const [includeDocuments, setIncludeDocuments] = useState<boolean>(false);

    const updataAllProducts = async () => {
        try {
            const productIds = productsToDuplicate.map(
                (product: IProduct) => product.id
            );

            const updatedData: any = {
                productIds,
                includeItems,
                includeImages,
                includeDocuments,
            };

            const request = await axios.post(
                `/api/organisations/${organisation?.id}/copyproducts`,
                updatedData
            );

            if (request) {
                clearState();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const clearState = useCallback(() => {
        // Clear state
        setProductsToDuplicate([]);
        setIncludeItems(false);
        setIncludeImages(false);
        setIncludeDocuments(false);
    }, []);

    return (
        <>
            <Modal
                show={showDuplicateModal}
                onHide={() => {
                    setShowDuplicateModal(false);
                    onClose();
                    clearState();
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Duplicate products')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('This will duplicate {{amount}} products', {
                            amount: products?.length || 0,
                        })}
                    </p>
                    {t('Choos what to duplicate')}

                    <Form>
                        <Form.Check
                            type='switch'
                            id='includeItems'
                            label={t('Include items')}
                            onChange={(e) => setIncludeItems(e.target.checked)}
                            defaultChecked={includeItems}
                        />
                        <Form.Check
                            type='switch'
                            id='includeImages'
                            label={t('Include images')}
                            onChange={(e) => setIncludeImages(e.target.checked)}
                            defaultChecked={includeImages}
                        />
                        <Form.Check
                            type='switch'
                            id='includeDocuments'
                            label={t('Include documents')}
                            onChange={(e) =>
                                setIncludeDocuments(e.target.checked)
                            }
                            defaultChecked={includeDocuments}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        onClick={() => {
                            onClose();
                            clearState();
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            setProductsToDuplicate(products);
                            setShowDuplicateModal(false);
                            setShowAlertModal({
                                title: 'Duplicate products',
                                message:
                                    'This will duplicate {{amount}} products',
                                messageData: showDuplicateModal?.length || 0,
                            });
                        }}
                    >
                        {t('Save')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <AlertModal
                showAlertModal={showAlertModal}
                onClose={() => {
                    setShowAlertModal(false);
                    clearState();
                }}
                onSave={() => {
                    updataAllProducts().then((data) => {
                        setShowAlertModal(false);
                        onClose();
                    });
                }}
            ></AlertModal>
        </>
    );
};
