import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IProduct from '../../../../types/IProductType';
import axios from 'axios';
import { useProductModals } from './ProductModals';
import { saveAs } from 'file-saver';
import { useAuth } from '../../../Auth/hooks/useAuth';

export const ProductPDFExportModal: React.FC<any> = ({
    products,
    onClose,
    onSave,
}) => {
    const { t } = useTranslation();

    const { setShowPDFExportProductsModal, showPDFExportProductsModal } =
        useProductModals();

    const { organisation } = useAuth();

    const exportAllProducts = async () => {
        try {
            const productIds = showPDFExportProductsModal.map(
                (product: IProduct) => product.id
            );

            const response = await axios.get(
                `/api/organisations/${
                    organisation?.id
                }/exportproductspdf?${productIds
                    .map(
                        (id: number, index: number) =>
                            `productIds[${index}]=${id}`
                    )
                    .join('&')}`,
                {
                    responseType: 'blob',
                }
            );

            if (response) {
                saveAs(response.data, 'products.pdf');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Modal
                show={showPDFExportProductsModal}
                onHide={() => {
                    setShowPDFExportProductsModal(false);
                    onClose();
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Export products as PDF')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t(
                            'This will export {{amount}} products to an pdf file',
                            { amount: showPDFExportProductsModal?.length || 0 }
                        )}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => onClose()}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            exportAllProducts().then((data) => {
                                setShowPDFExportProductsModal(false);
                            });
                        }}
                    >
                        {t('Save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
