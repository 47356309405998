import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { RichTextEditor } from '../../../../../components/RichTextEditor';

import { useProjectFormState } from '../ProjectFormState';
import IProjectType from '../../../../../types/IProjectType';

interface Props {}

export const DescriptionForm = (props: Props) => {
    const { t } = useTranslation();

    const { setFormState, ...project } = useProjectFormState();

    const updateDescription = useCallback(() => {
        const put = axios.put<IProjectType>(
            `/api/organisations/${project?.organisation_id}/projects/${project?.id}`,
            project
        );

        put.then((res) => {
            if (res.data) {
                setFormState((prev) => ({
                    ...prev,
                    ...res.data,
                }));
            }
        });
    }, [project, setFormState]);

    return (
        <Row>
            <Col sm={12} xl={12}>
                <Form>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    {t('Project description')}
                                </Form.Label>
                                <RichTextEditor
                                    value={project.description || ''}
                                    onChange={(e) =>
                                        setFormState((prev) => ({
                                            ...prev,
                                            description: e,
                                        }))
                                    }
                                    onBlur={updateDescription}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
