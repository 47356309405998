import React, { Component } from 'react';
import history from 'history/browser';

import { SimpleNavBar } from './SimpleNavBar';

interface ErrorBoundaryProps {}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
    const fallbackComponent = (clearErrors: () => void) => {
        return (
            <>
                <SimpleNavBar />
                <div style={{ textAlign: 'center', marginTop: '30vh' }}>
                    <h4 style={{ color: 'crimson' }}>Something went wrong.</h4>
                    <h5>Please contact support if the error persists.</h5>
                    <button
                        style={{
                            background: 'none',
                            marginTop: '1rem',
                            border: 'none',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: 'blue',
                        }}
                        onClick={() => {
                            clearErrors();
                            history.back();
                        }}
                    >
                        Take me back
                    </button>
                </div>
            </>
        );
    };
    return (
        <ErrorBoundaryClassComponent fallback={fallbackComponent}>
            {children}
        </ErrorBoundaryClassComponent>
    );
};

// There are no function comp yet for error boundary
// So Let's wrap the class component in a function component

interface Props {
    fallback?: (clearErrors: () => void) => JSX.Element;
}
type State = {
    hasError: boolean;
};

class ErrorBoundaryClassComponent extends Component<Props, State> {
    state: State = { hasError: false };

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            if (this.props.fallback)
                return this.props.fallback(() =>
                    this.setState({ hasError: false })
                );

            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}
