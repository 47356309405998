import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    value: string;
    onChange: (e: any) => void;
}

const stockOptions = [
    { title: 'Show quantity', value: 'show_quantity' },
    { title: 'In stock', value: 'in_stock' },
    { title: 'Order item', value: 'order' },
];

export function StockItemSelect({ value, onChange }: Props) {
    const { t } = useTranslation();

    return (
        <Form.Select
            value={value}
            onChange={(option) => option && onChange(option)}
        >
            {stockOptions.map(({ title, value }) => (
                <option key={title} value={value}>
                    {t(title)}
                </option>
            ))}
        </Form.Select>
    );
}
