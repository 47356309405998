import React, { CSSProperties } from 'react';
import { Stack } from 'react-bootstrap';

interface Props {
    name: string;
    value: string | number;
    icon: JSX.Element;
    style?: CSSProperties;
}

export const BasicStat: React.FC<Props> = ({ name, value, icon, style }) => {
    return (
        <Stack
            direction='horizontal'
            className='border'
            style={{
                minHeight: '7rem',
                ...style,
            }}
        >
            {icon}
            <div className='d-flex flex-column flex-grow-1 align-items-center justify-content-center'>
                <div className='d-block fs-1 fw-bold'>{value}</div>
                <div className='d-block text-center'>{name}</div>
            </div>
        </Stack>
    );
};
