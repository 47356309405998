import './DnDImage.scss';

export interface DnDImageProps {
    preview?: string;
    name: string;
    type: string;
    index: number;
}

export const DnDImage: React.FC<DnDImageProps> = ({
    preview,
    name,
    type,
    index,
}) => {
    return (
        <div className='thumb-inner'>
            {preview ? (
                <>
                    <img
                        id={`image-${index}`}
                        className='dnd-img'
                        src={preview}
                        alt='preview'
                    />
                </>
            ) : (
                <span className='dnd-img' />
            )}
        </div>
    );
};
