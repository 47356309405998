import React, { useCallback, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAuth } from '../../../Auth/hooks/useAuth';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';

interface Props {
    show: boolean;
    handleClose: () => void;
    placement: any;
}

export const PlacementEditModal: React.FC<Props> = ({
    show,
    handleClose,
    placement,
}) => {
    const { t } = useTranslation();
    const { organisation } = useAuth();
    const { projectId } = useParams();

    const {
        handleSubmit,
        reset,
        control,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (placement?.label) {
            setValue('title', placement.label);
        }
    }, [setValue, placement]);

    const onClose = () => {
        handleClose();
    };

    const onSubmit = useCallback(
        async (data: any) => {
            const { title } = data;
            if (organisation?.id && projectId && title && placement?.value) {
                try {
                    await axios.patch(
                        `/api/organisations/${organisation?.id}/projects/${projectId}/placements/${placement.value}`,
                        {
                            title,
                        }
                    );
                } catch (error) {
                    return;
                }
                handleClose();
            }
        },
        [placement, organisation, projectId, handleClose]
    );

    return (
        <Modal onHide={onClose} centered show={show}>
            <Modal.Header closeButton>
                <Modal.Title>{t('Edit placement')}</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                <Modal.Body>
                    <Form.Group className='mb-3'>
                        <Controller
                            control={control}
                            name='title'
                            defaultValue=''
                            rules={{ required: true }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Control
                                    required
                                    onChange={onChange}
                                    value={value}
                                    ref={ref}
                                    isInvalid={errors.title}
                                    placeholder={t('Enter title')}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Control.Feedback type='invalid'>
                        {errors.title?.message}
                    </Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer>
                    <Controller
                        name='submit'
                        control={control}
                        render={({ field: { ref }, formState }) => (
                            <Button
                                variant='primary'
                                type='submit'
                                disabled={formState.isSubmitting}
                            >
                                {formState.isSubmitting && (
                                    <span className='spinner-border spinner-border-sm mr-1' />
                                )}
                                {t('Save')}
                            </Button>
                        )}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
