import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import './DnDImageSlot.scss';
import './public-dot.scss';

export interface DnDImageSlotProps {
    move: (dragIndex: number, hoverIndex: number) => void;
    select: (index: number) => void;
    selected: boolean;
    isFirst: boolean;
    index: number;
    accept: string;
    forbidDrag: boolean;
    isPublic: boolean;
    onDrop: (file: any) => void;
}

export const DnDImageSlot: React.FC<DnDImageSlotProps> = ({
    move,
    select,
    selected,
    isFirst,
    children,
    index,
    accept,
    forbidDrag,
    isPublic,
    onDrop,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop({
        accept: accept,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item, monitor) {
            // trigger onDrop
            onDrop(item);
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Time to actually perform the action
            move(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.

            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: accept,
        item: () => {
            return { index };
        },
        canDrag: !forbidDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    useEffect(() => {
        if (isDragging) {
            select(index);
        }
    }, [index, isDragging, select]);

    const classes = [
        'dnd-img-thumb',
        isFirst ? 'cover' : '',
        'bg-light',
        'border',
        'border-3',
        selected ? 'border-warning' : 'border-light',
        forbidDrag ? 'default' : 'move',
        isDragging ? 'dragging' : '',
    ];

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            className={classes.join(' ')}
            onClick={() => !forbidDrag && select(index)}
        >
            {children}

            {isPublic && <span className='public-dot' />}
        </div>
    );
};
