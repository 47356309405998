import { Dropdown, DropdownButton } from 'react-bootstrap';
import IProduct from '../../../types/IProductType';
import { useProductModals } from './ProductModals/ProductModals';
import { Restricted } from '../../Auth/components/Restricted';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import { useTranslation } from 'react-i18next';
import { useTableSelectedProducts } from './SelectedProductsContext';

interface Props {
    className?: string;
}

export const ProductManageButton = ({ className }: Props) => {
    const { t } = useTranslation();
    const {
        setShowStatusModal,
        setViewingShowStatusModal,
        setShowMoveBetweenProjectsModal,
        setShowDuplicateModal,
        setShowExcelExportProductsModal,
        setShowPDFExportProductsModal,
    } = useProductModals();

    const { selectedItems: selectedProducts } = useTableSelectedProducts();

    // TODO: Not sure if resource permissions are dealt with accurately in here
    // FIXME: Nope its not.. And i dont know how it should be dealt with
    const product = selectedProducts[0] as ResourcePermissionsType<IProduct>;

    return (
        <DropdownButton
            id='dropdown-basic-button'
            title={<>{t('With selected')}...</>}
            variant='primary'
            disabled={!selectedProducts.length}
            className={['d-inline-block', className].join(' ')}
        >
            <Restricted to='edit single product' resource={product}>
                <Dropdown.Item
                    onClick={() => {
                        setShowStatusModal(selectedProducts);
                    }}
                >
                    {t('Update status')}
                </Dropdown.Item>
            </Restricted>
            <Restricted to='edit single product' resource={product}>
                <Dropdown.Item
                    onClick={() => {
                        setViewingShowStatusModal(selectedProducts);
                    }}
                >
                    {t('Update viewing status')}
                </Dropdown.Item>
            </Restricted>

            <Restricted to='edit single product' resource={product}>
                <Dropdown.Item
                    onClick={() => {
                        setShowMoveBetweenProjectsModal(selectedProducts);
                    }}
                >
                    {t('Move to project')}
                </Dropdown.Item>
            </Restricted>

            <Restricted to='create single product' resource={product}>
                <Dropdown.Item
                    onClick={() => {
                        setShowDuplicateModal(selectedProducts);
                    }}
                >
                    {t('Create duplicates')}
                </Dropdown.Item>
            </Restricted>

            <Restricted to='view any product' resource={product}>
                <Dropdown.Item
                    onClick={() => {
                        setShowExcelExportProductsModal(selectedProducts);
                    }}
                >
                    {t('Export to excel')}
                </Dropdown.Item>
            </Restricted>

            <Restricted to='view single product' resource={product}>
                <Dropdown.Item
                    onClick={() => {
                        setShowPDFExportProductsModal(selectedProducts);
                    }}
                >
                    {t('Export to pdf')}
                </Dropdown.Item>
            </Restricted>
        </DropdownButton>
    );
};
