import { useProjectFormState } from '../ProjectFormState';

export const PlacementPreview = (props: {}) => {
    const project = useProjectFormState();

    return (
        <div>
            {project?.firstPlacementTitle} {project?.secondPlacementTitle}{' '}
            {project?.thirdPlacementTitle} {project?.fourthPlacementTitle}
        </div>
    );
};
