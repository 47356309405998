import { useCallback, useState, useEffect } from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import axios from 'axios';

import { useSanctum } from 'react-sanctum';
import { Layout } from '../../../components/Layout/Layout';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Layout/Page';
import { useAuth } from '../hooks/useAuth';
import { Link } from 'react-router-dom';
import { WelcomeJumbotron } from '../components/WelcomeJumbotron';
import { Controller, useForm } from 'react-hook-form';
import { SEO } from '../../../components/SEO';

interface Props {}

axios.defaults.withCredentials = true;

export const LoginPage = (props: Props) => {
    const [done, setDone] = useState(false);
    const { t } = useTranslation();

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const [serverError, setServerError] = useState('');
    const [serverSuccess, setServerSuccess] = useState('');

    const { signIn, user } = useSanctum();
    const { clearAuthCache, organisation } = useAuth();
    const navigate = useNavigate();

    const onSubmit = useCallback(
        async (data: any) => {
            setServerError('');
            setServerError('');

            // clear auth cache
            clearAuthCache();

            const res: any = await signIn(
                data.email,
                data.password,
                data.stay
            ).catch((error) => {
                setServerError(error.response.data.message);
            });

            if (res) {
                setServerSuccess('Success!');
                setDone(true);
            }
        },
        [clearAuthCache, signIn]
    );

    useEffect(() => {
        if (done && organisation) {
            if (user && user?.is_sys_admin) {
                if (organisation.id === 1) {
                    navigate('/system-admin');
                } else {
                    navigate('/admin/projects');
                }
            } else {
                navigate('/admin/my-page');
            }
        }
    }, [navigate, done, user, organisation]);

    return (
        <Layout>
            <div id='page-content-wrapper'>
                <Page>
                    <Container>
                        <SEO title={t('Sign in')}></SEO>
                        <WelcomeJumbotron>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                                onReset={reset}
                            >
                                <Form.Group className='mb-3' controlId='email'>
                                    <Form.Label>
                                        {t('Email address')}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name='email'
                                        defaultValue=''
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Control
                                                required
                                                onChange={onChange}
                                                value={value}
                                                type='email'
                                                ref={ref}
                                                isInvalid={errors.email}
                                                placeholder={t('Enter email')}
                                            />
                                        )}
                                    />
                                </Form.Group>

                                <Form.Control.Feedback type='invalid'>
                                    {errors.email?.message}
                                </Form.Control.Feedback>

                                <Form.Group
                                    className='mb-3'
                                    controlId='password'
                                >
                                    <Form.Label>{t('Password')}</Form.Label>
                                    <Controller
                                        control={control}
                                        name='password'
                                        defaultValue=''
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Control
                                                required
                                                onChange={onChange}
                                                value={value}
                                                type='password'
                                                ref={ref}
                                                isInvalid={errors.password}
                                                placeholder={t(
                                                    'Enter password'
                                                )}
                                            />
                                        )}
                                    />
                                </Form.Group>

                                <Form.Control.Feedback type='invalid'>
                                    {errors.email?.password}
                                </Form.Control.Feedback>

                                <Form.Group className='mb-3' controlId='stay'>
                                    <Controller
                                        control={control}
                                        name='stay'
                                        defaultValue=''
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Check
                                                label={t('Stay signed in')}
                                                onChange={onChange}
                                                checked={value}
                                                ref={ref}
                                            />
                                        )}
                                    />
                                </Form.Group>
                                <div className='d-flex flex-fill flex-column gap-2 text-center'>
                                    <Controller
                                        name='submit'
                                        control={control}
                                        render={({
                                            field: { ref },
                                            formState,
                                        }) => (
                                            <Button
                                                variant='primary'
                                                type='submit'
                                                disabled={
                                                    formState.isSubmitting
                                                }
                                            >
                                                {formState.isSubmitting && (
                                                    <span className='spinner-border spinner-border-sm mr-1' />
                                                )}
                                                {t('Sign in')}
                                            </Button>
                                        )}
                                    />

                                    <div className='text-success'>
                                        {serverSuccess}
                                    </div>
                                    <div className='text-danger'>
                                        {serverError}
                                    </div>

                                    <Link to='/forgot-password'>
                                        {t('Forgot your password?')}
                                    </Link>
                                </div>
                            </Form>
                        </WelcomeJumbotron>
                    </Container>
                </Page>
            </div>
        </Layout>
    );
};
