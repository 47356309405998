import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';

interface Props {
    onChange: (value: string) => void;
    placeholder: string;
    size?: 'sm' | 'lg' | undefined;
    value?: string;
    onAction?: (value: string) => void;
}

export const SearchInput: React.FC<Props> = ({
    onChange,
    placeholder,
    size,
    value,
    onAction,
}) => {
    return (
        <InputGroup size={size} className={`${size === 'sm' ? 'm-1' : ''}`}>
            <FormControl
                placeholder={placeholder}
                aria-label={placeholder}
                aria-describedby='text-search-products'
                onChange={(e) => onChange(e.target.value.trim())}
                value={value}
                className='border-end-0'
                size={size}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (onAction) {
                            value && onAction(value);
                        }
                    }
                }}
            />
            {onAction ? (
                <Button
                    size='sm'
                    onClick={() => {
                        value && onAction(value);
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} />
                </Button>
            ) : (
                <InputGroup.Text>
                    <FontAwesomeIcon
                        size={'sm'}
                        className='text-secondary'
                        icon={faSearch}
                    />
                </InputGroup.Text>
            )}
        </InputGroup>
    );
};
