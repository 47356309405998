import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet } from 'react-router';
import { AdminSideNavLayout } from '../components/Layout/AdminSideNavLayout';
import { OrganisationFormPage } from '../pages/Organisation/pages/OrganisationFormPage';
import { IPrivateRoute } from '../types/IRouteType';
import { routesPrivate } from './routes';

const Organisation = () => {
    return (
        <AdminSideNavLayout routesPrivate={routesPrivate}>
            <Outlet />
        </AdminSideNavLayout>
    );
};

export const organisationPrivateRoutes: IPrivateRoute[] = [
    {
        name: 'Organisation',
        path: 'organisation',
        icon: <FontAwesomeIcon size='2x' icon={faPeopleGroup} />,
        element: <Organisation />,
        admin: true,
        children: [
            {
                index: true,
                path: '',
                admin: true,
                element: <OrganisationFormPage />,
            },
        ],
    },
];
