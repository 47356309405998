import React from 'react';

import { Image } from 'react-bootstrap';

import {
    thumbsContainer,
    thumbDocument,
    removeButton,
    img,
    thumb,
    thumbInner,
    dropzone,
} from './Styles';
import PDFIcone from '../../assets/icones/Dacke_PDF.svg';

interface Props {
    files: any[];
    handleRemove: (fileId: number) => void;
    title: string;
    type?: 'Documents' | 'Images';
    handleOnClick?: (fileId: number) => void;
    highlightId?: number;
}

export const SimpleDropZonePreview = ({
    files,
    handleRemove,
    title,
    type,
    handleOnClick,
    highlightId,
}: Props) => {
    return (
        <div
            className='d-flex flex-grow-1 flex-column border rounded-2 p-3'
            style={dropzone}
        >
            <p>{title}</p>
            <div
                style={thumbsContainer}
                className='d-flex flex-row align-items-start'
            >
                {files.map((file: any, index: number) =>
                    type === 'Documents' ? (
                        <div
                            className='d-flex flex'
                            key={index}
                            style={thumbDocument}
                        >
                            <div
                                onClick={() =>
                                    handleOnClick && handleOnClick(file.id)
                                }
                                key={index}
                                className='d-flex flex-column align-items-center m-1 p-1'
                                style={{
                                    cursor: handleOnClick ? 'pointer' : 'auto',
                                    border:
                                        file.id === highlightId
                                            ? '1px solid green'
                                            : 'none',
                                    borderRadius:
                                        file.id === highlightId
                                            ? '5px'
                                            : 'none',
                                }}
                            >
                                <Image src={PDFIcone} width='40' />
                                <span style={{ maxWidth: 100 }}>
                                    {file.name || file.title}
                                </span>
                            </div>

                            <div>
                                <button
                                    className='border border-1 border-light bg-light rounded-circle m-2'
                                    onClick={() => handleRemove(file.id)}
                                    style={removeButton as React.CSSProperties}
                                >
                                    x
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div key={index} style={thumb}>
                            <div
                                style={{
                                    ...thumbInner,
                                }}
                            >
                                <img
                                    src={file.preview}
                                    alt='preview'
                                    style={{
                                        ...img,
                                    }}
                                />
                            </div>

                            <div>
                                <button
                                    className='border border-1 border-light bg-light rounded-circle m-1'
                                    onClick={() => {
                                        handleRemove(file.id);
                                    }}
                                    style={removeButton as React.CSSProperties}
                                >
                                    x
                                </button>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
