import React from 'react';
import { Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ICriteriaType from '../../../types/ICriteriaType';
import ImgUnchecked from '../../../assets/icones/dacke-grey-01.png';
import ImgChecked from '../../../assets/icones/dacke-color-01.png';

import './Points.scss';

interface Props {
    value: number;
    criteria: ICriteriaType;
    onChange?: (criteria: ICriteriaType, value: number) => void;
}
const numToKey: (keyof ICriteriaType)[] = [
    'firstPointTitle',
    'secondPointTitle',
    'thirdPointTitle',
    'fourthPointTitle',
    'fifthPointTitle',
];
export const Points: React.FC<Props> = ({
    value: propsValue,
    onChange,
    criteria,
}) => {
    const value = onChange ? propsValue : propsValue || 0; // Minimum is 0 when displaying
    const { t } = useTranslation();
    return (
        <>
            <span className='text-nowrap'>
                {!!onChange && (
                    <OverlayTrigger
                        placement='left'
                        overlay={<Tooltip>{t('Clear criterias')}</Tooltip>}
                    >
                        <span
                            className='d-inline-block text-primary me-2'
                            key='0'
                            onClick={() => onChange && onChange(criteria, 0)}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: '18px',
                                border: '1px solid black',
                                borderRadius: '50%',
                                cursor: 'pointer',
                            }}
                        >
                            &times;
                        </span>
                    </OverlayTrigger>
                )}
                {Array.from(Array(5).keys()).map((v, i) => (
                    <div className='d-inline-block text-primary me-2' key={i}>
                        <OverlayTrigger
                            placement='bottom'
                            delay={{ show: 50, hide: 100 }}
                            overlay={
                                <Tooltip
                                    style={{
                                        visibility: criteria[numToKey[i]]
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                >
                                    {criteria[numToKey[i]]}
                                </Tooltip>
                            }
                        >
                            <Image
                                src={i < value ? ImgChecked : ImgUnchecked}
                                width='18px'
                                className={
                                    'p-0 mb-1 rounded-circle ' +
                                    (i < value ? '' : 'border')
                                }
                                onClick={() =>
                                    onChange && onChange(criteria, i + 1)
                                }
                                style={{
                                    boxShadow: '1px 1px 3px grey',
                                    cursor: onChange ? 'pointer' : 'auto',
                                }}
                            />
                        </OverlayTrigger>
                    </div>
                ))}
            </span>
        </>
    );
};
