import { useEffect, useMemo, useState } from 'react';
import useAxios from 'axios-hooks';
import {
    Breadcrumb,
    ButtonToolbar,
    Card,
    Col,
    Container,
    Row,
    Button,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ContactPersonPreview } from './components/ProjectForm/ContactPerson/ContactPersonPreview';
import { ShowContactPersonForm } from './components/ProjectForm/ContactPerson/ShowContactPersonForm';
import { DescriptionPreview } from './components/ProjectForm/Description/DescriptionPreview';
import { ShowDescriptionForm } from './components/ProjectForm/Description/ShowDescriptionForm';
import { DocumentsPreview } from './components/ProjectForm/Documents/DocumentsPreview';
import { ShowDocumentsForm } from './components/ProjectForm/Documents/ShowDocumentsForm';

import { Page } from '../../components/Layout/Page';
import IProjectType from '../../types/IProjectType';
import IServerResponseType from '../../types/IServerResponseType';
import ResourcePermissionsType from '../../types/ResourcePermissionsType';
import { useAuth } from '../Auth/hooks/useAuth';
import { Restricted } from '../Auth/components/Restricted';

import { ReactComponent as STARTDATEIcon } from '../../assets/icones/Dacke_startdatum.svg';
import { ReactComponent as ENDDATEIcon } from '../../assets/icones/Dacke_slutdatum.svg';
import { PageCardTitle } from '../../components/PageCardTitle';
import { PageSRLImage } from '../../components/PageSRLImage';
import { tryFormatDefaultDate } from '../../utils/helper';
import { Loading } from '../FullLoadingPage';
import { Accordion } from '../../components/Accordion/Accordion';

import { createImagesFromMedias } from '../../API/images';
import { createDocumentsFromMedias as createProjectDocumentsFromMedias } from '../../API/project/documents';
import {
    IProjectFormState,
    ProjectFormState,
} from './components/ProjectForm/ProjectFormState';

export const ProjectPage = () => {
    const { t } = useTranslation();

    const { projectId } = useParams<any>();
    const [show, setShow] = useState(false);

    const { organisation } = useAuth();

    const [{ data, loading }, refetch] = useAxios<
        IServerResponseType<ResourcePermissionsType<IProjectType>>
    >(`/api/organisations/${organisation?.id}/projects/${projectId}`, {
        manual: true,
    });

    const project = data?.data;
    const nrOfImages = project?.project_images?.medias.length;

    useEffect(() => {
        if (organisation) refetch();
    }, [organisation, refetch]);

    const accordionItems = useMemo(() => {
        return [
                {
                    title: t('Description'),
                    PreviewCompnent: DescriptionPreview,
                    FormCompnent: ShowDescriptionForm,
                },
                {
                    title: t('Contact person'),
                    PreviewCompnent: ContactPersonPreview,
                    FormCompnent: ShowContactPersonForm,
                },
                {
                    title: t('Documents'),
                    PreviewCompnent: DocumentsPreview,
                    FormCompnent: ShowDocumentsForm,
                },
        ];
    }, [t]);

    const accordion = 
        <ProjectFormState
                initialState={
                    {
                        id: project?.id,
                        title: project?.title,
                        project_start: project?.project_start
                            ?.toString()
                            .substring(0, 10),
                        project_finish: project?.project_finish
                            ?.toString()
                            .substring(0, 10),
                        is_active: project?.is_active,
                        firstPlacementTitle: project?.firstPlacementTitle,
                        secondPlacementTitle: project?.secondPlacementTitle,
                        thirdPlacementTitle: project?.thirdPlacementTitle,
                        fourthPlacementTitle: project?.fourthPlacementTitle,
                        contacts: project?.contacts,
                        primary_contact: project?.primary_contact,
                        description: project?.description,
                        region: project?.region,
                        organisation_id: project?.organisation_id,

                        projectImages: project?.project_images?.medias
                            ? createImagesFromMedias(project.project_images.medias)
                            : [],
                        project_images: {
                            id: project?.project_images?.id || null,
                        },

                        projectDocuments: project?.project_documents?.medias
                            ? createProjectDocumentsFromMedias(
                                project.project_documents.medias
                            )
                            : [],
                        project_documents: {
                            id: project?.project_documents?.id || null,
                        },
                    } as IProjectFormState
                }
            >
            <Accordion
                disabled={false}
                className='form-accordion'
                {...{ accordionItems }}
            />
        </ProjectFormState>;

    return (
        <Page>
            <Container fluid className='px-0 px-sm-inherit'>
                <ButtonToolbar className='d-flex flex-column flex-md-row align-content-md-center justify-content-between gap-2 mb-4'>
                    <Breadcrumb className='d-flex align-content-center'>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/admin/projects`,
                            }}
                        >
                            {t('Projects')}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/admin/projects/${project?.id}`,
                            }}
                            active
                        >
                            {project?.title}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </ButtonToolbar>
                {loading ? (
                    <Loading />
                ) : project ? (
                    <Card className='shadow'>
                        <Card.Body>
                            <Row className='g-0'>
                                <Col md={5}>
                                    <PageSRLImage
                                        images={project?.project_images}
                                        imageLimit={show ? 100 : 4}
                                    />
                                    {nrOfImages && nrOfImages > 3 && <Button
                                        className='d-block ms-auto me-3 mt-0 py-0'
                                        size='sm'
                                        variant='link'
                                        onClick={() => setShow((p) => !p)
                                        }
                                    >
                                        {show ? t('Show fewer') : t('Show more')}
                                    </Button>
                                    }
                                </Col>

                                <Col
                                    md={7}
                                    className='d-flex flex-column justify-content-between'
                                >
                                    <div className='d-flex flex-column flex-grow-1 gap-2'>
                                        <PageCardTitle
                                            title={project.title}
                                            description={project.description}
                                        ></PageCardTitle>

                                        <Card.Subtitle className='d-flex'>
                                            <span className='d-none d-sm-flex'>
                                                {t('Projectnumber')}
                                            </span>
                                            <span className='d-flex d-sm-none'>
                                                {t('Project')}
                                            </span>
                                            <span>: {project.id}</span>
                                        </Card.Subtitle>

                                        <div className='d-flex'>
                                            <div>
                                                <span className='pe-1'>
                                                    {t('Status')}:
                                                </span>
                                                <span className='pe-1'>
                                                    {project.is_active
                                                        ? t('Active')
                                                        : t('Inactive')}
                                                </span>
                                                {/* green dot */}
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            project.is_active
                                                                ? 'var(--bs-success)'
                                                                : 'var(--bs-danger)',
                                                        borderRadius: '50%',
                                                        width: '13px',
                                                        height: '13px',
                                                        marginTop: '2px',
                                                        position: 'absolute',
                                                        border: '2px solid #fff',
                                                        boxShadow:
                                                            '3px 3px 4px rgba(0,0,0,0.2)',
                                                    }}
                                                ></span>
                                            </div>
                                        </div>

                                        {!!project?.region && (
                                            <div>
                                                <span className='pe-1'>
                                                    {t('Region')}:
                                                </span>
                                                <span className='pe-1'>
                                                    {project.region}
                                                </span>
                                            </div>
                                        )}

                                        <Row id='dates'>
                                            <Col xs={6}>
                                                <div className='d-flex flex-column flex-fill'>
                                                    <div>{t('Start date')}</div>
                                                    <span>
                                                        <STARTDATEIcon
                                                            width='13'
                                                            className='me-1'
                                                        />
                                                        <span>
                                                            {tryFormatDefaultDate(
                                                                project?.project_start ||
                                                                    project?.created_at
                                                            )}
                                                        </span>
                                                    </span>
                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className='d-flex flex-column flex-fill'>
                                                    <div>{t('End date')}</div>
                                                    <span>
                                                        <ENDDATEIcon
                                                            width='12'
                                                            className='me-1'
                                                        />
                                                        <span>
                                                            {tryFormatDefaultDate(
                                                                project?.project_finish
                                                            )}
                                                        </span>
                                                    </span>
                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className='d-flex flex-column flex-fill'>
                                                    <div>
                                                        {t('Created date')}
                                                    </div>
                                                    <span>
                                                        <STARTDATEIcon
                                                            width='13'
                                                            className='me-1'
                                                        />
                                                        <span>
                                                            {tryFormatDefaultDate(
                                                                project?.created_at
                                                            )}
                                                        </span>
                                                    </span>
                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className='d-flex flex-column flex-fill'>
                                                    <div>
                                                        {t('Updated date')}
                                                    </div>
                                                    <span>
                                                        <ENDDATEIcon
                                                            width='12'
                                                            className='me-1'
                                                        />
                                                        <span>
                                                            {tryFormatDefaultDate(
                                                                project?.updated_at
                                                            )}
                                                        </span>
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>

                                        {accordion}
                                    </div>

                                    <Row
                                        className='mt-3 d-flex flex-column flex-md-row flex-wrap justify-content-between'
                                        style={{ boxSizing: 'border-box' }}
                                    >
                                        <Col
                                            xs={12}
                                            sm={6}
                                            xl={3}
                                            className='d-inline-flex'
                                        >
                                            <Restricted
                                                to='view any product'
                                                resource={project}
                                            >
                                                <Link
                                                    className='btn btn-secondary btn-proj flex-fill m-1'
                                                    to={`products`}
                                                >
                                                    {t('View products')}
                                                </Link>
                                            </Restricted>
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            xl={3}
                                            className='d-inline-flex'
                                        >
                                            <Restricted
                                                to='view any product'
                                                resource={project}
                                            >
                                                <Link
                                                    className='btn btn-secondary btn-proj flex-fill m-1'
                                                    to={`product-items`}
                                                >
                                                    {t(
                                                        'View product placements'
                                                    )}
                                                </Link>
                                            </Restricted>
                                        </Col>
                                        {organisation?.has_environment_data && (
                                            <Col
                                                xs={12}
                                                sm={6}
                                                xl={3}
                                                className='d-inline-flex'
                                            >
                                                <Restricted
                                                    to='view any product'
                                                    resource={project}
                                                >
                                                    <Link
                                                        className='btn btn-success btn-proj text-white flex-fill m-1'
                                                        to={`product-environment-data`}
                                                    >
                                                        {t(
                                                            'View environment data'
                                                        )}
                                                        
                                                    </Link>
                                                </Restricted>
                                            </Col>
                                        )}
                                        <Col
                                            xs={12}
                                            sm={6}
                                            xl={3}
                                            className='d-flex'
                                        >
                                            <Restricted
                                                to='edit single project'
                                                resource={project}
                                            >
                                                <Link
                                                    className='btn btn-primary btn-proj flex-fill m-1'
                                                    to={`project-form`}
                                                >
                                                    {t('Edit')}
                                                </Link>
                                            </Restricted>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                ) : null}
            </Container>
        </Page>
    );
};
