import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../Auth/hooks/useAuth';
import { useNavigate } from 'react-router';

interface Props {
    show: boolean;
    handleClose: (e?: any) => void;
}

export const OrganisationSelector: React.FC<Props> = ({
    show,
    handleClose,
}) => {
    const { organisations, organisation, setActiveOrganisation } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('Choose organisation')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Select
                    value={organisation?.id}
                    onChange={(e) => {
                        if (e.currentTarget.value && setActiveOrganisation) {
                            setActiveOrganisation(
                                parseInt(e.currentTarget.value)
                            );

                            handleClose();
                            navigate('/admin');
                        }
                    }}
                >
                    {organisations?.map((o) => {
                        return (
                            <option key={o.id} value={o.id}>
                                {String(o.id).padStart(3, '0')}, {o.name}
                            </option>
                        );
                    })}
                </Form.Select>
            </Modal.Body>
        </Modal>
    );
};
