import React from 'react';
import { Collapse } from 'react-bootstrap';
import { isOpen } from './utils';

interface AccordionItemProps {
    eventKey: string;
    openKeys: string[];
}
export const AccordionItem: React.FC<AccordionItemProps> = ({
    children,
    eventKey,
    openKeys,
}) => (
    <div>
        <Collapse in={isOpen(openKeys, eventKey)}>
            <div>{children}</div>
        </Collapse>
        <hr className='my-1 my-sm-3' />
    </div>
);
