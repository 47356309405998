import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import useAxios from 'axios-hooks';
import IEnvironmentData from '../../../../../types/IEnvironmentDataType';
import IProductEnvironmentData from '../../../../../types/IProductEnvironmentDataType';
import IServerResponseType from '../../../../../types/IServerResponseType';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import { useProductFormState } from '../ProductFormState';

export const useEnvironmentDataForm = () => {
    const { projectId, productId } = useParams();

    const { organisation } = useAuth();

    const { product_environment_data, setFormState } = useProductFormState();
    const prodEnvDataId = product_environment_data?.id;

    const [state, setState] = useState<IProductEnvironmentData>();

    const [{ data: envDataData }, fetchEnvironmentData] = useAxios<
        IServerResponseType<IEnvironmentData[]>
    >('/api/environment-data');
    const environmentData = envDataData?.data;

    const url = useMemo(
        () =>
            `/api/organisations/${organisation?.id}/projects/${projectId}/products/${productId}/productenvironmentdata`,
        [organisation?.id, projectId, productId]
    );

    useEffect(() => {
        if (!prodEnvDataId) return;
        axios
            .get(`${url}/${prodEnvDataId}`)
            .then((res) => res?.data?.data || {})
            .then(setState);
    }, [prodEnvDataId, url]);

    const setStates = useCallback(
        (data) => {
            setState(data);
            setFormState((prev) => ({
                ...prev,
                product_environment_data: data,
            }));
        },
        [setState, setFormState]
    );

    const updateProductEnvironmentData = useCallback(() => {
        if (productId) {
            if (!prodEnvDataId) {
                axios.post(url, state).then((res) => {
                    if (res.data.data) {
                        setStates(res.data.data);
                    }
                });
            } else {
                axios.put(`${url}/${prodEnvDataId}`, state).then((res) => {
                    if (res.data.data) {
                        setStates(res.data.data);
                    }
                });
            }
        }
    }, [productId, url, prodEnvDataId, state, setStates]);

    const onEnvironmentDataIdsChange = useCallback(
        (environment_data) => {
            if (productId) {
                if (!prodEnvDataId) {
                    axios
                        .post(url, { ...state, environment_data })
                        .then((res) => {
                            if (res.data.data) {
                                setStates(res.data.data);
                            }
                        });
                } else {
                    axios
                        .put(`${url}/${prodEnvDataId}`, {
                            ...state,
                            environment_data,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                setStates(res.data.data);
                            }
                        });
                }
            } else {
                setState(
                    (prev) =>
                        ({
                            ...prev,
                            environment_data,
                        } as IProductEnvironmentData)
                );
            }
        },
        [productId, url, prodEnvDataId, state, setStates]
    );

    return {
        environmentData,
        fetchEnvironmentData,
        productEnvironmentData: state,
        setProductEnvironmentData: setState,
        updateProductEnvironmentData,
        onEnvironmentDataIdsChange,
    };
};
