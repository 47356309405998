import React from 'react';

import { routes } from '../../routing/routes';

import { HeaderNavbar } from './HeaderNavbar';
import { Container } from 'react-bootstrap';

interface Props {}

export const Layout: React.FC<Props> = ({ children }) => {
    return (
        <>
            <HeaderNavbar links={routes.filter((route) => route.name)} />

            <Container fluid>{children}</Container>
        </>
    );
};
