import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { BackendOptions, RequestResponse } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios from 'axios';

axios.defaults.withCredentials = true;

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: process.env.NODE_ENV === 'development' ? ['en'] : ['sv'], // only fallback to sv in development mode
        supportedLngs:
            process.env.NODE_ENV === 'development' ? ['en', 'sv'] : ['sv'], // only supportedLngs to sv in development mode

        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath:
                process.env.REACT_APP_API_ENDPOINT +
                '/api/locales/{{lng}}/{{ns}}',
            addPath:
                process.env.REACT_APP_API_ENDPOINT +
                '/api/locales/{{lng}}/{{ns}}',

            ...(process.env.NODE_ENV === 'development'
                ? {
                      request: async function (
                          options: BackendOptions,
                          url: string,
                          payload: string,
                          callback: (err: any, data: RequestResponse) => void
                      ) {
                          try {
                              if (payload) {
                                  const request = await axios.post(
                                      url,
                                      payload
                                  );
                                  callback(null, request);
                              } else {
                                  const request = await axios.get(url);
                                  callback(null, request);
                              }
                          } catch (error: any) {
                              callback(
                                  error,
                                  error?.request as RequestResponse
                              );
                          }
                      },
                  }
                : {}),
        },
        saveMissing: process.env.NODE_ENV === 'development',
    });

export default i18n;
