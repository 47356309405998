import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function A({ children, href }: any) {
    return (
        <a href={href} target='_blank' rel='noreferrer'>
            {children}
        </a>
    );
}

const Markdown = ({ children }: { children: string }) => (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={{ a: A }}>
        {children}
    </ReactMarkdown>
);

interface Props {
    text: string;
    charLimit: number;
}
export const CollapsibleText: React.FC<Props> = ({ text, charLimit }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    if ((text?.length || 1) * 0.8 <= charLimit) {
        return <Markdown children={text} />;
    }

    const textToShow = show ? text : text.substring(0, charLimit) + '...';

    return (
        <div className='d-flex flex-column'>
            <Markdown children={textToShow} />
            {
                <Button
                    className='d-block ms-auto mt-0 py-0'
                    size='sm'
                    variant='link'
                    onClick={() => setShow((p) => !p)}
                >
                    {show ? t('Show less') : t('Show more')}
                </Button>
            }
        </div>
    );
};
