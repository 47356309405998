import React, { useMemo } from 'react';
import {
    Button,
    Card,
    Col,
    Container,
    OverlayTrigger,
    Stack,
    Row,
    Tooltip,
} from 'react-bootstrap';
import { TableOfContents } from './components/TableOfContents';
import { Page } from '../../../components/Layout/Page';
import { SEO } from '../../../components/SEO';
import { useTranslation } from 'react-i18next';

interface Props {}

export const PartnerServicesPage = (props: Props) => {
    const { t } = useTranslation();
    const borderRadius = '0.5rem';

    const data = useMemo(
        () => [
            {
                title: 'Kunskap & Idéer',
                name: 'kunskap',
                cards: [
                    {
                        title: '',
                        text: 'Med arkitektur hjälper vi våra kunder att flytta gränser och göra världen till en bättre plats.',
                        link: 'https://tengbom.se/',
                        linkName: 'Tengbom',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/03/Bild12.jpg',
                    },
                    {
                        title: '',
                        text: 'Kompanjonen gör det enklare för företag på den svenska bygg- och fastighetsmarknaden att köpa, sälja och hantera återbrukade produkter och restpartier.',
                        link: 'https://aterbrukskonsulten.se/',
                        linkName: 'Återbrukskonsulten',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/03/cropped-Rityta-4-kopia-17@4x-1024x220-1.png',
                    },
                    {
                        title: '',
                        text: 'Dacke.Online och Bengt Dahlgren har ett banbrytande samarbete inom professionellt återbruk. Bägge parter har intresse av att ta stöd av varandra vilket också har bekräftats av våra gemensamma kunder.',
                        link: 'https://bengtdahlgren.se/',
                        linkName: 'Bengt Dahlgren',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/02/KS_USP1-bengt-dahlgren.png',
                    },
                ],
            },
            {
                title: 'Inventering',
                name: 'inventering',
                cards: [
                    {
                        title: '',
                        text: 'Kompanjonen gör det enklare för företag på den svenska bygg- och fastighetsmarknaden att köpa, sälja och hantera återbrukade produkter och restpartier.',
                        link: 'https://aterbrukskonsulten.se/',
                        linkName: 'Återbrukskonsulten',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/03/cropped-Rityta-4-kopia-17@4x-1024x220-1.png',
                    },
                    {
                        title: '',
                        text: 'Dacke.Online och Bengt Dahlgren har ett banbrytande samarbete inom professionellt återbruk. Bägge parter har intresse av att ta stöd av varandra vilket också har bekräftats av våra gemensamma kunder.',
                        link: 'https://bengtdahlgren.se/',
                        linkName: 'Bengt Dahlgren',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/02/KS_USP1-bengt-dahlgren.png',
                    },
                ],
            },
            {
                title: 'Marknadsplats & Försäljning',
                name: 'marketplace',
                cards: [
                    {
                        title: '',
                        text: 'Dacke.Online är ett lättarbetat digitalt hållbarhetsverktyg för inventering, köp och sälj av återbrukade produkter av hög kvalitet. Utvecklat av användare för användare.',
                        link: 'https://dacke.online/',
                        linkName: 'förstasidan',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/06/dackelogo-svart.png',
                    },
                    {
                        title: '',
                        text: 'Återbruksmäklaren Kompanjonen hjälper företag att hitta, köpa och sälja återbrukade produkter och restpartier. Sedan många år är vi en av Sveriges främsta aktörer inom området.',
                        link: 'https://aterbruksmaklaren.se/',
                        linkName: 'Återbruksmäklaren',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/06/1@4x.png',
                    },
                ],
            },
            {
                title: 'Upphandling & Inköpsstöd',
                name: 'upphandling',
                cards: [
                    {
                        title: '',
                        text: 'Återbruksmäklaren Kompanjonen hjälper företag att hitta, köpa och sälja återbrukade produkter och restpartier. Sedan många år är vi en av Sveriges främsta aktörer inom området.',
                        link: 'https://aterbruksmaklaren.se/',
                        linkName: 'Återbruksmäklaren',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/06/1@4x.png',
                    },
                ],
            },
            /*
            {
                title: "Demontering",
                name: "demontering",
                cards: [
                    {
                        title: "",
                        text: "Vi hjälper företag återanvända resurser i ett stängt kretslopp istället för att tillverka produkter av nytt råmaterial. Våra tjänster utgår från kundernas behov och täcker ett stort antal områden, och förenar vår expertis inom olika ämnen.",
                        link: "https://demontera.com/",
                        linkName: 'Demontera',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/03/7EE88389-A294-4549-8A25-CB3A62830449-1024x526-1-e1654781613760.png',
                    },
                ],
            },
            */
            {
                title: 'Frakt & Logistik',
                name: 'frakt',
                cards: [
                    {
                        title: '',
                        text: 'Alwex Transport är medlem i TRB Sverige AB vilket ägs av ett flertal stora aktörer inom transport och logistikbranschen. Detta är aktörer med cirka 6 500 fordon och maskiner som samarbetar för klimatsmarta transporter och energieffektiva logistiklösningar inom branschen.',
                        link: 'https://www.alwex.se/lager_logistik',
                        linkName: 'Alwex',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/03/logo-1.png',
                    },
                ],
            },
            {
                title: 'Återtillverkning',
                name: 'atertillverkning',
                cards: [
                    {
                        title: '',
                        text: 'Vi återtillverkar kvalitetsarmaturer som redan har använts.',
                        link: 'https://www.fischer-lighting.com/english/',
                        linkName: 'Fischer Lighting',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/03/MK_USP1-fischerlighting.png',
                    },
                    {
                        title: '',
                        text: 'Brukspecialisten är Sveriges enda specialistkedja som säljer tjänster och systemlösningar inom murat & putsat byggande. Vi har kompletta system inom tegel, puts och betong för både nyproduktion och renovering, samt konsulttjänster inom fasadprojektering.',
                        link: 'https://www.brukspecialisten.se/',
                        linkName: 'Brukspecialisten',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/elementor/thumbs/Bruksspecialisten@2x-pllwvwi04kuxnpanv5b075mjz0ns0e3rsf1q0ff0n4.png',
                    },
                    {
                        title: '',
                        text: 'Inregos mission är att minimera använd IT-utrustnings påverkan på miljön och att förändra hur datorer, telefoner och annan teknik hanteras i samhället. I 25 år har Inrego lett vägen globalt kring återanvändning av IT-produkter.',
                        link: 'https://www.inrego.se/',
                        linkName: 'Inrego',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/03/inrego.jpg',
                    },
                ],
            },
            {
                title: 'Nätverk & Samverkan',
                name: 'natverk',
                cards: [
                    {
                        title: '',
                        text: 'Dacke.Online är medlem i 100 Gruppen, vars vision är en hållbar och cirkulär inredningsbransch som blir klimatneutral 2030. 100 Gruppen är en självklar informationskälla och drivande kraft.',
                        link: 'https://100gruppen.se/',
                        linkName: '100 Gruppen',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/02/KS_USP3-100gruppen.jpg',
                    },
                    {
                        title: '',
                        text: 'Idag är Cradlenet ett av världens äldsta cirkulära ekonomi- nätverk som hjälper företag och organisationer med omställningen till cirkulär ekonomi. Det gör vi genom att stödja våra medlemmar med bland annat veckovis temamöten, rådgivning, ny kunskap, omvärldsbevakning, studier, utbildningar, opinion och nätverkande lokalt, nationellt och på nordisk nivå.',
                        link: 'https://www.cradlenet.se/',
                        linkName: 'Cradlenet',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/06/cradlenet-logo.png',
                    },
                    {
                        title: '',
                        text: 'Centrum för cirkulärt byggande, ofta kallat CCBuild, är en arena där branschens aktörer möts och samverkar kring återbruk och cirkulära materialflöden vid byggande, rivning och förvaltning.',
                        link: 'https://ccbuild.se/',
                        linkName: 'CCBuild',
                        img: 'https://aterbrukskonsulten.se/wp-content/uploads/2022/06/ccblogo.jpg',
                    },
                ],
            },
        ],
        []
    );

    const Cards = data.map((item, index) => (
        <section key={`${item.name}-section`} id={item.name}>
            <h3 className='fw-bold' style={{ marginBottom: 24 }}>
                {item.title}
            </h3>

            <Row>
                {item.cards.map((item: any, index: number) => (
                    <Col
                        md={Math.floor(12 / item.length)}
                        sm
                        className='mb-4'
                        key={`${item.name}-${index}-card`}
                    >
                        <Card
                            className='border h-100 shadow'
                            style={{ borderRadius }}
                        >
                            <Card.Body
                                className='d-block text-truncate text-wrap'
                                style={{ maxHeight: 339 }}
                            >
                                {item.img && (
                                    <Card.Text className='text-center p-4'>
                                        <img
                                            src={item.img}
                                            className='h-100 img-fluid'
                                            style={{ maxHeight: 70 }}
                                            alt='Service logo'
                                        />
                                    </Card.Text>
                                )}
                                {item.title && (
                                    <Card.Title>{item.title}</Card.Title>
                                )}
                                <OverlayTrigger
                                    placement='right'
                                    delay={{ show: 50, hide: 100 }}
                                    overlay={
                                        <Tooltip className='fs-5'>
                                            {item.text}
                                        </Tooltip>
                                    }
                                >
                                    <Card.Text>{item.text}</Card.Text>
                                </OverlayTrigger>
                            </Card.Body>
                            {item.link && (
                                <Button
                                    variant='outline-primary'
                                    href={item.link}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='m-2'
                                >
                                    {t('Go to page')}
                                </Button>
                            )}
                        </Card>
                    </Col>
                ))}
            </Row>
        </section>
    ));

    const sections = data.map(({ title, name }) => ({ title, id: `#${name}` }));

    return (
        <Page style={{ marginBottom: 112 }}>
            <Container>
                <Row>
                    <Col xxl={2} className='mt-4 my-4 d-none d-xxl-block'>
                        <TableOfContents
                            sections={sections}
                            className='position-fixed top-1 fw-bold'
                        />
                    </Col>
                    <Col xxl={1} className='d-none d-xxl-block' />

                    <Col xxl={9}>
                        <Container fluid>
                            <Stack style={{ gap: 42 }}>
                                <div>
                                    <SEO
                                        title={t('Partner services')}
                                        className='fw-light lh-lh mb-3'
                                    ></SEO>
                                    <h4 className='fw-light'>
                                        Tveka inte att höra av dig vid frågor
                                        eller funderingar.
                                    </h4>
                                </div>
                                {Cards}
                            </Stack>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
