import { useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.scss';

interface Props {
    value: string;
    onChange: (newValue: string) => void;
    onBlur: () => void;
}

export const RichTextEditor = ({ value, onChange, onBlur }: Props) => {
    const ref = useRef<any>();
    const [editorState, setEditorState] = useState(() => {
        const raw = markdownToDraft(value);
        const contentState = convertFromRaw(raw);
        return EditorState.createWithContent(contentState);
    });

    const onEditorStateChange = (es: EditorState) => {
        setEditorState(es);
        const raw = convertToRaw(es.getCurrentContent());
        const markup = draftToMarkdown(raw);
        onChange(markup);
    };

    const toolbar = {
        options: ['list', 'link', 'inline', 'remove', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        link: {
            defaultTargetOption: '_blank',
            linkCallback: (link: string) => {
                // adding a link will unfocus the editor.
                // We use the focus to update the DB state so we need to refocus
                if (ref.current) {
                    const r = ref.current;
                    r.focus();
                    setTimeout(() => {
                        r.blur();
                        setTimeout(() => {
                            r.focus();
                        }, 0);
                    }, 0);
                }
                return link;
            },
        },
    };
    const wrapperStyle = {
        border: '1px solid lightgray',
        borderRadius: '0.65em',
    };
    const editorStyle = {
        borderRadius: '0.65em',
        maxHeight: 200,
        padding: '0 12px',
    };
    const toolbarStyle = {
        borderRadius: '0.65em',
    };

    return (
        <Editor
            editorRef={(e) => {
                if (e) ref.current = e;
            }}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            onBlur={onBlur}
            toolbar={toolbar}
            wrapperStyle={wrapperStyle}
            editorStyle={editorStyle}
            toolbarStyle={toolbarStyle}
            stripPastedStyles
        />
    );
};
