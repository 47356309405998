import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { CardRow } from './CardRow';
import { CoverImage } from '../../../../components/CoverImage';
import { PageCountPicker } from '../../../../components/PageCountPicker';
import { useTableSelectedProductEnvironmentData } from '../SelectedProductEnvironmentDataContext';

import { IImageMediaGroup } from '../../../../types/IMediaType';
import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import ProductEnvironmentData from '../../../../types/IProductEnvironmentDataType';
import IProjectType from '../../../../types/IProjectType';
import {
    ITableAction,
    PAGE_SIZE_CHANGED,
} from '../../../../hooks/useTableReducer';
import './ProductEnvironmentDataMobileView.scss';

interface CardProps {
    project?: ResourcePermissionsType<IProjectType>;
    item: ResourcePermissionsType<ProductEnvironmentData>;
    selected: boolean;
    toggle: () => void;
}

const ProductEnvironmentDataMobileCard = ({
    project,
    item,
    selected,
    toggle,
}: CardProps) => {
    const { t } = useTranslation();
    return (
        <div className='py-2 d-flex flex-column gap-1 px-1'>
            <Row>
                <Col xs={5}>
                    <CoverImage
                        images={
                            item?.product?.product_images as IImageMediaGroup
                        }
                        lightbox
                    />
                </Col>
                <Col xs={7}>
                    <Row>
                        <Form.Check
                            className='d-flex flex-row-reverse pe-3'
                            type='checkbox'
                            checked={selected}
                            onChange={() => toggle()}
                        />
                    </Row>

                    <CardRow
                        title={t('Category')}
                        value={item?.product?.category?.name}
                    />
                    <CardRow
                        title={t('Quantity')}
                        value={`${item?.quantity} ${item?.product?.category?.unit?.symbol}`}
                    />
                    <CardRow title={item?.substance} value={item?.material} />
                    <CardRow title={t('Waste code')} value={item?.waste_code} />
                    <CardRow
                        title={t('Is hazardous')}
                        value={t(item?.is_hazardous ? 'Yes' : 'No')}
                    />
                    <CardRow title={t('Cost')} value={`${item?.cost}`} />
                </Col>
            </Row>
            <Row className='pl-1'>
                <h5 className='fw-bold'>{item?.product?.title}</h5>
                <CardRow
                    title={project?.firstPlacementTitle}
                    value={item?.firstPlacementValue}
                />
                <CardRow
                    title={project?.secondPlacementTitle}
                    value={item?.secondPlacementValue}
                />
                <CardRow
                    title={project?.thirdPlacementTitle}
                    value={item?.thirdPlacementValue}
                />
                <CardRow
                    title={project?.fourthPlacementTitle}
                    value={item?.fourthPlacementValue}
                />
            </Row>
        </div>
    );
};

interface Props {
    project?: ResourcePermissionsType<IProjectType>;
    productEnvironmentData: ResourcePermissionsType<ProductEnvironmentData>[];
    queryPageSize?: number;
    totalCount?: number;
    dispatch?: React.Dispatch<ITableAction>;
}

export const ProductEnvironmentDataMobileView = ({
    project,
    productEnvironmentData,
    queryPageSize,
    totalCount,
    dispatch,
}: Props) => {
    const { selectedItems, setSelectedItems } =
        useTableSelectedProductEnvironmentData();

    function toggle(item: ResourcePermissionsType<ProductEnvironmentData>) {
        setSelectedItems((prev) => {
            if (prev.includes(item)) {
                return prev.filter((i) => i.id !== item.id);
            }
            return [...prev, item];
        });
    }

    return (
        <Stack gap={3}>
            <Stack
                gap={1}
                id='product-environment-data-mobile-view'
                className='overflow-hidden'
            >
                {productEnvironmentData.map((item) => (
                    <ProductEnvironmentDataMobileCard
                        key={item.product_item_id}
                        item={item}
                        project={project}
                        toggle={() => toggle(item)}
                        selected={selectedItems.includes(item)}
                    />
                ))}
            </Stack>
            <PageCountPicker
                className='d-flex justify-content-center'
                pageSize={queryPageSize || 0}
                onChangePageSize={(size) => {
                    dispatch &&
                        dispatch({
                            type: PAGE_SIZE_CHANGED,
                            payload: size,
                        });
                }}
                totalCount={totalCount || 0}
            />
        </Stack>
    );
};
