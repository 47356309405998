import React, { useEffect, useState } from 'react';
import { Form, Row, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Layout/Page';

import { Layout } from '../../../components/Layout/Layout';
import { Link } from 'react-router-dom';
import { WelcomeJumbotron } from '../components/WelcomeJumbotron';
import useAxios from 'axios-hooks';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router';
import { useAuth } from '../hooks/useAuth';
import { SEO } from '../../../components/SEO';

interface Props {}

export const ResetPasswordPage = (props: Props) => {
    const { clearAuthCache } = useAuth();
    const { t } = useTranslation();

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const [, post] = useAxios<any>(
        {
            url: `${process.env.REACT_APP_API_ENDPOINT}/reset-password`,
            method: 'POST',
        },
        { manual: true }
    );

    const { token } = useParams();
    const navigate = useNavigate();
    const [serverError, setServerError] = useState('');
    const [serverSuccess, setServerSuccess] = useState('');

    const onSubmit = (data: any) => {
        setServerError('');
        setServerSuccess('');
        post({ data: { ...data, token: token } })
            .then((data) => {
                setServerSuccess('Success!');
                navigate('/login');
            })
            .catch((err) => {
                setServerError(err.response.data.message);
            });
    };

    useEffect(() => {
        clearAuthCache();
    }, [clearAuthCache]);

    return (
        <Layout>
            <div id='page-content-wrapper'>
                <Page>
                    <Container>
                        <SEO title={t('Rest password')}></SEO>
                        <WelcomeJumbotron>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                                onReset={reset}
                            >
                                <Form.Group className='mb-3' controlId='email'>
                                    <Form.Label>
                                        {t('Email address')}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name='email'
                                        defaultValue=''
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Control
                                                required
                                                onChange={onChange}
                                                value={value}
                                                type='email'
                                                ref={ref}
                                                isInvalid={errors.email}
                                                placeholder={t('Enter email')}
                                            />
                                        )}
                                    />
                                </Form.Group>

                                <Form.Control.Feedback type='invalid'>
                                    {errors.email?.message}
                                </Form.Control.Feedback>

                                <Form.Group
                                    className='mb-3'
                                    controlId='password'
                                >
                                    <Form.Label>{t('Password')}</Form.Label>
                                    <Controller
                                        control={control}
                                        name='password'
                                        defaultValue=''
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Control
                                                required
                                                onChange={onChange}
                                                value={value}
                                                type='password'
                                                ref={ref}
                                                isInvalid={errors.password}
                                                placeholder={t(
                                                    'Enter password'
                                                )}
                                            />
                                        )}
                                    />
                                </Form.Group>

                                <Form.Control.Feedback type='invalid'>
                                    {errors.email?.password}
                                </Form.Control.Feedback>

                                <Form.Group
                                    className='mb-3'
                                    controlId='password_confirmation'
                                >
                                    <Form.Label>
                                        {t('Confirm Password')}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name='password_confirmation'
                                        defaultValue=''
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <Form.Control
                                                required
                                                onChange={onChange}
                                                value={value}
                                                type='password'
                                                ref={ref}
                                                isInvalid={errors.password}
                                                placeholder={t(
                                                    'Confirm password'
                                                )}
                                            />
                                        )}
                                    />
                                </Form.Group>

                                <Form.Control.Feedback type='invalid'>
                                    {errors.email?.password}
                                </Form.Control.Feedback>

                                <Row className='mb-3'>
                                    <Controller
                                        name='submit'
                                        control={control}
                                        render={({
                                            field: { ref },
                                            formState,
                                        }) => (
                                            <Button
                                                variant='primary'
                                                type='submit'
                                                disabled={
                                                    formState.isSubmitting
                                                }
                                            >
                                                {formState.isSubmitting && (
                                                    <span className='spinner-border spinner-border-sm mr-1' />
                                                )}
                                                {t('Reset')}
                                            </Button>
                                        )}
                                    />
                                    <div className='text-success'>
                                        {serverSuccess}
                                    </div>
                                    <div className='text-danger'>
                                        {serverError}
                                    </div>
                                </Row>

                                <Row className='mt-3'>
                                    <Link to={'/login'}>
                                        {t('Go back to login')}
                                    </Link>
                                </Row>
                            </Form>
                        </WelcomeJumbotron>
                    </Container>
                </Page>
            </div>
        </Layout>
    );
};
