import { IPrivateRoute } from '../types/IRouteType';
import { MePage } from '../pages/Auth/pages/MePage';

import { IRoute } from '../types/IRouteType';
import { CreatePasswordPage } from '../pages/Auth/pages/CreatePasswordPage';
import { ForgotPasswordPage } from '../pages/Auth/pages/ForgotPasswordPage';
import { LoginPage } from '../pages/Auth/pages/LoginPage';
import { ResetPasswordPage } from '../pages/Auth/pages/ResetPasswordPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

export const authPublicRoutes: IRoute[] = [
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
    },
    {
        path: '/reset-password/:token',
        element: <ResetPasswordPage />,
    },
    {
        path: '/create-password/:token',
        element: <CreatePasswordPage />,
    },
];

export const authPrivateRoutes: IPrivateRoute[] = [
    {
        name: 'My page',
        path: 'my-page',
        icon: <FontAwesomeIcon size='2x' icon={faUserCircle} />,
        element: <MePage />,
    },
];
