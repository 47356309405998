import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { generatePath, Link } from 'react-router-dom';
import IRoute from '../../types/IRouteType';

import { OrganisationSelector } from '../../pages/Organisation/components/OrganisationSelector';

import { LangSelect } from './LangSelect';
import { useTranslation } from 'react-i18next';
import { SimpleNavBar } from '../SimpleNavBar';
import { UserNav } from './UserNav';
import { Restricted } from '../../pages/Auth/components/Restricted';
import { useAuth } from '../../pages/Auth/hooks/useAuth';

interface Props {
    links: IRoute[];
}

export const HeaderNavbar = (props: Props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const { organisation } = useAuth();

    const isOrgWithProjects = (organisation?.id ?? 0) > 1;

    const { t } = useTranslation();

    return (
        <>
            <OrganisationSelector show={show} handleClose={handleClose} />
            <SimpleNavBar>
                <Navbar.Toggle
                    aria-controls='basic-navbar-nav'
                    className='me-0 me-sm-4'
                />
                <Navbar.Collapse id='basic-navbar-nav' className='me-0 me-sm-4'>
                    <Nav className='me-auto'>
                        {props.links.map((route, i) =>
                            route.children && route.children.length > 0 ? (
                                route.children
                                    .filter((route) => route.name)
                                    .map((croute, ii) => (
                                        <Nav.Link
                                            as={Link}
                                            key={i + '-' + ii}
                                            eventKey={i + '-' + ii} // Needed for SimpleNavbar>Navbar collapseOnSelect, so that clicking links collapses the menu
                                            className='fw-light text-uppercase text-nowrap'
                                            // https://reactrouter.com/web/api/generatePath
                                            to={generatePath(
                                                `${route.path}${
                                                    (!croute.index &&
                                                        croute.path) ||
                                                    ''
                                                }`
                                            )}
                                        >
                                            {croute.name && t(croute.name)}
                                        </Nav.Link>
                                    ))
                            ) : (
                                <Nav.Link
                                    as={Link}
                                    key={i}
                                    eventKey={i} // Needed for SimpleNavbar>Navbar collapseOnSelect, so that clicking links collapses the menu
                                    className='fw-light text-uppercase text-nowrap'
                                    // https://reactrouter.com/web/api/generatePath
                                    to={generatePath(`${route.path}`)}
                                >
                                    {route.name && t(route.name)}
                                </Nav.Link>
                            )
                        )}
                        {isOrgWithProjects && (
                            <Restricted toAuth>
                                <Nav.Link
                                    as={Link}
                                    eventKey={'492942941'} // Needed for SimpleNavbar>Navbar collapseOnSelect, so that clicking links collapses the menu
                                    className='fw-light text-uppercase text-nowrap'
                                    to={`/admin/projects`}
                                >
                                    {t('My projects')}
                                </Nav.Link>
                            </Restricted>
                        )}
                    </Nav>

                    <Nav className='p-0'>
                        <UserNav handleShow={handleShow}></UserNav>

                        {!process.env.NODE_ENV ||
                            (process.env.NODE_ENV === 'development' && (
                                <LangSelect></LangSelect>
                            ))}
                    </Nav>
                </Navbar.Collapse>
            </SimpleNavBar>
        </>
    );
};
