import { useEffect } from 'react';
import history from 'history/browser';

export default function useForceBlur() {
    useEffect(
        () =>
            history.listen(() => {
                if (window.document.activeElement instanceof HTMLElement) {
                    window.document.activeElement.blur();
                }
            }),
        []
    );
}
