import { Stack } from 'react-bootstrap';
import { useProjectFormState } from '../ProjectFormState';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExternalLink,
} from '@fortawesome/free-solid-svg-icons';

interface Props {}

export const ShowDocumentsForm = (props: Props) => {
    const { t } = useTranslation();

    const { ...project } = useProjectFormState();

    const labels = ['Avtal', 'Förmedling', 'Projekthandlingar', 'Rapporter', 'Ritningar', 'Ospecificerat'];
    const annotatedLabels = labels.map((label) => {
        const res: [string, any[]] = [label, project?.projectDocuments.filter((doc) => doc?.label === label)];
        return res;
    }).filter((tuple) => tuple[1].length);
    return (
        <Stack gap={2} className='mb-3'>
            <ul>
                {annotatedLabels.map((label) => {
                    return (
                        <Stack gap={2}>
                            <div className='fw-bold pt-3 fs-9F'>
                                {t(label[0])}
                            </div>
                            <ul>
                                {label[1].map((l) => (
                                    <a
                                        href={l.preview}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='text-decoration-none fw-bolder'
                                    >
                                    <li style={{listStyle: "disc"}} className='pe-5 text-break'>
                                    {l?.title || l?.name}
                                        <FontAwesomeIcon
                                            className='ms-2 text-secondary'
                                            icon={faExternalLink}
                                        />
                                    </li>
                                    </a>
                                ))}
                            </ul>
                        </Stack>
                    )}
                )}
            </ul>
        </Stack>
    );
};
