import React, { useCallback, useState } from 'react';
import IProjectType from '../../../../types/IProjectType';

export interface IProjectFormState extends IProjectType {
    projectLogos: any[] | never[];
    projectImages: any[] | never[];
    projectDocuments: any[] | never[];
}

interface IProjectFormStateContext extends IProjectFormState {
    setFormState: (
        callBack: (prev: IProjectFormState) => IProjectFormState
    ) => void;
    setProjectLogos: (callBack: (prev: any[]) => any[]) => void;
    setProjectImages: (callBack: (prev: any[]) => any[]) => void;
    setProjectDocuments: (callBack: (prev: any[]) => any[]) => void;
}

export const ProjectFormStateContext =
    React.createContext<IProjectFormStateContext>(
        {} as IProjectFormStateContext
    );

interface ProjectFormStateProps {
    initialState: IProjectFormState;
}

export const ProjectFormState: React.FC<ProjectFormStateProps> = ({
    initialState,
    children,
}) => {
    const [state, setFormState] = useState<IProjectFormState>(initialState);

    const setProjectLogos = useCallback((callBack: (prev: any[]) => any[]) => {
        setFormState((prev) => {
            return {
                ...prev,
                projectLogos: callBack(prev.projectLogos),
            };
        });
    }, []);
    const setProjectImages = useCallback((callBack: (prev: any[]) => any[]) => {
        setFormState((prev) => {
            return {
                ...prev,
                projectImages: callBack(prev.projectImages),
            };
        });
    }, []);
    const setProjectDocuments = useCallback(
        (callBack: (prev: any[]) => any[]) => {
            setFormState((prev) => {
                return {
                    ...prev,
                    projectDocuments: callBack(prev.projectDocuments),
                };
            });
        },
        []
    );

    return (
        <ProjectFormStateContext.Provider
            value={{
                ...state,
                setFormState,
                setProjectLogos,
                setProjectImages,
                setProjectDocuments,
            }}
        >
            {children}
        </ProjectFormStateContext.Provider>
    );
};

export const useProjectFormState = () =>
    React.useContext(ProjectFormStateContext);
