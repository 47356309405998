import IResourceType from '../../../types/IResourceType';
import ResourcePermissionsType from '../../../types/ResourcePermissionsType';
import { useIsAdmin } from '../hooks/useIsAdmin';
import { useIsAuth } from '../hooks/useIsAuth';
import { useIsSysAdmin } from '../hooks/useIsSysAdmin';
import { usePermission } from '../hooks/usePermission';

export type RestrictedProps = {
    to?: string;
    resource?: ResourcePermissionsType<IResourceType>;
    fallBackCompoonet?: JSX.Element | string;
    loadingComponent?: JSX.Element | string;
    toAuth?: boolean;
    toAdmin?: boolean;
    toSysAdmin?: boolean;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
export const Restricted: React.FunctionComponent<RestrictedProps> = ({
    to,
    toAuth,
    toAdmin,
    toSysAdmin,
    resource,
    fallBackCompoonet,
    loadingComponent,
    children,
}) => {
    // We "connect" to the provider thanks to the PermissionContext
    const [loadingAuth, isAuth] = useIsAuth();
    const [loading, hasPermission] = usePermission(to, resource);
    const [loadingSysAdmin, isSysAdmin] = useIsSysAdmin();
    const [loadingAdmin, isAdmin] = useIsAdmin();

    if (loading || loadingAuth || loadingAdmin || loadingSysAdmin) {
        return <>{loadingComponent}</>;
    }

    if (toSysAdmin && isSysAdmin) {
        return <>{children}</>;
    }

    if (toAdmin && isAdmin) {
        return <>{children}</>;
    }

    // If the user has that permission, render the children
    if (to && hasPermission) {
        return <>{children}</>;
    }

    if (toAuth && isAuth) {
        return <>{children}</>;
    }

    // Otherwise, render the fallback
    return <>{fallBackCompoonet}</>;
};
