import axios from 'axios';
import React, { useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import IContactPersonType from '../../../../../types/IContactPersonType';

interface Props {
    handleClose: (needRefetch: boolean) => void;
    show: boolean;
    contactPerson?: IContactPersonType;
    projectId: number;
    organisationId: number;
}

export const ContactPersonDeleteModal = ({
    contactPerson,
    handleClose,
    show,
    projectId,
    organisationId,
}: Props) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = () => {
        if (!contactPerson) return;
        setIsSubmitting(true);
        axios
            .delete(
                `/api/organisations/${organisationId}/projects/${projectId}/contacts/${contactPerson.id}`
            )
            .then(() => {
                setIsSubmitting(false);
                handleClose(true);
            })
            .catch(() => setIsSubmitting(false));
    };

    return (
        <Modal show={show} onHide={() => handleClose(false)} centered>
            <Modal.Header className='border-0 pb-0' closeButton>
                <h5 className='text-center w-100 ps-2 mt-2 text-uppercase fw-bold m-0'>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        width='16'
                        className='me-2'
                    />
                    {t('Remove contact')}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12} xl={12}>
                        <p>{t('Do you want to remove this contact person?')}</p>
                        <h6>{contactPerson?.name}</h6>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className='d-block w-100 text-uppercase'
                    variant='secondary'
                    onClick={onSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting && (
                        <span className='spinner-border spinner-border-sm me-1' />
                    )}
                    {t('Remove')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
