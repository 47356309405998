import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface ImageDataFormProps {
    selected: any;
    onBlurImageText: (e: any) => void;
    changeImageText: (data: any) => void;
    changeIsPublic: (data: any) => void;
    remove: () => void;
    hideIsPublic?: boolean;
}

export const ImageDataForm: React.FC<ImageDataFormProps> = ({
    selected,
    onBlurImageText,
    changeImageText,
    changeIsPublic,
    remove,
    hideIsPublic,
}) => {
    const { t } = useTranslation();

    const titleMaxLen = 150;
    return (
        <>
            <Form.Group className='mb-3' controlId='imageText'>
                <Form.Label>
                    {t('Image text')} {selected?.title?.length || 0} /{' '}
                    {titleMaxLen}
                </Form.Label>
                <Form.Control
                    type='text'
                    placeholder=''
                    onChange={changeImageText}
                    onBlur={onBlurImageText}
                    value={selected?.title || ''}
                    maxLength={titleMaxLen}
                    disabled={!selected}
                />
            </Form.Group>

            {hideIsPublic ? (
                <div className='py-3'></div>
            ) : (
                <Form.Group className='mb-3' controlId='public'>
                    <Form.Label>{t('Visible on Dacke Marketplace')}</Form.Label>
                    <Form.Check
                        type='switch'
                        id='custom-switch'
                        checked={!!selected?.is_public}
                        onChange={changeIsPublic}
                    />
                </Form.Group>
            )}

            <Button
                size='sm'
                onClick={remove}
                disabled={!selected}
                variant='danger'
            >
                {t('Remove')}
            </Button>
        </>
    );
};
