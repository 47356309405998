import axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IContactPersonType from '../../../../../types/IContactPersonType';

import { ReactComponent as EDITIcon } from '../../../../../assets/icones/Dacke_redigera.svg';
import { ReactComponent as ADDIcon } from '../../../../../assets/icones/Dacke_nytt.svg';

interface Props {
    handleClose: (needRefetch?: boolean) => void;
    show: boolean;
    projectId: number;
    organisationId: number;
    editContactPerson?: IContactPersonType;
}

export const ContactPersonCreateModal = ({
    handleClose,
    show,
    editContactPerson,
    projectId,
    organisationId,
}: Props) => {
    const { t } = useTranslation();

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue,
    } = useForm();

    const setFormValues = useCallback(
        (user?: IContactPersonType) => {
            setValue('name', user?.name ?? '');
            setValue('email', user?.email ?? '');
            setValue('phone', user?.phone ?? '');
            setValue('role', user?.role ?? '');
        },
        [setValue]
    );

    const onSubmit = (data: any) => {
        if (!editContactPerson) {
            // New user
            const post = axios.post<IContactPersonType>(
                `/api/organisations/${organisationId}/projects/${projectId}/contacts`,
                {
                    name: data['name'],
                    email: data['email'],
                    phone: data['phone'],
                    role: data['role'],
                }
            );
            post.then(() => {
                setFormValues();
                handleClose(true);
            });
        } else {
            // Edit existing user
            const put = axios.put<IContactPersonType>(
                `/api/organisations/${organisationId}/projects/${projectId}/contacts/${editContactPerson.id}`,
                {
                    id: editContactPerson.id,
                    name: data['name'],
                    email: data['email'],
                    phone: data['phone'],
                    role: data['role'],
                }
            );
            put.then(() => {
                handleClose(true);
            });
        }
    };

    // Reset form if editContactPerson changes
    useEffect(() => {
        setFormValues(editContactPerson);
    }, [setFormValues, editContactPerson]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                <Modal.Header className='border-0 pb-0' closeButton>
                    {editContactPerson ? (
                        <h5 className='text-center w-100 ps-2 mt-2 text-uppercase fw-bold m-0'>
                            <EDITIcon width='20' className='me-1' />
                            {t('Edit contact')}
                        </h5>
                    ) : (
                        <h5 className='text-center w-100 ps-2 mt-2 text-uppercase fw-bold m-0'>
                            <ADDIcon width='20' className='me-1' />
                            {t('New contact')}
                        </h5>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} xl={12}>
                            <Row>
                                <Col sm={12} lg={12}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='text-muted'>
                                            {t('Name')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name='name'
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <Form.Control
                                                    required
                                                    onChange={onChange}
                                                    value={value}
                                                    type='text'
                                                    ref={ref}
                                                    isInvalid={errors.name}
                                                    placeholder={t(
                                                        'Enter name'
                                                    )}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={12}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='text-muted'>
                                            {t('Email')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name='email'
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <Form.Control
                                                    required
                                                    onChange={onChange}
                                                    value={value}
                                                    type='email'
                                                    ref={ref}
                                                    isInvalid={errors.email}
                                                    placeholder={t(
                                                        'Enter email'
                                                    )}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={12}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='text-muted'>
                                            {t('Phone number')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name='phone'
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <Form.Control
                                                    onChange={onChange}
                                                    value={value}
                                                    type='text'
                                                    ref={ref}
                                                    isInvalid={errors.email}
                                                    placeholder={t(
                                                        'Enter phone number'
                                                    )}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={12}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='text-muted'>
                                            {t('Position/Role')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name='role'
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <Form.Control
                                                    required
                                                    onChange={onChange}
                                                    value={value}
                                                    type='text'
                                                    ref={ref}
                                                    isInvalid={errors.role}
                                                    placeholder={t(
                                                        'Enter role'
                                                    )}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                {editContactPerson ? (
                    <Modal.Footer>
                        <Controller
                            name='submit'
                            control={control}
                            render={({ field: { ref }, formState }) => (
                                <Button
                                    className='d-block w-100 text-uppercase'
                                    variant='secondary'
                                    type='submit'
                                    disabled={formState.isSubmitting}
                                >
                                    {formState.isSubmitting && (
                                        <span className='spinner-border spinner-border-sm mr-1' />
                                    )}
                                    {t('Save changes')}
                                </Button>
                            )}
                        />
                    </Modal.Footer>
                ) : (
                    <Modal.Footer>
                        <Controller
                            name='submit'
                            control={control}
                            render={({ field: { ref }, formState }) => (
                                <Button
                                    className='d-block w-100 text-uppercase'
                                    variant='secondary'
                                    type='submit'
                                    disabled={formState.isSubmitting}
                                >
                                    {formState.isSubmitting && (
                                        <span className='spinner-border spinner-border-sm mr-1' />
                                    )}
                                    {t('Add new')}
                                </Button>
                            )}
                        />
                    </Modal.Footer>
                )}
            </Form>
        </Modal>
    );
};
